import styled from "@emotion/styled";
import { Text } from "@medi24-da2c/web-ui";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";

const displayName = "Section.ListItemMessage";

const ListItemStyled = styled(Text.Themed)`
  list-style: disc;
  margin: 0;
`.withComponent("li");

function ListItemMessage({ style, ...props }) {
  return (
    <OptionalSection {...props}>
      <ListItemStyled style={style}>
        <OptionalMessage {...props} />
      </ListItemStyled>
    </OptionalSection>
  );
}
ListItemMessage.displayName = displayName;
ListItemMessage.propTypes = OptionalMessage.propTypes;
ListItemMessage.defaultProps = OptionalMessage.defaultProps;

export default ListItemMessage;
