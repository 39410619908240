import { useState } from "react";
import PropTypes from "prop-types";
import { OptionalText, useSearchLinks } from "components/OptionalMessage";
import isValidConsentForm from "utils/isValidConsentForm";
import makeTrackedClick from "tracking/makeTrackedClick";

import platform from "../../utils/platform";
import MessageButton from "../Button/MessageButton";
import ConsentCheckbox from "./ConsentCheckbox";

const displayName = "ConsentFormBase";

function ConsentFormBase({ chat, idList, onRedirectUrl }) {
  const withHtml = useSearchLinks();
  const [agreements, setAgreements] = useState(
    idList.reduce((choices, name) => {
      choices[name] = false;
      return choices;
    }, {})
  );

  const clickTrack = makeTrackedClick("START_CONVERSATION_CLICK");

  function handleSubmit(event) {
    event.preventDefault();

    // Error doesn't have to be shown because button is disabled anyway
    /* istanbul ignore next */
    if (!isValidConsentForm(agreements)) {
      return;
    }

    clickTrack();
    platform.redirect(onRedirectUrl(agreements));
  }

  function handleChange(event) {
    const { name } = event.target;
    const newAgreements = {
      ...agreements,
      [name]: !agreements[name],
    };

    setAgreements(newAgreements);
  }

  /*
    For reference the message Ids used in consent forms
    id="consentForm.termsOfUse"
    id="consentForm.privacyPolicy"
    id="consentForm.allowNotifications"
    id="consentForm.clearHistory"
   */

  return (
    <form data-testid={`${displayName}-${chat}`} onSubmit={handleSubmit}>
      {idList.map((name) => (
        <ConsentCheckbox
          key={name}
          name={name}
          value={!!agreements[name]}
          values={withHtml}
          onChange={handleChange}
        />
      ))}

      <OptionalText id="consentForm.startConversationInfo" />
      <MessageButton
        id="consentForm.submitButton"
        type="submit"
        disabled={!isValidConsentForm(agreements)}
        data-testid="start-conversation-button"
      />
    </form>
  );
}

ConsentFormBase.displayName = displayName;

ConsentFormBase.propTypes = {
  chat: PropTypes.string.isRequired,
  idList: PropTypes.arrayOf(PropTypes.string.isRequired),
  // Function to construct the redirect URL based on selected checkboxes
  onRedirectUrl: PropTypes.func.isRequired,
};

export default ConsentFormBase;
