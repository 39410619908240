import baseConfig from "../medi24/config";
import itInherited from "./translations/it.inherit.json";
import enInherited from "./translations/en.inherit.json";

// inherit-partner.sh medi24 subpartner azp-italy 19 Italy digitalhealthxtra azpit medi24 azpit AZPIT it en

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHXTRA_AZPIT_19",
    partnerGroup: "allianz",
    partnerCountry: "Italy",
    partnerCode: "azpit",
    subPartnerCode: "AZPIT",
  },
  features: {
    ...baseConfig.features,
    DHA: true,
    MyDoc: false,
    QuickSummary: null,
  },
  languages: "it en".split(" "),
  messages: {
    it: itInherited,
    en: enInherited,
  },
};

export default config;
