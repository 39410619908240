const colorBorder = "#fff";
const colorBackground = "#7360f2";

// <g transform="translate(-8,-8)"><rect width="650" height="680" fill="black" /></g>
// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function ViberLogo() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-8 -8 680 680"
      role="img"
      aria-labelledby="icon-viber-logo-title"
    >
      <title id="icon-viber-logo-title">Viber</title>
      <defs id="icon-viber-logo-defs199">
        <style id="icon-viber-logo-style197">
          {`.cls-vbr-1{stroke-width:2;stroke:${colorBackground};fill:${colorBorder}}.cls-vbr-3{fill:none;stroke:${colorBorder};stroke-linecap:round;stroke-linejoin:round;stroke-width:16.86px}`}
        </style>
      </defs>
      <path
        id="icon-viber-logo-path-caption-border"
        d="M560.651 64.998c-16.56-15.28-83.48-63.86-232.54-64.52 0 0-175.78-10.6-261.47 68-47.7 47.71-64.48 117.52-66.25 204.07-1.77 86.55-4.06 248.75 152.29 292.73h.15l-.1 67.11s-1 27.17 16.89 32.71c21.64 6.72 34.34-13.93 55-36.19 11.34-12.22 27-30.17 38.8-43.89 106.93 9 189.17-11.57 198.51-14.61 21.59-7 143.76-22.65 163.63-184.84 20.51-167.17-9.92-272.91-64.91-320.57z"
        className="cls-vbr-1"
      />
      <path
        id="icon-viber-logo-caption-bubble"
        d="M525.881 98.768c-14-12.72-74.43-50.69-200.52-51.24 0 0-149.31-9.81-221.79 55.84-40.34 39.83-53.93 99.06-55.42 171.34-1.49 72.28-9.21 210.36 123 247.09 0 0-.52 102.51-.58 111.52 0 6.3 1 10.61 4.6 11.5 2.59.63 6.47-.71 9.77-4 21.14-21.23 88.82-102.88 88.82-102.88 90.81 5.93 163.06-12 170.83-14.54 18.32-5.89 117.41-14.4 134.18-149.86 17.33-139.63-6.37-234.94-52.89-274.77z"
        fill={colorBackground}
      />
      <path
        id="icon-viber-logo-path-phone-wave1"
        d="M389.471 268.768q-2.46-49.59-50.38-52.09"
        className="cls-vbr-3"
      />
      <path
        id="icon-viber-logo-path-phone-wave2"
        d="M432.721 283.268q1-46.2-27.37-77.2c-19-20.74-45.3-32.16-79.05-34.63"
        className="cls-vbr-3"
      />
      <path
        id="icon-viber-logo-path-phone-wave3"
        d="M477.001 300.588q-.61-80.17-47.91-126.28t-117.65-46.6"
        className="cls-vbr-3"
      />
      <path
        id="icon-viber-logo-path-phone"
        d="M340.761 381.678s11.85 1 18.23-6.86l12.44-15.65c6-7.76 20.48-12.71 34.66-4.81a366.67 366.67 0 0130.91 19.74c9.41 6.92 28.68 23 28.74 23 9.18 7.75 11.3 19.13 5.05 31.13 0 .07-.05.19-.05.25a129.81 129.81 0 01-25.89 31.88c-.12.06-.12.12-.23.18q-13.38 11.18-26.29 12.71a17.39 17.39 0 01-3.84.24 35 35 0 01-11.18-1.72l-.28-.41c-13.26-3.74-35.4-13.1-72.27-33.44a430.39 430.39 0 01-60.72-40.11 318.31 318.31 0 01-27.31-24.22l-.92-.92-.92-.92-.92-.92c-.31-.3-.61-.61-.92-.92a318.31 318.31 0 01-24.22-27.31 430.83 430.83 0 01-40.11-60.71c-20.34-36.88-29.7-59-33.44-72.28l-.41-.28a35 35 0 01-1.71-11.18 16.87 16.87 0 01.23-3.84q1.61-12.89 12.73-26.31c.06-.11.12-.11.18-.23a129.53 129.53 0 0131.88-25.88c.06 0 .18-.06.25-.06 12-6.25 23.38-4.13 31.12 5 .06.06 16.11 19.33 23 28.74a366.67 366.67 0 0119.74 30.94c7.9 14.17 2.95 28.68-4.81 34.66l-15.65 12.44c-7.9 6.38-6.86 18.23-6.86 18.23s23.18 87.73 109.79 109.84z"
        className="cls-vbr-1"
      />
    </svg>
  );
}

export default ViberLogo;
