import PropTypes from "prop-types";
import EligibilityModalBase from "../Modal";
import InitialStep from "../InitialStep";
import GenerateCodeStep from "./GenerateCodeStep";
import EnterCodeStep from "./EnterCodeStep";
import SuccessStep from "./SuccessStep";
import ErrorStep from "./ErrorStep";

const displayName = "MyDocModal";

const titleId = "chooseMessengerPage.generateActivationCodeModal.title";

function MyDocModal({ isOpen, onClose, ...props }) {
  return (
    <EligibilityModalBase
      titleId={props.titleId || titleId}
      trackId="TELECONSULT_ELIGIBILITY_DIALOG"
      idInfix="MyDoc"
      isOpen={isOpen}
      onClose={onClose}
      initialStep={InitialStep}
      enterCodeStep={EnterCodeStep}
      generateCodeStep={GenerateCodeStep}
      successStep={SuccessStep}
      errorStep={ErrorStep}
    />
  );
}

MyDocModal.displayName = displayName;

MyDocModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MyDocModal;
