// import * as codeType from "constants/generateCodeTypes";
import baseConfig from "../mc-asia-mydoc-only/config";
import idInherited from "./translations/id.inherit.json";
import idOverrides from "./translations/id.json";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh mc-asia-mydoc-only subpartner mc-core-id 10 Indonesia digitalhealthxtra id NOBOT halodoc MCCOREID id en
// DEVTEST Test Indonesia with Mastercard: 2020202021
const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHXTRA_ID_10",
    partnerCountry: "Indonesia",
    partnerCode: "halodoc",
    subPartnerCode: "MCCOREID",
  },
  languages: "id en".split(" "),
  messages: {
    id: { ...idInherited, ...idOverrides },
    en: { ...enInherited, ...enOverrides },
  },
};

// window.console.warn('CONFIG', config.general.partnerOverride, config.messages.id['global.teleName'], config.messages.id['landingPage.myDoc.overview'])
export default config;
