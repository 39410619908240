import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { borderWidthThin } from "@medi24-da2c/web-ui/design-tokens";

const displayName = "LandingPage.GeneralInfo";

const GeneralInfoStyled = styled.div`
  width: 100%;
  background-color: ${(themedProps) =>
    themedProps.theme.landingPageGeneralInfo.bgrColor};
  border-top: ${borderWidthThin} solid
    ${(themedProps) => themedProps.theme.general.borderColor};
`;

function GeneralInfo(props) {
  const theme = useTheme();
  return <GeneralInfoStyled theme={theme} {...props} />;
}
GeneralInfo.displayName = displayName;

export default GeneralInfo;
