import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import makeTrackedClick from "tracking/makeTrackedClick";
import platform from "../../utils/platform";
import EnterCodeStepBase from "../EnterCodeStep";
import useActivationCode from "./useActivationCode";

const displayName = "MyDocModal.EnterCodeStep";

function EnterCodeStep({ idInfix, setStep, setErrorCode }) {
  const [loading, setLoading] = useState(false);
  const { willGetInvitationUrl } = useActivationCode({
    loading,
    onSetLoading: setLoading,
    setStep,
    setInviteUrl: platform.redirect,
    setErrorCode,
  });

  const clickTrack = makeTrackedClick("START_TELECONSULT_CLICK");

  const handleContinueButtonClick = useCallback(
    function (activationCode) {
      clickTrack();
      // window.console.warn(`${displayName}.handleContinueButtonClick ${activationCode}`);
      willGetInvitationUrl(activationCode);
    },
    [willGetInvitationUrl, clickTrack]
  );

  // window.console.warn(`${displayName} render`);
  return (
    <EnterCodeStepBase
      idInfix={idInfix || "MyDoc"}
      loading={loading}
      onSetLoading={setLoading}
      onContinueButtonClick={handleContinueButtonClick}
    />
  );
}
EnterCodeStep.displayName = displayName;

EnterCodeStep.propTypes = {
  idInfix: PropTypes.string, // message infix to insert into messages for different flavour or messages
  setStep: PropTypes.func.isRequired,
  setErrorCode: PropTypes.func.isRequired,
};

export default EnterCodeStep;
