import baseConfig from "../medi24/config";
import esInherited from "./translations/es.inherit.json";
import enInherited from "./translations/en.inherit.json";
import esOverrides from "./translations/es.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh medi24 subpartner azp-spain 17 Spain digitalhealthxtra azpes medi24 azpes AZPES es en

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHXTRA_AZPES_17",
    partnerGroup: "allianz",
    partnerCountry: "Spain",
    partnerCode: "azpes",
    subPartnerCode: "AZPES",
  },
  languages: "es en".split(" "),
  messages: {
    es: { ...esInherited, ...esOverrides },
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
