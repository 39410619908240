import baseConfig from "../medi24/config";
import jaInherited from "./translations/ja.inherit.json";
import enInherited from "./translations/en.inherit.json";
import jaOverrides from "./translations/ja.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh medi24 subpartner azp-japan 24 Japan digitalhealthxtra azpjp medi24 azpjp AZPJP ja en

// const contactEmail = "yoshiharu.kumagai@allianz.com";

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHXTRA_AZPJP_24",
    partnerGroup: "allianz",
    partnerCountry: "Japan",
    partnerCode: "azpjp",
    subPartnerCode: "AZPJP",
  },

  features: {
    ...baseConfig.features,
    showLineApp: true,
    hideTelegram: true,
    noMedicalConsultation: true,
  },

  languages: "ja en".split(" "),
  messages: {
    ja: { ...jaInherited, ...jaOverrides },
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
