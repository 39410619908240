// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function WhatsAppLogo() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      role="img"
      aria-labelledby="icon-whatsapp-logo-title"
    >
      <title id="icon-whatsapp-logo-title">WhatsApp</title>
      <path
        fill="#ddd"
        d="M3.53 35.162a23.567 23.567 0 01-3.005-11.53C.53 10.601 11.179 0 24.263 0c6.346.003 12.309 2.464 16.791 6.931 4.481 4.468 6.948 10.406 6.946 16.721-.005 13.032-10.654 23.634-23.737 23.634a23.885 23.885 0 01-11.077-2.729L0 48l3.53-12.838z"
      />
      <linearGradient
        id="wl-linear-gradient-1"
        gradientUnits="userSpaceOnUse"
        x1="24.166"
        y1="45.877"
        x2="24.166"
        y2="1.805"
      >
        <stop offset="0" stopColor="#20b038" />
        <stop offset="1" stopColor="#60d66a" />
      </linearGradient>
      <path
        fill="url(#wl-linear-gradient-1)"
        d="M2.139 45.877L5.251 34.56a21.731 21.731 0 01-2.928-10.918c.005-12.041 9.845-21.837 21.935-21.837 5.868.003 11.375 2.277 15.516 6.405 4.142 4.128 6.421 9.615 6.419 15.45-.005 12.041-9.845 21.839-21.934 21.839-.001 0 .001 0 0 0h-.01a21.992 21.992 0 01-10.481-2.658L2.139 45.877z"
      />
      <linearGradient
        id="wl-linear-gradient-2"
        gradientUnits="userSpaceOnUse"
        x1="24.166"
        y1="46.667"
        x2="24.166"
        y2="1.014"
      >
        <stop offset="0" stopColor="#f9f9f9" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <path
        fill="url(#wl-linear-gradient-2)"
        d="M1.349 46.667l3.223-11.722a22.519 22.519 0 01-3.033-11.31C1.545 11.162 11.737 1.014 24.261 1.014c6.078.003 11.782 2.359 16.072 6.634 4.29 4.276 6.651 9.96 6.649 16.005-.005 12.472-10.199 22.622-22.721 22.622-.001 0 .001 0 0 0h-.01a22.772 22.772 0 01-10.858-2.754L1.349 46.667zm12.603-7.241l.69.407a18.916 18.916 0 009.612 2.621h.007c10.409 0 18.88-8.435 18.885-18.803a18.652 18.652 0 00-5.526-13.302 18.8 18.8 0 00-13.351-5.514c-10.417 0-18.888 8.434-18.892 18.801a18.704 18.704 0 002.887 10.006l.45.711-1.908 6.938 7.146-1.865z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.583 14.178c-.425-.942-.873-.96-1.278-.977-.331-.014-.71-.013-1.088-.013-.379 0-.994.142-1.514.707-.52.566-1.987 1.934-1.987 4.715 0 2.782 2.035 5.47 2.318 5.848.284.377 3.928 6.268 9.698 8.535 4.796 1.884 5.772 1.509 6.813 1.415s3.359-1.367 3.832-2.688c.473-1.32.473-2.452.331-2.688-.142-.236-.52-.377-1.088-.66-.568-.283-3.359-1.651-3.879-1.839s-.899-.283-1.278.283c-.379.565-1.466 1.839-1.797 2.216-.331.378-.662.425-1.23.142-.568-.284-2.396-.88-4.565-2.806-1.688-1.499-2.827-3.349-3.158-3.915-.331-.565-.035-.872.249-1.154.255-.253.568-.66.852-.99.283-.33.378-.566.567-.943.189-.378.095-.708-.047-.99-.143-.283-1.247-3.08-1.751-4.198z"
      />
    </svg>
  );
}

export default WhatsAppLogo;
