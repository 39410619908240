import { useState } from "react";
import { OptionalMessage, OptionalSection } from "components/OptionalMessage";
import Link from "./Link";
import Modal from "./Modal";

const displayName = "PlatformInfo";

const id = "chooseMessengerPage.whichPlatformButton";

function PlatformInfo() {
  const [isOpen, setModalVisibility] = useState(false);

  return (
    <OptionalSection id={id}>
      <Link
        as="button"
        data-testid="platform-button"
        onClick={() => setModalVisibility(true)}
      >
        <OptionalMessage id={id} />
      </Link>
      <Modal isOpen={isOpen} onClose={() => setModalVisibility(false)} />
    </OptionalSection>
  );
}
PlatformInfo.displayName = displayName;

export default PlatformInfo;
