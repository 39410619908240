import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import {
  fontMedium,
  fontLarge,
  fontWeightBold,
  spacingMedium,
} from "@medi24-da2c/web-ui/design-tokens";
import { PHONE_LARGE_MEDIA_MAX as PHONE_MEDIA } from "@medi24-da2c/web-ui/media";

const displayName = "NextStepStyledButton";

const StyledButtonBase = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0 ${spacingMedium};
  outline: 0;
  height: 8rem;
  width: 100%;
  margin: 0;
  border-radius: 0;
  background-color: ${(themedProps) =>
    themedProps.isFocusVisible
      ? themedProps.theme.general.a11yColor
      : "transparent"};
  font-size: ${fontLarge};
  color: ${(themedProps) => themedProps.theme.general.textColor};
  text-align: left;
  text-transform: none;
  font-weight: ${fontWeightBold};
  font-family: ${(themedProps) => themedProps.theme.fontFamily};
  line-height: 1.2;
  cursor: pointer;

  @media ${PHONE_MEDIA} {
    font-size: ${(themedProps) => {
      return themedProps.withSmallFont ? fontMedium : fontLarge;
    }};
  }

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

function StyledButton(props) {
  const theme = useTheme();
  return <StyledButtonBase theme={theme} {...props} />;
}
StyledButton.displayName = displayName;
StyledButton.propTypes = {
  isFocusVisible: PropTypes.bool,
  withSmallFont: PropTypes.bool, // Use a smaller font size for PHONE_LARGE or smaller
};

export default StyledButton;
