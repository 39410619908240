import PropTypes from "prop-types";
import ActivationCodeForm from "components/ActivationCodeForm";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import CustomContent from "./CustomContent";

const displayName = "EligibilityCheck.EnterCodeStep";

function EnterCodeStep({
  idInfix,
  loading,
  onSetLoading,
  onContinueButtonClick,
}) {
  useTrackPageEffect("ELIGIBILITY_ENTER_CODE_CONTENT");

  return (
    <CustomContent>
      <ActivationCodeForm
        idInfix={idInfix}
        loading={loading}
        onSetLoading={onSetLoading}
        onSubmit={onContinueButtonClick}
      />
    </CustomContent>
  );
}

EnterCodeStep.displayName = displayName;

EnterCodeStep.propTypes = {
  idInfix: PropTypes.string,
  loading: PropTypes.bool,
  onSetLoading: PropTypes.func.isRequired,
  onContinueButtonClick: PropTypes.func.isRequired,
};

export default EnterCodeStep;
