import PropTypes from "prop-types";

import { ErrorBoundary } from "@medi24-da2c/web-ui";
import Carousel from "../Carousel";
import Wrapper from "./Wrapper";
import Content from "./Content";

const displayName = "Overview";

function Overview({ children, ...props }) {
  return (
    <ErrorBoundary>
      <Wrapper {...props}>
        <Content>
          <Carousel>{children}</Carousel>
        </Content>
      </Wrapper>
    </ErrorBoundary>
  );
}

Overview.displayName = displayName;

Overview.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Overview;
