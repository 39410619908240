import baseConfig from "../mc-asia/config";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh mc-asia subpartner mc-mena-kw 18 Kuwait digitalhealthxtra kw allianz mydoc MCMENAKW en

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHXTRA_KW_18",
    partnerCountry: "Kuwait",
    subPartnerCode: "MCMENAKW",
  },
  features: {
    ...baseConfig.features,
    DHA: true,
    MyDoc: false,
    QuickSummary: true,
  },
  languages: "en".split(" "),
  messages: {
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
