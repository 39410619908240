import styled from "@emotion/styled";

import Fields from "./Fields";

// Form element wich in combintion with Inputs wrapper
// Forces form submit button / bottom controls to stick to the bottom of container
// Given it has "flex-direction: column" style specified
const BasicForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

BasicForm.Fields = Fields;

export default BasicForm;
