import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useOptionalMessage } from "hooks/useOptionalMessage";

const displayName = "CookiesSettingsModal.Headline";

const HeadlineStyled = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

function Headline({ toolTipId, toolTip, values, children, ...props }) {
  const toolTipText = useOptionalMessage(toolTipId, toolTip, values) || toolTip;
  return (
    <HeadlineStyled title={toolTipText} {...props}>
      {children}
    </HeadlineStyled>
  );
}
Headline.displayName = displayName;
Headline.propTypes = {
  toolTipId: PropTypes.string,
  toolTip: PropTypes.string,
  values: PropTypes.object,
};

export default Headline;
