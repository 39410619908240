import { useState } from "react";
import PropTypes from "prop-types";

import {
  INITIAL_STEP,
  ENTER_CODE_STEP,
  GENERATE_CODE_STEP,
  SUCCESS_STEP,
  ERROR_STEP,
} from "constants/steps";

const displayName = "StepsManager";

function StepsManager({
  idInfix,
  initialStep: InitialStep,
  enterCodeStep: EnterCodeStep,
  generateCodeStep: GenerateCodeStep,
  successStep: SuccessStep,
  errorStep: ErrorStep,
}) {
  const [step, setStep] = useState(INITIAL_STEP);
  const [activationCodeDetails, setActivationCodeDetails] = useState(null);
  const [errorCode, setErrorCode] = useState(null);

  switch (step) {
    case INITIAL_STEP:
      return <InitialStep idInfix={idInfix} setStep={setStep} />;
    case ENTER_CODE_STEP:
      return (
        <EnterCodeStep
          idInfix={idInfix}
          setErrorCode={setErrorCode}
          setStep={setStep}
        />
      );
    case GENERATE_CODE_STEP:
      return (
        <GenerateCodeStep
          idInfix={idInfix}
          setActivationCodeDetails={setActivationCodeDetails}
          setErrorCode={setErrorCode}
          setStep={setStep}
        />
      );
    case SUCCESS_STEP:
      return (
        <SuccessStep
          idInfix={idInfix}
          activationCodeDetails={activationCodeDetails}
          setErrorCode={setErrorCode}
          setStep={setStep}
        />
      );
    case ERROR_STEP:
      return <ErrorStep errorCode={errorCode} setStep={setStep} />;
    default:
      throw new Error(
        "Eligibility check Modal step not recognized. Please use one of predefined steps."
      );
  }
}

StepsManager.displayName = displayName;

StepsManager.propTypes = {
  idInfix: PropTypes.string, // message infix to insert into messages for different flavour or messages
  initialStep: PropTypes.func.isRequired,
  enterCodeStep: PropTypes.func.isRequired,
  generateCodeStep: PropTypes.func.isRequired,
  successStep: PropTypes.func.isRequired,
  errorStep: PropTypes.func.isRequired,
};

export default StepsManager;
