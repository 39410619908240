import baseConfig from "../mc-asia-mydoc-only/config";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh mc-asia-mydoc-only subpartner mc-core-ph 6 Philippines digitalhealthxtra ph allianz mydoc MCCOREPH en

// DEVTEST Test Phillipines with Mastercard: 2020202022
const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHXTRA_PH_6",
    partnerCountry: "Philippines",
    subPartnerCode: "MCCOREPH",
  },
  languages: "en".split(" "),
  messages: {
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
