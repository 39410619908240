import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Headline as HeadlineBase } from "@medi24-da2c/web-ui";
import { OptionalMessage } from "../../components/OptionalMessage";

const displayName = "LandingPage.Headline";

const HeadlineStyled = styled(HeadlineBase.Themed)`
  color: ${(themedProps) =>
    themedProps.theme.landingPageGeneralInfo.headlineColor};
`;

function Headline({ id, ...props }) {
  const theme = useTheme();
  return (
    <HeadlineStyled theme={theme} {...props}>
      <OptionalMessage id={id} />
    </HeadlineStyled>
  );
}
Headline.displayName = displayName;

export default Headline;
