import PropTypes from "prop-types";
import useNavigate from "hooks/useNavigate";
import SimpleHeadline from "components/SimpleHeadline";
import SimpleMessageButton from "components/SimpleMessageButton";
import Modal from "components/Modal";
import ErrorMessage from "./ErrorMessage";

const displayName = "ErrorCode";

function ErrorCode({ errorCode }) {
  const navigate = useNavigate();

  function handleSubmit(activationCode) {
    navigate.toLandingPage();
  }

  return (
    <>
      <SimpleHeadline
        id="chooseMessengerPage.generateActivationCodeModal.errorStep.headline"
        defaultMessage="Oops"
      />
      <Modal.Content>
        <ErrorMessage errorCode={errorCode} />
      </Modal.Content>
      <Modal.Controls>
        <SimpleMessageButton
          id="chooseMessengerPage.generateActivationCodeModal.errorStep.retryButton"
          data-testid="retry-button"
          onClick={handleSubmit}
        />
      </Modal.Controls>
    </>
  );
}

ErrorCode.displayName = displayName;

ErrorCode.propTypes = {
  errorCode: PropTypes.string.isRequired,
};

export default ErrorCode;
