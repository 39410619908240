import * as codeType from "constants/generateCodeTypes";
import baseConfig from "../medi24-allianz/config";
import deInherited from "./translations/de.inherit.json";
import enInherited from "./translations/en.inherit.json";
import deOverride from "./translations/de.json";
import enOverride from "./translations/en.json";

// The origin of BDAE GROUP was the foundation of the association Bund der Auslands-Erwerbstätigen (BDAE) e.V. (“Association of People Working Abroad”) in 1995.
// https://www.bdae.com/en/bdae-gruppe-en/about-bdae-group

// inherit-partner.sh medi24-allianz subpartner bdae 16 Germany digitalhealth bdae allianz BDAE NONE de en

const contactEmail = "vertragsinfo@bdae.com";

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTH_BDAE_16",
    partnerGroup: "bdae",
    partnerCountry: "Germany",
    partnerCode: "BDAE",
    subPartnerCode: "",
    generalEmail: contactEmail,
  },
  features: {
    ...baseConfig.features,
    generateStepType: codeType.POLICY_NUMBER,
    noMedicalConsultation: true,
    noRecording: true,
    noProvisionOfServices: true,
    policyNumberRegex: /^P[0-9]+\.[0-9]+$/g,
    helplineEmail: contactEmail,
  },
  languages: "de en".split(" "),
  messages: {
    de: { ...deInherited, ...deOverride },
    en: { ...enInherited, ...enOverride },
  },
};

export default config;
