import { getInstance } from "partnerConfigs/singleton";

function useQuickSummary() {
  const { config } = getInstance();
  if (config.features && null === config.features.QuickSummary) {
    return null;
  }
  return !!(config.features && config.features.QuickSummary);
}

export default useQuickSummary;
