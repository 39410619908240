import { getInstance } from "partnerConfigs/singleton";

function useDisableMyDocButton() {
  const { config } = getInstance();
  let hasDHA = false;
  try {
    hasDHA = config.features.DHA === "disabled";
  } catch (ignore) {}

  return hasDHA;
}

export default useDisableMyDocButton;
