import baseConfig from "../mastercard/config";
import enInherited from "./translations/en.inherit.json";
import msInherited from "./translations/ms.inherit.json";
import thInherited from "./translations/th.inherit.json";
import viInherited from "./translations/vi.inherit.json";
import idInherited from "./translations/id.inherit.json";
import zhHantInherited from "./translations/zh-Hant.inherit.json";
// NEWLANGUAGE ADD ABOVE import all languages for Mastercard Asia

// inherit-partner.sh mastercard subpartner mc-asia 9999 None MASTERCARD-ASIA-PARTNER INHERIT allianz mydoc NONE en ms th vi zh-Hant

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "MASTERCARD_ASIA_PARTNER_INHERIT",
  },
  messages: {
    en: enInherited,
    ms: msInherited,
    th: thInherited,
    vi: viInherited,
    id: idInherited,
    "zh-Hant": zhHantInherited,
    // NEWLANGUAGE ADD ABOVE access to all languages for Mastercard Asia
  },
};

config.languages = Object.keys(config.messages).sort();

export default config;
