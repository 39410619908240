import styled from "@emotion/styled";
import { OptionalSection } from "components/OptionalMessage";
import ChatBubble from "./ChatBubble";
import ChatHandle from "./ChatHandle";

const ChatBubblesWrapper = styled.div`
  position: relative;
  width: 26rem;
  margin-left: 3rem;
`;

function ChatBubbles() {
  return (
    <OptionalSection id="landingPage.emma.chatBubbleIntro">
      <ChatBubblesWrapper>
        <ChatBubble id="landingPage.emma.chatBubbleIntro" />
        <ChatBubble id="landingPage.emma.chatBubbleQuestion" />
        <ChatHandle />
      </ChatBubblesWrapper>
    </OptionalSection>
  );
}

export default ChatBubbles;
