import { OptionalTitle } from "components/OptionalMessage";

// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function ManPointingAtApps() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 200"
      role="img"
      aria-labelledby="icon-man-pointing-at-apps-title"
    >
      <OptionalTitle
        id="image.manPointingAtApps"
        titleId="icon-man-pointing-at-apps-title"
      />
      <path
        fill="#008390"
        d="M303.347 200s-11.49-76.616-110.457-72.786C111.408 130.367 97.629 200 97.629 200h205.718z"
      />
      <path
        fill="#f6aa98"
        d="M225.322 61.815c1.085-13.705-1.551-41.902-28.744-43.083-27.655-1.202-41.531 2.597-42.746 26.898-1.124 22.524 4.154 51.372 14.173 64.336l.65 20.379c.234 6.415 1.273 13.578 12.083 16.791 10.809 3.213 41.192-6.171 40.775-13.143-.409-6.848-2.03-32.075-2.734-43.013a54.496 54.496 0 001.88-4.609c4.372-1.586 7.298-9.362 8.129-13.312.94-4.454-.473-8.554-3.466-11.244z"
      />
      <path
        fill="#ea8f81"
        d="M181.057 116.699c-4.949.712-9.327-1.916-13.051-6.734l.564 17.674 28.325-15.274c-4.504 1.96-9.731 3.454-15.838 4.334z"
      />
      <path
        fill="#19262b"
        d="M164.11 70.228c-.321-1.5.373-2.999 1.567-3.341 1.189-.34 2.407.596 2.749 2.089.32 1.481-.392 2.982-1.578 3.336-1.191.354-2.402-.589-2.738-2.084zm25.789.634c1.22-.156 2.171-1.515 2.092-3.035-.095-1.54-1.137-2.644-2.377-2.507-1.226.15-2.165 1.516-2.093 3.043.091 1.531 1.148 2.641 2.378 2.499zM213.29 68.955c1.817 3.168 5.544-3.852 7.113-5.163 3.34-2.8 6.243-1.924 7.871 2.199.285.875 6.219-13.21 5.41-19.869-.799-6.667-.63-13.816-8.88-18.377-3.057-8.615-8.291-18.39-24.496-19.554-16.215-1.165-18.89 6.965-27.588 6.822-37.156-.613-23.839 37.941-19.024 38.655 23.311 3.455 43.505-12.275 45.018-11.767 2.987 1.005 2.662 14.536 11.331 15.855 1.618.246 1.079 5.961 3.034 10.753.069.162.138.317.211.446z"
      />
      <path
        fill="#ea8f81"
        d="M174.826 90.841a1.184 1.184 0 01-.954-1.203l.754-19.99a1.178 1.178 0 011.22-1.128c.68.019 1.163.566 1.146 1.219l-.764 19.99a1.16 1.16 0 01-1.219 1.127.34.34 0 01-.183-.015zm12.044 2.643c-3.117 2.688-6.953 3.13-6.989 3.132l.007.04c-.882.131-1.85.194-2.858.113-.65-.033-1.238.45-1.284 1.118a1.209 1.209 0 001.108 1.286c.871.062 1.719.045 2.516-.035 3.585-.344 6.202-1.828 6.561-2.106.165-.089.315-.163.479-.265a14.362 14.362 0 002.031-1.475 1.204 1.204 0 00.121-1.696 1.212 1.212 0 00-1.692-.112z"
      />
      <path
        fill="#027885"
        d="M229.133 200h24.773s-8.231-28.867-9.586-28.975c-8.945-.845 1.175 17.18-15.187 28.975z"
      />
      <path
        fill="#f6aa98"
        d="M116.15 200s-6.699-29.903-7.141-35.593c-.366-4.7.402-21.143.68-26.719l.024.02c.018-.381.023-.734.03-1.09l.042-.805c-.009-.038-.024-.076-.034-.114a31.306 31.306 0 00-.745-6.782c-.338-1.599-7.747-14.009-12.612-14.791-2.889-.466-5.461 1.614-7.079 3.382-6.388-7.062-20.857-23.92-25.17-24.641-2.198-.367-4.89 1.881-4.307 4.693.464 2.232 11.848 18.364 16.524 24.924-6.945 6.835-12.695 18.265-10.329 24.731 4.578 12.51 16.611 21.533 17.746 23.999 1.863 4.05-1.045 25.514-1.017 28.787h33.388z"
      />
      <path
        fill="#ea8f81"
        d="M76.362 122.483c-4.676-6.56-16.06-22.692-16.524-24.924 0 0 12.672 16.774 18.822 22.873a30.737 30.737 0 00-2.298 2.051zm8.234 33.307c7.065.003 17.055-15.515 17.055-15.515s-9.908 10.558-15.755 11.289c-5.655.707-14.479-9.22-15.675-7.066-1.195 2.154 8.73 11.289 14.375 11.292zm21.004-25.059c-.846-4.448-14.45-12.242-14.45-12.242s14.783 10.168 13.894 17.287c-.001 0 1.308-1.083.556-5.045z"
      />
      <g>
        <linearGradient
          id="mpaa-linear-gradient-1"
          gradientUnits="userSpaceOnUse"
          x1="-372.95"
          y1="529.953"
          x2="-373.001"
          y2="529.833"
          gradientTransform="matrix(240 0 0 -240 89616.008 127196.422)"
        >
          <stop offset="0" stopColor="#37aee2" />
          <stop offset="1" stopColor="#1e96c8" />
        </linearGradient>
        <circle
          fill="url(#mpaa-linear-gradient-1)"
          cx="100.941"
          cy="24.545"
          r="24.545"
        />
        <path
          fill="#c8daea"
          d="M96.441 35.795c-.795 0-.66-.3-.934-1.057l-2.338-7.696 18-10.679-14.728 19.432z"
        />
        <path
          fill="#a9c9dd"
          d="M96.441 35.795c.614 0 .885-.281 1.227-.614l3.273-3.182-4.082-2.462-.418 6.258z"
        />
        <linearGradient
          id="mpaa-linear-gradient-2"
          gradientUnits="userSpaceOnUse"
          x1="-371.677"
          y1="529.237"
          x2="-371.638"
          y2="529.162"
          gradientTransform="matrix(130.4927 0 0 -109.4498 48603.813 57950.027)"
        >
          <stop offset="0" stopColor="#eff7fc" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <path
          fill="url(#mpaa-linear-gradient-2)"
          d="M96.858 29.538l9.892 7.308c1.129.623 1.943.3 2.225-1.048l4.026-18.974c.412-1.653-.63-2.403-1.71-1.912l-23.643 9.117c-1.614.647-1.604 1.548-.294 1.949l6.067 1.894 14.047-8.862c.663-.402 1.272-.186.772.257L96.858 29.538z"
        />
      </g>
      <g>
        <path
          fill="#dddddd"
          d="M8.878 90.12a25.784 25.784 0 01-3.263-12.571C5.62 63.34 17.184 51.781 31.392 51.781c6.891.003 13.367 2.687 18.234 7.557 4.866 4.871 7.545 11.346 7.543 18.232-.006 14.209-11.569 25.769-25.777 25.769a25.849 25.849 0 01-12.028-2.976l-14.319 3.755L8.878 90.12z"
        />
        <linearGradient
          id="mpaa-linear-gradient-3"
          gradientUnits="userSpaceOnUse"
          x1="31.287"
          y1="102.045"
          x2="31.287"
          y2="53.507"
        >
          <stop offset="0" stopColor="#20b038" />
          <stop offset="1" stopColor="#60d66a" />
        </linearGradient>
        <path
          fill="url(#mpaa-linear-gradient-3)"
          d="M7.127 102.045l3.413-12.464a24.009 24.009 0 01-3.212-12.024c.006-13.262 10.799-24.051 24.06-24.051 6.436.003 12.477 2.508 17.019 7.054a23.903 23.903 0 017.041 17.016c-.006 13.262-10.799 24.052-24.059 24.052-.001 0 .001 0 0 0h-.011a24.05 24.05 0 01-11.497-2.927l-12.754 3.344z"
        />
        <linearGradient
          id="mpaa-linear-gradient-4"
          gradientUnits="userSpaceOnUse"
          x1="31.287"
          y1="102.915"
          x2="31.287"
          y2="52.636"
        >
          <stop offset="0" stopColor="#f9f9f9" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <path
          fill="url(#mpaa-linear-gradient-4)"
          d="M6.26 102.915l3.536-12.91a24.873 24.873 0 01-3.327-12.456c.006-13.737 11.185-24.913 24.923-24.913 6.667.003 12.924 2.598 17.63 7.307 4.705 4.709 7.295 10.969 7.293 17.627-.006 13.736-11.187 24.915-24.923 24.915-.001 0 .001 0 0 0h-.011a24.916 24.916 0 01-11.91-3.033L6.26 102.915zm13.824-7.975l.757.449a20.69 20.69 0 0010.543 2.887h.008c11.417 0 20.71-9.29 20.715-20.708a20.581 20.581 0 00-6.062-14.651A20.579 20.579 0 0031.4 56.844c-11.426 0-20.718 9.289-20.723 20.707a20.644 20.644 0 003.167 11.02l.493.783-2.093 7.641 7.84-2.055z"
        />
        <path
          fill="#ffffff"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.163 67.134c-.466-1.037-.958-1.058-1.401-1.076-.363-.016-.779-.015-1.193-.015-.415 0-1.09.156-1.66.779-.571.623-2.18 2.13-2.18 5.193 0 3.064 2.232 6.025 2.543 6.441.311.415 4.308 6.903 10.638 9.4 5.26 2.074 6.331 1.662 7.473 1.558 1.142-.104 3.684-1.506 4.203-2.96.519-1.454.519-2.7.363-2.961-.156-.259-.571-.415-1.194-.727-.623-.311-3.684-1.818-4.255-2.026s-.986-.311-1.401.312c-.415.623-1.608 2.025-1.971 2.44-.363.416-.727.468-1.349.157-.623-.312-2.628-.969-5.008-3.091-1.851-1.65-3.101-3.689-3.464-4.312-.363-.623-.039-.96.273-1.271.28-.279.623-.727.934-1.091.311-.364.415-.623.622-1.038.208-.416.104-.779-.052-1.091-.157-.31-1.367-3.39-1.921-4.621z"
        />
      </g>
      <g>
        <path
          fill="#f4f4f4"
          d="M267.364 55.729v24.06c0 3.49 2.855 6.345 6.345 6.345h34.9c3.49 0 6.345-2.855 6.345-6.345v-24.06h-47.59z"
        />
        <path
          fill="#713535"
          d="M308.609 48.062h-34.9c-3.49 0-6.345 2.855-6.345 6.345v2.115h47.591v-2.115c0-3.49-2.856-6.345-6.346-6.345z"
        />
        <path
          fill="#f4f4f4"
          d="M303.496 54.407h-17.358c-.825 0-1.5-.675-1.5-1.5v-.173c0-.825.675-1.5 1.5-1.5h17.358c.825 0 1.5.675 1.5 1.5v.173c0 .825-.675 1.5-1.5 1.5z"
        />
        <circle fill="#c40000" cx="272.338" cy="52.821" r="1.586" />
        <circle fill="#a66a18" cx="276.552" cy="52.821" r="1.586" />
        <circle fill="#00d254" cx="280.766" cy="52.821" r="1.586" />
        <path
          fill="#ffffff"
          d="M298.405 92.635c-6.93-.876-12.191-5.861-12.191-11.777 0-6.577 6.447-11.928 14.37-11.928s14.37 5.351 14.37 11.928c0 4.402-2.288 6.882-3.127 7.697-4.744 5.119-12.288 7.489-12.596 7.587l-1.774.568.948-4.075z"
        />
        <path
          fill="#e83363"
          d="M313.897 80.858c0-6.003-5.96-10.87-13.313-10.87-7.352 0-13.313 4.866-13.313 10.87 0 5.762 5.496 10.465 12.439 10.834l-.801 3.444s7.533-2.324 12.143-7.301c.786-.766 2.845-2.96 2.845-6.977z"
        />
        <path
          fill="#ffffff"
          d="M282.856 79.322c6.93-.876 12.191-5.861 12.191-11.777 0-6.577-6.447-11.928-14.37-11.928s-14.37 5.351-14.37 11.928c0 4.402 2.288 6.881 3.127 7.697 4.744 5.119 12.288 7.489 12.596 7.587l1.774.568-.948-4.075z"
        />
        <path
          fill="#dddddd"
          d="M267.364 67.545c0-6.003 5.96-10.87 13.313-10.87 7.352 0 13.313 4.866 13.313 10.87 0 5.762-5.496 10.465-12.439 10.834l.801 3.444s-7.533-2.324-12.143-7.301c-.786-.766-2.845-2.96-2.845-6.977z"
        />
      </g>
    </svg>
  );
}

export default ManPointingAtApps;
