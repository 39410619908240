import styled from "@emotion/styled";

import { spacingMedium } from "@medi24-da2c/web-ui/design-tokens";
import SlimLink from "components/SlimLink";

const Link = styled(SlimLink)`
  margin-bottom: ${spacingMedium};
`;

export default Link;
