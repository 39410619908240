import styled from "@emotion/styled";
import { Subheadline as SubheadlineBase } from "@medi24-da2c/web-ui";
import { DESKTOP_MEDIA } from "@medi24-da2c/web-ui/media";

const Subheadline = styled(SubheadlineBase.Themed)`
  display: none;
  margin: 0;
  cursor: default;

  @media ${DESKTOP_MEDIA} {
    display: block;
  }
`;

export default Subheadline;
