import * as codeType from "./generateCodeTypes";

// AZ Elementar partner
export const AZE_PARTNER = {
  urlId: "aze",
  partnerCode: "aze",
  generateStepType: codeType.POLICY_NUMBER_AGE,
  helplinePhoneNumber: "+43 5 9009 588",
  helplineEmail: "gesundheitsversicherung@allianz.at",
  policyNumberRegex: /^(([aA]40)|([lL]95))[0-9]{7}$/,
};

export const APKV_PARTNER = {
  urlId: "Aq4cVt",
  partnerCode: "apkv",
  generateStepType: codeType.EMAIL_ONLY,
};

export const MASTERCARD01_PARTNER = {
  urlId: "mc01",
};
