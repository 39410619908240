import { lazy, Suspense } from "react";
import EnterActivationCodeModal from "components/EnterActivationCodeModal";
import isPredefinedPartner from "utils/isPredefindedPartner";
import { getInstance } from "partnerConfigs/singleton";
import { URL_QUERY_PARTNER_ID } from "constants/keys";
import useQueryParams from "hooks/useQueryParams";
import * as partners from "constants/partners";
import * as URL from "constants/urls";

const EligibilityCheckModal = lazy(() =>
  import("eligibilityCheck/GenericModal")
);
const { generateStepType } = getInstance().config.features;

const trackId = "DHA_ELIGIBILITY_DIALOG";

// Render appropriate modal based on partner ID passed in URL
function EmmaEligibilityModal(props) {
  const query = useQueryParams();
  const currentPartnerId = query.get(URL_QUERY_PARTNER_ID);

  // is the partner specified in URL or eligiblity modal in config?
  if (isPredefinedPartner(partners, currentPartnerId) || generateStepType) {
    return (
      <Suspense fallback={null}>
        <EligibilityCheckModal trackId={trackId} {...props} />
      </Suspense>
    );
  }
  return (
    <EnterActivationCodeModal
      trackId={trackId}
      {...props}
      redirectUrl={URL.CHOOSE}
    />
  );
}

export default EmmaEligibilityModal;
