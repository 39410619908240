import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { OptionalMessage } from "components/OptionalMessage";

const displayName = "ChatBubble";

const ChatBubbleStyled = styled.p`
  background-color: ${(themedProps) => {
    return themedProps.theme.landingPageGeneralInfo.bgrColor;
  }};
  color: ${(themedProps) => {
    return themedProps.theme.landingPageGeneralInfo.textColor;
  }};
  margin: 0;
  padding: 1rem 1.4rem 1.2rem 1.4rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2;
  border-radius: 1.2rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

function ChatBubble({ id, toolTip, toolTipId, values, ...props }) {
  const theme = useTheme();
  return (
    <ChatBubbleStyled theme={theme} {...props}>
      <OptionalMessage
        id={id}
        values={values}
        toolTipId={toolTipId}
        toolTip={toolTip}
      />
    </ChatBubbleStyled>
  );
}
ChatBubble.displayName = displayName;
ChatBubble.propTypes = {
  ...OptionalMessage.propTypes,
};

export default ChatBubble;
