import PropTypes from "prop-types";

import { ENTER_CODE_STEP, GENERATE_CODE_STEP } from "constants/steps";
import { INVITE_ERROR } from "../../api/errorCodes";

import ErrorStepBase from "../ErrorStep";

const displayName = "MyDocModalErrorStep";

function MyDocModalErrorStep(props) {
  return (
    <ErrorStepBase
      idInfix="MyDoc"
      {...props}
      retryStep={
        props.errorCode === INVITE_ERROR ? ENTER_CODE_STEP : GENERATE_CODE_STEP
      }
    />
  );
}

MyDocModalErrorStep.displayName = displayName;

MyDocModalErrorStep.propTypes = {
  errorCode: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default MyDocModalErrorStep;
