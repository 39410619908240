import { getInstance } from "partnerConfigs/singleton";

function useDisableMyDocButton() {
  const { config } = getInstance();
  let hasMyDoc = false;
  try {
    hasMyDoc = config.features.MyDoc === "disabled";
  } catch (ignore) {}

  return hasMyDoc;
}

export default useDisableMyDocButton;
