import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import {
  borderWidthThin,
  spacingMedium,
} from "@medi24-da2c/web-ui/design-tokens";
import { DESKTOP_MEDIA } from "@medi24-da2c/web-ui/media";

const displayName = "Messenger.Wrapper";

const WrapperStyled = styled.li`
  position: relative;
  list-style: none;
  display: flex;
  align-items: center;
  border-bottom: ${borderWidthThin} solid
    ${(themedProps) => themedProps.theme.general.borderColor};

  &:last-of-type {
    border: 0;
  }

  @media ${DESKTOP_MEDIA} {
    flex-direction: column;
    border-right: ${borderWidthThin} solid
      ${(themedProps) => themedProps.theme.general.borderColor};
    border-bottom: none;
    padding: ${spacingMedium};
  }
`;

function Wrapper(props) {
  const theme = useTheme();
  return <WrapperStyled theme={theme} {...props} />;
}
Wrapper.displayName = displayName;

export default Wrapper;
