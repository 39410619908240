import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link as LinkBase } from "@medi24-da2c/web-ui";
import { fontLarge } from "@medi24-da2c/web-ui/design-tokens";

const displayName = "LargeLink";

// A link using the general link color
const LinkStyled = styled(LinkBase)`
  font-size: ${fontLarge};
  color: ${(themedProps) => {
    return themedProps.theme.general.linkColor;
  }};
`;

function Link(props) {
  const theme = useTheme();
  return <LinkStyled theme={theme} {...props} />;
}
Link.displayName = displayName;
Link.propTypes = {
  ...LinkBase.propTypes,
};

export default Link;
