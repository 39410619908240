import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import { OptionalSection } from "components/OptionalMessage";
import useDisableMyDocButton from "hooks/useDisableMyDocButton";
import useDisableDHAButton from "hooks/useDisableDHAButton";
import SimpleMessageButton from "components/SimpleMessageButton";
import useDoctorChat from "hooks/useDoctorChat";
import useDHAFaq from "hooks/useDHAFaq";
import useMyDoc from "hooks/useMyDoc";
import platform from "utils/platform";
import useDHA from "hooks/useDHA";
import useValidActivationCode from "../../hooks/useValidActivationCode";
import LoadingButton from "../../components/Button/LoadingButton";

const buttonSectionStyle = { marginTop: `2rem` };

const buttonStyle = { marginTop: `1rem`, maxWidth: "97%" };

const displayName = "QuickLinks";

function QuickLinks({ ident, myDocLoading, onClickEmma, onClickMyDoc }) {
  const partnerDHA = useDHA();
  const partnerDHAFaq = useDHAFaq();
  const partnerDoctorChat = useDoctorChat();
  const hasDHA = partnerDHA && (partnerDHAFaq || partnerDoctorChat);
  const partnerMyDoc = useMyDoc();
  const disableDHA = useDisableDHAButton();
  const disableMyDoc = useDisableMyDocButton();
  const { hasCode } = useValidActivationCode();

  const handelMyDocClicked = (event) => {
    event.preventDefault();
    if (!hasCode) {
      platform.scrollIntoView("#teleconsultation-carousel");
    }
    onClickMyDoc();
  };

  const handelEmmaClicked = (event) => {
    platform.scrollIntoView("#dha-carousel");
    onClickEmma();
  };

  return (
    <ErrorBoundary>
      <OptionalSection id="landingPage.quickLink.emma">
        <div style={buttonSectionStyle}>
          {hasDHA && (
            <SimpleMessageButton
              id="landingPage.quickLink.emma"
              data-testid={`quickLink-DHA${ident}`}
              disabled={disableDHA}
              style={buttonStyle}
              onClick={handelEmmaClicked}
            />
          )}
          {partnerMyDoc && (
            <LoadingButton
              disabled={disableMyDoc}
              loading={myDocLoading}
              style={buttonStyle}
              id="landingPage.quickLink.myDoc"
              data-testid={`quickLink-MyDoc${ident}`}
              onClick={handelMyDocClicked}
            />
          )}
        </div>
      </OptionalSection>
    </ErrorBoundary>
  );
}
QuickLinks.displayName = displayName;
QuickLinks.propTypes = {
  myDocLoading: PropTypes.bool,
  onClickEmma: PropTypes.func.isRequired,
  onClickMyDoc: PropTypes.func.isRequired,
};

export default QuickLinks;
