import styled from "@emotion/styled";

import { zIndexDefault } from "@medi24-da2c/web-ui/design-tokens";
import { IntroConversationChatHandle } from "../Icons";

const HandleWrapper = styled.div`
  position: absolute;
  width: 5.6rem;
  height: 3.6rem;
  bottom: 1rem;
  left: -5.4rem;
  z-index: ${zIndexDefault};
`;

function ChatHandle() {
  return (
    <HandleWrapper>
      <IntroConversationChatHandle />
    </HandleWrapper>
  );
}

export default ChatHandle;
