import baseConfig from "../mc-core-my/config";
import msInherited from "./translations/ms.inherit.json";
import msOverrides from "./translations/ms.json";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh mc-core-my subpartner mc-hsbc-my 21 Malaysia digitalhealthxtra hsbc-my allianz mydoc MCHSBCMY ms en
// DEVTEST Test "MCHSBCMY" with BIN "6060606060"
const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHXTRA_HSBC_MY_21",
    partnerGroup: "hsbc-mastercard",
    partnerCountry: "Malaysia",
    subPartnerCode: "MCHSBCMY",
  },
  // languages: 'ms en'.split(' '),
  languages: "en".split(" "),
  messages: {
    ms: { ...msInherited, ...msOverrides },
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
