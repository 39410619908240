import englishOverrides from "./translations/en.json";
import germanOverrides from "./translations/de.json";
import frenchOverrides from "./translations/fr.json";
import spanishOverrides from "./translations/es.json";

const config = {
  general: {
    title: "Medi24",
    partnerOverride: "MEDI24",
    partnerGroup: "medi24",
    partnerCountry: "Europe",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    generalEmail: "digitalhealth@medi24.com",
  },
  // The languages are shown on LanguagePicker in specified order
  // For list of supported languages check translations/supportedLanguages
  languages: ["en", "de", "fr", "es"],
  messages: {
    en: englishOverrides,
    de: germanOverrides,
    fr: frenchOverrides,
    es: spanishOverrides,
  },
  features: {
    DHA: true,
    MyDoc: false,
  },
  footer: {
    address: {
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
      city: "3006 Bern",
      country: "ch",
    },
    legal: {
      securityNumber: "ZSR 0388.0.02",
      commercialRegister: "CHE-101.216.589",
    },
  },
};

export default config;
