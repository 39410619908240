import PropTypes from "prop-types";
import ConsentFormPicker from "components/ConsentForm/ConsentFormPicker";
import useLanguage, { toChatBotLanguage } from "hooks/useLanguage";
import * as URL from "constants/urls";
import GettingStartedPage from "../GettingStartedPage";

const displayName = "TelegramGettingStartedPage";

function TelegramGettingStartedPage(props) {
  const [selectedLanguage] = useLanguage();

  return (
    <GettingStartedPage {...props} messengerName="Telegram">
      {({ activationCode }) => (
        <ConsentFormPicker
          chat="Telegram"
          activationCode={activationCode}
          messengerLink={`${URL.TELEGRAM_URL()}AC-${activationCode}_Language-${toChatBotLanguage(
            selectedLanguage
          )}_Platform-Telegram`}
        />
      )}
    </GettingStartedPage>
  );
}
TelegramGettingStartedPage.displayName = displayName;
TelegramGettingStartedPage.propTypes = {
  forceValid: PropTypes.bool,
};

export default TelegramGettingStartedPage;
