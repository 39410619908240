import baseConfig from "../mc-asia/config";
import enInherited from "./translations/en.inherit.json";
import msInherited from "./translations/ms.inherit.json";
import thInherited from "./translations/th.inherit.json";
import viInherited from "./translations/vi.inherit.json";
import idInherited from "./translations/id.inherit.json";
import zhHantInherited from "./translations/zh-Hant.inherit.json";
// NEWLANGUAGE ADD ABOVE import all languages for Mastercard Asia MyDoc only

// inherit-partner.sh mc-asia subpartner mc-asia-mydoc-only 9999 None MASTERCARD-ASIA-MYDOC-ONLY-PARTNER INHERIT allianz mydoc NONE en ms th vi zh-Hant

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "MASTERCARD_ASIA_MYDOC_ONLY_PARTNER_INHERIT",
  },
  features: {
    ...baseConfig.features,
    DHA: false,
    MyDoc: true,
  },
  messages: {
    en: enInherited,
    ms: msInherited,
    th: thInherited,
    vi: viInherited,
    id: idInherited,
    "zh-Hant": zhHantInherited,
    // NEWLANGUAGE ADD ABOVE access to all languages for Mastercard Asia MyDoc only
  },
};

config.languages = Object.keys(config.messages).sort();

export default config;
