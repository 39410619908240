import PropTypes from "prop-types";
import { Content, ErrorBoundary } from "@medi24-da2c/web-ui";
import useValidActivationCode from "hooks/useValidActivationCode";
import useTranslatedTitle from "hooks/useTranslatedTitle";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import SimpleHeadline from "components/SimpleHeadline";
import SimpleText from "components/SimpleText";
import ValidActivationCodeGuard from "../ValidActivationCodeGuard";

const displayName = "GettingStartedPage";

function GettingStartedPage({ messengerName, forceValid, children }) {
  const { activationCode } = useValidActivationCode();

  useTranslatedTitle("gettingStartedPage.title", "Getting started");
  // i.e. SECURE_WEB_CHAT_STARTED_PAGE, VIBER_STARTED_PAGE, TELEGRAM_STARTED_PAGE...
  useTrackPageEffect(
    `${messengerName.toUpperCase().replace(/[^A-Z]+/gi, "_")}_STARTED_PAGE`
  );

  return (
    <ErrorBoundary>
      <ValidActivationCodeGuard forceValid={forceValid}>
        <Content>
          <SimpleHeadline id="gettingStartedPage.headline" />
          <SimpleText
            id="gettingStartedPage.consentInfo"
            values={{ messengerName }}
          />
          {children({ activationCode })}
        </Content>
      </ValidActivationCodeGuard>
    </ErrorBoundary>
  );
}
GettingStartedPage.displayName = displayName;
GettingStartedPage.propTypes = {
  messengerName: PropTypes.string.isRequired,
  forceValid: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

export default GettingStartedPage;
