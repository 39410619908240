import baseConfig from "../medi24/config";
import enInherited from "./translations/en.inherit.json";
import deInherited from "./translations/de.inherit.json";

// inherit-partner.sh medi24 theme medi24-allianz 9999 NONE MEDI24-WITH-ALLIANZ-THEME INHERIT allianz NONE NONE en de

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerGroup: "medi24",
    partnerOverride: "MEDI24_WITH_ALLIANZ_THEME_INHERIT_9999",
    partnerCountry: "NONE",
  },
  languages: "en de".split(" "),
  messages: {
    en: enInherited,
    de: deInherited,
  },
};

export default config;
