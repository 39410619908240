import baseConfig from "../mc-asia/config";
import enInherited from "./translations/en.inherit.json";
import yueInherited from "./translations/zh-Yue.inherit.json";
import enOverrides from "./translations/en.json";
// import yueOverrides from "./translations/zh-Yue.json";

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHX_MC_HK_12",
    partnerCountry: "Hong Kong",
    subPartnerCode: "MCCOREHK",
  },
  languages: "yue en".split(" "),
  messages: {
    // yue: { ...yueInherited, ...yueOverrides },
    yue: yueInherited,
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
