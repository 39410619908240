import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import {
  CONSENT_ALLOW_ALL,
  CONSENT_REJECTED,
  CONSENT_PENDING,
  CONSENT_PRIVACY,
} from "constants/cookiesConsent";
import SimpleMessageButton from "components/SimpleMessageButton";
import { OptionalMessage } from "components/OptionalMessage";
import { getCookiesConsent } from "utils/cookiesConsent";
import { DEV_DEBUG_COOKIES } from "constants/switches";
import withHtml, { makeLinkTo } from "hooks/withHtml";
import FormCheckbox from "components/FormCheckbox";
import Link from "components/PlatformInfo/Link";
import useNavigate from "hooks/useNavigate";
import ModalBase from "components/Modal";
import * as URL from "constants/urls";
import MyHeadline from "./Headline";

const displayName = "CookiesSettingsModal";
const displayName2 = "SimpleCookiesDialog";
const displayName3 = "ExpandedCookiesDialog";

const modalStyle = {
  top: "2rem",
  maxWidth: "80%",
  minHeight: "34rem",
};
const headlineStyle = { padding: 0, margin: 0 };
const marginStyle = { marginTop: "1rem" };
const buttonStyle = {
  ...marginStyle,
  alignItems: "center",
};

function SimpleCookiesDialog({
  onAcceptAllClick,
  onSettingsClick,
  onPrivacyLinkClick,
}) {
  const values = {
    ...withHtml,
    linkToPrivacy: makeLinkTo(
      "link-to-privacy-notice",
      void 0,
      onPrivacyLinkClick
    ),
  };
  return (
    <>
      <ModalBase.Message id="cookiesPrivacy.overview" values={values} />
      <ModalBase.Controls>
        <SimpleMessageButton
          id="cookiesPrivacy.acceptAllButton"
          name="cookiesPrivacy.acceptAllButton"
          data-testid="cookiesPrivacy.acceptAllButton"
          style={buttonStyle}
          onClick={onAcceptAllClick}
        />
        <Link
          data-testid="cookiesPrivacy.settingsButton"
          as="button"
          onClick={onSettingsClick}
          style={marginStyle}
        >
          <OptionalMessage id="cookiesPrivacy.settingsButton" />
        </Link>
      </ModalBase.Controls>
    </>
  );
}
SimpleCookiesDialog.displayName = displayName2;
SimpleCookiesDialog.propTypes = {
  onAcceptAllClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onPrivacyLinkClick: PropTypes.func.isRequired,
};

function ExpandedCookiesDialog({
  cookiesConsent,
  onAcceptAllClick,
  onRequiredOnlyClick,
  onConfirmClick,
}) {
  const [checkState, setCheckState] = useState(
    getCookiesConsent(cookiesConsent)
  );

  const handleCheck = useCallback(
    (event) => {
      const id = event.target.id;
      setCheckState({
        ...checkState,
        [id]: !checkState[id],
      });
    },
    [checkState, setCheckState]
  );

  const handleConfirmClick = useCallback(() => {
    onConfirmClick({
      ...checkState,
      "allow-necessary-cookies": true,
    });
  }, [checkState, onConfirmClick]);

  const prefsToolTipId = checkState["allow-preferences-cookies"]
    ? "cookiesPrivacy.allowMessage"
    : "cookiesPrivacy.refusedMessage";
  const statsToolTipId = checkState["allow-statistics-cookies"]
    ? "cookiesPrivacy.allowMessage"
    : "cookiesPrivacy.refusedMessage";

  return (
    <>
      <ModalBase.Message id="cookiesPrivacySettings.overview" />
      <ModalBase.TitleMessage
        id="cookiesPrivacySettings.manage.title"
        titleId="cookiesPrivacySettings.manage.title"
      />
      <ModalBase.Controls>
        <SimpleMessageButton
          id="cookiesPrivacy.allowAllButton"
          name="cookiesPrivacy.allowAllButton"
          data-testid="cookiesPrivacy.allowAllButton"
          style={buttonStyle}
          onClick={onAcceptAllClick}
        />
        <SimpleMessageButton
          inverse
          id="cookiesPrivacy.requiredOnlyButton"
          name="cookiesPrivacy.requiredOnlyButton"
          data-testid="cookiesPrivacy.requiredOnlyButton"
          style={marginStyle}
          onClick={onRequiredOnlyClick}
        />
      </ModalBase.Controls>
      <MyHeadline toolTipId="cookiesPrivacySettings.necessary.always">
        <ModalBase.TitleMessage
          id="cookiesPrivacySettings.necessary.headline"
          titleId="cookiesPrivacySettings.necessary.headline"
          style={headlineStyle}
        />
        <ModalBase.Message id="cookiesPrivacySettings.necessary.always" />
      </MyHeadline>
      <ModalBase.Message
        id="cookiesPrivacySettings.necessary.content"
        toolTipId="cookiesPrivacySettings.necessary.always"
      />
      <MyHeadline
        data-testid="allow-preferences-cookies-headline"
        toolTipId={prefsToolTipId}
      >
        <ModalBase.TitleMessage
          id="cookiesPrivacySettings.preferences.headline"
          titleId="cookiesPrivacySettings.preferences.headline"
          style={headlineStyle}
        />
        <FormCheckbox
          id="allow-preferences-cookies"
          name="allow-preferences-cookies"
          data-testid="allow-preferences-cookies"
          value={checkState["allow-preferences-cookies"]}
          onChange={handleCheck}
          children=""
        />
      </MyHeadline>
      <ModalBase.Message
        id="cookiesPrivacySettings.preferences.content"
        toolTipId={prefsToolTipId}
      />

      <MyHeadline
        data-testid="allow-statistics-cookies-headline"
        toolTipId={statsToolTipId}
      >
        <ModalBase.TitleMessage
          id="cookiesPrivacySettings.statistics.headline"
          titleId="cookiesPrivacySettings.statistics.headline"
          style={headlineStyle}
        />
        <FormCheckbox
          id="allow-statistics-cookies"
          name="allow-statistics-cookies"
          data-testid="allow-statistics-cookies"
          value={checkState["allow-statistics-cookies"]}
          onChange={handleCheck}
          children=""
        />
      </MyHeadline>
      <ModalBase.Message
        id="cookiesPrivacySettings.statistics.content"
        toolTipId={statsToolTipId}
      />

      <ModalBase.Controls>
        <SimpleMessageButton
          id="cookiesPrivacy.confirmButton"
          name="cookiesPrivacy.confirmButton"
          data-testid="cookiesPrivacy.confirmButton"
          style={buttonStyle}
          onClick={handleConfirmClick}
        />
      </ModalBase.Controls>
    </>
  );
}
ExpandedCookiesDialog.displayName = displayName3;
ExpandedCookiesDialog.propTypes = {
  cookiesConsent: PropTypes.string,
  onAcceptAllClick: PropTypes.func.isRequired,
  onRequiredOnlyClick: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
};

function CookiesSettingsModal({
  isOpen,
  showSettings,
  onClose,
  cookiesConsent,
}) {
  const navigate = useNavigate();
  const [isExpanded, setExpanded] = useState(showSettings);
  const showExpanded = showSettings || isExpanded;

  const handleClose = useCallback(
    (cookieString) => {
      const expansion =
        cookiesConsent !== CONSENT_PRIVACY &&
        cookiesConsent !== CONSENT_PENDING;

      const state =
        "string" === typeof cookieString
          ? getCookiesConsent(cookieString)
          : cookieString;

      setExpanded(expansion);
      onClose(state);
    },
    [onClose, setExpanded, cookiesConsent]
  );

  const handleAll = useCallback(
    () => handleClose(CONSENT_ALLOW_ALL),
    [handleClose]
  );

  const handleReject = useCallback(
    () => handleClose(CONSENT_REJECTED),
    [handleClose]
  );

  const handlePrivacyLink = useCallback(() => {
    handleClose(CONSENT_PRIVACY);
    navigate.redirectWithSearch(URL.PRIVACY);
  }, [handleClose, navigate]);

  const showSettingsDialog = useCallback(
    () => setExpanded(true),
    [setExpanded]
  );

  return (
    <ModalBase
      hideCloseButton
      titleId={
        showExpanded ? "cookiesPrivacySettings.title" : "cookiesPrivacy.title"
      }
      trackId={showExpanded ? "COOKIE_SETTINGS_DIALOG" : "COOKIE_POPUP_DIALOG"}
      isOpen={!!isOpen}
      style={modalStyle}
      onClose={handleClose}
    >
      <ErrorBoundary>
        {DEV_DEBUG_COOKIES && <span>{cookiesConsent}</span>}
        {showExpanded ? (
          <ModalBase.ScrollableContent>
            <ExpandedCookiesDialog
              cookiesConsent={cookiesConsent}
              onRequiredOnlyClick={handleReject}
              onAcceptAllClick={handleAll}
              onConfirmClick={handleClose}
            />
          </ModalBase.ScrollableContent>
        ) : (
          <ModalBase.Content>
            <SimpleCookiesDialog
              onAcceptAllClick={handleAll}
              onSettingsClick={showSettingsDialog}
              onPrivacyLinkClick={handlePrivacyLink}
            />
          </ModalBase.Content>
        )}
      </ErrorBoundary>
    </ModalBase>
  );
}
CookiesSettingsModal.displayName = displayName;
CookiesSettingsModal.propTypes = {
  isOpen: PropTypes.bool,
  showSettings: PropTypes.bool,
  cookiesConsent: PropTypes.string,
};

export default CookiesSettingsModal;
