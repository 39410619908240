import useDHA from "hooks/useDHA";
import useMyDoc from "hooks/useMyDoc";
import useWebApp from "hooks/useWebApp";
import useDHAFaq from "hooks/useDHAFaq";
import useDoctorChat from "hooks/useDoctorChat";
import usePrivacyFaq from "hooks/usePrivacyFaq";
import useSymptomChecker from "hooks/useSymptomChecker";
import useMedicalHotline from "hooks/useMedicalHotline";
import useTranslatedTitle from "hooks/useTranslatedTitle";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import useScrollHighlight from "hooks/useScrollHighlight";
import useValidActivationCode from "hooks/useValidActivationCode";

import FaqPageEmma from "./FaqPageEmma";
import FaqPageMyDocOnly from "./FaqPageMyDocOnly";

function FaqPage() {
  // Contact info is hidden when valid activation code is not provided
  const { isValidCode } = useValidActivationCode();
  const hasMedicalHotline = useMedicalHotline();
  const hasSymptomChecker = useSymptomChecker();
  const hasPrivacyFaq = usePrivacyFaq();
  const hasDoctorChat = useDoctorChat();
  const { expand, highlight, scrollTo } = useScrollHighlight();
  const hasDHAFaq = useDHAFaq();
  const hasWebApp = useWebApp();
  const hasMyDoc = useMyDoc();
  const hasDHA = useDHA();

  const options = {
    hasSymptomChecker,
    hasMedicalHotline,
    hasPrivacyFaq,
    hasDoctorChat,
    isValidCode,
    hasWebApp,
    highlight,
    hasMyDoc,
    scrollTo,
    expand,
    hasDHA: hasDHA && hasDHAFaq,
  };

  useTranslatedTitle("faqPage.title", "FAQ");
  useTrackPageEffect("FAQ_PAGE");

  return hasMyDoc && !hasDHA ? FaqPageMyDocOnly(options) : FaqPageEmma(options);
}

export default FaqPage;
