import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";
import Headline from "@medi24-da2c/web-ui/Headline/Headline";
import { OptionalMessage } from "components/OptionalMessage";
import Legal from "./Legal";

const displayName = "BenefitPoint";
const displayName2 = "MobileGroup";
const displayName3 = "BenefitHeadline";
const displayName4 = "MobileOverview";

// TODO(2021-05) should be using @emotion/styled instead of inline styles
const styleBenefitPoint = {
  fontSize: "1.6rem",
  marginTop: "1.5rem",
  width: "41rem",
};

const styleMobileDivs = {
  marginTop: "2rem",
};

const styleHeadline = { width: "28rem" };

export function BenefitHeadline({ id }) {
  const theme = useTheme();
  return (
    <Headline theme={theme} style={styleHeadline}>
      <OptionalMessage id={id} />
    </Headline>
  );
}
BenefitHeadline.displayName = displayName3;
BenefitHeadline.propTypes = {
  id: PropTypes.string.isRequired,
};

export function MobileGroup({ children }) {
  return <div style={styleMobileDivs}>{children}</div>;
}
MobileGroup.displayName = displayName2;

export function BenefitPoint({ id, br }) {
  return (
    <>
      <Legal style={styleBenefitPoint}>
        <OptionalMessage id={id} />
      </Legal>
      {br ? <br /> : null}
    </>
  );
}
BenefitPoint.displayName = displayName;
BenefitPoint.propTypes = {
  id: PropTypes.string.isRequired,
  br: PropTypes.bool,
};

export function MobileOverview(props) {
  const theme = useTheme();
  const styleOverviewPadding = {
    fontFamily: theme.fontFamily,
    padding: "0.5rem",
  };

  return (
    <div data-testid={props["data-testid"]} style={styleOverviewPadding}>
      {props.children}
    </div>
  );
}
MobileOverview.displayName = displayName4;
