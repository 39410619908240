import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Text as TextBase } from "@medi24-da2c/web-ui";
import { spacingMedium } from "@medi24-da2c/web-ui/design-tokens";
import {
  OptionalMessageOr,
  OptionalSectionOr,
} from "components/OptionalMessage";

const displayName = "OptionalTextOr";

const Text = styled(TextBase.Themed)`
  padding: 0 6rem 0 ${spacingMedium};

  margin-top: 0;
`;

function OptionalTextOr(props) {
  return (
    <OptionalSectionOr {...props}>
      <Text>
        <OptionalMessageOr {...props} />
      </Text>
    </OptionalSectionOr>
  );
}
OptionalTextOr.displayName = displayName;
OptionalTextOr.propTypes = {
  id: PropTypes.string,
  idDefault: PropTypes.string,
};

export default OptionalTextOr;
