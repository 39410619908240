import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import useTranslatedTitle from "hooks/useTranslatedTitle";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import usePrivacyPage from "hooks/usePrivacyPage";
import PrivacyNotice from "./PrivacyNotice";

const displayName = "PrivacyNoticePage";

function PrivacyNoticePage({ languages }) {
  let languageList = usePrivacyPage();
  if (languages) {
    languageList = languages;
  }
  const language = languageList[0];
  const isBilingual = languageList.length > 1;
  let secondLanguage;
  if (isBilingual) {
    secondLanguage = languageList[1];
  }

  useTranslatedTitle("privacyPage.title", "Privacy");
  useTrackPageEffect("PRIVACY_PAGE");

  return (
    <ErrorBoundary>
      <div data-testid={displayName}>
        <PrivacyNotice
          key={language}
          locale={language}
          isBilingual={isBilingual}
        />
        {secondLanguage && (
          <PrivacyNotice
            number={2}
            key={secondLanguage}
            locale={secondLanguage}
          />
        )}
      </div>
    </ErrorBoundary>
  );
}
PrivacyNoticePage.displayName = {
  languages: PropTypes.arrayOf(PropTypes.string),
};

export default PrivacyNoticePage;
