import PropTypes from "prop-types";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import Modal from "components/Modal";
import LoadingButton from "../../components/Button/LoadingButton";
import StepHeadline from "../StepHeadline";
import ActivationCode from "./ActivationCode";

const displayName = "EligibilityCheck.SuccessStep";

const buttonStyle = {
  width: "80%",
};

const divStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginBottom: "2rem",
  marginTop: "2rem",
};

function SuccessStep({
  activationCodeDetails,
  upsellSuffix,
  loading,
  onSetLoading,
  onContinueButtonClick,
}) {
  useTrackPageEffect("ELIGIBILITY_SUCCESS_CONTENT");

  return (
    <>
      <Modal.ScrollableContent>
        <StepHeadline id="chooseMessengerPage.generateActivationCodeModal.successStep.headline" />
        <Modal.Message id="chooseMessengerPage.generateActivationCodeModal.successStep.successMessage" />
        <ActivationCode>{activationCodeDetails.activationCode}</ActivationCode>
        <Modal.Message id="chooseMessengerPage.generateActivationCodeModal.successStep.futureUse" />
        <Modal.Message id="chooseMessengerPage.generateActivationCodeModal.successStep.additionalInfo" />
        <Modal.Message
          id={`chooseMessengerPage.generateActivationCodeModal.successStep.upsell${upsellSuffix}`}
        />
      </Modal.ScrollableContent>
      <div style={divStyle}>
        <LoadingButton
          id="chooseMessengerPage.generateActivationCodeModal.successStep.continueButton"
          data-testid="continue-button"
          onClick={onContinueButtonClick}
          style={buttonStyle}
          loading={loading}
        />
      </div>
    </>
  );
}
SuccessStep.displayName = displayName;
SuccessStep.propTypes = {
  // TODO(2021-07) add idInfix for alternate MyDoc messages see InitialStep
  upsellSuffix: PropTypes.string,
  activationCodeDetails: PropTypes.shape({
    activationCode: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  onSetLoading: PropTypes.func,
  onContinueButtonClick: PropTypes.func.isRequired,
};
SuccessStep.defaultProps = {
  upsellSuffix: "",
};

export default SuccessStep;
