import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Text } from "@medi24-da2c/web-ui";
import { fontSmall } from "@medi24-da2c/web-ui/design-tokens";

const displayName = "LandingPage.Legal";

const LegalStyled = styled(Text)`
  width: 53rem;
  font-size: ${fontSmall};
  margin-top: 0;
  margin-bottom: 0;
  color: ${(themedProps) => themedProps.theme.landingPageGetStarted.textColor};

  &:first-of-type {
    margin-top: 0;
  }

  @media only screen and (max-width: 966px) {
    width: auto;
    margin-left: 0;
  }
`.withComponent("small");

function Legal(props) {
  const theme = useTheme();
  return <LegalStyled theme={theme} {...props} />;
}
Legal.displayName = displayName;

export default Legal;
