import styled from "@emotion/styled";

import { DESKTOP_MEDIA } from "@medi24-da2c/web-ui/media";

const MessengerNameAffix = styled.span`
  display: none;

  @media ${DESKTOP_MEDIA} {
    display: inline;
  }
`;

export default MessengerNameAffix;
