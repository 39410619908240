import PropTypes from "prop-types";
import { getInstance } from "partnerConfigs/singleton";
import useTranslatedTitle from "hooks/useTranslatedTitle";
import { useOptionalMessage } from "hooks/useOptionalMessage";

const displayName = "PartnerConfig";
const displayName2 = "JsonTable";
const displayName3 = "ThemeTable";
const displayName4 = "Color";

function toString(thing) {
  let text = `${thing}`;
  if ("function" === typeof thing) {
    text = text.replace(/\s+/g, " ").replace(/\(\s*\).+/, "");
  }
  return text.trim().length ? text : "[EMPTY]";
}

const width = 12;
const themeWidth = 20;
// const height = 18;
const wrapper = { padding: "3rem" };
const table = { outline: "1px solid black", width: `${3 * width}rem` };
const left = { textAlign: "left", width: `${width}rem` };
const right = { textAlign: "right", width: `${width}rem` };
/*
const logo = {
  width: `${10 * width}rem`,
  height: `${height}rem`,
  display: "block",
  // overflow: "scroll",
};
*/
const background = {
  width: `${6 * width}rem`,
  height: `${width}rem`,
  display: "block",
  overflow: "scroll",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};
const themeTable = {
  outline: "1px solid black",
  width: `${3 * themeWidth}rem`,
};
const themeLeft = { textAlign: "left", width: `${themeWidth}rem` };
const themeRight = { textAlign: "right", width: `${themeWidth}rem` };
const colorBox = {
  display: "inline-block",
  width: "2rem",
  height: "2rem",
  margin: "2px",
  padding: "2px",
  outline: "1px solid black",
};
const exampleBox = {
  display: "inline-block",
  minWidth: "10rem",
  height: "2rem",
  margin: "2px",
  padding: "2px",
  textAlign: "center",
  fontWeight: 900,
};

function JsonTable({ __title, __heading, ...props }) {
  return (
    <table style={table}>
      <thead>
        <tr>
          <th style={left} colSpan={__heading ? 1 : 2}>
            {__title}:
          </th>
          {__heading ? <th style={left}>{__heading}</th> : void 0}
        </tr>
      </thead>
      <tbody>
        {Object.keys(props).map(function jsonValue(key) {
          return (
            <tr key={key}>
              <th style={right}>{key}:</th>
              <td>{toString(props[key])}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
JsonTable.displayName = displayName2;
JsonTable.propTypes = {
  __title: PropTypes.string.isRequired,
  __heading: PropTypes.string,
};

function Color({ name, color, palette }) {
  const sample = {
    ...exampleBox,
    fontFamily: palette.fontFamily,
    textTransform: palette.textTransform,
    borderRadius: palette.borderRadius,
    color: /Bgr/.test(name) ? void 0 : palette.textColor,
    backgroundColor: /Bgr/.test(name) ? color : palette.bgrColor,
    border: palette.borderColor
      ? `1px solid ${palette.borderColor}`
      : `1px solid transparent`,
  };
  sample.color = /(^bgr|Bgr|^border)Color$/.test(name) ? sample.color : color;
  if (/error/.test(name)) {
    sample.backgroundColor = palette.errorBgrColor
      ? palette.errorBgrColor
      : sample.backgroundColor;
    sample.color = palette.errorColor ? palette.errorColor : sample.color;
  }
  if (/checkbox/.test(name)) {
    sample.backgroundColor = palette.checkboxBgrColor
      ? palette.checkboxBgrColor
      : sample.backgroundColor;
    sample.color = palette.checkboxTickColor
      ? palette.checkboxTickColor
      : sample.color;
  }
  if (/input/.test(name)) {
    sample.backgroundColor = palette.inputBgrColor
      ? palette.inputBgrColor
      : sample.backgroundColor;
    sample.color = palette.inputTextColor
      ? palette.inputTextColor
      : sample.color;
    sample.border = palette.inputBorderColor
      ? `1px solid ${palette.inputBorderColor}`
      : sample.border;
  }
  return (
    <span>
      <div style={{ ...colorBox, backgroundColor: color }} />
      <div style={sample}>{name}</div>
    </span>
  );
}
Color.displayName = displayName4;
Color.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  palette: PropTypes.object.isRequired,
};

function ThemeTable({ __title, __fontFamily, ...props }) {
  return (
    <table style={themeTable}>
      <thead>
        <tr>
          <th style={themeLeft}>{__title}:</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(props).map(function themeValue(key) {
          let value = toString(props[key]);
          let colorValue = value;
          let color = "";
          if (typeof props[key] === "function") {
            colorValue = "";
            value = value.replace(/\n/g, "").replace(/\(.+$/, "");
          } else if (/^(#|rgb)/.test(value)) {
            color = (
              <Color
                name={key}
                color={value}
                palette={{ fontFamily: __fontFamily, ...props }}
              />
            );
            value = "";
            colorValue = ` ${colorValue}`;
          } else {
            colorValue = "";
          }
          return (
            <tr key={key}>
              <th style={themeRight}>
                {key}:{colorValue}
              </th>
              <td>
                {value}
                {color}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
ThemeTable.displayName = displayName3;
ThemeTable.propTypes = {
  __title: PropTypes.string.isRequired,
  __fontFamily: PropTypes.string,
};

function PartnerConfig() {
  const emmaName = useOptionalMessage("global.emmaName");
  const teleName = useOptionalMessage("global.teleName");
  const browserTitle = useTranslatedTitle("landingPage.title");
  const faqTitle = useTranslatedTitle("faqPage.title");
  const partnerConfig = getInstance();
  const Logotype = partnerConfig.Logotype;
  const theme = partnerConfig.theme;
  // window.console.warn('PARTN', theme)
  const prod = `https://${partnerConfig.infra.appName}.${partnerConfig.infra.partnerName}.medi24.com/`;
  const dev = `https://dev.${partnerConfig.infra.appName}.${partnerConfig.infra.partnerName}.access-to-care-medi24.com/`;

  return (
    <div style={wrapper}>
      <h1>partnerConfigs/{partnerConfig.partner}</h1>
      <p>
        Demonstrates the range of changes that partners can make to fonts, color
        scheme, icons, images, and text. Everything can be changed except
        portions shown with an amber highlight.
      </p>
      <h4>
        <span>Production URL: </span>
        <a target="_blank" rel="noreferrer" href={prod}>
          {prod}
        </a>
      </h4>
      <h4>
        <span>Developmenet URL: </span>
        <a target="_blank" rel="noreferrer" href={dev}>
          {dev}
        </a>
      </h4>
      <JsonTable __title="infra" {...partnerConfig.infra} />
      <JsonTable
        __title="config.languages [visible]"
        __heading={partnerConfig.config.languages.join(", ")}
      />
      <JsonTable
        __title="config.messages [internal]"
        __heading={Object.keys(partnerConfig.config.messages).join(", ")}
      />
      <JsonTable __title="config.general" {...partnerConfig.config.general} />
      <JsonTable __title="config.features" {...partnerConfig.config.features} />
      <JsonTable
        __title="config.footer.address"
        {...partnerConfig.config.footer.address}
      />
      <JsonTable
        __title="config.footer.legal"
        {...partnerConfig.config.footer.legal}
      />
      <JsonTable
        __title="Miscellaneous"
        {...{
          "Web Browser Title": browserTitle,
          "FAQ Page Title, etc": faqTitle,
          "DHA Name": emmaName,
          "Teleconsultation Name": teleName,
        }}
      />
      <h2>Web Icons:</h2>
      <figure>
        <img
          src="favicon.ico"
          alt="Web Site Favourite Icon"
          title="Web Site Favourite Icon"
        />
        <figcaption>
          Web Site Favourite Icon (16px,24px,32px,64px, favicon.ico)
        </figcaption>
      </figure>
      <figure>
        <img
          src="icon-192.png"
          alt="Web Site Icon 192px"
          title="Web Site Icon 192px"
        />
        <figcaption>Web Site Icon (192px,icon-192.png)</figcaption>
      </figure>
      <figure>
        <img
          src="icon-512.png"
          alt="Web Site Icon 512px"
          title="Web Site Icon 512px"
        />
        <figcaption>Web Site Icon (512px,icon-512.png)</figcaption>
      </figure>
      <h2>LogoType:</h2>
      <figure>
        {Logotype()}
        <figcaption>
          Web Site Header Logo (variable size ex:1024/121 364/88, LogoType.svg)
        </figcaption>
      </figure>
      <h2>IntroConversationBgr:</h2>
      <figure>
        <div
          style={{
            ...background,
            width: 1600,
            height: 500,
            backgroundColor: theme.general.bgrColor,
            backgroundImage: `url(${partnerConfig.IntroConversationBgr})`,
          }}
        />
        <figcaption>
          Web Site Intro Banner for Desktop (1600x400-500,
          IntroConversationBgr.jpg)
        </figcaption>
      </figure>
      <h2>IntroConversationBgrMobile:</h2>
      <figure>
        <div
          style={{
            ...background,
            width: 800,
            height: 260,
            backgroundColor: theme.general.bgrColor,
            backgroundImage: `url(${partnerConfig.IntroConversationBgrMobile})`,
          }}
        />
        <figcaption>
          Web Site Intro Banner for Mobile (800x260,
          IntroConversationBgrMobile.jpg)
        </figcaption>
      </figure>
      <h2>theme:</h2>
      {Object.keys(theme).map(function renderSubTheme(key) {
        if (key === "fontFamily") {
          return <JsonTable key={key} __title={key} __heading={theme[key]} />;
        } else if (key === "themeName") {
          return <JsonTable key={key} __title={key} __heading={theme[key]} />;
        }
        return (
          <ThemeTable
            key={key}
            __title={key}
            {...theme[key]}
            __fontFamily={theme.fontFamily}
          />
        );
      })}
      <hr />
    </div>
  );
}
PartnerConfig.displayName = displayName;

export default PartnerConfig;
