import styled from "@emotion/styled";

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

export default Overlay;
