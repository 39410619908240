import PropTypes from "prop-types";
import useLanguage from "hooks/useLanguage";
import ConsentFormBase from "../ConsentForm/ConsentFormBase";
import makeUrl from "./makeUrl";

const displayName = "WebChatConsentForm";

const checkBoxList = [
  "termsOfUse",
  "privacyPolicy",
  "allowNotifications",
  "clearHistory",
];

function WebChatConsentForm({ chat, activationCode }) {
  const [selectedLanguage] = useLanguage();

  function makeRedirectUrl(agreements) {
    return makeUrl({ chat, activationCode, selectedLanguage, ...agreements });
  }

  return (
    <ConsentFormBase
      chat={chat}
      idList={checkBoxList}
      onRedirectUrl={makeRedirectUrl}
    />
  );
}
WebChatConsentForm.displayName = displayName;
WebChatConsentForm.propTypes = {
  chat: PropTypes.string.isRequired,
  activationCode: PropTypes.string.isRequired,
};

export default WebChatConsentForm;
