import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import { OptionalSection } from "components/OptionalMessage";
import LoadingButton from "components/Button/LoadingButton";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import { useSearchLinks } from "hooks/withHtml";
import BasicForm from "components/BasicForm";
import Modal from "components/Modal";
import ButtonAndLoader from "./ButtonAndLoader";

const displayName = "GenerateCodeForm";

const idOverview = "generateActivationCodeForm.policyNumber.overview";

const modalStyle = {
  width: "100%",
};

function GenerateCodeForm({
  onSubmit,
  loading,
  children,
  idLegalInfo,
  isDirty,
}) {
  const withHtml = useSearchLinks();
  useTrackPageEffect("ELIGIBILITY_GENERATE_CONTENT");

  return (
    <ErrorBoundary>
      <BasicForm onSubmit={onSubmit} noValidate>
        <Modal.ScrollableContent style={modalStyle}>
          <OptionalSection id={idOverview}>
            <Modal.Message id={idOverview} />
          </OptionalSection>
          <BasicForm.Fields>{children}</BasicForm.Fields>
          <OptionalSection id={idLegalInfo} values={withHtml}>
            <Modal.Message id={idLegalInfo} values={withHtml} />
          </OptionalSection>
        </Modal.ScrollableContent>
        <ButtonAndLoader>
          <LoadingButton
            data-testid="generate-code-button"
            id="generateActivationCodeForm.generateCodeButton"
            loading={loading}
            disabled={!isDirty || loading}
            type="submit"
          />
        </ButtonAndLoader>
      </BasicForm>
    </ErrorBoundary>
  );
}

GenerateCodeForm.displayName = displayName;

GenerateCodeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  idLegalInfo: PropTypes.string,
  isDirty: PropTypes.bool,
};

export default GenerateCodeForm;
