import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Subheadline as SubheadlineBase } from "@medi24-da2c/web-ui";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";

const displayName = "Card.Subheadline.Message";

const Subheadline = styled(SubheadlineBase.Themed)`
  text-align: center;
`;

function Message({ id, values, ...props }) {
  return (
    <OptionalSection id={id} values={values}>
      <Subheadline {...props}>
        <OptionalMessage id={id} values={values} />
      </Subheadline>
    </OptionalSection>
  );
}
Message.displayName = displayName;
Message.propTypes = {
  id: PropTypes.string,
  testId: PropTypes.string,
  values: PropTypes.object,
};

Subheadline.Message = Message;

export default Subheadline;
