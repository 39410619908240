import styled from "@emotion/styled";

import { Text } from "@medi24-da2c/web-ui";
import { spacingLarge } from "@medi24-da2c/web-ui/design-tokens";

const ContactInfo = styled(Text)`
  margin-bottom: 0;
  margin-top: ${spacingLarge};
`;

export default ContactInfo;
