import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {
  fontLarge,
  fontWeightBold,
  borderRadius,
} from "@medi24-da2c/web-ui/design-tokens";
import { Text as TextBase } from "@medi24-da2c/web-ui";

const Text = styled(TextBase.Themed)`
  font-size: ${fontLarge};
  font-weight: ${fontWeightBold};
  letter-spacing: 0.1rem;
`;

const Highlight = styled.span`
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: ${borderRadius};
  padding: 0 0.6rem;
`;

const displayName = "EligibilityCheck.SuccessStep.ActivationCode";

function ActivationCode({ children }) {
  return (
    <Text>
      <Highlight>{children}</Highlight>
    </Text>
  );
}

ActivationCode.displayName = displayName;

ActivationCode.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ActivationCode;
