import { OptionalTitle } from "components/OptionalMessage";

// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function WomanWithBinoculars() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 200"
      role="img"
      aria-labelledby="icon-woman-with-binoculars"
    >
      <OptionalTitle
        id="image.womanWithBinoculars"
        titleId="icon-woman-with-binoculars"
      />
      <path
        fill="#19262b"
        d="M64.021 190.879c-45.837-27.394-39.971-52.821-22.716-64.859 17.256-12.038 22.716-8.603 31.64-18.223s-.457-20.957 9.278-35.905c13.386-20.749 24.894-13.416 34.58-24.394C126.489 36.52 131.532.823 168.358.012c36.826-.811 46.966 38.975 46.966 38.975s4.87 85.3-27.582 112.478c-32.451 27.177-36.914 37.602-123.721 39.414z"
      />
      <path
        fill="#f6aa98"
        d="M210.313 136.418l-15.91-7.409V98.436H144.708l.025 30.926-15.177 7.279c.671 17.337 32.886 19.561 39.407 19.899v.05s.22-.006.593-.022c.372.016.592.022.592.022v-.05c6.521-.339 39.494-2.785 40.165-20.122z"
      />
      <path
        fill="#ea8f81"
        d="M179.541 98.436h-34.833l.025 30.926-15.177 7.279c.42 10.836 3.153 15.768 14.242 18.026l35.743-56.231z"
      />
      <path
        fill="#f6aa98"
        d="M170.118 14.266c-12.613 0-23.834 7.584-31.049 19.372-5.238 8.558-8.37 19.327-8.37 31.038 0 27.84 17.649 53.391 39.42 53.391 1.114 0 1.894-.075 3.304-.214 20.224-2.423 36.116-26.761 36.116-53.177-.001-27.841-17.65-50.41-39.421-50.41z"
      />
      <path
        fill="#ea8f81"
        d="M144.159 67.91c0-35.9-2.212-34.055-2.212-34.055-1.133 0-2.16.011-3.128.032-5.238 8.558-8.37 19.327-8.37 31.038 0 27.84 17.649 53.391 39.42 53.391 1.114 0 2.214-.083 3.304-.214-9.373-2.701-29.014-12.819-29.014-50.192z"
      />
      <path
        fill="#19262b"
        d="M203.773 24.205S182.512 4.895 163.48 8.039c-29.857 4.932-36.406 32.625-36.406 32.625s-3.689 10.891 9.721 5.411c7.944-3.247 13.069-9.908 15.927-14.8 8.409 6.202 21.347 12.661 38.755 12.588 19.76-.083 12.296-19.658 12.296-19.658zM211.285 91.285c-13.41 5.48-12.197-8.176-9.721-9.444 14.023-7.177 23.131 3.964 9.721 9.444z"
      />
      <path
        fill="#c99859"
        d="M86.602 156.401l44.032-20.257s10.461 17.272 40.869 17.272c30.409 0 38.193-17.272 38.193-17.272l40.627 18.002 4.865 36.733H82.223l4.379-34.478z"
      />
      <path
        fill="#b98447"
        d="M140.998 145.642c-7.388-4.585-10.364-9.497-10.365-9.498l-44.032 20.257-4.379 34.478h61.323l19.635-37.922c-9.83-1.121-17.064-4.139-22.182-7.315"
      />
      <path
        fill="#f6aa98"
        d="M79.809 143.904l21.529-35.477s-3.639-30.323-2.729-36.387c.91-6.064 3.741-19.103 7.581-23.651 0 0 11.219-10.613 17.89-13.341 6.671-2.729 13.646-.91 14.556 0 .909.91.606 5.457-3.336 5.761-3.942.303-21.832 7.277-20.619 18.193 1.213 10.916 13.039 28.503 13.039 28.503s8.187-5.458 9.097-5.458 16.374-12.431 20.316-7.58c3.942 6.671-5.458 7.656-9.097 14.289-3.638 6.634-10.916 19.671-26.077 28.464-5.761 17.284-12.263 39.182-12.263 39.182l-29.887-12.498zM256.56 143.904l-21.529-35.477s3.639-30.323 2.729-36.387c-.91-6.064-3.742-19.103-7.581-23.651 0 0-11.219-10.613-17.89-13.341-6.671-2.729-13.646-.91-14.556 0-.909.91-.606 5.457 3.336 5.761 3.942.303 21.832 7.277 20.619 18.193-1.213 10.916-13.039 28.503-13.039 28.503s-8.187-5.458-9.097-5.458c-.91 0-16.374-12.431-20.316-7.58-3.942 6.671 5.458 7.656 9.097 14.289 3.638 6.634 10.916 19.671 26.077 28.464 5.761 17.284 12.263 39.182 12.263 39.182l29.887-12.498z"
      />
      <path
        fill="#ea8f81"
        d="M166.136 88.736l-.01-.001a.994.994 0 01-.901-1.082l2.514-27.739c.05-.546.448-1.006 1.082-.898.547.048.95.533.899 1.08l-2.515 27.738a.998.998 0 01-1.069.902zM114.16 111.937c1.854-6.307-12.361-11.047-12.741-40.015-.088-6.707.125-13.114.125-13.114-1.428 4.834-2.255 10.05-2.934 13.232-2.526 11.824 2.729 36.387 2.729 36.387L79.81 143.904l8.217 3.435 7.601 3.512c-.001 0 16.678-32.607 18.532-38.914zM175.146 97.473c-2.337 1.122-5.391 1.641-9.092.28a.998.998 0 01-.589-1.278.999.999 0 011.278-.589c7.784 2.863 12.008-3.993 12.183-4.285a.998.998 0 011.366-.343.99.99 0 01.339 1.364c-.031.052-1.935 3.148-5.485 4.851zM221.689 59.001L208.65 87.504s-8.187-5.458-9.097-5.458c-.91 0-16.374-12.431-20.316-7.58-3.942 6.671 5.458 7.656 9.097 14.289 3.638 6.634 10.916 19.671 26.077 28.464 5.761 17.284 12.263 39.182 12.263 39.182l8.096-3.386-14.149-37.93c-12.852-34.454 10.482-51.895 1.068-56.084z"
      />
      <path
        fill="#008390"
        d="M201.98 38.987c-7.745 0-14.574 3.924-18.606 9.892-3.582-2.742-7.61-3.946-10.646-4.473a6.255 6.255 0 00-4.543-1.953 6.255 6.255 0 00-4.543 1.953c-3.036.527-7.064 1.731-10.646 4.473-4.032-5.968-10.861-9.892-18.606-9.892-12.392 0-22.438 10.046-22.438 22.438s10.046 22.438 22.438 22.438 22.439-10.046 22.439-22.438c0-.97-.068-1.923-.188-2.861 2.116-2.924 5.417-4.174 7.923-4.708a6.243 6.243 0 003.621 1.155c1.35 0 2.597-.43 3.621-1.155 2.506.534 5.807 1.784 7.923 4.708a22.682 22.682 0 00-.188 2.86c0 12.393 10.046 22.438 22.438 22.438 12.393 0 22.439-10.046 22.439-22.438s-10.046-22.437-22.438-22.437z"
      />
      <path
        fill="#016f79"
        d="M164.082 48.732a4.103 4.103 0 118.206.002 4.103 4.103 0 01-8.206-.002z"
      />
      <path
        fill="#c99859"
        d="M256.863 139.355l-30.523 16.071L241.602 200h49.83zM79.506 139.355l30.523 16.071L94.767 200H44.938z"
      />
      <path
        fill="#b98447"
        d="M253.447 200h-11.845l-15.262-44.574 7.764-4.089zM72.182 200H44.938l34.568-60.645 17.266 9.091z"
      />
      <circle fill="#c2dde5" cx="201.875" cy="61.425" r="17.586" />
      <path
        fill="#e6f2f6"
        d="M198.483 54.99c-2.173 0-1.774.598-4.434.598-2.166 0-3.363-3.049-6.511-4.183a17.482 17.482 0 00-2.963 7.632h18.387c-.001 0-.71-4.047-4.479-4.047z"
      />
      <path
        fill="#a6d7d9"
        d="M201.98 79.012c7.774 0 14.356-5.049 16.681-12.042H185.3c2.324 6.993 8.906 12.042 16.68 12.042z"
      />
      <circle fill="#c2dde5" cx="134.389" cy="61.425" r="17.586" />
      <path
        fill="#a6d7d9"
        d="M134.389 79.012c7.774 0 14.357-5.049 16.681-12.042h-33.361c2.323 6.993 8.907 12.042 16.68 12.042z"
      />
      <path
        fill="#e6f2f6"
        d="M129.724 57.199h18.903s-1.364-5.533-6.662-5.499c-3.516 0-4.334 3.144-6.152 3.144s-1.545-.408-3.029-.408c-2.575-.001-3.06 2.763-3.06 2.763z"
      />
    </svg>
  );
}

export default WomanWithBinoculars;
