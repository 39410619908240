import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { spacingMedium } from "@medi24-da2c/web-ui/design-tokens";
import { OptionalMessageOr, makeIdOptions } from "components/OptionalMessage";
import { ENTER_CODE_STEP, GENERATE_CODE_STEP } from "constants/steps";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import NextStepButton from "./NextStepButton";
import InfoLineExpand from "./InfoLineExpand";

const Buttons = styled.ul`
  margin: 0;
  padding: 0;
  padding-bottom: ${spacingMedium};
`;

const displayName = "InitialStep";

const key = "initialStep";
const idOptions = makeIdOptions(key);

/*
  For reference MyDoc alternate messages:
  id="chooseMessengerPage.generateActivationCodeModal.initialStep.MyDoc.info"
  id="chooseMessengerPage.generateActivationCodeModal.initialStep.MyDoc.enterActivationCodeScenarioButton"
  id="chooseMessengerPage.generateActivationCodeModal.initialStep.MyDoc.generateNewCodeScenarioButton"
*/

function InitialStep({ idInfix, setStep }) {
  useTrackPageEffect("ELIGIBILITY_MENU_CONTENT");

  return (
    <>
      <InfoLineExpand
        info={idOptions(
          "chooseMessengerPage.generateActivationCodeModal.initialStep.info",
          idInfix
        )}
        help={idOptions(
          "chooseMessengerPage.generateActivationCodeModal.initialStep.help",
          idInfix
        )}
      />
      <Buttons>
        <NextStepButton onClick={() => setStep(ENTER_CODE_STEP)}>
          <OptionalMessageOr
            {...idOptions(
              "chooseMessengerPage.generateActivationCodeModal.initialStep.enterActivationCodeScenarioButton",
              idInfix
            )}
          />
        </NextStepButton>
        <NextStepButton onClick={() => setStep(GENERATE_CODE_STEP)}>
          <OptionalMessageOr
            {...idOptions(
              "chooseMessengerPage.generateActivationCodeModal.initialStep.generateNewCodeScenarioButton",
              idInfix
            )}
          />
        </NextStepButton>
      </Buttons>
    </>
  );
}

InitialStep.displayName = displayName;

InitialStep.propTypes = {
  idInfix: PropTypes.string,
  setStep: PropTypes.func.isRequired,
};

export default InitialStep;
