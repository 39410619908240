// A message Id button which is full width on small screens but limited size on a Tablet
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { TABLET_MEDIA } from "@medi24-da2c/web-ui/media";
import SimpleMessageButton from "components/SimpleMessageButton";

const displayName = "MessageButton";

const MessageButtonStyled = styled(SimpleMessageButton)`
  @media ${TABLET_MEDIA} {
    margin: 0 auto;
  }
`;

function MessageButton({ align, ...props }) {
  return align === "center" ? (
    <MessageButtonStyled {...props} />
  ) : (
    <SimpleMessageButton {...props} />
  );
}

MessageButton.displayName = displayName;
MessageButton.propTypes = {
  ...SimpleMessageButton.propTypes,
  align: PropTypes.string,
};

export default MessageButton;
