import PropTypes from "prop-types";
import ConsentFormBase from "./ConsentFormBase";

const displayName = "ConsentForm";

const checkBoxList = ["termsOfUse", "privacyPolicy", "allowNotifications"];

function ConsentForm({ chat, messengerLink }) {
  function makeRedirectUrl(agreements) {
    // Navigate to messenger URL and append notifications param if allowNotifications checkbox was checked
    const messengerLinkWithAgreements = agreements.allowNotifications
      ? messengerLink + "_AllowNotifications"
      : messengerLink;
    return messengerLinkWithAgreements;
  }

  return (
    <ConsentFormBase
      chat={chat}
      idList={checkBoxList}
      onRedirectUrl={makeRedirectUrl}
    />
  );
}
ConsentForm.displayName = displayName;
ConsentForm.propTypes = {
  chat: PropTypes.string.isRequired,
  messengerLink: PropTypes.string.isRequired,
};

export default ConsentForm;
