// A message Id button with loading spinner which is full width on small screens but limited size on a Tablet
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Loader as LoaderBase } from "@medi24-da2c/web-ui";
import { zIndexDefault } from "@medi24-da2c/web-ui/design-tokens";
import MessageButton from "./MessageButton";

const displayName = "LoadingButton";

const LoaderStyled = styled(LoaderBase)`
  z-index: ${zIndexDefault};
  background-color: transparent;
  color: ${(themedProps) => {
    return themedProps.inverse
      ? themedProps.theme.button.bgrColor
      : themedProps.theme.button.textColor;
  }};
`;

function LoadingButton({ disabled, inverse, loading, ...props }) {
  const theme = useTheme();
  disabled = disabled || loading;
  const suffix = loading ? (
    <LoaderStyled
      inverse={inverse}
      theme={theme}
      data-testid="loadingButton.spin"
    />
  ) : (
    void 0
  );

  return (
    <MessageButton
      {...props}
      inverse={inverse}
      disabled={disabled}
      suffix={suffix}
    />
  );
}
LoadingButton.displayName = displayName;
LoadingButton.propTypes = {
  ...MessageButton.propTypes,
  loading: PropTypes.bool,
};

export default LoadingButton;
