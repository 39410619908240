import baseConfig from "../mc-asia/config";
import msInherited from "./translations/ms.inherit.json";
import msOverrides from "./translations/ms.json";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh mc-asia subpartner mc-core-my 5 Malaysia digitalhealthxtra my allianz mydoc MCCOREMY en ms

// DEVTEST Test Malaysia with Mastercard: 2020202026
const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHXTRA_MY_5",
    partnerCountry: "Malaysia",
    subPartnerCode: "MCCOREMY",
  },
  features: {
    ...baseConfig.features,
    bilingualPrivacy: ["en", "ms"],
    noMedicalHotline: true,
  },
  // languages: "ms en".split(" "),
  languages: "en".split(" "),
  messages: {
    ms: { ...msInherited, ...msOverrides },
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
