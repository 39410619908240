import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Headline as HeadlineBase } from "@medi24-da2c/web-ui";
import { spacingLarge, spacingMedium } from "@medi24-da2c/web-ui/design-tokens";
import { OptionalMessage } from "components/OptionalMessage";

const displayName = "ChooseMessengerPage.Headline.Message";

const Headline = styled(HeadlineBase.Themed)`
  padding-right: ${spacingMedium};
  padding-left: ${spacingMedium};
  margin-bottom: ${spacingLarge};
`;

function Message({ id, values }) {
  return (
    <Headline>
      <OptionalMessage id={id} values={values} />
    </Headline>
  );
}
Message.displayName = displayName;
Message.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};
Headline.Message = Message;

export default Headline;
