import baseConfig from "../mc-asia/config";
import viInherited from "./translations/vi.inherit.json";
import viOverrides from "./translations/vi.json";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh mc-asia subpartner mc-core-vn 9 Vietnam digitalhealthxtra vn allianz mydoc MCCOREVN en vi

// DEVTEST Test Vietnam with Mastercard: 2020202024
const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHXTRA_VN_9",
    partnerCountry: "Vietnam",
    subPartnerCode: "MCCOREVN",
  },
  languages: "vi en".split(" "),
  messages: {
    en: { ...enInherited, ...enOverrides },
    vi: { ...viInherited, ...viOverrides },
  },
};

export default config;
