import styled from "@emotion/styled";

const CustomButtons = styled.div`
  margin: 0 auto;
  padding: 2rem 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 45rem;
`;

export default CustomButtons;
