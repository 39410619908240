// const { FONT, hasKeys } = require("./theme");

const PARTNER_NAME = process.env.REACT_APP_PARTNER_NAME || "medi24";
const INCLUDE = `./${PARTNER_NAME}`;

// There seems to be no way to import these without breaking something...
// between unit tests, storybook, dev hotloader and production build something
// fishy is going on...
const FONT =
  '"Allianz Neo", Helvetica, Arial, "Microsoft YaHei New", "Microsoft Yahei", SimSun, STXihei, sans-serif';

const configPartner = require(`${INCLUDE}/config`).default;
const theme = { ...require(`${INCLUDE}/theme`).default };
// Apply global fontFamily if no partner override
theme.fontFamily = theme.fontFamily || FONT;

const config = {
  // module.exports = {
  // NOTE: changes here MUST be mimiced in __dev__/getPartnerConfig
  // so that Unit tests and webpack builds are coherent
  partner: PARTNER_NAME,
  infra: require(`${INCLUDE}/infra.json`),
  config: configPartner,
  theme,
  Logotype: theme.header.logo || require(`${INCLUDE}/Logotype`).default,
  SecondLogo: configPartner.features.secondLogo || null,
  IntroConversationBgr:
    theme.landingPageGeneralInfo.bgrImage ||
    require(`${INCLUDE}/IntroConversationBgr.jpg`),
  IntroConversationBgrMobile:
    theme.landingPageGeneralInfo.bgrImageMobile ||
    require(`${INCLUDE}/IntroConversationBgrMobile.jpg`),
};

if (process.env.NODE_ENV === "development") {
  window.console.log("PARTNER_NAME", PARTNER_NAME);
  // window.console.log("PARTNER_GROUP", config.config.general.partnerGroup);
  // window.console.log("CONFIG", config);
}

export default config;
