import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Text as TextBase, fontSmall } from "@medi24-da2c/web-ui";
import { OptionalMessage } from "components/OptionalMessage";

const displayName = "LandingPage.Text";

const styleAvailabilityIndicator = {
  fontSize: fontSmall,
  fontStyle: "italic",
};

const TextStyled = styled(TextBase.Themed)`
  color: ${(themedProps) => themedProps.theme.landingPageGeneralInfo.textColor};

  &:last-child {
    margin-bottom: 0;
  }
`;

function Text(props) {
  const theme = useTheme();
  return <TextStyled theme={theme} {...props} />;
}
Text.displayName = displayName;

function Messages({ id, idAvailable, values }) {
  return (
    <Text>
      <OptionalMessage id={id} values={values} />
      <br />
      <OptionalMessage style={styleAvailabilityIndicator} id={idAvailable} />
    </Text>
  );
}
Messages.displayName = `${displayName}.Messages`;
Messages.propTypes = {
  ...OptionalMessage.propTypes,
  idAvailable: PropTypes.string,
};
Text.Messages = Messages;

export default Text;
