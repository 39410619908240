function isValidConsentForm(agreements) {
  let valid = true;
  const { termsOfUse, privacyPolicy } = agreements;

  if (!termsOfUse || !privacyPolicy) {
    valid = false;
  }

  return valid;
}

export default isValidConsentForm;
