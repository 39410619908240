import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import makeTrackedClick from "tracking/makeTrackedClick";
import platform from "../../utils/platform";
import SuccessStepBase from "../SuccessStep";
import useActivationCode from "./useActivationCode";

const displayName = "MyDocModal.SuccessStep";

function MyDocModalSuccessStep({
  activationCodeDetails,
  setStep,
  setErrorCode,
}) {
  const [loading, setLoading] = useState(false);
  const { willGetInvitationUrl } = useActivationCode({
    loading,
    onSetLoading: setLoading,
    setStep,
    setInviteUrl: platform.redirect,
    setErrorCode,
  });

  const willHandleContinueButtonClick = useCallback(async () => {
    const activationCode = activationCodeDetails.activationCode;
    willGetInvitationUrl(activationCode);
  }, [willGetInvitationUrl, activationCodeDetails]);
  const clickTrack = makeTrackedClick(
    "START_TELECONSULT_CLICK",
    void 0,
    willHandleContinueButtonClick
  );

  // upsellSuffix changes the upsell message Id in the Success Modal
  // id=chooseMessengerPage.generateActivationCodeModal.successStep.upsellMyDoc
  return (
    <SuccessStepBase
      idInfix="MyDoc"
      upsellSuffix="MyDoc"
      activationCodeDetails={activationCodeDetails}
      loading={loading}
      onSetLoading={setLoading}
      onContinueButtonClick={clickTrack}
    />
  );
}
MyDocModalSuccessStep.displayName = displayName;
MyDocModalSuccessStep.propTypes = {
  activationCodeDetails: PropTypes.shape({
    activationCode: PropTypes.string.isRequired,
    // No longer used, provided by back end api
    // inviteId: PropTypes.string.isRequired,
  }).isRequired,
  setStep: PropTypes.func.isRequired,
  setErrorCode: PropTypes.func.isRequired,
};

export default MyDocModalSuccessStep;
