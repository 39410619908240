import PropTypes from "prop-types";
import { FormCheckbox as Checkbox } from "@medi24-da2c/web-ui";

const displayName = "FormCheckbox";

function FormCheckbox({ value, ...props }) {
  // So far all checkboxes' values are either true or false
  // Therefore direct mapping of value to checked prop
  return <Checkbox {...props} value={value} checked={value} />;
}
FormCheckbox.displayName = displayName;
FormCheckbox.propTypes = {
  value: PropTypes.bool.isRequired,
};

export default FormCheckbox;
