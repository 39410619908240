import styled from "@emotion/styled";

import { spacingMedium } from "@medi24-da2c/web-ui/design-tokens";

const ScrollableContent = styled.section`
  padding: 0 ${spacingMedium};
  overflow-y: auto;
`;

export default ScrollableContent;
