import { useState } from "react";
import ActivationCodeForm from "components/ActivationCodeForm";
import { WomanWithTabletAndPlant } from "components/Icons";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import useNavigate from "hooks/useNavigate";
import BasicInfoPage from "../BasicInfoPage";
import Wrapper from "./Wrapper";

const displayName = "WrongActivationCodePage";

function WrongActivationCodePage() {
  useTrackPageEffect("WRONG_CODE_PAGE");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function handleSubmit(activationCode) {
    navigate.toWrongCode(activationCode);
  }

  return (
    <BasicInfoPage
      headlineId={"wrongActivationCodePage.title"}
      textId={"wrongActivationCodePage.overview"}
      illustration={<WomanWithTabletAndPlant />}
      extraContent={
        <Wrapper>
          <ActivationCodeForm
            showErrorsInPlace
            loading={loading}
            onSetLoading={setLoading}
            onSubmit={handleSubmit}
          />
        </Wrapper>
      }
    />
  );
}
WrongActivationCodePage.displayName = displayName;

export default WrongActivationCodePage;
