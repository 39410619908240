import styled from "@emotion/styled";
import { Content as ContentBase } from "@medi24-da2c/web-ui";

const displayName = "ContentHalf";
const displayName2 = "ContentHalfRight";

export const ContentHalf = styled(ContentBase)`
  width: 50%;
`;
ContentHalf.displayName = displayName;

// TODO(2021-05) should be using @emotion/styled instead of inline styles
const stylePanelRight = {
  display: "flex",
  flexDirection: "column",
  paddingTop: "3rem",
  paddingLeft: "8rem",
};

export function ContentHalfRight(props) {
  return (
    <ContentHalf data-testid={props["data-testid"]} style={stylePanelRight}>
      {props.children}
    </ContentHalf>
  );
}
ContentHalfRight.displayName = displayName2;
