import ImageCarousel from "components/ImageCarousel";

const displayName = "MyDocOverview";

const cards = [
  "landingPage.myDoc.slide1.headline",
  "landingPage.myDoc.slide2.headline",
  "landingPage.myDoc.slide3.headline",
];

function MyDocOverview() {
  return (
    <ImageCarousel
      id="teleconsultation-carousel"
      data-testid="mydoc-overview"
      cards={cards}
    />
  );
}
MyDocOverview.displayName = displayName;

export default MyDocOverview;
