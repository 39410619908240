import { OptionalMessage } from "../OptionalMessage";
import Text from "./Text";

const displayName = "ModalBase.Message";

function Message({ id, toolTip, toolTipId, values }) {
  return (
    <Text>
      <OptionalMessage
        id={id}
        values={values}
        toolTipId={toolTipId}
        toolTip={toolTip}
      />
    </Text>
  );
}
Message.displayName = displayName;
Message.propTypes = {
  ...OptionalMessage.propTypes,
};

export default Message;
