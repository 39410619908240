import PropTypes from "prop-types";
import { INVALID_ERROR, EXPIRED_ERROR, GENERIC_ERROR } from "api/errorCodes";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import SimpleText from "components/SimpleText";

const displayName = "ErrorMessage";

function ErrorMessage({ errorCode }) {
  const errorIds = {
    [INVALID_ERROR]: "chooseMessengerPage.activationCodeModal.invalidError",
    [EXPIRED_ERROR]: "chooseMessengerPage.activationCodeModal.validationError",
    [GENERIC_ERROR]: "chooseMessengerPage.activationCodeModal.genericError",
  };

  useTrackPageEffect("ERROR_ELIGIBILITY_CONTENT");

  return <SimpleText id={errorIds[errorCode]} />;
}

ErrorMessage.displayName = displayName;

ErrorMessage.propTypes = {
  errorCode: PropTypes.string.isRequired,
};

export default ErrorMessage;
