import { OptionalTitle } from "components/OptionalMessage";

// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function ManWithBeardPointingAtApps() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2493.91 1813.51"
      width="100%"
      height="275px"
      role="img"
      aria-labelledby="icon-man-pointing-at-apps-title"
    >
      <OptionalTitle
        id="image.manWithBeardPointingAtApps"
        titleId="icon-man-pointing-at-apps-title"
      />
      <defs>
        <style>
          {`.cls-mbpa-1{fill:#99d9da;}`}
          {`.cls-mbpa-2{fill:#000001;}`}
          {`.cls-mbpa-3{fill:#fca172;}`}
          {`.cls-mbpa-4{fill:#100206;}`}
          {`.cls-mbpa-5{fill:#fff;}`}
          {`.cls-mbpa-6{fill:#f35451;}`}
          {`.cls-mbpa-7{fill:#e23333;}`}
          {`.cls-mbpa-8{fill:#181819;}`}
          {`.cls-mbpa-9{fill:url(#icon-man-pointing-at-apps-linear-gradient);}`}
          {`.cls-mbpa-10{fill:url(#icon-man-pointing-at-apps-linear-gradient-2);}`}
          {`.cls-mbpa-11{fill:url(#icon-man-pointing-at-apps-linear-gradient-3);}`}
          {`.cls-mbpa-12{fill:#888a8e;}`}
          {`.cls-mbpa-13{fill:#f6f6f6;}`}
          {`.cls-mbpa-14{fill:#d5d5d5;}`}
          {`.cls-mbpa-15{fill:#f4f4f4;}`}
          {`.cls-mbpa-16{fill:#713535;}`}
          {`.cls-mbpa-17{fill:#c40000;}`}
          {`.cls-mbpa-18{fill:#a66a18;}`}
          {`.cls-mbpa-19{fill:#00d254;}`}
          {`.cls-mbpa-20{fill:#e83363;}`}
          {`.cls-mbpa-21{fill:#ddd;}`}
        </style>
        <linearGradient
          id="icon-man-pointing-at-apps-linear-gradient"
          x1="1218.48"
          y1="835.59"
          x2="1222.16"
          y2="835.59"
          gradientTransform="matrix(0, -74.8, -74.8, 0, 63418.07, 91776.52)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#20b038" />
          <stop offset="1" stopColor="#60d66a" />
        </linearGradient>
        <linearGradient
          id="icon-man-pointing-at-apps-linear-gradient-2"
          x1="1218.21"
          y1="835.4"
          x2="1221.88"
          y2="835.4"
          gradientTransform="matrix(0, -77.5, -77.5, 0, 65659.05, 95050.3)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f9f9f9" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="icon-man-pointing-at-apps-linear-gradient-3"
          x1="1279.72"
          y1="88.12"
          x2="1279.72"
          y2="373.34"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#7ad4fa" />
          <stop offset="1" stopColor="#3d62eb" />
        </linearGradient>
      </defs>
      <path
        id="icon-man-pointing-at-apps-blob"
        className="cls-mbpa-1"
        d="M2513.39,1331.7c38.15-57.09,166.45-249.09,146.65-482.53-47.88-564.76-1077.92-977-1443.88-707.51-203.46,149.83-39.68,245.26-449,487.84C419,835.89,173.45,810.07,168.26,1110.08c-7.9,455.93,798.52,806.1,1574,661.56C1897.42,1742.74,2287.29,1670.08,2513.39,1331.7Z"
        transform="translate(-168.2 -57.12)"
      />
      <g id="icon-man-pointing-at-apps-man-pointing">
        <polygon
          className="cls-mbpa-2"
          points="1505.88 1344.81 1060.84 1344.81 1035.47 1813.51 1510.44 1813.51 1505.88 1344.81"
        />
        <path
          className="cls-mbpa-3"
          d="M1113.69,1114.13l-101,58.08s-23.16-226.09-21.45-248.87,40.35-47.67,61.18-67.69-45-6.65-45-6.65,15.94-117,1.33-113.29-29.2,84.91-29.2,84.91-5.58-30-22.55-10.35c0,0-11.28-15.64-25,3.73,0,0-12.83-22.21-26.91,13.67s17,98.41,8.88,113.84-99.85,345.75-32.65,411.24,287-64.92,287-64.92Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-3"
          d="M1803.32,1141.75s203.35,217.83,208.47,260S1803.36,1666,1803.36,1666s-84.29,33.6-108.78,61.5-121,70-15.78-71.76l-2.83-24.59s46.52,2.95,67.59-14.13,108.2-173.36,115.61-201.15c0,0-194.36-82.44-196.27-132.86S1803.32,1141.75,1803.32,1141.75Z"
          transform="translate(-168.2 -57.12)"
        />
        <ellipse
          className="cls-mbpa-3"
          cx="1288.04"
          cy="725.27"
          rx="90.84"
          ry="122.16"
        />
        <ellipse
          className="cls-mbpa-3"
          cx="1541.95"
          cy="791.5"
          rx="25.91"
          ry="15.91"
          transform="matrix(0.55, -0.83, 0.83, 0.55, -136.39, 1585.01)"
        />
        <ellipse
          className="cls-mbpa-3"
          cx="1365.41"
          cy="793.78"
          rx="15.91"
          ry="25.91"
          transform="translate(-368.21 527.19) rotate(-22.55)"
        />
        <path
          className="cls-mbpa-3"
          d="M1385.91,833.36c4.55,7.4,24.49,107.58,0,126.12s-43.28,27.65-43.28,27.65,60.93,101.41,140.95,57,74.89-63.81,74.89-63.81-33.6-12-36.45-51.82-1.71-100.8-1.71-100.8Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-2"
          d="M1476.46,667.53s-12,34.64-39.3,40.3c-16.61,3.43-53.78.45-59.24,17.76-3.71,11.76-15.57,46.73-12.5,56.74,0,0-40.45-131.76-13.68-162.16s78.94-36,104.43-30.38c48.35,10.6,63,59,90.91,53.88s43.28,45.07,34.74,68.4-35.47,75.88-44.22,58.79S1482.72,717.72,1476.46,667.53Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-4"
          d="M1527.15,745.09s21.2,75.71-71.19,77.45c-90.55,1.71-82.31-32.47-92.83-68.34,0,0-22.21,155.47,79.16,158.89S1555.62,810,1539.67,755.34,1527.15,745.09,1527.15,745.09Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-5"
          d="M1423.5,834.5s39.86,8.62,60.08-2c0,0-3.36,37.76-30.3,32.38S1423.5,834.5,1423.5,834.5Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          d="M1459.92,811.13a9.15,9.15,0,0,1-4.3-1c-4-2.11-5.2-7.26-5.88-12.5-1.39-10.75,2.78-40.74,3-42a.85.85,0,0,1,1-.72.86.86,0,0,1,.72,1c-.05.31-4.32,31-3,41.56.59,4.56,1.65,9.46,5,11.21s8.55.59,16.24-3.38a.86.86,0,0,1,1.15.37.85.85,0,0,1-.37,1.14C1467.79,809.69,1463.37,811.13,1459.92,811.13Z"
          transform="translate(-168.2 -57.12)"
        />
        <ellipse cx="1328.03" cy="710.15" rx="8.41" ry="11.66" />
        <ellipse cx="1244.05" cy="710.32" rx="8.41" ry="11.66" />
        <path
          className="cls-mbpa-6"
          d="M1715.72,1008.45s-72.52-44.17-179.28-44.1c0,0-2.11,59-83.16,49.39s-80.61-45.17-80.61-45.17-99.87,1.35-114.5,21c-13.38,18-47.82,543.49-53.66,633.85-.46,7.16,6.42,13.17,15.06,13.17h467.6c8.23,0,14.93-5.46,15.08-12.27Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-6"
          d="M1311.56,974.86s-75.1,12.81-129.78,40.72c-38.81,19.81-80.94,52-102.12,69a28.94,28.94,0,0,0-10.21,28.54c11.88,56.47,49.5,232.89,56.2,236.24,8,4,123-14.81,142.94-46.7S1311.56,974.86,1311.56,974.86Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-6"
          d="M1634.63,1003.88c65.36-38.74,224,103.06,241.08,185.07,0,0-62.45,113.31-128.61,182-7,7.22-37.86-19.46-44.79-26.72L1617.15,1255S1580.49,1036,1634.63,1003.88Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          d="M944.74,870.32a11.33,11.33,0,0,1-1.9-.16,9.56,9.56,0,0,1-6.55-4.29c-1.89-2.85-2.65-6.69-2.83-10.48-1.54,5.71-3.9,11.54-7.37,13.78a5.81,5.81,0,0,1-5.21.66c-11.43-3.81-8-42.31-7.82-44a.63.63,0,0,1,.69-.57.62.62,0,0,1,.57.69c0,.39-3.52,39.13,7,42.62a4.57,4.57,0,0,0,4.12-.51c4.41-2.86,6.94-12.51,8.22-19.31a59.71,59.71,0,0,1,1.09-7.17.64.64,0,0,1,.73-.48.62.62,0,0,1,.52.69c0,.33-.33,3.24-1.05,7.08-.46,5.16-.37,12,2.44,16.24a8.31,8.31,0,0,0,5.71,3.74,8.11,8.11,0,0,0,6.75-1.65c4.22-3.37,6-10.71,6.73-15.37a39,39,0,0,1,.49-4.26.65.65,0,0,1,.7-.52.64.64,0,0,1,.56.67c0,.2-.1,1.92-.48,4.34-.18,3.33.05,7.72,2.13,9.75a4.44,4.44,0,0,0,4.29.94c7.84-1.65,11.44-31,12.17-42.18a.63.63,0,0,1,.67-.59.64.64,0,0,1,.6.67,206.33,206.33,0,0,1-2.59,20.66c-2.59,14.12-6.15,21.75-10.59,22.68a5.66,5.66,0,0,1-5.44-1.27,9.55,9.55,0,0,1-2.33-5.48c-1.1,4.07-3,8.45-6.11,11A9.19,9.19,0,0,1,944.74,870.32Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-7"
          d="M1231.55,1327.65a184.88,184.88,0,0,0,16.38-21.82,187.18,187.18,0,0,0,12.93-23.78,192.58,192.58,0,0,0,9.49-25.59A186.73,186.73,0,0,0,1276,1230a183.67,183.67,0,0,0,1.83-27.13c0-1.13,0-2.25-.06-3.38a1.27,1.27,0,0,0-2.54,0,182.19,182.19,0,0,1-1.29,26.82,184.14,184.14,0,0,1-5.16,26.22,186.86,186.86,0,0,1-21.23,49.07,180,180,0,0,1-15.64,21.69c-.72.86-1.46,1.72-2.2,2.57a1.31,1.31,0,0,0,0,1.8,1.29,1.29,0,0,0,1.8,0Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-7"
          d="M1712.68,1350.53l-19.18-28.16c-6.2-9.1-12.52-18.12-18.45-27.4a172.55,172.55,0,0,1-14.84-27.81,149.49,149.49,0,0,1-8.61-29.52,146.43,146.43,0,0,1-2.31-30.58c0-1.29.1-2.57.17-3.86.09-1.64-2.45-1.63-2.54,0a150.07,150.07,0,0,0,9.37,60.91,164.59,164.59,0,0,0,14.19,28.38c5.76,9.33,12.08,18.32,18.25,27.38l19.35,28.41,2.41,3.54c.91,1.34,3.12.07,2.19-1.29Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-7"
          d="M1386.81,1000.31a96.45,96.45,0,0,0,28.86,17.41,139.6,139.6,0,0,0,31,7.59c10.2,1.42,20.6,2.69,30.9,1.7a70.72,70.72,0,0,0,27.48-8.23,60.75,60.75,0,0,0,21-19.3c.7-1.05,1.37-2.11,2-3.19a1.27,1.27,0,0,0-2.19-1.29,58.36,58.36,0,0,1-19.32,20,66.23,66.23,0,0,1-26,9.17c-10,1.38-20,.4-29.91-.83a151,151,0,0,1-30.46-6.61,93.82,93.82,0,0,1-28.28-15.38q-1.7-1.35-3.33-2.79c-1.22-1.07-3,.72-1.8,1.8Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-8"
          d="M1451.74,1860.76q.62-41,1.22-81.93.61-40.75,1.22-81.51l.69-46c0-2.46-3.78-2.46-3.81,0l-1.23,81.93-1.22,81.51-.69,46c0,2.46,3.78,2.46,3.82,0Z"
          transform="translate(-168.2 -57.12)"
        />
      </g>
      <g id="icon-man-pointing-at-apps-whatsapp-icon">
        <path
          className="cls-mbpa-5"
          d="M774.14,639.14,794,566a138.91,138.91,0,0,1-18.75-70.57,141.13,141.13,0,0,1,241.12-99.61c26.83,26.83,41.16,62.12,41.16,100A141.11,141.11,0,0,1,848.75,619.66Zm78.29-45.21,4.41,2.57a118.65,118.65,0,1,0-39.7-39l2.94,4.41-11.76,43.38Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-9"
          d="M778.92,634l19.48-70.58c-11.76-20.58-18.38-44.1-18-68,0-75,61-136.37,136.36-136.37a135.43,135.43,0,0,1,96.3,40.07c25.73,25.73,40.06,59.91,39.7,96.3,0,75-61,136.36-136.37,136.36a137.87,137.87,0,0,1-65-16.54Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-10"
          d="M774.14,639.14,794,566a138.91,138.91,0,0,1-18.75-70.57,141.13,141.13,0,0,1,241.12-99.61c26.83,26.83,41.16,62.12,41.16,100A141.11,141.11,0,0,1,848.75,619.66Zm78.29-45.21,4.41,2.57a118.65,118.65,0,1,0-39.7-39l2.94,4.41-11.76,43.38Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-5"
          d="M881.1,436.25c-2.58-5.88-5.52-5.88-8.09-6.25H866.4a13,13,0,0,0-9.56,4.41c-3.31,3.68-12.5,12.13-12.5,29.41s12.5,34.18,14.34,36.38,24.26,39,60.28,53.3c29.77,11.76,36,9.56,42.26,8.82,6.62-.73,21-8.45,23.9-16.91,2.94-8.08,2.94-15.43,2.2-16.9s-3.31-2.21-6.61-4c-3.68-1.83-21-10.29-24.26-11.39s-5.52-1.84-8.09,1.84c-2.21,3.67-9.19,11.39-11,14-2.2,2.21-4,2.58-7.72.74s-15.06-5.51-28.3-17.64c-10.66-9.19-17.64-21-19.48-24.26-2.2-3.68-.36-5.52,1.47-7.35a47.05,47.05,0,0,0,5.15-6.25c1.84-2.21,2.2-3.68,3.67-5.88a6.28,6.28,0,0,0-.36-6.25C890.65,460.14,884.41,443.23,881.1,436.25Z"
          transform="translate(-168.2 -57.12)"
        />
        <circle className="cls-mbpa-11" cx="1279.72" cy="230.73" r="142.61" />
        <polygon
          className="cls-mbpa-12"
          points="1267.72 316.37 1329.27 145.09 1255.85 262.57 1267.72 316.37"
        />
        <polygon
          className="cls-mbpa-13"
          points="1179.77 244.21 1352.41 285.87 1329.27 145.09 1179.77 244.21"
        />
        <polygon
          className="cls-mbpa-14"
          points="1224.5 255.01 1267.72 316.37 1255.85 262.57 1329.27 145.09 1224.5 255.01"
        />
      </g>
      <g id="icon-man-pointing-at-apps-telegram-icon">
        <path
          className="cls-mbpa-15"
          d="M1780.05,429.07V568.66a36.92,36.92,0,0,0,36.81,36.82h202.49a36.93,36.93,0,0,0,36.81-36.82V429.07Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-16"
          d="M2019.35,384.59H1816.86a36.92,36.92,0,0,0-36.81,36.81v12.28h276.11V421.4A36.91,36.91,0,0,0,2019.35,384.59Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-15"
          d="M1989.56,421.4H1889.09a8.85,8.85,0,0,1-8.82-8.82v-.77a8.85,8.85,0,0,1,8.82-8.82h100.47a8.85,8.85,0,0,1,8.83,8.82v.77A8.85,8.85,0,0,1,1989.56,421.4Z"
          transform="translate(-168.2 -57.12)"
        />
        <circle className="cls-mbpa-17" cx="1640.71" cy="355.08" r="9.2" />
        <circle className="cls-mbpa-18" cx="1665.16" cy="355.08" r="9.2" />
        <circle className="cls-mbpa-19" cx="1689.61" cy="355.08" r="9.2" />
        <path
          className="cls-mbpa-5"
          d="M1960.15,643.19c-40.21-5.08-70.74-34-70.74-68.32,0-38.16,37.41-69.21,83.38-69.21s83.37,31.05,83.37,69.21c0,25.53-13.27,39.92-18.14,44.66-27.52,29.69-71.29,43.45-73.08,44l-10.29,3.29Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-20"
          d="M2050,574.87c0-34.83-34.58-63.07-77.24-63.07s-77.23,28.24-77.23,63.07c0,33.43,31.89,60.71,72.17,62.85l-4.65,20s43.7-13.48,70.45-42.36C2038.08,610.9,2050,598.18,2050,574.87Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-5"
          d="M1869.93,566c40.2-5.08,70.73-34,70.73-68.33,0-38.15-37.4-69.2-83.37-69.2s-83.38,31.05-83.38,69.2c0,25.54,13.28,39.93,18.14,44.66,27.53,29.7,71.3,43.45,73.08,44l10.3,3.3Z"
          transform="translate(-168.2 -57.12)"
        />
        <path
          className="cls-mbpa-21"
          d="M1780.05,497.62c0-34.83,34.58-63.06,77.23-63.06s77.24,28.23,77.24,63.06c0,33.44-31.89,60.72-72.17,62.86l4.65,20s-43.7-13.49-70.45-42.36C1792,533.66,1780.05,520.93,1780.05,497.62Z"
          transform="translate(-168.2 -57.12)"
        />
      </g>
    </svg>
  );
}

export default ManWithBeardPointingAtApps;
