import Wrapper from "./Wrapper";
import ChatBubbles from "./ChatBubbles";
import Content from "./Content";

const displayName = "EmmaConversation";

function EmmaConversation() {
  return (
    <Wrapper data-testid={displayName}>
      <Content>
        <ChatBubbles />
      </Content>
    </Wrapper>
  );
}
EmmaConversation.displayName = displayName;

export default EmmaConversation;
