import baseConfig from "../medi24/config";
import frOverrides from "./translations/fr.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh medi24 theme tg-france 11 France personal-assistant france medi24 NONE NONE fr en

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "PERSONAL_ASSISTANT_FRANCE_11",
    partnerGroup: "travelguardian",
    partnerCountry: "France",
    subPartnerCode: "",
  },
  languages: "fr en".split(" "),
  messages: {
    fr: frOverrides,
    en: enOverrides,
  },
};

export default config;
