import baseConfig from "../mastercard/config";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh mastercard subpartner mc-core-au 2 Australia digitalhealthxtra au allianz mydoc MCCOREAU en
// DEVTEST Test Australia with Mastercard: 2020202023
const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHXTRA_AU_2",
    partnerCountry: "Australia",
    subPartnerCode: "MCCOREAU",
  },
  features: {
    ...baseConfig.features,
    DHA: true,
    MyDoc: true,
  },
  languages: "en".split(" "),
  messages: {
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
