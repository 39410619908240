import { getInstance } from "partnerConfigs/singleton";
import useLanguage from "./useLanguage";

function useSmallFont() {
  const { config } = getInstance();
  const [language] = useLanguage();
  const enabledLanguage = config.features.smallScreenFonts || {};

  const withSmallFont = !!enabledLanguage[language];
  return withSmallFont;
}

export default useSmallFont;
