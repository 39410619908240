import styled from "@emotion/styled";

import { TABLET_MEDIA } from "@medi24-da2c/web-ui/media";

const Wrapper = styled.div`
  @media ${TABLET_MEDIA} {
    max-width: 36rem;
    margin: 0 auto;
  }
`;

export default Wrapper;
