const allianzBlue = "#003781";
const allianzRed = "#e5231b";

// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function Logotype({ style } = {}) {
  // Courtesy of https://www.freeconvert.com/svg-converter converted from .eps
  // file provided by vendor and then touched up
  return (
    <svg
      style={style}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 121"
      width="1024pt"
      height="121pt"
      preserveAspectRatio="xMidYMid meet"
      role="img"
      aria-labelledby="icon-logo-title"
    >
      <title id="icon-logo-title">Allianz Assistance</title>
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0,121) scale(0.1,-0.1)"
        stroke="none"
        fill={allianzBlue}
      >
        <g id="icon-logo-eagle">
          <path
            d="M4122 1190 c-170 -45 -308 -152 -380 -295 -71 -143 -86 -331 -38
    -480 85 -267 327 -429 611 -411 168 10 299 70 409 186 113 121 161 257 154
    445 -7 175 -59 295 -179 412 -140 137 -376 195 -577 143z m321 -101 c206 -69
    337 -255 337 -479 -1 -301 -208 -510 -505 -510 -233 0 -413 136 -479 359 -18
    62 -21 215 -6 276 45 174 165 304 328 356 83 26 243 26 325 -2z"
          />
          <path
            d="M4140 972 c0 -34 3 -41 23 -44 l22 -3 3 -328 2 -327 91 0 90 0 -3
    350 c-3 320 -5 351 -21 367 -14 14 -38 19 -112 21 l-95 4 0 -40z"
          />
          <path
            d="M3980 853 c-38 -13 -40 -30 -40 -310 l0 -273 85 0 85 0 0 295 0 295
    -57 -1 c-32 0 -65 -3 -73 -6z"
          />
          <path
            d="M4440 566 l0 -296 90 0 91 0 -3 280 c-2 210 -6 282 -16 292 -7 7 -45
    14 -87 16 l-75 4 0 -296z"
          />
        </g>
        <g id="icon-logo-allianz">
          <path
            d="M150 1006 c0 -32 3 -35 33 -38 27 -3 32 -7 35 -33 1 -18 -42 -163
-107 -358 l-110 -328 96 3 96 3 26 90 27 90 115 3 114 3 26 -93 25 -93 102 -3
c56 -1 102 1 102 5 0 4 -47 175 -105 378 -80 281 -110 374 -126 388 -18 15
-44 17 -185 17 l-164 0 0 -34z m259 -298 c17 -64 31 -122 31 -127 0 -7 -31
-11 -81 -11 -72 0 -80 2 -75 18 48 166 81 260 87 250 4 -7 21 -66 38 -130z"
          />
          <path
            d="M730 1005 c0 -27 4 -35 18 -35 41 0 42 -10 42 -372 l0 -348 90 0 90
0 0 379 c0 332 -2 382 -16 395 -12 13 -38 16 -120 16 l-104 0 0 -35z"
          />
          <path
            d="M1050 1006 c0 -28 4 -35 23 -38 l22 -3 3 -358 2 -357 95 0 95 0 0
379 0 380 -22 15 c-17 12 -48 16 -120 16 l-98 0 0 -34z"
          />
          <path
            d="M1443 1038 c-30 -15 -47 -60 -36 -95 20 -60 136 -80 184 -32 30 30
    26 92 -7 118 -29 23 -102 28 -141 9z"
          />
          <path
            d="M1370 805 c0 -27 4 -35 18 -35 40 0 42 -15 42 -272 l0 -248 90 0 90
    0 0 279 c0 241 -2 282 -16 295 -12 13 -38 16 -120 16 l-104 0 0 -35z"
          />
          <path
            d="M1865 836 c-85 -20 -138 -44 -132 -60 3 -8 11 -33 18 -56 11 -35 16
    -40 33 -35 148 42 186 45 217 14 11 -11 19 -30 17 -41 -3 -18 -16 -24 -89 -38
    -175 -36 -221 -75 -227 -196 -6 -111 25 -159 112 -175 48 -9 133 11 175 40
    l31 22 9 -24 c13 -33 28 -37 132 -37 l89 0 0 28 c0 21 -7 32 -25 40 l-25 12 0
    163 c0 238 -16 302 -86 336 -40 20 -176 24 -249 7z m153 -363 c-3 -53 -6 -59
    -37 -80 -78 -53 -138 -3 -100 81 12 26 71 54 117 55 22 1 23 -2 20 -56z"
          />
          <path
            d="M2626 835 c-21 -7 -54 -25 -74 -39 l-36 -26 -11 33 -10 32 -107 3
    -108 3 0 -35 c0 -28 4 -35 23 -38 l22 -3 3 -257 2 -258 95 0 95 0 0 205 0 205
    33 20 c45 29 99 23 108 -10 4 -14 7 -114 8 -222 l1 -198 91 0 90 0 -3 258 c-3
    290 -5 297 -79 326 -48 19 -91 20 -143 1z"
          />
          <path
            d="M2956 824 c-12 -11 -16 -35 -16 -85 l0 -69 34 0 c28 0 35 4 38 23 3
    21 9 22 87 25 68 2 82 0 74 -10 -6 -7 -63 -87 -127 -176 l-116 -164 0 -59 0
    -59 235 0 235 0 0 60 0 59 -130 3 -130 3 125 175 125 175 0 57 0 58 -209 0
    c-177 0 -212 -2 -225 -16z"
          />
        </g>
        <g id="icon-logo-assistance" fill={allianzRed}>
          <path
            d="M5231 658 c-62 -211 -116 -389 -118 -395 -4 -10 18 -13 90 -13 l94 0
24 92 25 93 110 3 110 3 24 -96 24 -95 98 0 c54 0 98 1 98 3 0 1 -53 178 -118
392 l-118 390 -114 3 -115 2 -114 -382z m263 62 c14 -58 29 -115 32 -127 6
-22 4 -23 -70 -23 -42 0 -76 3 -76 6 0 8 48 188 62 232 5 19 13 30 17 25 5 -4
20 -55 35 -113z"
          />
          <path
            d="M5988 837 c-99 -28 -138 -77 -138 -172 0 -100 41 -142 188 -189 58
    -20 79 -45 62 -76 -15 -28 -69 -35 -134 -16 -30 9 -58 21 -61 26 -11 19 -26
    10 -40 -23 -33 -79 -32 -81 33 -110 48 -22 74 -27 139 -27 164 0 243 62 243
    190 0 93 -34 130 -166 178 -90 34 -107 51 -80 81 27 29 90 28 151 -4 27 -13
    50 -23 50 -22 1 1 11 26 23 56 25 61 26 59 -46 92 -54 26 -162 33 -224 16z"
          />
          <path
            d="M6489 837 c-60 -17 -103 -49 -123 -91 -20 -43 -21 -128 -1 -166 18
    -35 79 -73 163 -101 74 -25 89 -40 74 -74 -16 -34 -67 -42 -137 -20 -31 10
    -58 22 -61 26 -11 18 -24 8 -39 -28 -32 -77 -32 -77 33 -106 48 -22 74 -27
    139 -27 152 0 229 52 247 165 15 95 -28 150 -160 201 -101 39 -119 58 -82 88
    29 23 113 17 161 -13 l33 -20 20 52 c10 28 21 55 22 59 2 5 -21 20 -52 34 -67
    30 -170 40 -237 21z"
          />
          <path
            d="M6903 1038 c-27 -13 -47 -60 -39 -93 9 -37 50 -59 108 -58 77 1 117
    52 92 118 -15 38 -110 58 -161 33z"
          />
          <path
            d="M7298 837 c-99 -28 -138 -77 -138 -172 0 -100 41 -142 187 -189 65
    -21 80 -38 65 -71 -15 -33 -66 -41 -136 -21 -30 9 -58 21 -61 26 -11 19 -26
    10 -40 -23 -33 -79 -32 -81 33 -110 48 -22 74 -27 139 -27 89 0 133 11 181 47
    46 35 62 72 62 148 0 62 -2 67 -38 103 -27 27 -62 47 -122 68 -98 36 -117 56
    -81 85 32 26 87 24 146 -6 27 -13 50 -23 50 -22 1 1 11 26 23 56 25 61 26 59
    -46 92 -54 26 -162 33 -224 16z"
          />
          <path
            d="M7730 953 c-15 -118 -13 -113 -65 -113 l-45 0 0 -60 0 -60 44 0 44 0
    4 -192 c3 -210 7 -226 66 -264 30 -19 148 -23 195 -5 25 10 27 15 27 66 l0 55
    -40 0 c-27 0 -47 7 -60 20 -18 18 -20 33 -20 170 l0 150 65 0 65 0 0 60 0 60
    -65 0 -65 0 0 100 0 100 -69 0 -69 0 -12 -87z"
          />

          <path
            d="M8223 840 c-59 -12 -143 -50 -143 -64 0 -7 7 -33 16 -59 l15 -46 46
    20 c26 11 71 22 102 25 48 5 60 3 84 -16 18 -14 27 -31 27 -49 0 -28 -1 -28
    -77 -35 -164 -15 -243 -82 -243 -206 0 -108 50 -160 153 -160 63 0 119 19 154
    52 l23 21 0 -36 0 -37 80 0 81 0 -3 249 c-3 236 -4 251 -24 278 -37 49 -79 66
    -168 69 -45 2 -100 -1 -123 -6z m147 -385 c0 -51 -2 -56 -31 -70 -44 -23 -90
    -16 -109 18 -13 24 -13 30 0 54 20 35 54 53 103 53 l37 0 0 -55z"
          />
          <path
            d="M8964 841 c-36 -9 -95 -44 -114 -66 -21 -25 -30 -17 -30 25 l0 40
    -80 0 -80 0 0 -295 0 -295 85 0 85 0 0 199 0 200 28 19 c60 42 104 38 121 -10
    6 -18 11 -112 11 -219 l0 -189 85 0 85 0 0 243 c0 264 -5 289 -60 329 -30 21
    -93 30 -136 19z"
          />
          <path
            d="M9437 836 c-64 -18 -116 -61 -152 -127 -25 -46 -30 -68 -33 -144 -7
    -152 34 -242 132 -290 85 -42 220 -36 283 13 20 16 21 21 11 65 -6 26 -15 47
    -18 47 -4 0 -24 -7 -43 -15 -110 -46 -187 16 -187 151 0 81 15 128 51 158 38
    32 89 34 141 6 l37 -20 10 38 c19 73 18 75 -25 99 -52 29 -142 37 -207 19z"
          />
          <path
            d="M9920 841 c-126 -39 -195 -148 -193 -306 1 -135 43 -214 138 -261 46
    -22 69 -27 133 -27 79 0 172 21 206 46 18 13 18 16 2 65 l-17 51 -45 -20 c-49
    -20 -136 -25 -178 -9 -30 11 -53 44 -62 88 l-6 32 171 0 171 0 0 28 c-1 70
    -23 169 -48 216 -22 41 -38 56 -81 79 -56 28 -134 35 -191 18z m121 -141 c9
    -14 20 -40 24 -57 l7 -33 -86 0 c-93 0 -96 2 -75 57 18 47 39 64 77 61 24 -2
    41 -11 53 -28z"
          />
          <path d="M6880 545 l0 -295 85 0 85 0 0 295 0 295 -85 0 -85 0 0 -295z" />
        </g>
      </g>
    </svg>
  );
}

export default Logotype;
