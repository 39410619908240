import styled from "@emotion/styled";

import { Content as ContentBase } from "@medi24-da2c/web-ui";
import { TABLET_MEDIA } from "@medi24-da2c/web-ui/media";

const Content = styled(ContentBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 0;

  @media ${TABLET_MEDIA} {
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 8rem;
    padding-top: 6rem;
  }
`;

export default Content;
