import { Children } from "react";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { DESKTOP_MEDIA } from "@medi24-da2c/web-ui/media";
import { borderWidthThin } from "@medi24-da2c/web-ui/design-tokens";

const displayName = "Messengers";

const Styled = styled.ul`
  margin: 0;
  padding: 0;
  border-top: ${borderWidthThin} solid
    ${(themedProps) => themedProps.theme.general.borderColor};
  border-bottom: ${borderWidthThin} solid
    ${(themedProps) => themedProps.theme.general.borderColor};

  @media ${DESKTOP_MEDIA} {
    border-top: 0;
    border-bottom: 0;
    display: grid;
    grid-template-columns: ${(themedProps) =>
      `repeat(${Children.toArray(themedProps.children).length}, 1fr)`};
  }
`;

function Messengers(props) {
  const theme = useTheme();
  return <Styled theme={theme} {...props} />;
}
Messengers.displayName = displayName;

export default Messengers;
