import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { SlimArrow } from "../Icons";

const displayName = "NextStepArrowIcon";

const Icon = styled.span`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 1rem;
  color: ${(themedProps) => themedProps.theme.button.nextStepArrowColor};
`;

function ArrowIcon(props) {
  const theme = useTheme();
  return (
    <Icon theme={theme} {...props}>
      <SlimArrow />
    </Icon>
  );
}
ArrowIcon.displayName = displayName;

export default ArrowIcon;
