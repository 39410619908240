import * as codeType from "constants/generateCodeTypes";
import { hasLetterAndNumber } from "../../eligibilityCheck/GenerateCodeSteps/isValidBinOnlyForm";
import baseConfig from "../mc-core-au/config";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh mc-core-au subpartner azp-india 25 India digitalhealth in allianz NONE CSRIN en

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTH_IN_25",
    partnerGroup: "allianz",
    partnerCountry: "India",
    // partnerCode: "", enable for theme inheritance delete for sub-partner
    subPartnerCode: "CSRIN",
    privacyEmail: "digitalhealth.privacy@medi24.com",
    generalEmail: "digitalhealth@medi24.com",
  },
  features: {
    ...baseConfig.features,
    generateStepType: codeType.BIN_ONLY,
    binOnlyRegex: hasLetterAndNumber,
    DHA: true,
    noDHA: false,
    noWebApp: false,
    noRecording: false,
    noPrivacyFaq: false,
    noDoctorChat: false,
    noUsefulLinks: false,
    noMedicalHotline: true,
    noSymptomChecker: true,
    noMedicalConsultation: false,
    noProvisionOfServices: false,
    MyDoc: true,
    QuickSummary: true,
    showViber: false,
    showLineApp: false,
    hideTelegram: true,
  },
  languages: "en".split(" "),
  messages: {
    // "en": enInherited,
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
