import styled from "@emotion/styled";

import { Link as LinkBase } from "@medi24-da2c/web-ui";

const Link = styled(LinkBase)`
  text-transform: uppercase;
  text-align: left;
`;

export default Link;
