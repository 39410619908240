import baseTheme from "./theme.inherit.js";
import BGR_IMAGE_MOBILE from "./IntroConversationBgrMobile.jpg";
import BGR_IMAGE from "./IntroConversationBgr.jpg";
import LOGO from "./Logotype";

let bgrImageMobile;
let bgrImage;
let logo;

if (
  "undefined" !== typeof process &&
  process.env.NODE_ENV !== "test" &&
  process.env.NODE_ENV !== "production"
) {
  // when running under node, but not unit tests
  bgrImageMobile = "./IntroConversationBgrMobile.jpg";
  bgrImage = "./IntroConversationBgr.jpg";
  logo = "./Logotype.js";
} else {
  bgrImageMobile = BGR_IMAGE_MOBILE;
  bgrImage = BGR_IMAGE;
  logo = LOGO;
}

const iocButtonBlue = "#007bb7";
const iocTextBlue = "#1a3e73";
const iocBgBlue = "#daf0f2";

const theme = {
  ...baseTheme,
  themeName: "ioc-allianz-blue",
  general: {
    ...baseTheme.general,
    manifestThemeColor: iocButtonBlue,
    headlineColor: iocTextBlue,
    textColor: iocTextBlue,
    linkColor: iocTextBlue,
  },
  button: {
    ...baseTheme.button,
    bgrColor: iocButtonBlue,
    nextStepArrowColor: iocButtonBlue,
  },
  header: {
    ...baseTheme.header,
    logo,
  },
  footer: {
    ...baseTheme.footer,
    linkColor: iocTextBlue,
  },
  languagePicker: {
    ...baseTheme.languagePicker,
    selectedColor: iocButtonBlue,
  },
  carousel: {
    ...baseTheme.carousel,
    activeBulletColor: iocButtonBlue,
  },
  form: {
    ...baseTheme.form,
    checkboxBgrColor: iocButtonBlue,
  },
  landingPageGeneralInfo: {
    ...baseTheme.landingPageGeneralInfo,
    headlineColor: iocTextBlue,
    textColor: iocTextBlue,
    bgrImageMobile,
    bgrImage,
  },
  landingPageGetStarted: {
    ...baseTheme.landingPageGetStarted,
    bgrColor: iocBgBlue,
    textColor: iocTextBlue,
  },
  landingPageIntroOverview: {
    ...baseTheme.landingPageIntroOverview,
    bgrColor: iocBgBlue,
  },
};

export default theme;
