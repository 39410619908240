import PropTypes from "prop-types";

import useValidActivationCode from "hooks/useValidActivationCode";
import WrongActivationCodePage from "../WrongActivationCodePage";

const displayName = "ValidActivationCodeGuard";

function ValidActivationCodeGuard({ forceValid, children }) {
  const { isValidCode } = useValidActivationCode();

  if (!isValidCode && !forceValid) {
    return <WrongActivationCodePage />;
  }

  return children;
}

ValidActivationCodeGuard.displayName = displayName;

ValidActivationCodeGuard.propTypes = {
  forceValid: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default ValidActivationCodeGuard;
