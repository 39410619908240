import styled from "@emotion/styled";
import { Subheadline } from "@medi24-da2c/web-ui";

import {
  fontWeightNormal,
  spacingSmall,
} from "@medi24-da2c/web-ui/design-tokens";
import { OptionalMessage } from "../../components/OptionalMessage";

const displayName = "EligibilityCheck.StepHeadline";

const StepHeadlineStyled = styled(Subheadline.Themed)`
  margin-top: 0;
  margin-bottom: ${spacingSmall};
  font-weight: ${fontWeightNormal};
`.withComponent("h3");

function StepHeadline({ id, ...props }) {
  return (
    <StepHeadlineStyled {...props}>
      <OptionalMessage id={id} />
    </StepHeadlineStyled>
  );
}

StepHeadline.displayName = displayName;

export default StepHeadline;
