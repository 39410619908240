import { OptionalTitle } from "components/OptionalMessage";

// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function WomanAndManDiscussingSecurity() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 200"
      role="img"
      aria-labelledby="icon-woman-and-man-discussing-security"
    >
      <OptionalTitle
        id="image.womanAndManDiscussingSecurity"
        titleId="icon-woman-and-man-discussing-security"
      />
      <path
        fill="#008390"
        d="M124.696 122.244S85.738 76.212 69.015 73.103l-23.743-5.395-35.255 25.49 12.549 86.026h92.653s-6.102-26.569-9.472-40.74c25.552-1.054 18.949-16.24 18.949-16.24z"
      />
      <path
        fill="#016f79"
        d="M27.814 115.45c-7.675 1.081-8.452 63.947-8.452 63.947l72.034-.173c-32.863-.093-55.488-64.913-63.582-63.774z"
      />
      <path
        fill="#f6aa98"
        d="M81.516 131.72s-7.959-1.658-11.367.401c-3.407 2.06-7.188 6.691-9.331 9.774-2.144 3.086-3.026 3.986-3.026 3.986l17.42-6.062 6.304-8.099z"
      />
      <path
        fill="#016f79"
        d="M93.996 95.284c8.774 8.202 21.473 22.181 21.473 22.181s-2.891 2.344-10.147 7.801c0-.001-1.674-9.828-11.326-29.982z"
      />
      <path
        fill="#ea8f81"
        d="M76.943 133.106s-4.963.721-5.077 1.63c3.428-.061 3.513-.088 3.513-.088l1.564-1.542z"
      />
      <path
        fill="#19262b"
        d="M62.726 155.069s-1.332-1.621-1.406-1.758c-.059-.111-.035-.253.082-.408l31.306-41.441c.266-.353.918-.638 1.458-.639l64.636-.058c.272 0 .462.069.552.185.092.113 1.348 1.63 1.348 1.63"
      />
      <path
        fill="#24333b"
        d="M129.353 154.51c-.265.352-.919.638-1.457.64l-64.639.058c-.543 0-.764-.284-.498-.634l31.305-41.445c.266-.35.92-.636 1.456-.636l64.638-.059c.542 0 .765.285.499.635l-31.304 41.441z"
      />
      <path
        fill="#a6d7d9"
        d="M110.136 133.7c1.972-2.416 5.491-4.377 7.866-4.379 2.373-.005 2.7 1.951.727 4.367-1.971 2.417-5.491 4.378-7.865 4.381-2.374.004-2.698-1.953-.728-4.369z"
      />
      <path
        fill="#f6aa98"
        d="M58.168 158.683l4.903-2.305s6.012 2.307 12.809-1.715c6.796-4.02 14.317-8.181 15.409-14.544.702-4.082-2.241-5.703-3.411-5.724-1.255-.025-16.107.354-16.107.354s-5.304 2.774-7.747 5.405c-2.446 2.632-9.924 6.241-9.924 6.241l4.068 12.288z"
      />
      <path
        fill="#008390"
        d="M52.19 61.919c-2.85-.966-31.77 11.579-40.06 23.164C3.841 96.668-2.23 109.333.785 131.09c3.016 21.755 3.843 44.412 14.514 51.029 10.672 6.616 46.406-22.454 46.406-22.454s-5.559-8.804-6.574-15.001c-15.201 4.081-24.28 6.396-24.28 6.396l-3.646-42.746L52.19 61.919z"
      />
      <path
        fill="#f6aa98"
        d="M69.567 85.049c-1.27 9.426-29.883-2.363-27.589-16.971 2.761-17.592 3.207-20.414 3.207-20.414l26.199 20.418s-.545 7.542-1.817 16.967z"
      />
      <path
        fill="#ea8f81"
        d="M45.272 47.646l26.214 19.268s-1.134 14.188-1.579 15.544L45.272 47.646z"
      />
      <path
        fill="#f6aa98"
        d="M70.837 70.859c7.152-.136 10.941-20.952 11.944-30.659 1.002-9.707-1.552-20.79-3.121-24.506-1.567-3.714-36.22 5.535-36.22 5.535l-3.185 14.002s-3.009.996-.502 7.888c2.151 5.912 5.314 4.572 5.314 4.572s6.157 23.555 25.77 23.168z"
      />
      <path
        fill="#19262b"
        d="M47.397 40.27c-.785.202-5.587-7.748-8.068-4.201-2.711-1.701-5.291-12.842-3.177-20.231 2.124-7.388 8.954-8.162 8.954-8.162S46.263-5.127 68.241 2.34c7.326 2.488 16.964 16.134 13.365 18.558-11.181 7.535-30.604-.223-30.427.399.701 2.463.784 4.89-.575 7.152-.651 1.083-2.15 1.494-2.73 2.581-.479.905-.275 2.517-.279 3.541-.001.66.654 5.48-.198 5.699zM75.378 40.745c-.124 1.024.34 1.927 1.04 2.016.699.083 1.36-.675 1.484-1.7.125-1.031-.338-1.933-1.039-2.017-.691-.085-1.359.669-1.485 1.701zM62.778 40.841c-.124 1.022.343 1.925 1.04 2.016.694.078 1.361-.674 1.488-1.708.124-1.023-.34-1.925-1.041-2.015-.692-.082-1.36.673-1.487 1.707z"
      />
      <path
        fill="#f6aa98"
        d="M134.539 147.645c1.753.952 9.538-2.029 13.288-5.719 3.75-3.69 6.302-8.114 5.86-10.836-.443-2.722-2.241-5.825-2.241-5.825s-3.325 4.192-4.036 5.08c-.71.886-3.688 1.076-5.418.745-2.238-.43-4.252 1.334-5.364 2.605s-4.839 7.811-4.637 10.208c.202 2.398 1.88 3.395 2.548 3.742z"
      />
      <path
        fill="#ea8f81"
        d="M68.665 59.889c1.3.46 2.941.552 4.805-.406a.542.542 0 00.234-.72.522.522 0 00-.712-.236c-3.925 2.01-6.562-1.401-6.673-1.551a.518.518 0 00-.736-.095.538.538 0 00-.098.753c.018.023 1.209 1.565 3.18 2.255zM71.598 39.712a.569.569 0 00-.564.627l1.084 13.149a.573.573 0 00.566.532l.048-.003.077-.013a.578.578 0 00.444-.614l-1.084-13.148a.578.578 0 00-.571-.53"
      />
      <path
        fill="#5d2310"
        d="M182.127 103.726s-14.367-11.557-5.653-29.831c4.694-9.843 12.734-9.075 17.684-20.756 4.95-11.681-2.431-23.167 5.255-38.033 7.685-14.868 34.393-21.476 48.204-4.963 13.808 16.512 12.376 32.441 6.384 43.193-5.994 10.753-11.205 37.17-11.205 37.17l-26.316 12.743-34.353.477z"
      />
      <path
        fill="#c99859"
        d="M296.833 200H188.644l2.978-90.824s23.768-27.547 41.693-32.605c17.923-5.06 37.693-1.365 55.522 20.592 8.248 10.086 30.043 73.253 30.043 73.253l-23.401 20.373 1.354 9.211z"
      />
      <path
        fill="#f6aa98"
        d="M223.979 53.692l27.217-12.202s3.845 16.139 7.821 34.626c2.287 10.627-30.147 19.626-32.102 2.967l-2.936-25.391z"
      />
      <path
        fill="#ea8f81"
        d="M224.332 56.746l2.448 21.16s18.699-2.874 20.42-16.058c-22.904 9.382-10.428-6.159-22.868-5.102z"
      />
      <path
        fill="#f6aa98"
        d="M236.151 69.914c6.328-1.079 18.105-10.93 15.045-28.424 2.673-1.166 4.915-8.405 1.672-13.912-3.446-5.853-6.208-.262-6.78 1.701-.572 1.963-2.453 6.65-15.743-10.865-1.302 7.699-14.776 23.888-21.031 25.289 4.169 10.826 16.802 27.922 26.837 26.211z"
      />
      <path
        fill="#5d2310"
        d="M217.982 44.29c-.395-.87-1.196-1.347-1.784-1.065-.601.272-.753 1.203-.359 2.083.401.87 1.202 1.351 1.795 1.069.589-.283.747-1.213.348-2.087zM230.807 38.968c-.391-.879-1.189-1.366-1.782-1.093-.593.275-.757 1.208-.377 2.089.391.87 1.189 1.354 1.78 1.09.597-.271.761-1.201.379-2.086z"
      />
      <path
        fill="#ea8f81"
        d="M226.054 54.118l.011-.006a.638.638 0 00.434-.792L223.2 41.478c-.095-.34-.394-.589-.777-.442a.653.653 0 00-.437.792l3.301 11.843a.63.63 0 00.767.447zM231.009 57.209c1.423-.842 2.786-2.286 3.383-4.757a.647.647 0 00-.458-.779.632.632 0 00-.767.473c-1.267 5.196-6.364 4.969-6.583 4.954a.634.634 0 00-.66.606.634.634 0 00.597.68c.031.001 2.335.104 4.488-1.177z"
      />
      <path
        fill="#b98447"
        d="M268.302 200h-79.658l8.493-78.607c21.124 0 19.514 78.607 71.165 78.607zM276.957 123.505l13.645 47.817s-24.574-18.497-13.645-47.817z"
      />
      <path
        fill="#f6aa98"
        d="M254.484 145.061l-4.646-4.672s-10.72-2.11-13.318-3.587c-2.598-1.477-5.136-4.378-5.471-8.38-.333-4.004-1.006-10.451-1.006-10.451l7.172-4.846s5.622-2.754 8.044-2.534c3.556.321 7.799 3.466 9.509 6.445 1.708 2.98 4.745 10.203 6.156 11.791 1.578 1.777 5.102 4.049 5.102 4.049l-11.542 12.185z"
      />
      <path
        fill="#c99859"
        d="M315.245 186.951c12.526-14.697-2.803-25.702-10.385-31.371-7.587-5.671-40.415-25.934-40.415-25.934l-12.71 16.322s21.527 26.099 29.594 32.987c8.066 6.889 21.335 22.754 33.916 7.996z"
      />
      <path
        fill="#ea8f81"
        d="M241.042 114.063s2.919-1.435 5.009-1.081c2.255.381 2.914 1.722 4.409 4.429l-9.418-3.348z"
      />
      <path
        fill="#f6aa98"
        d="M215.747 108.818c1.985-5.196 36.555 9.017 36.555 9.017l-16.523 2.876c0 .001-22.014-6.697-20.032-11.893zM185.121 100.801s-10.552-14.245-10.411 5.423c.033 4.548 8.253 22.265 20.837 19.26.815-.194 6.833-2.854 8.46-6.063 1.627-3.21 3.117-7.946 3.363-10.107.244-2.161 1.077-11.185-2.089-11.974-3.169-.789-4.955 7.205-4.955 7.205l-15.205-3.744z"
      />
      <path
        fill="#f6aa98"
        d="M185.085 134.737l-.644-13.932 19.891-3.553-2.53 16.864z"
      />
      <path
        fill="#24333b"
        d="M202.164 115.075c-.077.567-.609.955-1.192.873l-20.35-2.876c-.582-.081-.991-.606-.915-1.172l5.204-38.225a1.04 1.04 0 011.192-.873l20.352 2.876c.58.082.989.606.914 1.172l-5.205 38.225z"
      />
      <path
        fill="#6bbfc3"
        d="M200.349 111.99l-18.07-2.551 4.652-34.166 18.066 2.555z"
      />
      <path
        fill="#f6aa98"
        d="M179.058 116.055c1.126.208 1.231-1.717 2.8-12.042 1.373-9.038 2.719-15.747 1.233-16.052-1.865-.383-7.888 2.092-8.402 17.947-.085 1.732 4.369 10.147 4.369 10.147z"
      />
      <path
        d="M197.111 91.549c.218-1.709-.962-3.275-2.641-3.496-1.677-.223-3.213.985-3.432 2.693a3.148 3.148 0 001.379 3.029l-2.563 6.257 6.073.804-.907-6.717a3.113 3.113 0 002.091-2.57zm0 0"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#19262b"
      />
      <path
        fill="#c99859"
        d="M182.164 199.646c16.132 3.099 18.035-14.895 18.34-24.658.304-9.764 1.821-42.081 1.821-42.081l-19.176-5.795s-9.317 32.098-12.417 41.674c-3.1 9.575-6.418 27.429 11.432 30.86z"
      />
    </svg>
  );
}

export default WomanAndManDiscussingSecurity;
