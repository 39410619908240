import * as codeType from "constants/generateCodeTypes";
import baseConfig from "../mc-asia/config";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh mc-asia subpartner mc-core-emma-lite 15 Germany digitalhealth eu2 NOBOT NONE NONE en

// DEVTEST Test Emma Lite with BIN number: 1011121314 and 2020202020
const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTH_EU2_15",
    partnerCountry: "Germany",
    partnerCode: "MCDEMOEMMALIGHT",
    subPartnerCode: "",
  },
  features: {
    ...baseConfig.features,
    DHA: true,
    noDHA: true,
    noWebApp: true,
    noPrivacyFaq: true,
    noUsefulLinks: true,
    noDoctorChat: false,
    noMedicalHotline: true,
    noSymptomChecker: true,
    noRecording: true,
    noMedicalConsultation: true,
    noProvisionOfServices: true,
    MyDoc: false,
    QuickSummary: true,
    showViber: true,
    generateStepType: codeType.BIN_ONLY,
    GetStartedDoctorChat: true,
    redirectAppDoctorChat: {},
    helplineEmail: null,
  },
  languages: "en".split(" "),
  messages: {
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
