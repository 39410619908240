import baseConfig from "../mc-demo-eu/config";
import englishInherited from "./translations/en.inherit.json";
import englishOverrides from "./translations/en.json";
import chineseTraditionalInherited from "./translations/zh-Hant.inherit.json";
//import chineseTraditionalOverrides from "./translations/zh-Hant.json";

// DEVTEST Test Singapore with Mastercard: 2020202028
const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "MC-DEMO-HK",
    partnerCountry: "Hong Kong",
  },
  languages: ["zh-Hant", "en"],
  messages: {
    en: { ...englishInherited, ...englishOverrides },
    "zh-Hant": chineseTraditionalInherited,
    //    "zh-Hant": {
    //      ...chineseTraditionalInherited,
    //      ...chineseTraditionalOverrides,
    //    },
  },
  features: {
    ...baseConfig.features,
    DHA: true,
    MyDoc: true,
  },
};

export default config;
