import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import {
  spacingMedium,
  borderRadius,
  zIndexModal,
} from "@medi24-da2c/web-ui/design-tokens";
import { use100vh } from "react-div-100vh";
import { isIOS } from "react-device-detect";
import { TABLET_MEDIA } from "@medi24-da2c/web-ui/media";

const displayName = "DialogBase";
const displayName2 = "Dialog";

const DialogBase = styled.section`
  display: flex;
  flex-direction: column;
  background: ${(themedProps) => themedProps.theme.modal.bgrColor};
  min-height: 30rem;
  max-height: calc(100vh - 4rem);
  width: calc(100% - ${spacingMedium} - ${spacingMedium});
  left: 50%;
  top: 2rem;
  position: fixed;
  transform: translate(-50%, 0);
  z-index: ${zIndexModal};
  border-radius: ${borderRadius};
  overflow: hidden;

  @media ${TABLET_MEDIA} {
    max-width: 60rem;
    top: 5%;
  }
`;
DialogBase.displayName = displayName;

const DialogSafeHeight = styled(DialogBase)`
  max-height: calc(
    ${(props) => {
        return props.safeHeight;
      }}px - 4rem
  );
`;

function DialogResponsive(props) {
  const safeHeight = use100vh();

  return <DialogSafeHeight {...props} safeHeight={safeHeight} />;
}

// Prevent cropping bottom part of dialog on iOS
function Dialog({ forceIOS, ...props }) {
  const theme = useTheme();
  return forceIOS || isIOS ? (
    <DialogResponsive theme={theme} {...props} />
  ) : (
    <DialogBase theme={theme} {...props} />
  );
}
Dialog.propTypes = {
  forceIOS: PropTypes.bool,
};
Dialog.displayName = displayName2;

export default Dialog;
