import styled from "@emotion/styled";
import { Content as ContentBase } from "@medi24-da2c/web-ui";
import { spacingMedium } from "@medi24-da2c/web-ui/design-tokens";

const Content = styled(ContentBase)`
  max-width: 48rem;
  padding-bottom: ${spacingMedium};
`;

export default Content;
