import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Link } from "@medi24-da2c/web-ui";

// FIX medi24 Link useTheme
const SlimLinkStyled = styled(Link)`
  line-height: 1.1;

  span {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
`;

function SlimLink(props) {
  const theme = useTheme(); // PROPER WAY TO GET THEME CONTEXT
  return <SlimLinkStyled theme={theme} {...props} />;
}

export default SlimLink;
