import PropTypes from "prop-types";
import ConsentFormPicker from "components/ConsentForm/ConsentFormPicker";
import GettingStartedPage from "../GettingStartedPage";

const displayName = "ViberGettingStartedPage";

function ViberGettingStartedPage(props) {
  return (
    <GettingStartedPage {...props} messengerName="Viber">
      {({ activationCode }) => (
        <ConsentFormPicker chat="Viber" activationCode={activationCode} />
      )}
    </GettingStartedPage>
  );
}
ViberGettingStartedPage.displayName = displayName;
ViberGettingStartedPage.propTypes = {
  forceValid: PropTypes.bool,
};

export default ViberGettingStartedPage;
