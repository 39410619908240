import ImageCarousel from "components/ImageCarousel";
import useDoctorChat from "hooks/useDoctorChat";
import useDHAFaq from "hooks/useDHAFaq";

/* Script to be used to add id use quotes in case of spaces with
words
__scripts__/json-insert.sh \
image.manSeekingMedicalAdvice \
image.personChattingWithDoctor \
"Person on a phone chatting with doctor." \
src/translations/*.json
*/

const displayName = "EmmaOverview";

const HIDE = void 0;
const dhaId = "dha-carousel";
const dhaTestId = "emma-overview";

function EmmaOverview() {
  const hasDHAFaq = useDHAFaq();
  const partnerDoctorChat = useDoctorChat();
  const hasDoctorChat = partnerDoctorChat && !hasDHAFaq;

  const cards = [
    hasDoctorChat ? HIDE : "landingPage.emma.slide1.headline",
    "landingPage.emma.slide2.headline",
    "landingPage.emma.slide3.headline",
  ];

  return <ImageCarousel id={dhaId} data-testid={dhaTestId} cards={cards} />;
}

EmmaOverview.displayName = displayName;

export default EmmaOverview;
