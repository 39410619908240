import styled from "@emotion/styled";
import {
  PHONE_SMALL_MEDIA,
  PHONE_SMALL_MEDIA_MAX,
  PHONE_LARGE_MEDIA,
  PHONE_LARGE_MEDIA_MAX,
  TABLET_MEDIA,
  TABLET_MEDIA_MAX,
  DESKTOP_MEDIA,
  DESKTOP_MEDIA_MAX,
} from "@medi24-da2c/web-ui/media";

export const HideDivOnSmallPhone = styled.div`
  @media ${PHONE_SMALL_MEDIA} {
    display: none;
    visibility: hidden;
  }
`;
HideDivOnSmallPhone.displayName = "HideDivOnSmallPhone";

export const HideDivOnLargePhone = styled.div`
  @media ${PHONE_LARGE_MEDIA} {
    display: none;
    visibility: hidden;
  }
`;
HideDivOnLargePhone.displayName = "HideDivOnLargePhone";

export const HideDivOnTablet = styled.div`
  @media ${TABLET_MEDIA} {
    display: none;
    visibility: hidden;
  }
`;
HideDivOnTablet.displayName = "HideDivOnTablet";

export const HideDivOnDesktop = styled.div`
  @media ${DESKTOP_MEDIA} {
    display: none;
    visibility: hidden;
  }
`;
HideDivOnDesktop.displayName = "HideDivOnDesktop";

export const HideSpanOnLargePhone = styled.span`
  @media ${PHONE_LARGE_MEDIA} {
    display: none;
    visibility: hidden;
  }
`;
HideSpanOnLargePhone.displayName = "HideSpanOnLargePhone";

export const HideSpanOnSmallPhone = styled.span`
  @media ${PHONE_SMALL_MEDIA} {
    display: none;
    visibility: hidden;
  }
`;
HideSpanOnSmallPhone.displayName = "HideSpanOnSmallPhone";

export const HideSpanOnTablet = styled.span`
  @media ${TABLET_MEDIA} {
    display: none;
    visibility: hidden;
  }
`;
HideSpanOnTablet.displayName = "HideSpanOnTablet";

export const HideSpanOnDesktop = styled.span`
  @media ${DESKTOP_MEDIA} {
    display: none;
    visibility: hidden;
  }
`;
HideSpanOnDesktop.displayName = "HideSpanOnDesktop";

export const HideDivBelowSmallPhone = styled.div`
  @media ${PHONE_SMALL_MEDIA_MAX} {
    display: none;
    visibility: hidden;
  }
`;
HideDivBelowSmallPhone.displayName = "HideDivBelowSmallPhone";

export const HideDivBelowLargePhone = styled.div`
  @media ${PHONE_LARGE_MEDIA_MAX} {
    display: none;
    visibility: hidden;
  }
`;
HideDivBelowLargePhone.displayName = "HideDivBelowLargePhone";

export const HideDivBelowTablet = styled.div`
  @media ${TABLET_MEDIA_MAX} {
    display: none;
    visibility: hidden;
  }
`;
HideDivBelowTablet.displayName = "HideDivBelowTablet";

export const HideDivBelowDesktop = styled.div`
  @media ${DESKTOP_MEDIA_MAX} {
    display: none;
    visibility: hidden;
  }
`;
HideDivBelowDesktop.displayName = "HideDivBelowDesktop";

export const HideSpanBelowSmallPhone = styled.span`
  @media ${PHONE_SMALL_MEDIA_MAX} {
    display: none;
    visibility: hidden;
  }
`;
HideSpanBelowSmallPhone.displayName = "HideSpanBelowSmallPhone";

export const HideSpanBelowLargePhone = styled.span`
  @media ${PHONE_LARGE_MEDIA_MAX} {
    display: none;
    visibility: hidden;
  }
`;
HideSpanBelowLargePhone.displayName = "HideSpanBelowLargePhone";

export const HideSpanBelowTablet = styled.span`
  @media ${TABLET_MEDIA_MAX} {
    display: none;
    visibility: hidden;
  }
`;
HideSpanBelowTablet.displayName = "HideSpanBelowTablet";

export const HideSpanBelowDesktop = styled.span`
  @media ${DESKTOP_MEDIA_MAX} {
    display: none;
    visibility: hidden;
  }
`;
HideSpanBelowDesktop.displayName = "HideSpanBelowDesktop";
