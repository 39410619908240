import PropTypes from "prop-types";
import useGetStartedDoctorChat from "hooks/useGetStartedDoctorChat";
import DoctorChatConsentForm from "../DoctorChatConsentForm";
import WebChatConsentForm from "../WebChatConsentForm";
import ConsentForm from "./ConsentForm";

const displayName = "ConsentFormPicker";

function ConsentFormPicker({ chat, activationCode, messengerLink }) {
  const usingDoctorChat = useGetStartedDoctorChat();

  if (usingDoctorChat) {
    return (
      <DoctorChatConsentForm chat={chat} activationCode={activationCode} />
    );
  }
  return chat === "WebChat" ? (
    <WebChatConsentForm chat={chat} activationCode={activationCode} />
  ) : (
    <ConsentForm chat={chat} messengerLink={messengerLink} />
  );
}
ConsentFormPicker.displayName = displayName;
ConsentFormPicker.propTypes = {
  chat: PropTypes.string.isRequired,
  activationCode: PropTypes.string,
  messengerLink: PropTypes.string,
};

export default ConsentFormPicker;
