import useQueryParams from "hooks/useQueryParams";
import isValidActivationCode from "utils/isValidActivationCode";
import { URL_QUERY_ACTIVATION_CODE } from "constants/keys";

function useValidActivationCode() {
  const query = useQueryParams();
  let activationCode;
  let isValidCode = false;
  let hasCode = false;

  if (query.has(URL_QUERY_ACTIVATION_CODE)) {
    hasCode = true;
    activationCode = query.get(URL_QUERY_ACTIVATION_CODE);
    isValidCode = isValidActivationCode(activationCode);
  }
  return { isValidCode, activationCode, hasCode };
}

export default useValidActivationCode;
