import PropTypes from "prop-types";
import usePartner from "hooks/usePartner";
import * as URL from "constants/urls";
import useLanguage, {
  toChatBotLanguage,
  toChatBotCountry,
} from "hooks/useLanguage";
import ConsentFormBase from "../ConsentForm/ConsentFormBase";
import makeUrl from "../WebChatConsentForm/makeUrl";

const displayName = "DoctorChatConsentForm";

const checkBoxList = ["termsOfUse", "privacyPolicy"];

function DoctorChatConsentForm({ chat, activationCode }) {
  const partner = usePartner();
  const [selectedLanguage] = useLanguage();

  function makeRedirectUrl(agreements) {
    const country = toChatBotCountry(partner.config.general.partnerCountry);
    let redirectApp = partner.config.features.redirectAppDoctorChat || {};
    redirectApp = redirectApp[chat.toLowerCase()] || chat.toLowerCase();
    let doctorChatUrl;
    if (chat === "WebChat") {
      doctorChatUrl = makeUrl({
        chat: `DoctorChat,${partner.partner}`,
        selectedLanguage,
        activationCode,
        agreements,
        country,
      });
    } else {
      doctorChatUrl = `${URL.DRCHAT_URL()}&redirectApp=${redirectApp}&uniqueId=${activationCode}&lang=${toChatBotLanguage(
        selectedLanguage
      )}&country=${country}`;
    }

    return doctorChatUrl;
  }

  return (
    <ConsentFormBase
      chat={`DoctorChat-${chat}`}
      idList={checkBoxList}
      onRedirectUrl={makeRedirectUrl}
    />
  );
}
DoctorChatConsentForm.displayName = displayName;
DoctorChatConsentForm.propTypes = {
  chat: PropTypes.string.isRequired,
  activationCode: PropTypes.string.isRequired,
};

export default DoctorChatConsentForm;
