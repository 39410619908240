import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";
import { Content, Headline } from "@medi24-da2c/web-ui";

import { OptionalSection, OptionalMessage } from "../OptionalMessage";
import { useOptionalMessage } from "../../hooks/useOptionalMessage";
import CollapsibleSection from "../CollapsibleSection";
import {
  PropTypeIdValueListList,
  BulletSection,
  EMPTY_OBJECT,
  EMPTY_LIST,
  Highlight,
  defaultTo,
  getProps,
} from "../BulletSection";

const displayName = "AccordionSection";
const displayName2 = "AccordionList";

const OPT_SEC = "os";

export const PropTypeIdValueListListList = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      tree: PropTypes.string, // For debugging the hierarchy
      id: PropTypes.string,
      values: PropTypes.object,
      textList: PropTypeIdValueListList,
    }),
  ])
);

export const PropTypeIdValueListListListList = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      tree: PropTypes.string, // For debugging the hierarchy
      id: PropTypes.string,
      values: PropTypes.object,
      textList: PropTypeIdValueListListList,
    }),
  ])
);

export function AccordionSection({
  id,
  tree,
  highlight,
  expand,
  expandAll,
  textList,
  values,
}) {
  const globals = values;
  const theme = useTheme();
  const trunk = defaultTo(tree, id || "ACCSEC");
  const highlighter = new Highlight(theme, highlight);

  const expandThis = !!expand && new RegExp(expand).test(trunk);
  const openerTree = `${trunk}.opener`;
  const highlightSection =
    highlighter.section || highlighter.getItemHighlight(openerTree, OPT_SEC);
  const highlightOpener = highlighter.getItemHighlight(openerTree);

  return (
    <OptionalSection id={id} values={values} data-tree={`${trunk}-outer`}>
      <CollapsibleSection
        testId={id}
        id={trunk}
        data-tree={trunk}
        title={useOptionalMessage(id, values)}
        expand={expandAll || expandThis}
        style={highlightSection}
        openerStyle={highlightOpener}
      >
        {textList.map(function renderSections(props, index) {
          const { id, tree, innerValues, textList } = getProps(
            props,
            globals,
            trunk,
            index,
            "text"
          );

          return !id ? null : (
            <BulletSection
              key={id}
              tree={tree}
              highlight={highlight}
              id={id}
              values={innerValues}
              textList={textList}
            />
          );
        })}
      </CollapsibleSection>
    </OptionalSection>
  );
}
AccordionSection.displayName = displayName;
AccordionSection.propTypes = {
  tree: PropTypes.string, // For debugging the hierarchy
  expandAll: PropTypes.bool,
  expand: PropTypes.string,
  highlight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  values: PropTypes.object,
  textList: PropTypeIdValueListListList,
};
AccordionSection.defaultProps = {
  textList: EMPTY_LIST,
  values: EMPTY_OBJECT,
};

export function AccordionList({
  id,
  tree,
  highlight,
  expand,
  expandAll,
  textList,
  values,
}) {
  const globals = values;
  const theme = useTheme();
  const trunk = defaultTo(tree || "ACCLIST");
  const highlighter = new Highlight(theme, highlight);
  const highlightSection = highlighter.getItemHighlight(trunk);

  return !id ? null : (
    <Content key={id} theme={theme}>
      <Headline.Themed style={highlightSection}>
        <OptionalMessage id={id} data-tree={trunk} values={values} />
      </Headline.Themed>
      {textList.map(function renderAccordionSections(props, index) {
        const { id, tree, innerValues, textList } = getProps(
          props,
          globals,
          trunk,
          index,
          "text"
        );

        return !id ? null : (
          <AccordionSection
            key={id}
            tree={tree}
            expandAll={expandAll}
            expand={expand}
            highlight={highlight}
            id={id}
            values={innerValues}
            textList={textList}
          />
        );
      })}
    </Content>
  );
}
AccordionList.displayName = displayName2;
AccordionList.propTypes = {
  tree: PropTypes.string, // For debugging the hierarchy
  expandAll: PropTypes.bool,
  expand: PropTypes.string,
  highlight: PropTypes.string,
  id: PropTypes.string,
  values: PropTypes.object,
  textList: PropTypeIdValueListListListList,
};
AccordionList.defaultProps = {
  textList: EMPTY_LIST,
  values: EMPTY_OBJECT,
};
