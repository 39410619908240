import {
  URL_QUERY_ACTIVATION_CODE,
  URL_QUERY_EXPAND,
  URL_QUERY_HIGHLIGHT,
} from "../constants/keys";
import useValidActivationCode from "./useValidActivationCode";
import useQueryParams from "./useQueryParams";

function useValidActivationCodeQuery() {
  const query = useQueryParams();
  const { hasCode, isValidCode } = useValidActivationCode();

  query.delete(URL_QUERY_EXPAND);
  query.delete(URL_QUERY_HIGHLIGHT);

  let queryString = query.toString();
  let isStripped = false;
  if (hasCode && !isValidCode) {
    query.delete(URL_QUERY_ACTIVATION_CODE);
    queryString = query.toString();
    isStripped = true;
  }
  const symbol = queryString.length ? "?" : "";
  queryString = symbol + queryString;

  return {
    queryString,
    isStripped,
  };
}

export default useValidActivationCodeQuery;
