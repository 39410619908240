import styled from "@emotion/styled";
import { getInstance } from "partnerConfigs/singleton";
import useNavigate from "../../hooks/useNavigate";
import useValidActivationCodeQuery from "../../hooks/useValidActivationCodeQuery";
// eslint-disable-next-line no-unused-vars
const displayName = "LogotypeClickable";

const Wrapper = styled.span`
  box-sizing: border-box;
  width: fit-content;
  height: fit-content;
`;

function LogotypeClickable() {
  const { Logotype } = getInstance();
  const { queryString } = useValidActivationCodeQuery();
  const navigate = useNavigate();

  function handleLogoClick(event) {
    event.preventDefault();
    navigate.toLandingPage(queryString);
  }
  return (
    <Wrapper data-testid="app-logo" onClick={handleLogoClick}>
      <Logotype />
    </Wrapper>
  );
}

export default LogotypeClickable;
