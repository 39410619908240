import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import {
  spacingMedium,
  borderWidthThin,
} from "@medi24-da2c/web-ui/design-tokens";
import { useFocusRing } from "@react-aria/focus";
import NextStepButtonBase from "components/NextStepButton";
import useSmallFont from "hooks/useSmallFont";

const Wrapper = styled.li`
  list-style: none;
  border-top: ${borderWidthThin} solid
    ${(themedProps) => themedProps.theme.general.borderColor};

  &:last-of-type {
    border-bottom: ${borderWidthThin} solid
      ${(themedProps) => themedProps.theme.general.borderColor};
  }
`;

const Label = styled.span`
  margin-right: ${spacingMedium};
`;

const displayName = "InitialStep.NextStepButton";

function NextStepButton({ onClick, children }) {
  const theme = useTheme();
  const { isFocusVisible, focusProps } = useFocusRing();
  const withSmallFont = useSmallFont();

  return (
    <Wrapper theme={theme}>
      <NextStepButtonBase
        {...focusProps}
        isFocusVisible={isFocusVisible}
        withSmallFont={withSmallFont}
        onClick={onClick}
      >
        <Label>{children}</Label>
      </NextStepButtonBase>
    </Wrapper>
  );
}

NextStepButton.displayName = displayName;

NextStepButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default NextStepButton;
