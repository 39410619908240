import axios from "axios";
import { useRef, useEffect } from "react";
import { invokeNowOrLater } from "constants/switches";
import useLanguage, { toChatBotMyDocLanguage } from "hooks/useLanguage";
import { INVITE_ERROR } from "../../api/errorCodes";
import { ERROR_STEP } from "../../constants/steps";
import * as api from "../../api/selfRegistration";

const displayName = "MyDocModal.useActivationCode";

/**
 * Get GENERIC_ERROR or specific coce, without throwing another error.
 * @param  {object} response with data.errors array of error objects with an error.code property.
 * @returns {string} GENERIC_ERROR or some other allowed error code string.
 */
export function getErrorCode(response) {
  return INVITE_ERROR;
}

// Custom React Hook
// Handles communication with backend API, loading state, and errors
// Generates mydoc invitation url from activation code based on provided (form) values
// Values for willGetInvitationUrl must be passed in the shape accepted by API
// It must be an object with one of the keys specified as "userKey"
function useActivationCode(props) {
  // window.console.warn(displayName, props);
  const { loading, onSetLoading, setStep, setInviteUrl, setErrorCode } = props;
  const setLoading = onSetLoading;

  let requestCancelTokenRef = useRef(null);
  const [language] = useLanguage();

  useEffect(() => {
    requestCancelTokenRef.current = axios.CancelToken.source();

    return () => {
      requestCancelTokenRef.current.cancel();
    };
  }, []);

  // Use in handleSubmit
  async function willGetInvitationUrl(activationCode) {
    let inviteUrl;
    let assignStep;
    let assignError;

    // window.console.warn(`${displayName}.willGetInvitationUrl Show Loader ${activationCode}`);
    setLoading(true);

    try {
      inviteUrl = await api.willGetInvitationFromActivationCode(
        activationCode,
        toChatBotMyDocLanguage(language),
        requestCancelTokenRef.current.token
      );
      // window.console.warn(`${displayName}.willGetInvitationUrl result ${inviteUrl}`);
    } catch (error) {
      // window.console.warn(`${displayName}.willGetInvitationUrl catch`, error);
      if (!axios.isCancel(error)) {
        // window.console.warn(`MyDocModal willGetInvitationUrl !axios.isCancel`);
        const errorCode = getErrorCode(error.response);
        // window.console.warn(`MyDocModal willGetInvitationUrl not cancel`, errorCode);
        assignError = errorCode;
        /* istanbul ignore next */
        if (setStep) {
          assignStep = ERROR_STEP;
        }
      }
    } finally {
      // window.console.warn(`${displayName}.willGetInvitationUrl finally`);
      invokeNowOrLater(() => {
        if (inviteUrl) {
          setInviteUrl(inviteUrl);
        }
        if (assignError) {
          setErrorCode(assignError);
        }
        if (assignStep) {
          setStep(assignStep);
        }
        if (!inviteUrl) {
          setLoading(false);
        }
      }, `${displayName}.willGetInvitationUrl URL:${inviteUrl} EC:${assignError} setLoading(false)`);
    }
  }

  return { willGetInvitationUrl, loading };
}

export default useActivationCode;
