// HiddenState for debugging or adding hidden items to the DOM
import styled from "@emotion/styled";
import { DEV_SHOW_HIDDEN_STATE } from "constants/switches";

const displayName = "HiddenState";

const Visible = styled("span")``;

const Invisible = styled("span")`
  display: none;
  visibility: hidden;
`;

/* istanbul ignore next */
const Span = DEV_SHOW_HIDDEN_STATE ? Visible : Invisible;

function HiddenState({ id, children, ...props }) {
  // window.console.warn(`${displayName} ${DEV_SHOW_HIDDEN_STATE}`, children);
  return (
    <Span {...props} aria-hidden="true" data-testid={id}>
      {children}
    </Span>
  );
}
HiddenState.displayName = displayName;

export default HiddenState;
