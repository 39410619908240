import baseConfig from "../mc-asia/config";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh mc-asia subpartner mc-core-sg 4 Singapore digitalhealthxtra sg allianz mydoc MCCORESG en

// DEVTEST Test Singapore with Mastercard: 2020202027
const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHXTRA_SG_4",
    partnerCountry: "Singapore",
    subPartnerCode: "MCCORESG",
  },
  languages: "en".split(" "),
  messages: {
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
