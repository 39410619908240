import PropTypes from "prop-types";
import { OptionalText } from "components/OptionalMessage";
import FormCheckbox from "../FormCheckbox";

const displayName = "ConsentForm.ConsentCheckbox";

function ConsentCheckbox({ name, value, values, onChange }) {
  const id = `consentForm.${name}`;
  // TODO(2022-01-07) Making the text the label is good on desktop but maybe not on mobile
  // so really should onlt do it for small screens? wait to see if business wants it...
  return (
    <FormCheckbox
      name={name}
      id={`checkbox-${id}`}
      value={value}
      onChange={onChange}
    >
      <label htmlFor={`checkbox-${id}`}>
        <OptionalText id={id} values={values} />
      </label>
    </FormCheckbox>
  );
}
ConsentCheckbox.displayName = displayName;
ConsentCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  values: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default ConsentCheckbox;
