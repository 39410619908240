import styled from "@emotion/styled";

import { spacingMedium } from "@medi24-da2c/web-ui/design-tokens";

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 10rem;
  padding: ${spacingMedium};
  flex: 1;
`;

export default Controls;
