// Like OptionalText but centered
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Text as TextBase } from "@medi24-da2c/web-ui";
import { OptionalMessage } from "components/OptionalMessage";

const displayName = "Card.Text.Message";

const Text = styled(TextBase.Themed)`
  text-align: center;
`;

function Message({ id, values, ...props }) {
  return (
    <Text {...props}>
      <OptionalMessage id={id} values={values} />
    </Text>
  );
}
Message.displayName = displayName;
Message.propTypes = {
  id: PropTypes.string.isRequired,
  testId: PropTypes.string,
  values: PropTypes.object,
};

Text.Message = Message;

export default Text;
