import baseConfig from "../mc-core-emma-lite/config";
import ukInherited from "./translations/uk.inherit.json";
import enInherited from "./translations/en.inherit.json";
import ukOverrides from "./translations/uk.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh mc-core-emma-lite subpartner mc-emlt-ua 20 Ukraine digitalhealth ua NOBOT mceuua MCEUUA uk en

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTH_UA_20",
    partnerCountry: "Ukraine",
    partnerCode: "MCEUUA",
    subPartnerCode: "",
  },
  features: {
    ...baseConfig.features,
    QuickSummary: true,
    showViber: true,
    redirectAppDoctorChat: {
      whatsapp: "whatsapp.emmaukraine",
    },
    smallScreenFonts: { uk: true },
  },
  languages: "uk en".split(" "),
  messages: {
    uk: { ...ukInherited, ...ukOverrides },
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
