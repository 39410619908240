import { useState } from "react";
import { useLocation, useNavigate as useReactNavigate } from "react-router-dom";
import { URL_QUERY_ACTIVATION_CODE, URL_QUERY_LANGUAGE } from "constants/keys";
import useQueryParams from "hooks/useQueryParams";
import * as URL from "constants/urls";

// eslint-disable-next-line no-unused-vars
const displayName = "useNavigate";

function useNavigate({ activationCode } = {}) {
  const query = useQueryParams();
  const { search } = useLocation();
  const navigate = useReactNavigate();

  function setQuery(code) {
    query.set(URL_QUERY_ACTIVATION_CODE, code);
  }

  if (activationCode) {
    // Add new activation code to URL or replace existing one
    setQuery(activationCode);
  }

  return useState({
    toLanguage(language) {
      if (query.has(URL_QUERY_LANGUAGE)) {
        query.set(URL_QUERY_LANGUAGE, language);
        navigate(`?${query}`);
      }
    },
    toWrongCode(activationCode) {
      // Append activation code to URL and redirect to LandingPage
      setQuery(activationCode);
      navigate(`${URL.HOME}?${query}`);
    },
    toLandingPage(queryString = "") {
      queryString = "string" !== typeof queryString ? "" : queryString;
      // Redirect to LandingPage
      navigate(`${URL.HOME}${queryString}`);
    },
    toFaqPage(queryString = "" /* topicId string coming... */) {
      queryString = "string" !== typeof queryString ? "" : queryString;
      // const topic = "string" === typeof topicId ? topicId : void 0
      navigate(`${URL.FAQ}${queryString}`);
    },
    toPrivacyPage(queryString = "" /* topicId string coming ... */) {
      queryString = "string" !== typeof queryString ? "" : queryString;
      // const topic = "string" === typeof topicId ? topicId : void 0
      navigate(`${URL.PRIVACY}${queryString}`);
    },
    toChooseMessenger(activationCode) {
      if (activationCode) {
        // Add new activation code to URL or replace existing one
        setQuery(activationCode);
      }
      navigate(`${URL.CHOOSE}?${query}`);
    },
    toActivateChat(redirectUrl, activationCode) {
      setQuery(activationCode);
      navigate(`${redirectUrl}?${query}`);
    },
    redirectWithSearch(redirectUrl, newSearch = search || "") {
      navigate(`${redirectUrl}${newSearch}`);
    },
  })[0];
}

export default useNavigate;
