// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function TelegramLogo() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      role="img"
      aria-labelledby="icon-telegram-logo-title"
    >
      <title id="icon-telegram-logo-title">Telegram</title>
      <linearGradient
        id="te-linear-gradient-1"
        gradientUnits="userSpaceOnUse"
        x1="-655.129"
        y1="407.473"
        x2="-655.179"
        y2="407.356"
        gradientTransform="matrix(240 0 0 -240 157262 97801)"
      >
        <stop offset="0" stopColor="#37aee2" />
        <stop offset="1" stopColor="#1e96c8" />
      </linearGradient>
      <circle fill="url(#te-linear-gradient-1)" cx="24" cy="24" r="24" />
      <path
        fill="#c8daea"
        d="M19.6 35c-.778 0-.645-.294-.914-1.034L16.4 26.441 34 16 19.6 35z"
      />
      <path
        fill="#a9c9dd"
        d="M19.6 35c.6 0 .865-.274 1.2-.6l3.2-3.112-3.992-2.407L19.6 35z"
      />
      <linearGradient
        id="te-linear-gradient-2"
        gradientUnits="userSpaceOnUse"
        x1="-653.136"
        y1="406.155"
        x2="-653.097"
        y2="406.082"
        gradientTransform="matrix(130.4927 0 0 -109.4498 85255.117 44478.164)"
      >
        <stop offset="0" stopColor="#eff7fc" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <path
        fill="url(#te-linear-gradient-2)"
        d="M20.008 28.882l9.672 7.146c1.104.609 1.9.294 2.175-1.025l3.937-18.553c.403-1.616-.616-2.349-1.672-1.87l-23.118 8.914c-1.578.633-1.569 1.513-.288 1.906l5.933 1.852 13.735-8.665c.648-.393 1.244-.182.755.252L20.008 28.882z"
      />
    </svg>
  );
}

export default TelegramLogo;
