import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import ModaBase from "components/Modal";
import StepsManager from "./StepsManager";

// Simple state machine to manage modal steps
// Base for other eligibility check modals
const displayName = "EligibilityModalBase";

const titleId = "chooseMessengerPage.generateActivationCodeModal.title";

function EligibilityModalBase(props) {
  const { isOpen, trackId, trackOptions, onClose, ...steps } = props;

  return (
    <ModaBase
      titleId={props.titleId || titleId}
      trackId={trackId}
      trackOptions={trackOptions}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ErrorBoundary>
        <StepsManager {...steps} />
      </ErrorBoundary>
    </ModaBase>
  );
}

EligibilityModalBase.displayName = displayName;

EligibilityModalBase.propTypes = {
  titleId: PropTypes.string,
  idInfix: PropTypes.string,
  trackId: PropTypes.string.isRequired,
  trackOptions: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialStep: PropTypes.func.isRequired,
  enterCodeStep: PropTypes.func.isRequired,
  generateCodeStep: PropTypes.func.isRequired,
  successStep: PropTypes.func.isRequired,
  errorStep: PropTypes.func.isRequired,
};

export default EligibilityModalBase;
