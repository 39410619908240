import usePartner from "hooks/usePartner";
import { BinOnlyStep } from "../GenerateCodeSteps";

// eslint-disable-next-line no-unused-vars
const displayName = "MyDocModalGenerateCodeStep";

function MyDocModalGenerateCodeStep(props) {
  const partner = usePartner();
  const config = partner.config;
  const general = partner.config.general;
  const partnerCode = general.subPartnerCode;
  const binOnlyRegex = config.features.binOnlyRegex;
  return (
    <BinOnlyStep
      idInfix="MyDoc"
      {...props}
      partnerCode={partnerCode}
      binOnlyRegex={binOnlyRegex}
    />
  );
}

export default MyDocModalGenerateCodeStep;
