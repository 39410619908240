import PropTypes from "prop-types";
import { ErrorBoundary } from "@medi24-da2c/web-ui";
import Content from "@medi24-da2c/web-ui/Content/Content";
import useDHA from "hooks/useDHA";
import useMyDoc from "hooks/useMyDoc";
import {
  HideDivBelowDesktop,
  HideDivOnDesktop,
} from "components/Visibility/Visibility";
import { OptionalSection } from "components/OptionalMessage";
import useQuickSummaryBenefits from "hooks/useQuickSummaryBenefits";
import {
  MobileQuickLinksContent,
  DesktopQuickLinksContent,
} from "./QuickLinksContent";
import { ContentHalf, ContentHalfRight } from "./ContentHalf";
import QuickLinks from "./QuickLinks";
import Legal from "./Legal";
import {
  MobileOverview,
  MobileGroup,
  BenefitHeadline,
  BenefitPoint,
} from "./BenefitPoint";
import { QuickLinkOverview } from "./QuickLinkOverview";

const displayName = "QuickSummary";

function Benefit1({ spaced }) {
  const result = (
    <>
      {spaced && <br />}
      <BenefitPoint
        br={spaced}
        id="landingPage.quickLink.benefits.item1.headline"
      />
      <BenefitPoint
        br={spaced}
        id="landingPage.quickLink.benefits.item1.content"
      />
    </>
  );
  return spaced ? <MobileGroup>{result}</MobileGroup> : result;
}
Benefit1.displayName = "Benefit1";
Benefit1.propTypes = {
  spaced: PropTypes.bool,
};

function Benefit2({ spaced }) {
  const result = (
    <>
      <BenefitPoint
        br={spaced}
        id="landingPage.quickLink.benefits.item2.headline"
      />
      <BenefitPoint
        br={spaced}
        id="landingPage.quickLink.benefits.item2.content"
      />
    </>
  );
  return spaced ? <MobileGroup>{result}</MobileGroup> : result;
}
Benefit2.displayName = "Benefit2";
Benefit2.propTypes = {
  ...Benefit1.propTypes,
};

function Benefit3({ spaced }) {
  const result = (
    <>
      <BenefitPoint
        br={spaced}
        id="landingPage.quickLink.benefits.item3.headline"
      />
      <BenefitPoint id="landingPage.quickLink.benefits.item3.content" />
    </>
  );
  return spaced ? <MobileGroup>{result}</MobileGroup> : result;
}
Benefit3.displayName = "Benefit3";
Benefit3.propTypes = {
  ...Benefit1.propTypes,
};

function Benefits({ hasDHA, hasMyDoc, spaced }) {
  return (
    <>
      {hasDHA && <Benefit1 spaced={spaced} />}
      {hasMyDoc && (
        <>
          <Benefit2 spaced={spaced} />
          <Benefit3 spaced={spaced} />
        </>
      )}
    </>
  );
}
Benefits.displayName = "Benefits";
Benefits.propTypes = {
  ...Benefit1.propTypes,
  hasDHA: PropTypes.bool,
  hasMyDoc: PropTypes.bool,
};

function QuickSummary({ myDocLoading, onClickEmma, onClickMyDoc }) {
  const partnerDHA = useDHA();
  const partnerMyDoc = useMyDoc();
  const partnerShowBenefits = useQuickSummaryBenefits();

  return (
    <ErrorBoundary>
      <OptionalSection id="landingPage.quickLink.headline">
        <Content data-testid={displayName}>
          {/* Mobile - one big column */}
          <HideDivOnDesktop data-testid={`${displayName}-div-mobile`}>
            <MobileQuickLinksContent data-testid={`${displayName}-mobile`}>
              <Content data-testid={`${displayName}-panel`}>
                <BenefitHeadline id="landingPage.quickLink.headline" />
                <MobileOverview data-testid={`${displayName}-overview-group`}>
                  <Legal>
                    <QuickLinkOverview />
                  </Legal>
                  <br />
                  <BenefitPoint id="landingPage.quickLink.benefits" />
                </MobileOverview>
                <Benefits
                  spaced
                  hasDHA={partnerDHA || partnerShowBenefits}
                  hasMyDoc={partnerMyDoc || partnerShowBenefits}
                />
                <QuickLinks
                  ident="-footer"
                  onClickEmma={onClickEmma}
                  onClickMyDoc={onClickMyDoc}
                  myDocLoading={myDocLoading}
                />
              </Content>
            </MobileQuickLinksContent>
          </HideDivOnDesktop>
          {/* Bigger Screens - two column layout */}
          <HideDivBelowDesktop data-testid={`${displayName}-div-desktop`}>
            <DesktopQuickLinksContent
              data-testid={`${displayName}-wide-screen`}
            >
              <ContentHalf data-testid={`${displayName}-panel-left`}>
                <BenefitHeadline id="landingPage.quickLink.headline" />
                <QuickLinkOverview />

                <QuickLinks
                  ident="-inner"
                  onClickEmma={onClickEmma}
                  onClickMyDoc={onClickMyDoc}
                  myDocLoading={myDocLoading}
                />
              </ContentHalf>

              <ContentHalfRight data-testid={`${displayName}-panel-right`}>
                <BenefitPoint id="landingPage.quickLink.benefits" />
                <Benefits
                  hasDHA={partnerDHA || partnerShowBenefits}
                  hasMyDoc={partnerMyDoc || partnerShowBenefits}
                />
              </ContentHalfRight>
            </DesktopQuickLinksContent>
          </HideDivBelowDesktop>
        </Content>
      </OptionalSection>
    </ErrorBoundary>
  );
}
QuickSummary.displayName = displayName;
QuickSummary.propTypes = {
  myDocLoading: PropTypes.bool,
  onClickEmma: PropTypes.func.isRequired,
  onClickMyDoc: PropTypes.func.isRequired,
};

export default QuickSummary;
