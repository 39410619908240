import PropTypes from "prop-types";
import IllustrationWrapper from "./IllustrationWrapper";
import Subheadline from "./Subheadline";
import Headline from "./Headline";
import Wrapper from "./Wrapper";
import Text from "./Text";

const displayName = "Card";

export function Card(props) {
  const { testId, headlineId, subheadlineId, textId, illustration } = props;
  return (
    <Wrapper data-testid={`${displayName}-wrapper-${testId}`}>
      <Headline.Message
        id={headlineId}
        data-testid={`${displayName}-Headline`}
      />
      {illustration && (
        <IllustrationWrapper
          data-testid={`${displayName}-Headline-Illustration`}
        >
          {illustration}
        </IllustrationWrapper>
      )}
      <Subheadline.Message
        id={subheadlineId}
        data-testid={`${displayName}-SubHeadline`}
      />
      <Text.Message id={textId} data-testid={`${displayName}-Text`} />
    </Wrapper>
  );
}
Card.displayName = displayName;
Card.propTypes = {
  testId: PropTypes.string.isRequired,
  headlineId: PropTypes.string.isRequired,
  subheadlineId: PropTypes.string,
  textId: PropTypes.string.isRequired,
  illustration: PropTypes.element,
};
Card.defaultProps = {};
