import {
  Content,
  Headline,
  Subheadline,
  ErrorBoundary,
} from "@medi24-da2c/web-ui";

import useDoctorChat from "hooks/useDoctorChat";
import useUsefulLinks from "hooks/useUsefulLinks";
import { getInstance } from "partnerConfigs/singleton";
import useMedicalHotline from "hooks/useMedicalHotline";
import useSymptomChecker from "hooks/useSymptomChecker";
import useTranslatedTitle from "hooks/useTranslatedTitle";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import {
  BulletPreamble,
  BulletParagraphs,
  BulletSubSections,
} from "components/BulletSection";
import Section from "components/Section";
import { OptionalMessage, useSearchLinks } from "components/OptionalMessage";

const displayName = "TermsOfUsePage";

const HIDE = void 0;

function TermsOfUsePage() {
  const { theme } = getInstance();
  const hasDoctorChat = useDoctorChat();
  const hasUsefulLinks = useUsefulLinks();
  const withSearchLinks = useSearchLinks();
  const hasMedicalHotline = useMedicalHotline();
  const hasSymptomChecker = useSymptomChecker();

  useTranslatedTitle("termsOfUsePage.title", "Terms of Use");
  useTrackPageEffect("TERMS_PAGE");

  return (
    <ErrorBoundary>
      <Content data-testid={displayName}>
        <Headline.Themed>
          <OptionalMessage id="termsOfUsePage.headline" />
        </Headline.Themed>
        <Section>
          <BulletSubSections
            theme={theme}
            tree="terms1"
            id="termsOfUse.general.headline"
            paraList={[
              {
                tree: "terms1.para1",
                preList: [
                  "termsOfUse.general.p1",
                  "termsOfUse.general.p2",
                  "termsOfUse.general.p3",
                  "termsOfUse.general.p4",
                ],
              },
            ]}
          />
        </Section>
        <Section>
          <BulletSubSections
            theme={theme}
            tree="terms2"
            id="termsOfUse.scope.headline"
            paraList={[
              {
                tree: "terms2.para1",
                preList: [
                  "termsOfUse.scope.p1",
                  "termsOfUse.scope.p2",
                  "termsOfUse.scope.p3",
                  "termsOfUse.scope.p4",
                  "termsOfUse.scope.p5",
                ],
              },
            ]}
          />
        </Section>
        <Section>
          <Subheadline.Themed>
            <OptionalMessage id="termsOfUse.individualServices.headline" />
          </Subheadline.Themed>
          <BulletParagraphs
            theme={theme}
            tree="terms3.1"
            id="termsOfUse.individualServices.generalInfo.headline"
            paraList={[
              {
                tree: "terms3.1.para1",
                preList: [
                  "termsOfUse.individualServices.generalInfo.p1",
                  "termsOfUse.individualServices.generalInfo.p2",
                  "termsOfUse.individualServices.generalInfo.p3",
                  "termsOfUse.individualServices.generalInfo.p4",
                ],
                textList: [
                  "termsOfUse.individualServices.generalInfo.list.item1",
                  "termsOfUse.individualServices.generalInfo.list.item2",
                  "termsOfUse.individualServices.generalInfo.list.item3",
                  "termsOfUse.individualServices.generalInfo.list.item4",
                  "termsOfUse.individualServices.generalInfo.list.item5",
                  "termsOfUse.individualServices.generalInfo.list.item6",
                ],
              },
              {
                tree: "terms3.1.para2",
                preList: [
                  "termsOfUse.individualServices.generalInfo.p5",
                  "termsOfUse.individualServices.generalInfo.p6",
                  "termsOfUse.individualServices.generalInfo.p7",
                  "termsOfUse.individualServices.generalInfo.p8",
                ],
              },
            ]}
          />
          <BulletPreamble
            theme={theme}
            tree="terms3.2"
            id={
              hasSymptomChecker
                ? "termsOfUse.individualServices.symptomCheck.headline"
                : HIDE
            }
            preList={[
              "termsOfUse.individualServices.symptomCheck.p1",
              "termsOfUse.individualServices.symptomCheck.p2",
              "termsOfUse.individualServices.symptomCheck.p3",
              "termsOfUse.individualServices.symptomCheck.p4",
              "termsOfUse.individualServices.symptomCheck.p5",
            ]}
          />
          <BulletPreamble
            theme={theme}
            tree="terms3.3"
            id={
              hasDoctorChat
                ? "termsOfUse.individualServices.doctorChat.headline"
                : HIDE
            }
            preList={[
              "termsOfUse.individualServices.doctorChat.p1",
              "termsOfUse.individualServices.doctorChat.p2",
              "termsOfUse.individualServices.doctorChat.p3",
              "termsOfUse.individualServices.doctorChat.p4",
              "termsOfUse.individualServices.doctorChat.p5",
              "termsOfUse.individualServices.doctorChat.p6",
            ]}
          />
          <BulletParagraphs
            theme={theme}
            tree="terms3.4"
            id={
              hasMedicalHotline
                ? "termsOfUse.individualServices.medicalHotline.headline"
                : HIDE
            }
            paraList={[
              {
                tree: "terms3.4.para1",
                preList: [
                  "termsOfUse.individualServices.medicalHotline.p1",
                  "termsOfUse.individualServices.medicalHotline.p2",
                  "termsOfUse.individualServices.medicalHotline.p3",
                  "termsOfUse.individualServices.medicalHotline.p4",
                ],
                textList: [
                  "termsOfUse.individualServices.medicalHotline.list.item1",
                  "termsOfUse.individualServices.medicalHotline.list.item2",
                  "termsOfUse.individualServices.medicalHotline.list.item3",
                ],
              },
              {
                tree: "terms3.4.para2",
                preList: [
                  "termsOfUse.individualServices.medicalHotline.p5",
                  "termsOfUse.individualServices.medicalHotline.p6",
                  "termsOfUse.individualServices.medicalHotline.p7",
                  "termsOfUse.individualServices.medicalHotline.p8",
                  "termsOfUse.individualServices.medicalHotline.p9",
                  "termsOfUse.individualServices.medicalHotline.p10",
                  "termsOfUse.individualServices.medicalHotline.p11",
                ],
              },
            ]}
          />
          <BulletPreamble
            theme={theme}
            tree="terms3.5"
            id={
              hasUsefulLinks
                ? "termsOfUse.individualServices.links.headline"
                : HIDE
            }
            preList={[
              "termsOfUse.individualServices.links.p1",
              "termsOfUse.individualServices.links.p2",
              "termsOfUse.individualServices.links.p3",
              "termsOfUse.individualServices.links.p4",
              "termsOfUse.individualServices.links.p5",
              "termsOfUse.individualServices.links.p6",
            ]}
          />
        </Section>
        <Section>
          <BulletSubSections
            theme={theme}
            tree="terms4"
            values={withSearchLinks}
            id="termsOfUse.dataProtection.headline"
            paraList={[
              {
                tree: "terms4.para1",
                preList: [
                  "termsOfUse.dataProtection.p1",
                  "termsOfUse.dataProtection.p2",
                ],
              },
            ]}
          />
        </Section>
        <Section>
          <Subheadline.Themed>
            <OptionalMessage id="termsOfUse.disclaimers.headline" />
          </Subheadline.Themed>
          <BulletParagraphs
            theme={theme}
            tree="terms5.1"
            id="termsOfUse.disclaimers.emmaInGeneral.headline"
            paraList={[
              {
                tree: "terms5.1.para1",
                preList: [
                  "termsOfUse.disclaimers.emmaInGeneral.p1",
                  "termsOfUse.disclaimers.emmaInGeneral.p2",
                ],
                textList: [
                  "termsOfUse.disclaimers.emmaInGeneral.list.item1",
                  "termsOfUse.disclaimers.emmaInGeneral.list.item2",
                ],
              },
              {
                tree: "terms5.1.para2",
                preList: [
                  "termsOfUse.disclaimers.emmaInGeneral.p3",
                  "termsOfUse.disclaimers.emmaInGeneral.p4",
                  "termsOfUse.disclaimers.emmaInGeneral.p5",
                ],
              },
            ]}
          />
          <BulletParagraphs
            theme={theme}
            tree="terms5.2"
            id="termsOfUse.disclaimers.doctorChat.headline"
            paraList={[
              {
                tree: "terms5.2.para1",
                preList: [
                  "termsOfUse.disclaimers.doctorChat.p1",
                  "termsOfUse.disclaimers.doctorChat.p2",
                  "termsOfUse.disclaimers.doctorChat.p3",
                ],
                textList: [
                  "termsOfUse.disclaimers.doctorChat.list1.item1",
                  "termsOfUse.disclaimers.doctorChat.list1.item2",
                  "termsOfUse.disclaimers.doctorChat.list1.item3",
                  "termsOfUse.disclaimers.doctorChat.list1.item4",
                ],
              },
              {
                tree: "terms5.2.para2",
                preList: [
                  "termsOfUse.disclaimers.doctorChat.p4",
                  "termsOfUse.disclaimers.doctorChat.p5",
                ],
                textList: [
                  "termsOfUse.disclaimers.doctorChat.list2.item1",
                  "termsOfUse.disclaimers.doctorChat.list2.item2",
                  "termsOfUse.disclaimers.doctorChat.list2.item3",
                ],
              },
            ]}
          />
          <BulletPreamble
            theme={theme}
            tree="terms5.3"
            id={
              hasMedicalHotline
                ? "termsOfUse.disclaimers.medicalHotline.headline"
                : HIDE
            }
            preList={[
              "termsOfUse.disclaimers.medicalHotline.p1",
              "termsOfUse.disclaimers.medicalHotline.p2",
              "termsOfUse.disclaimers.medicalHotline.p3",
            ]}
          />
          <BulletPreamble
            theme={theme}
            tree="terms5.4"
            id={hasUsefulLinks ? "termsOfUse.disclaimers.links.headline" : HIDE}
            preList={["termsOfUse.disclaimers.links.p1"]}
          />
        </Section>
        <Section>
          <BulletSubSections
            theme={theme}
            tree="terms6"
            id="termsOfUse.changes.headline"
            paraList={[
              {
                tree: "terms6.para1",
                preList: ["termsOfUse.changes.p1"],
              },
            ]}
          />
        </Section>
        <Section>
          <BulletSubSections
            theme={theme}
            tree="terms7"
            id="termsOfUse.intellectualProperty.headline"
            paraList={[
              {
                tree: "terms7.para1",
                preList: [
                  "termsOfUse.intellectualProperty.p1",
                  "termsOfUse.intellectualProperty.p2",
                ],
              },
            ]}
          />
        </Section>
        <Section>
          <BulletSubSections
            theme={theme}
            tree="terms8"
            id="termsOfUse.provisions.headline"
            paraList={[
              {
                tree: "terms8.para1",
                preList: [
                  "termsOfUse.provisions.p1",
                  "termsOfUse.provisions.p2",
                  "termsOfUse.provisions.p3",
                  "termsOfUse.updates",
                ],
              },
            ]}
          />
        </Section>
      </Content>
    </ErrorBoundary>
  );
}

export default TermsOfUsePage;
