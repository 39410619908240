import baseConfig from "../mastercard/config";
import englishInherited from "./translations/en.inherit.json";
import englishOverrride from "./translations/en.json";

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "MC-DEMO-EU",
    partnerCountry: "Europe",
  },
  languages: ["en"],
  features: {
    ...baseConfig.features,
    DHA: true,
    MyDoc: false,
  },
  messages: {
    en: { ...englishInherited, ...englishOverrride },
  },
};

export default config;
