import { useCallback } from "react";
import PropTypes from "prop-types";

import { OptionalMessage } from "components/OptionalMessage";
import useNavigate from "hooks/useNavigate";
import Modal from "../Modal";
import EnterActivationCodeForm from "./EnterActivationCodeForm";
import Link from "./Link";

const displayName = "EnterActivationCodeModal";

function EnterActivationCodeModal({
  isOpen,
  trackId,
  trackOptions,
  redirectUrl,
  onClose,
}) {
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    function (activationCode) {
      navigate.toActivateChat(redirectUrl, activationCode);
    },
    [redirectUrl, navigate]
  );

  return (
    <Modal
      titleId="chooseMessengerPage.activationCodeModal.title"
      trackId={trackId}
      trackOptions={trackOptions}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Message id="chooseMessengerPage.activationCodeModal.content.info" />
        <Link>
          <OptionalMessage id="chooseMessengerPage.activationCodeModal.content.helpLink" />
        </Link>
        <EnterActivationCodeForm onSubmit={handleSubmit} />
      </Modal.Content>
    </Modal>
  );
}

EnterActivationCodeModal.displayName = displayName;

EnterActivationCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  trackId: PropTypes.string.isRequired,
  trackOptions: PropTypes.object,
  redirectUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EnterActivationCodeModal;
