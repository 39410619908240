import PropTypes from "prop-types";
import { Headline } from "@medi24-da2c/web-ui";
import { OptionalSection, OptionalMessage } from "components/OptionalMessage";

const displayName = "SimpleHeadline";

function SimpleHeadline({ id, values, ...props }) {
  return (
    <OptionalSection id={id} values={values}>
      <Headline.Themed {...props}>
        <OptionalMessage id={id} values={values} />
      </Headline.Themed>
    </OptionalSection>
  );
}
SimpleHeadline.displayName = displayName;
SimpleHeadline.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object,
};

export default SimpleHeadline;
