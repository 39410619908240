import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";
import { Subheadline } from "@medi24-da2c/web-ui";
import SimpleText from "components/SimpleText";
import Section from "components/Section";
import {
  OptionalSection,
  OptionalMessage,
  OptionalText,
  sprout,
} from "components/OptionalMessage";

const displayName = "BulletSection";
const displayName2 = "BulletList";
const displayName3 = "BulletPreamble";
const displayName4 = "BulletParagraphs";
const displayName5 = "TextBullets";
const displayName6 = "BulletSubSections";
const displayName7 = "NumberedSection";
const displayName8 = "NumberedSectionList";

const OPT_SEC = "os";
const OPT_MSG = "om";

export const EMPTY_LIST = [];
export const EMPTY_OBJECT = {};

export const PropTypeIdValueList = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      tree: PropTypes.string, // For anchor id and debugging the hierarchy
      id: PropTypes.string,
      values: PropTypes.object,
    }),
  ])
);

export const PropTypeIdValueListList = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      tree: PropTypes.string, // For anchor id and debugging the hierarchy
      id: PropTypes.string,
      values: PropTypes.object,
      textList: PropTypeIdValueList,
    }),
  ])
);

export const PropTypeParaList = PropTypes.arrayOf(
  PropTypes.shape({
    tree: PropTypes.string, // For anchor id and debugging the hierarchy
    preList: PropTypeIdValueListList,
    textList: PropTypeIdValueListList,
  })
);

export const PropTypePreambleList = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      tree: PropTypes.string, // For anchor id and debugging the hierarchy
      id: PropTypes.string,
      preList: PropTypeIdValueListList,
      textList: PropTypeIdValueListList,
    }),
  ])
);

export const PropTypeParaPreambleList = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      tree: PropTypes.string, // For anchor id and debugging the hierarchy
      id: PropTypes.string,
      paraList: PropTypeParaList,
      preambleList: PropTypePreambleList,
    }),
  ])
);

export function defaultTo(thing, value) {
  if (thing === false || thing === null || "undefined" === typeof thing) {
    return value;
  }
  return `${thing}`;
}

export function getProps(
  propsIn = {},
  globals = EMPTY_OBJECT,
  trunk,
  index,
  type = "item"
) {
  const props = propsIn === null ? {} : propsIn;
  let id,
    tree,
    values = EMPTY_OBJECT,
    preList = EMPTY_LIST,
    textList = EMPTY_LIST,
    paraList = EMPTY_LIST,
    preambleList = EMPTY_LIST;

  if ("string" === typeof props) {
    id = props;
    values = globals;
  } else {
    id = props.id;
    tree = props.tree;
    values = values === globals ? values : { ...globals, ...props.values };
    preList = props.preList || EMPTY_LIST;
    textList = props.textList || EMPTY_LIST;
    paraList = props.paraList || EMPTY_LIST;
    preambleList = props.preambleList || EMPTY_LIST;
  }
  tree =
    defaultTo(tree, tree) ||
    `${defaultTo(trunk, "TRUNK")}.${type}${defaultTo(index, "000")}`;

  return {
    id,
    tree,
    innerValues: values,
    preList,
    textList,
    paraList,
    preambleList,
  };
}

/**
 * an object for supplying highlight style to sections and items.
 * @param       {object} theme     The theme object to grab highlight style props from.
 * @param       {boolean|string} highlight true to highlight a section or a string to highlight an item by tree node name.
 * @constructor
 */
export function Highlight(theme, highlight = false) {
  const highlightStyle = highlight
    ? {
        backgroundColor: theme.general.highlightBgrColor,
        borderRadius: theme.button.borderRadius,
        boxShadow: "rgba(0, 0, 0, 0.08) 0 3px 10px 0",
        padding: "0.5rem",
      }
    : void 0;
  this.highlight = highlight;
  this.style = highlightStyle;
  this.section = "string" === typeof highlight ? void 0 : highlightStyle;
  this.item = void 0;
}
Highlight.prototype = {
  /**
   * gets the highlight style for an item given the tree node name.
   * @param  {string} tree the item's tree node name.
   * @param  {string} type the tree node prefix. default is "om".
   * @return {object}      a style object with highlighting or undefined.
   */
  getItemHighlight: function (tree, type = OPT_MSG) {
    this.item = `${type}-${tree}`.match(new RegExp(`${this.highlight}`, "i"))
      ? this.style
      : void 0;
    return this.item;
  },
};

export function BulletList({ id, tree, highlight, textList, values }) {
  const globals = values;
  const theme = useTheme();

  // NOTE: EARLY EXIT!
  if (!id || textList.length < 1) {
    return null;
  }

  const highlighter = new Highlight(theme, highlight);
  const trunk = defaultTo(tree, "BULLIST");
  const highlightSection =
    highlighter.section || highlighter.getItemHighlight(trunk, OPT_SEC);

  return (
    <ul key={`ul-${id}`} style={highlightSection} data-tree={trunk}>
      {sprout(trunk)}
      {textList.map((props, index) => {
        const { id, tree, innerValues } = getProps(
          props,
          globals,
          trunk,
          index,
          "bullet"
        );
        const highlightItem = highlighter.getItemHighlight(tree);

        return !id ? null : (
          <Section.ListItem
            key={id}
            id={id}
            values={innerValues}
            data-tree={tree}
            style={highlightItem}
          />
        );
      })}
    </ul>
  );
}
BulletList.displayName = displayName2;
BulletList.propTypes = {
  tree: PropTypes.string, // For anchor id and debugging the hierarchy
  highlight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  values: PropTypes.object,
  textList: PropTypeIdValueList,
};
BulletList.defaultProps = {
  tree: displayName2,
  textList: EMPTY_LIST,
  values: EMPTY_OBJECT,
};

export function BulletSection({ id, tree, highlight, textList, values }) {
  const globals = values;
  const theme = useTheme();
  const trunk = defaultTo(tree, id || "BULSEC");
  const highlighter = new Highlight(theme, highlight);

  const sectionTree = `${trunk}.head`;
  const highlightSection =
    highlighter.section || highlighter.getItemHighlight(trunk, OPT_SEC);
  const highlightSectionMessage = highlighter.getItemHighlight(sectionTree);

  return !id ? null : (
    <OptionalSection key={id} id={id} values={values} data-tree={trunk}>
      <section style={highlightSection}>
        <Section.Headline
          key={id}
          id={id}
          values={values}
          data-tree={sectionTree}
          style={highlightSectionMessage}
        />
        {textList.map((props, index) => {
          const { id, tree, innerValues, textList } = getProps(
            props,
            globals,
            trunk,
            index,
            "sub"
          );

          const textTree = `${tree}.head`;
          const highlightItem = highlighter.getItemHighlight(textTree);
          const highlightListSection = highlighter.getItemHighlight(
            textTree,
            OPT_SEC
          );

          return !id ? null : (
            <OptionalSection
              key={id}
              id={id}
              values={innerValues}
              data-tree={textTree}
            >
              <section style={highlightListSection}>
                <OptionalText
                  id={id}
                  values={innerValues}
                  data-tree={textTree}
                  style={highlightItem}
                />
                <BulletList
                  id={id}
                  tree={`${tree}.list`}
                  highlight={highlight}
                  textList={textList}
                  values={innerValues}
                />
              </section>
            </OptionalSection>
          );
        })}
      </section>
    </OptionalSection>
  );
}
BulletSection.displayName = displayName;
BulletSection.propTypes = {
  tree: PropTypes.string, // For anchor id and debugging the hierarchy
  highlight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  values: PropTypes.object,
  textList: PropTypeIdValueListList,
};
BulletSection.defaultProps = {
  tree: displayName,
  textList: EMPTY_LIST,
  values: EMPTY_OBJECT,
};

export function TextBullets({
  id,
  tree,
  highlight,
  preList,
  textList,
  values,
}) {
  const globals = values;
  const theme = useTheme();
  const trunk = defaultTo(tree, id || "TXTBUL");
  const highlighter = new Highlight(theme, highlight);
  const idBullets = id;
  let hasPreList = false;
  let bullets = null;

  const preRender = preList.map((props, index) => {
    const { id, tree, innerValues } = getProps(
      props,
      globals,
      trunk,
      index,
      "text"
    );

    hasPreList = hasPreList || id;
    let highlightBullets;
    let highlightItem = highlighter.getItemHighlight(tree);
    if (index + 1 >= preList.length) {
      highlightBullets = highlighter.getItemHighlight(tree, OPT_SEC);
      bullets = highlightBullets ? (
        <BulletList
          key={idBullets}
          id={idBullets}
          tree={trunk}
          highlight={!!highlightBullets}
          textList={textList}
          values={globals}
        />
      ) : null;
    }

    return !id ? null : (
      <section key={id} style={highlightBullets}>
        <SimpleText
          id={id}
          values={innerValues}
          data-tree={tree}
          style={highlightBullets || highlightItem}
        />
        {bullets}
      </section>
    );
  });

  return (
    <>
      {preRender}
      {bullets ? null : (
        <BulletList
          key={idBullets}
          id={idBullets}
          tree={trunk}
          highlight={highlight}
          textList={textList}
          values={globals}
        />
      )}
    </>
  );
}

TextBullets.displayName = displayName5;
TextBullets.propTypes = {
  tree: PropTypes.string, // For anchor id and debugging the hierarchy
  highlight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  values: PropTypes.object,
  preList: PropTypeIdValueListList,
  textList: PropTypeIdValueListList,
};
TextBullets.defaultProps = {
  tree: displayName5,
  preList: EMPTY_LIST,
  textList: EMPTY_LIST,
  values: EMPTY_OBJECT,
};

export function BulletPreamble({
  id,
  tree,
  highlight,
  preList,
  textList,
  values,
}) {
  const globals = values;
  const theme = useTheme();
  const trunk = defaultTo(tree, id || "BULPRE");
  const highlighter = new Highlight(theme, highlight);

  const sectionTree = `${trunk}.head`;
  const highlightItem = highlighter.getItemHighlight(sectionTree);
  const highlightSection =
    highlighter.section || highlighter.getItemHighlight(trunk, OPT_SEC);

  return !id ? null : (
    <OptionalSection key={id} id={id} values={globals} data-tree={trunk}>
      <section style={highlightSection}>
        <Section.Headline
          key={id}
          id={id}
          values={globals}
          data-tree={sectionTree}
          style={highlightItem}
        />
        <TextBullets
          id={id}
          tree={trunk}
          highlight={highlight}
          preList={preList}
          textList={textList}
          values={globals}
        />
      </section>
    </OptionalSection>
  );
}
BulletPreamble.displayName = displayName3;
BulletPreamble.propTypes = {
  tree: PropTypes.string, // For anchor id and debugging the hierarchy
  highlight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  values: PropTypes.object,
  preList: PropTypeIdValueListList,
  textList: PropTypeIdValueListList,
};
BulletPreamble.defaultProps = {
  tree: displayName3,
  preList: EMPTY_LIST,
  textList: EMPTY_LIST,
  values: EMPTY_OBJECT,
};

export function BulletParagraphs({ id, tree, highlight, paraList, values }) {
  const globals = values;
  const theme = useTheme();
  const trunk = defaultTo(tree, id || "BULPARA");
  const highlighter = new Highlight(theme, highlight);

  const sectionTree = `${trunk}.head`;
  const highlightItem = highlighter.getItemHighlight(sectionTree);
  const highlightSection =
    highlighter.section || highlighter.getItemHighlight(trunk, OPT_SEC);

  return !id ? null : (
    <OptionalSection key={id} id={id} values={values} data-tree={trunk}>
      <section style={highlightSection}>
        <Section.Headline
          key={id}
          id={id}
          values={values}
          data-tree={sectionTree}
          style={highlightItem}
        />
        {paraList.map((props, index) => {
          const { tree, preList, textList } = getProps(
            props,
            globals,
            trunk,
            index,
            "para"
          );
          const highlightBullets =
            highlighter.section || highlighter.getItemHighlight(tree, OPT_SEC);

          return (
            <TextBullets
              key={index}
              id={id}
              tree={tree}
              highlight={highlightBullets || highlight}
              values={globals}
              preList={preList}
              textList={textList}
            />
          );
        })}
      </section>
    </OptionalSection>
  );
}
BulletParagraphs.displayName = displayName4;
BulletParagraphs.propTypes = {
  tree: PropTypes.string, // For anchor id and debugging the hierarchy
  highlight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  values: PropTypes.object,
  paraList: PropTypeParaList,
};
BulletParagraphs.defaultProps = {
  tree: displayName4,
  paraList: EMPTY_LIST,
  values: EMPTY_OBJECT,
};

export function BulletSubSections({ id, tree, highlight, paraList, values }) {
  const globals = values;
  const theme = useTheme();
  const trunk = defaultTo(tree, id || "BULSUBSEC");
  const highlighter = new Highlight(theme, highlight);
  const sectionTree = `${trunk}.head`;
  const highlightItem = highlighter.getItemHighlight(sectionTree);
  const highlightSection =
    highlighter.section || highlighter.getItemHighlight(trunk, OPT_SEC);

  return !id ? null : (
    <OptionalSection key={id} id={id} values={values} data-tree={trunk}>
      <section style={highlightSection}>
        <Subheadline.Themed key={id}>
          <OptionalMessage
            id={id}
            values={values}
            data-tree={`${trunk}.head`}
            style={highlightItem}
          />
        </Subheadline.Themed>
        {paraList.map((props, index) => {
          const { tree, preList, textList } = getProps(
            props,
            globals,
            trunk,
            index,
            "sub"
          );

          return (
            <TextBullets
              key={index}
              id={id}
              tree={tree}
              highlight={highlight}
              values={globals}
              preList={preList}
              textList={textList}
            />
          );
        })}
      </section>
    </OptionalSection>
  );
}
BulletSubSections.displayName = displayName6;
BulletSubSections.propTypes = {
  tree: PropTypes.string, // For anchor id and debugging the hierarchy
  highlight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  values: PropTypes.object,
  paraList: PropTypeParaList,
};
BulletSubSections.defaultProps = {
  tree: displayName6,
  paraList: EMPTY_LIST,
  values: EMPTY_OBJECT,
};

export function NumberedSection({
  id,
  tree,
  highlight,
  paraList,
  preambleList,
  values,
}) {
  const globals = values;
  const theme = useTheme();
  const trunk = defaultTo(tree, id || "BULSUBSECPRE");
  const highlighter = new Highlight(theme, highlight);
  const highlightSection =
    highlighter.section || highlighter.getItemHighlight(trunk, OPT_SEC);

  return !id ? null : (
    <OptionalSection key={id} id={id} values={values} data-tree={trunk}>
      <Section style={highlightSection}>
        <BulletSubSections
          tree={trunk}
          highlight={highlight}
          key={id}
          id={id}
          values={values}
          paraList={paraList}
        />
        {preambleList.map((props, index) => {
          const { id, tree, preList, textList } = getProps(
            props,
            globals,
            trunk,
            index,
            "bullpre"
          );

          return (
            <BulletPreamble
              key={index}
              tree={tree}
              highlight={highlight}
              id={id}
              values={values}
              textList={textList}
              preList={preList}
            />
          );
        })}
      </Section>
    </OptionalSection>
  );
}
NumberedSection.displayName = displayName7;
NumberedSection.propTypes = {
  tree: PropTypes.string, // For anchor id and debugging the hierarchy
  highlight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  values: PropTypes.object,
  paraList: PropTypeParaList,
  preambleList: PropTypePreambleList,
};
NumberedSection.defaultProps = {
  tree: displayName7,
  paraList: EMPTY_LIST,
  preambleList: EMPTY_LIST,
  values: EMPTY_OBJECT,
};

export function NumberedSectionList({
  tree,
  highlight,
  textList,
  paraList,
  values,
}) {
  const globals = values;
  const theme = useTheme();
  const trunk = defaultTo(tree, "NUMSECLIST");
  const highlighter = new Highlight(theme, highlight);
  const highlightSection =
    highlighter.section || highlighter.getItemHighlight(trunk, OPT_SEC);

  return (
    <section style={highlightSection}>
      {sprout(trunk)}
      {textList.map(function renderIntro(props, index) {
        const { id, tree, innerValues } = getProps(
          props,
          globals,
          `${trunk}.txt`,
          index,
          "intro"
        );
        const highlightItem = highlighter.getItemHighlight(tree);

        return !id ? null : (
          <SimpleText
            key={id}
            id={id}
            values={innerValues}
            data-tree={tree}
            style={highlightItem}
          />
        );
      })}
      {paraList.map(function renderNumberedSections(props, index) {
        const { id, tree, paraList, preambleList, innerValues } = getProps(
          props,
          globals,
          `${trunk}.sec`,
          index,
          "number"
        );

        return (
          <NumberedSection
            key={index}
            tree={tree}
            highlight={highlight}
            id={id}
            values={innerValues}
            paraList={paraList}
            preambleList={preambleList}
          />
        );
      })}
    </section>
  );
}
NumberedSectionList.displayName = displayName8;
NumberedSectionList.propTypes = {
  tree: PropTypes.string, // For anchor id and debugging the hierarchy
  highlight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  values: PropTypes.object,
  textList: PropTypeIdValueList,
  paraList: PropTypeParaPreambleList,
};
NumberedSectionList.defaultProps = {
  tree: displayName8,
  textList: EMPTY_LIST,
  paraList: EMPTY_LIST,
  values: EMPTY_OBJECT,
};

// BulletHeadline =  Section.Headline ul li...
// BulletSection =   Section.Headline text ul li...
// BulletPreamble =  Section.Headline text... ul li...
// BulletParagraphs = Section.Headline [text... ul li...]...
// BulletSubSections = Subheadline [text... ul li...]...
