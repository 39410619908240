import styled from "@emotion/styled";

const Button = styled.button`
  all: inherit;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default Button;
