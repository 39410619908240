import baseConfig from "../medi24/config";
import esOverrides from "./translations/es.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh medi24 theme tg-spain 13 Spain personal-assistant spain medi24 NONE NONE es en

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "PERSONAL_ASSISTANT_SPAIN_13",
    partnerGroup: "travelguardian",
    partnerCountry: "Spain",
  },
  languages: "es en".split(" "),
  messages: {
    es: esOverrides,
    en: enOverrides,
  },
};

export default config;
