import styled from "@emotion/styled";

// Assuming there are always header, main and footer content sections
// Force footer to always be at the bottom
const Wrapper = styled.div`
  min-width: 320px;
  min-height: 100%;
`;

export default Wrapper;
