import systemMessages from "./system";
import englishTranslations from "./en.json";
import germanTranslations from "./de.json";
import frenchTranslations from "./fr.json";
import italianTranslations from "./it.json";
import spanishTranslations from "./es.json";
import malaysianTranslations from "./ms.json";
import vietnameseTranslations from "./vi.json";
import thaiTranslations from "./th.json";
import indonesianTranslations from "./id.json";
import chineseTraditionalTranslations from "./zh-Hant.json";
import chineseCantoneseTranslations from "./zh-Yue.json";
import ukrainianTranslations from "./uk.json";
// you need to turn these on when using demo-all partner...
// import chineseSimplifiedTranslations from "./zh-Hans.json";
import arabicTranslations from "./ar.json";
import japaneseTranslations from "./ja.json";
// import russianTranslations from "./ru.json";
// NEWLANGUAGE ADD ABOVE import all languages

// Taken from ISO language name column of
// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
export const languageNames = {
  ar: "Arabic",
  en: "English",
  de: "German",
  fr: "French",
  it: "Italian",
  es: "Spanish",
  ms: "Malay",
  vi: "Vietnamese",
  th: "Thai",
  id: "Indonesian",
  "zh-Hant": "Chinese Traditional",
  yue: "Cantonese",
  uk: "Ukrainian",
  ja: "Japanese",
  // NEWLANGUAGE ADD ABOVE name for each language
};

export const translations = {
  en: englishTranslations,
  de: germanTranslations,
  fr: frenchTranslations,
  it: italianTranslations,
  es: spanishTranslations,
  ms: malaysianTranslations, // Bahasa Melayu
  vi: vietnameseTranslations,
  th: thaiTranslations,
  id: indonesianTranslations, // Bahasa Indonesia
  uk: ukrainianTranslations,
  // NOT valid ISO language codes, will need special handling...
  /*
    https://webmasters.stackexchange.com/a/110117
    https://unicode-org.github.io/cldr-staging/charts/latest/supplemental/language_territory_information.html
    https://www.w3schools.com/tags/ref_language_codes.asp
    https://www.w3schools.com/tags/ref_country_codes.asp
    https://en.wikipedia.org/wiki/Noto_fonts

    Note that you can specify a subset for the Chinese language, for example for Chinese (Simplified) = zh-Hans, for Chinese (Traditional) = zh-Hant. Also you can specify / localize the country for your Chinese words - info UN about Language-Territory Information. For example, Chinese for Singapore lang=zh-SG.

    Chinese encoded
    &#x4F60;&#x597D;&#x55CE; 你好嗎

    lang="zh-Hant"
    lang="zh-Hans-HK"
    Traditional Chinese (for Hong Kong and other regions): zh-TW
    Simplified Chinese (for mainland China): zh-CN
  */
  "zh-Hant": chineseTraditionalTranslations,
  yue: chineseCantoneseTranslations,
  // "zh-Hans": chineseSimplifiedTranslations,
  // you need to turn these on when using demo-all partner...
  // ru: russianTranslations,
  ar: arabicTranslations,
  ja: japaneseTranslations,
  // NEWLANGUAGE ADD ABOVE access to all languages
};

Object.keys(translations).forEach(function addGlobalLanguage(language) {
  Object.keys(systemMessages).forEach(function addSystemMessages(key) {
    if (!(key in translations[language])) {
      translations[language][key] = systemMessages[key];
    }
  });
  translations[language]["system.language"] = languageNames[language];
});

export function englishFirst(less, more) {
  const before = less === "en" ? "  " : less;
  const after = more === "en" ? "  " : more;
  return before.localeCompare(after);
}

export function generateSupportedLanguages(messages) {
  return Object.keys(messages).sort(englishFirst);
}

export const supportedLanguages = generateSupportedLanguages(translations);
