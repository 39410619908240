import PropTypes from "prop-types";

import SimpleMessageButton from "components/SimpleMessageButton";
import Modal from "../Modal";

const displayName = "PlatformInfoModal"; // aka WhichMessengerModal

function PlatformInfoModal({ isOpen, onClose }) {
  return (
    <Modal
      titleId="chooseMessengerPage.whichPlatformModal.title"
      trackId="WHICH_MESSENGER_DIALOG"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.ScrollableContent>
        <Modal.Message id="chooseMessengerPage.whichPlatformModal.content.messengersParagraph" />
        <Modal.Message id="chooseMessengerPage.whichPlatformModal.content.webChatParagraph" />
        <Modal.Message id="chooseMessengerPage.whichPlatformModal.content.closingParagraph" />
      </Modal.ScrollableContent>
      <Modal.Controls>
        <SimpleMessageButton
          id="chooseMessengerPage.whichPlatformModal.closeButon"
          data-testid="modal-bottom-close-button"
          onClick={onClose}
        />
      </Modal.Controls>
    </Modal>
  );
}

PlatformInfoModal.displayName = displayName;

PlatformInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PlatformInfoModal;
