import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { spacingLarge } from "@medi24-da2c/web-ui/design-tokens";

const displayName = "LandingPage.GetStarted";

const GetStartedStyled = styled.div`
  width: 100%;
  background-color: ${(themedProps) =>
    themedProps.theme.landingPageGetStarted.bgrColor};
  padding-top: ${spacingLarge};
`;

function GetStarted(props) {
  const theme = useTheme();
  return <GetStartedStyled theme={theme} {...props} />;
}
GetStarted.displayName = displayName;

export default GetStarted;
