import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { englishFirst } from "translations/languages";
import openIcon from "components/Icons/open-new.png";
import usePartner from "hooks/usePartner";
import * as URL from "constants/urls";

const displayName = "DevAdminPage";

const styleInput = { fontSize: "x-large" };
const styleRight = { textAlign: "right" };
const styleWrap = { fontSize: "x-large", padding: "3rem" };
const styleCell = { padding: "0.5rem" };
const styleIcon = {
  width: "1rem",
  height: "1rem",
  verticalAlign: "super",
};

const title = "open in new window";

function OpenNew({ label, url }) {
  return (
    <a href={url} target="_blank" rel="noreferrer" title={title}>
      {label}
      <img style={styleIcon} src={openIcon} alt={title} />
    </a>
  );
}
OpenNew.displayName = "OpenNew";
OpenNew.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

function SmallLinks({ label, noProd, devUrl, prodUrl }) {
  return (
    <small>
      [
      <OpenNew label="DEV" url={devUrl} />
      {!noProd && (
        <>
          {" | "}
          <OpenNew label="PROD" url={prodUrl} />
        </>
      )}
      ]
    </small>
  );
}
SmallLinks.displayName = "SmallLinks";
SmallLinks.propTypes = {
  label: PropTypes.string.isRequired,
  devUrl: PropTypes.string.isRequired,
  prodUrl: PropTypes.string,
  noProd: PropTypes.bool,
};

/* istanbul ignore next */
const combinedPartners = process.env.REACT_APP_SHOW_DEV_LINKS
  ? require("../../partnerConfigs/__dev__/combined.json")
  : void 0;

if (combinedPartners) {
  // Add in pseudo-partners for dev preview sites
  for (let number = 0; number < 10; number++) {
    const partner = `dev-preview0${number}`;
    combinedPartners[partner] = {
      partner,
      isPreview: true,
      devUrl: `https://dev.pv0${number}.access-to-care-medi24.com`,
    };
  }
}

function urlPair(devBaseUrl, prodBaseUrl, query, languages, tail = "") {
  let devUrl = `${devBaseUrl}${query}${tail}`;
  let prodUrl = `${prodBaseUrl}${query}${tail}`;
  const mapped = languages ? (
    languages.sort(englishFirst).map(function renderLanguages(lang) {
      devUrl = `${devBaseUrl}${query}?lang=${lang}${tail}`;
      prodUrl = `${prodBaseUrl}${query}?lang=${lang}${tail}`;
      return (
        <td key={lang} style={styleCell}>
          <b>{lang.toUpperCase()}</b>
          <SmallLinks
            label="DEV"
            noProd={!prodBaseUrl}
            devUrl={devUrl}
            prodUrl={prodUrl}
          />
          &nbsp;
        </td>
      );
    })
  ) : (
    <td key="en" style={styleCell}>
      <SmallLinks
        label="DEV"
        noProd={!prodBaseUrl}
        devUrl={devUrl}
        prodUrl={prodUrl}
      />
      &nbsp;
    </td>
  );
  return mapped;
}

function getContext(partner) {
  const {
    isPreview,
    partnerGroup,
    partnerCountry,
    generateStepType,
    languages,
    DHA,
    noDHA,
    MyDoc,
    showViber,
    showLineApp,
    hideWebChat,
    hideWhatsApp,
    hideTelegram,
  } = partner;

  /* istanbul ignore next */
  const coreFeatures = [
    noDHA ? (DHA ? "LITE" : "") : "DHA",
    MyDoc ? "CONSULT" : "",
  ]
    .filter((feature) => feature.length)
    .join("+");

  /* istanbul ignore next */
  const channels = [
    hideWhatsApp ? "" : "WhatsApp",
    hideTelegram ? "" : "Telegram",
    hideWebChat ? "" : "WebChat",
    showViber ? "Viber" : "",
    showLineApp ? "LINE" : "",
  ]
    .filter((feature) => feature.length)
    .join("+");

  const search = (
    isPreview
      ? [partner.partner]
      : [
          partner.partner,
          partnerCountry,
          partnerGroup,
          generateStepType,
          coreFeatures,
          channels,
          languages,
        ]
  )
    .join(" ")
    .toLowerCase();
  return { ...partner, channels, coreFeatures, search };
}

function LinkTable({ searchInfo, devUrl, prodUrl, lang = null }) {
  return (
    <>
      {searchInfo}
      <table>
        <tbody>
          <tr>
            <th style={styleRight}>Ver:</th>
            {urlPair(devUrl, prodUrl, "/version.txt", null)}
          </tr>
          <tr>
            <th style={styleRight}>Home:</th>
            {urlPair(devUrl, prodUrl, URL.HOME, lang)}
          </tr>
          <tr>
            <th style={styleRight}>Error:</th>
            {urlPair(devUrl, prodUrl, "/notfound", lang)}
          </tr>
          <tr>
            <th style={styleRight}>WrongAC:</th>
            {urlPair(devUrl, prodUrl, URL.HOME, lang, "&ac=99")}
          </tr>
          <tr>
            <th style={styleRight}>Old Browser:</th>
            {urlPair(devUrl, prodUrl, URL.HOME, lang, "#simulate-old-browser")}
          </tr>
          <tr>
            <th style={styleRight}>Choose:</th>
            {urlPair(devUrl, prodUrl, URL.CHOOSE, lang, "&ac=as1231234")}
          </tr>
          <tr>
            <th style={styleRight}>Logout:</th>
            {urlPair(devUrl, prodUrl, URL.LOGOUT, lang)}
          </tr>
          <tr>
            <th style={styleRight}>FAQ:</th>
            {urlPair(devUrl, prodUrl, URL.FAQ, lang)}
          </tr>
          <tr>
            <th style={styleRight}>FAQ Highlight SACH:</th>
            {urlPair(
              devUrl,
              prodUrl,
              URL.FAQ,
              lang,
              "&ex=faq-sh&hs=os-faq-sach#om-faq-sach.head"
            )}
          </tr>
          <tr>
            <th style={styleRight}>FAQ Highlight SACNWH:</th>
            {urlPair(
              devUrl,
              prodUrl,
              URL.FAQ,
              lang,
              "&ex=faq-sh&hs=os-faq-sacnwh#om-faq-sacnwh.head"
            )}
          </tr>
          <tr>
            <th style={styleRight}>FAQ Highlight SWEH:</th>
            {urlPair(
              devUrl,
              prodUrl,
              URL.FAQ,
              lang,
              "&ex=faq-sh&hs=os-faq-sweh#om-faq-sweh.head"
            )}
          </tr>
          <tr>
            <th style={styleRight}>Terms:</th>
            {urlPair(devUrl, prodUrl, URL.TERMS, lang)}
          </tr>
          <tr>
            <th style={styleRight}>Privacy:</th>
            {urlPair(devUrl, prodUrl, URL.PRIVACY, lang)}
          </tr>
        </tbody>
      </table>
      <hr />
    </>
  );
}

function DevAdminPage() {
  const { partner, config } = usePartner();
  const [filter, setFilter] = useState("");
  const [searchMe] = useState(() => {
    const order = Object.keys(combinedPartners || {}).sort(
      function byPartnerName(less, more) {
        const before = /dev-preview/.test(less) ? `zzz${less}` : less;
        const after = /dev-preview/.test(more) ? `zzz${more}` : more;
        return before.localeCompare(after);
      }
    );

    return order.map(function makeSearchString(partner) {
      const { search } = getContext(combinedPartners[partner]);
      return search;
    });
  });

  /* istanbul ignore next */
  const handleChange = useCallback((event) => {
    const search = event.target.value.trim();
    setFilter(search.toLowerCase());
  }, []);

  const order = searchMe
    .filter(function filterPartners(search) {
      /* istanbul ignore next */
      return !filter || search.match(filter);
    })
    .map(function getPartner(search) {
      return search.split(/ +/)[0];
    });

  const json = null; //<hr /><xmp>{JSON.stringify(combinedPartners, void 0, "   ")}</xmp>

  /* istanbul ignore next */
  return combinedPartners ? (
    <section style={styleWrap}>
      <h1>
        {config.general.partnerGroup} {config.general.partnerCountry} {partner}
      </h1>
      <h2>Shortcut to other Partner Pages</h2>
      <label>
        Filter: <input style={styleInput} onChange={handleChange} />
        <span> {order.length} found</span>
      </label>
      {order.map(function renderPartner(partner) {
        const {
          isPreview,
          inherits,
          partnerGroup,
          partnerCountry,
          generateStepType,
          webchat,
          devUrl,
          prodUrl,
          languages,
          coreFeatures,
          channels,
        } = getContext(combinedPartners[partner]);
        const lang = (languages || "en").split(" ");
        const searchInfo = null; //<h5>search: {search}</h5>

        return isPreview ? (
          <section key={partner}>
            <h2 id={partner}>{partner}</h2>
            <LinkTable
              searchInfo={searchInfo}
              devUrl={devUrl}
              prodUrl={prodUrl}
            />
          </section>
        ) : (
          <section key={partner}>
            <h2 id={partner}>
              {partner} | {partnerCountry} | {partnerGroup} |{" "}
              {generateStepType || "NO_GEN"}
            </h2>
            <h3>
              {coreFeatures} [inherits {inherits}]
            </h3>
            <h4>
              {channels} chat: {webchat}
            </h4>
            <LinkTable
              searchInfo={searchInfo}
              devUrl={devUrl}
              prodUrl={prodUrl}
              lang={lang}
            />
          </section>
        );
      })}
      {json}
    </section>
  ) : null;
}
DevAdminPage.displayName = displayName;

export default DevAdminPage;
