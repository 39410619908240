import PropTypes from "prop-types";
import { Card } from "../Card";

const displayName = "ImageCard";

function ImageCard({ id, children }) {
  // pull off last part of "message.id.headline" and replace as needed
  const idSubHeadline = id.replace(/\.[^.]+$/, ".subheadline");
  const idDescription = id.replace(/\.[^.]+$/, ".description");

  return (
    <Card
      testId={`${displayName}-${id}`}
      headlineId={id}
      illustration={children}
      subheadlineId={idSubHeadline}
      textId={idDescription}
    />
  );
}
ImageCard.displayName = displayName;
ImageCard.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ImageCard;
