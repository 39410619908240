import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { spacingLarge } from "@medi24-da2c/web-ui/design-tokens";

const displayName = "LandingPage.ButtonFooter";

const ButtonFooterStyled = styled.div`
  width: 100%;
  background-color: ${(themedProps) => themedProps.theme.general.bgrColor};
  padding-top: ${spacingLarge};
`;

function ButtonFooter(props) {
  const theme = useTheme();
  return <ButtonFooterStyled theme={theme} {...props} />;
}
ButtonFooter.displayName = displayName;

export default ButtonFooter;
