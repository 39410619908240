import styled from "@emotion/styled";
import {
  spacingMedium,
  spacingLarge,
  spacingSmall,
} from "@medi24-da2c/web-ui/design-tokens";
import { DESKTOP_MEDIA } from "@medi24-da2c/web-ui/media";

const MessengerIcon = styled.div`
  width: 4.8rem;
  min-width: 4.8rem;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  margin: 0 ${spacingSmall} 0 ${spacingMedium};
  cursor: default;

  @media ${DESKTOP_MEDIA} {
    width: 10rem;
    margin: ${spacingLarge} 0;
  }
`;

export default MessengerIcon;
