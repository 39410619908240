import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { FormInput } from "@medi24-da2c/web-ui";
import { useOptionalMessage } from "hooks/useOptionalMessage";
import GenerateCodeForm from "../GenerateCodeForm";
import useActivationCode from "./useActivationCode";
import isValidBinOnlyForm from "./isValidBinOnlyForm";

// Eligibility check for activation code: valid BIN code (first 6 digits on the credit card)
const displayName = "BinOnlyStep";

const idLabel = "generateActivationCodeForm.binNumber.label";
const idPlaceholder = "generateActivationCodeForm.binNumber.placeholder";
const idLegalInfo = "generateActivationCodeForm.binNumber.legalInfo";

function BinOnlyStep(props) {
  const { binOnlyRegex } = props;
  // window.console.warn(`${displayName} binOnlyRegex ${binOnlyRegex}`)
  const [loading, setLoading] = useState(false);
  const [binNumber, setBinNumber] = useState("");
  const validator = (values) => {
    // window.console.warn(`${displayName}.validator(${binOnlyRegex})`, values)
    const result = isValidBinOnlyForm(values, binOnlyRegex);
    // window.console.warn(`${displayName}.validator(${binOnlyRegex}) = ${result}`, values)
    return result;
  };
  const { willGenerateCode } = useActivationCode(
    {
      ...props,
      loading,
      onSetLoading: setLoading,
    },
    validator
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      // Transform values to shape which remote API will understand
      willGenerateCode({
        userKey: binNumber,
      });
    },
    [willGenerateCode, binNumber]
  );

  const handleChange = useCallback(
    (event) => {
      const { value } = event.target;
      setBinNumber(value.trim());
    },
    [setBinNumber]
  );

  const isDirty = useCallback(() => {
    return binNumber !== "";
  }, [binNumber]);

  // TODO(2021-07) change useOptionalMessage to useOptionalMessageOr for alternate MyDoc messages see ActivationCodeForm.js
  const binNumberLabel = useOptionalMessage(idLabel);
  const binNumberPlaceholder = useOptionalMessage(idPlaceholder);

  return (
    <GenerateCodeForm
      idLegalInfo={idLegalInfo}
      loading={loading}
      isDirty={isDirty()}
      onSubmit={handleSubmit}
    >
      <FormInput
        data-testid={`FormInput-${idLabel}`}
        label={binNumberLabel}
        placeholder={binNumberPlaceholder}
        name="binNumber"
        type="text"
        spellCheck="false"
        autoCorrect="off"
        autoComplete="off"
        value={binNumber}
        onChange={handleChange}
      />
    </GenerateCodeForm>
  );
}

BinOnlyStep.displayName = displayName;

BinOnlyStep.propTypes = {
  // TODO(2021-07) add idInfix for alternate MyDoc messages see InitialStep
  setActivationCodeDetails: PropTypes.func.isRequired,
  setErrorCode: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  partnerCode: PropTypes.string.isRequired,
  binOnlyRegex: PropTypes.instanceOf(RegExp),
};

export default BinOnlyStep;
