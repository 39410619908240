// Enter Activation Code directly in modal - medi24 partner site
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormInput } from "@medi24-da2c/web-ui";
import { useState, useEffect, useCallback, useRef } from "react";
import useActivationCodeStatus from "components/ActivationCodeForm/useActivationCodeStatus";
import ButtonWrapper from "components/ActivationCodeForm/ButtonWrapper";
import ErrorCode from "components/ActivationCodeForm/ErrorCode";
import isValidActivationCode from "utils/isValidActivationCode";
import { useOptionalMessageOr } from "hooks/useOptionalMessage";
import { makeIdOptions } from "components/OptionalMessage";
import { INVALID_ERROR, VALID_CODE } from "api/errorCodes";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import HiddenState from "components/HiddenState";
import LoadingButton from "../Button/LoadingButton";
import BasicForm from "../BasicForm";

const displayName = "EnterActivationCodeModal.EnterActivationCodeForm";

const trackId = "ELIGIBILITY_ENTER_CODE_CONTENT";
const key = "codeInput";

const Form = styled(BasicForm)`
  overflow: visible;
`;

const buttonStyle = {
  margin: "0.5rem",
};

const divStyle = {
  display: "flex",
  flexDirection: "row",
};

const idOptions = makeIdOptions(key);

function TrackEnterCodeContent() {
  useTrackPageEffect(trackId);

  return <HiddenState id={trackId} />;
}

function EnterActivationCodeForm({ idInfix, onSubmit }) {
  const [activationCode, setActivationCode] = useState("");
  const [touched, setTouched] = useState(false);
  const [codeStatus, setCodeStatus] = useState();
  const [loading, onSetLoading] = useState(false);

  const success = useRef({ previous: false });
  success.current.current = codeStatus === VALID_CODE;
  const error = codeStatus && codeStatus !== VALID_CODE;

  const { willGetCodeStatus } = useActivationCodeStatus({
    setCodeStatus,
    onSetLoading,
  });

  const handleSubmit = useCallback(
    function (event) {
      event.preventDefault();
      event.stopPropagation();
      setTouched(true);

      if (!isValidActivationCode(activationCode)) {
        setCodeStatus(INVALID_ERROR);
      } else {
        willGetCodeStatus(activationCode);
      }
    },
    [activationCode, willGetCodeStatus]
  );

  const handleChange = useCallback(
    function (event) {
      const value = event.target.value.trim();
      setActivationCode(value);
      setCodeStatus();
    },
    [setActivationCode]
  );

  const inputIds = idOptions("activationCodeForm.codeInput.label", idInfix);
  const inputLabel = useOptionalMessageOr(inputIds.id, inputIds.idDefault);

  useEffect(() => {
    if (
      success.current.current &&
      success.current.previous !== success.current.current
    ) {
      onSubmit(activationCode);
    }
    success.current.previous = success.current.current;
  });

  if (error) {
    return <ErrorCode errorCode={codeStatus} />;
  }

  return (
    <Form>
      <BasicForm.Fields>
        <div style={divStyle}>
          <TrackEnterCodeContent />
          <FormInput
            type="text"
            autoCapitalize="off"
            spellCheck="false"
            autoCorrect="off"
            autoComplete="off"
            label={inputLabel}
            name="activationCode"
            value={activationCode}
            touched={touched}
            disabled={loading}
            onChange={handleChange}
          />
        </div>
      </BasicForm.Fields>
      <ButtonWrapper>
        <LoadingButton
          id="activationCodeForm.continueButton"
          style={buttonStyle}
          disabled={!activationCode}
          data-testid="continue-button"
          loading={loading}
          onClick={handleSubmit}
        />
      </ButtonWrapper>
    </Form>
  );
}

EnterActivationCodeForm.displayName = displayName;

EnterActivationCodeForm.propTypes = {
  idInfix: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default EnterActivationCodeForm;
