import { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "@emotion/react";
import { AppBar, ErrorBoundary, GlobalStyle } from "@medi24-da2c/web-ui";

import { CookiesConsentProvider } from "context/CookiesConsentContext";
import { enabledLanguages } from "translations/supportedLanguages";
import { getInstance } from "partnerConfigs/singleton";
import useScrollToTop from "hooks/useScrollToTop";
import messages from "translations/messages";
import useLanguage from "hooks/useLanguage";
import {
  logAnalytics,
  startQueue,
  logState,
  DEBUG,
} from "../../tracking/track";
import LogotypeClickable from "../LogotypeClickable";
import LanguagePicker from "../LanguagePicker";
import platform from "../../utils/platform";
import AppRoutes from "../AppRoutes";
import AppFooter from "../AppFooter";
import Wrapper from "./Wrapper";
import Main from "./Main";

// eslint-disable-next-line no-unused-vars
const displayName = "App";

function startAnalytics() {
  if (DEBUG) {
    window.__AA_logAnalytics = logAnalytics;
    logState("App");
  }
  startQueue();
}

startAnalytics();

function App() {
  // Restores scroll position on each route change
  useScrollToTop();
  const [selectedLanguage] = useLanguage();
  const { theme, SecondLogo } = getInstance();

  useEffect(function afterRender() {
    const loader = platform.querySelector("#background-loader");
    const oldBrowser = platform.querySelector("#old-browser");
    /* istanbul ignore next */
    if (loader) {
      loader.classList.add("hide-initially");
    }
    /* istanbul ignore next */
    if (oldBrowser) {
      // Hide the IE related message if the app gets rendered
      oldBrowser.classList.add("hide-initially");
    }
  });

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Wrapper>
          <IntlProvider
            locale={selectedLanguage}
            messages={messages[selectedLanguage]}
          >
            <CookiesConsentProvider>
              <AppBar
                logoLinkTo={""}
                logotype={<LogotypeClickable />}
                mainContent={SecondLogo && <SecondLogo />}
                decorBarContent={
                  enabledLanguages.length > 1 && (
                    <LanguagePicker enabledLanguages={enabledLanguages} />
                  )
                }
              />
              <Main>
                <AppRoutes />
              </Main>
              <AppFooter />
            </CookiesConsentProvider>
          </IntlProvider>
        </Wrapper>
      </ThemeProvider>
      <div id="application-rendered" />
    </ErrorBoundary>
  );
}

export default App;
