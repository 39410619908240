import {
  HideSpanBelowLargePhone,
  HideSpanOnLargePhone,
} from "components/Visibility/Visibility";
import AllianzLogo from "../medi24-allianz/Logotype";
import logoBDAE from "./LogoBDAE.svg";

const alt = "BDAE Mit Sicherheit ins Ausland!";

const styleRightLogo = {
  height: "80px",
  marginTop: "-2.5rem",
  position: "absolute",
};

const styleRightLogoBelow = {
  height: "40px",
  display: "block",
  marginTop: "-1rem",
  marginLeft: "-1rem",
};

const styleLeftLogoAbove = {
  height: "50%",
  marginTop: "-1rem",
};

function Logotype() {
  return (
    <>
      <HideSpanBelowLargePhone>{LogotypeLarge()}</HideSpanBelowLargePhone>
      <HideSpanOnLargePhone>{LogotypeSmall()}</HideSpanOnLargePhone>
    </>
  );
}

// Large screen put BDAE logo adjacent to Allianz logo
function LogotypeLarge() {
  return (
    <>
      {AllianzLogo()}
      <img style={styleRightLogo} alt={alt} title={alt} src={logoBDAE} />
    </>
  );
}

// Small screen stack the logos on top of each other and make them smaller
// as we cannot reduce the height of the AppBar easily
function LogotypeSmall() {
  return (
    <>
      {AllianzLogo({ style: styleLeftLogoAbove })}
      <img style={styleRightLogoBelow} alt={alt} title={alt} src={logoBDAE} />
    </>
  );
}

export default Logotype;
