import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Text as TextBase } from "@medi24-da2c/web-ui";

const displayName = "LandingPage.Callout";

const TextStyled = styled(TextBase.Themed)`
  color: ${(themedProps) => themedProps.theme.general.linkColor};

  &:last-child {
    margin-bottom: 0;
  }
`;

function Text(props) {
  const theme = useTheme();
  return <TextStyled theme={theme} {...props} />;
}
Text.displayName = displayName;

export default Text;
