import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";
import { Content, ErrorBoundary } from "@medi24-da2c/web-ui";
import useValidActivationCode from "hooks/useValidActivationCode";
// import ShowVisibility from "components/Visibility/ShowVisibility";
import MessageButton from "components/Button/MessageButton";
import EmmaConversation from "components/EmmaConversation";
import useQuickSummary from "hooks/useQuickSummary";
import EmmaOverview from "components/EmmaOverview";
import { useSearchLinks } from "hooks/withHtml";
import useNavigate from "hooks/useNavigate";
import useMyDoc from "hooks/useMyDoc";
import useModal from "hooks/useModal";
import useDHA from "hooks/useDHA";

import EmmaEligibilityModal from "./EmmaEligibilityModal";
import QuickSummary from "./QuickSummary";
import GeneralInfo from "./GeneralInfo";
import GetStarted from "./GetStarted";
import Headline from "./Headline";
import Text from "./Text";

const displayName = "EmmaIntroduction";

function EmmaIntroduction({
  hideEmmaOverview,
  hideEmmaConversation,
  hideGeneralInfo,
  forceDHA,
  forceQuickSummary,
  forceMyDoc,
  onClickMyDoc,
  myDocLoading,
}) {
  const theme = useTheme();
  const partnerDHA = useDHA();
  const navigate = useNavigate();
  const partnerMyDoc = useMyDoc();
  const values = useSearchLinks();
  const { hasCode } = useValidActivationCode();
  const partnerQuckSummary = useQuickSummary();
  const { isOpen, showModal, hideModal } = useModal();
  const hasDHA = forceDHA === undefined ? partnerDHA : forceDHA;
  const hasMyDoc = forceMyDoc === undefined ? partnerMyDoc : forceMyDoc;
  const hasQuickSummary =
    forceQuickSummary === undefined ? partnerQuckSummary : forceQuickSummary;
  const showQuickSummary =
    hasQuickSummary === null ? false : hasMyDoc || hasQuickSummary;

  hideEmmaOverview = hideEmmaOverview || !hasDHA;
  hideGeneralInfo = hideGeneralInfo || !hasDHA;

  // Navigate directly to Choose Messenger page if activation code is passed in URL
  // Otherwise show modal with form to submit a new code
  function handleGetStartedClick() {
    if (hasCode) {
      navigate.toChooseMessenger();
    } else {
      showModal();
    }
  }

  // for showing phone/tablet/desktop breakpoint guage
  // or making design agency screen shots
  const showVisibility = null; //<ShowVisibility />

  return (
    <ErrorBoundary>
      {showVisibility}
      {hideEmmaConversation ? void 0 : <EmmaConversation />}
      {showQuickSummary && (
        <QuickSummary
          myDocLoading={myDocLoading}
          onClickEmma={handleGetStartedClick}
          onClickMyDoc={onClickMyDoc}
        />
      )}

      {hideGeneralInfo ? (
        void 0
      ) : (
        <GeneralInfo data-testid="GeneralInfo">
          <Content>
            <Headline id="landingPage.emma.headline" />
            <Text.Messages
              id={
                hasMyDoc
                  ? "landingPage.emmaMyDoc.overview"
                  : "landingPage.emma.overview"
              }
              idAvailable="landingPage.emma.availabilityIndicator"
              values={values}
            />
          </Content>
        </GeneralInfo>
      )}
      {hideEmmaOverview ? void 0 : <EmmaOverview theme={theme} />}

      {hasDHA && (
        <GetStarted data-testid={`${displayName}-get-started-section`}>
          <Content data-testid={`${displayName}-emma-get-started`}>
            <MessageButton
              align="center"
              id="landingPage.getStartedButton"
              theme={theme}
              onClick={handleGetStartedClick}
            />
          </Content>
        </GetStarted>
      )}
      <EmmaEligibilityModal isOpen={isOpen} onClose={hideModal} />
    </ErrorBoundary>
  );
}
EmmaIntroduction.displayName = displayName;
EmmaIntroduction.propTypes = {
  // To allow use of component in StoryBook regardless of partner config
  forceDHA: PropTypes.bool,
  forceMyDoc: PropTypes.bool,
  forceQuickSummary: PropTypes.bool,
  // To allow use of component in StoryBook until all props.theme issues fixed
  hideGeneralInfo: PropTypes.bool,
  hideEmmaConversation: PropTypes.bool,
  hideEmmaOverview: PropTypes.bool,
  myDocLoading: PropTypes.bool,
  onClickMyDoc: PropTypes.func.isRequired,
};

export default EmmaIntroduction;
