export const DEV_CHECK_FAQ = true; // turn on to diagnose FAQ message keys in unit tests
export const DEV_DEBUG_TREE = false; // turn on to diagnose FAQ/Terms/Privacy tree Ids
export const DEV_SHOW_HIDDEN_STATE = false; // turn on to show content of HiddenState Components
export const DEV_DEBUG_COOKIES = false; // turn on display of CONSENT_ value in cookie dialog
export const DEV_DEBUG_ADOBE = false; // turn on debugging of Adobe analytics in DEV env

export const NO_COOKIE_DIALOG = false; // disables all cookie dialogs until we are ready

const API_DELAY = 7000;

/* istanbul ignore next */
export const delayApiCalls =
  process.env.REACT_APP_API_DEBUG === "simulate" && !process.env.JEST_WORKER_ID;

const log = window.console.warn;

/* istanbul ignore next */
if (delayApiCalls) {
  log(
    `invokeNowOrLater has been set to delay for ${API_DELAY}ms to see the loading spinner`
  );
}

/* istanbul ignore next */
export const invokeNowOrLater = delayApiCalls
  ? (fn, reason = "unknown") => {
      log(`invokeNowOrLater delay ${reason}`);
      setTimeout(() => {
        log(`invokeNowOrLater invoke ${reason}`);
        fn();
      }, API_DELAY);
    }
  : (fn) => fn();
