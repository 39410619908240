import EmmaEligibilityModal from "pages/LandingPage/EmmaEligibilityModal";
import SimpleMessageButton from "components/SimpleMessageButton";
import useTranslatedTitle from "hooks/useTranslatedTitle";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import { WomanWithBinoculars } from "components/Icons";
import useNavigate from "hooks/useNavigate";
import useModal from "hooks/useModal";
import useDHA from "hooks/useDHA";
import BasicInfoPage from "../BasicInfoPage";
import CustomButtons from "./CustomButtons";

const displayName = "WebChatLogoutPage";

export const buttonStyle = {
  margin: "0 1rem",
};

function WebChatLogoutPage() {
  const partnerDHA = useDHA();
  const navigate = useNavigate();
  const { isOpen, showModal, hideModal } = useModal();

  useTranslatedTitle("webChatLogoutPage.title", "Logout");
  useTrackPageEffect("CHAT_LOGOUT_PAGE");

  function handleHomeClick(event) {
    event.preventDefault();
    navigate.toLandingPage();
  }

  return (
    <>
      <BasicInfoPage
        headlineId={"webChatLogoutPage.title"}
        textId={"webChatLogoutPage.overview"}
        illustration={<WomanWithBinoculars />}
      />
      <CustomButtons>
        {partnerDHA && (
          <SimpleMessageButton
            id="webChatLogoutPage.loginButton"
            style={buttonStyle}
            onClick={showModal}
            data-testid="login-button"
          />
        )}
        <SimpleMessageButton
          id="webChatLogoutPage.homeButton"
          style={buttonStyle}
          onClick={handleHomeClick}
          data-testid="home-button"
        />
      </CustomButtons>

      <EmmaEligibilityModal isOpen={isOpen} onClose={hideModal} />
    </>
  );
}
WebChatLogoutPage.displayName = displayName;

export default WebChatLogoutPage;
