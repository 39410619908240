import PropTypes from "prop-types";
import ConsentFormPicker from "components/ConsentForm/ConsentFormPicker";
import GettingStartedPage from "../GettingStartedPage";

const displayName = "WebChatGettingStartedPage";

function WebChatGettingStartedPage(props) {
  return (
    <GettingStartedPage {...props} messengerName="Secure Web Chat">
      {({ activationCode }) => (
        <ConsentFormPicker chat="WebChat" activationCode={activationCode} />
      )}
    </GettingStartedPage>
  );
}
WebChatGettingStartedPage.displayName = displayName;
WebChatGettingStartedPage.propTypes = {
  forceValid: PropTypes.bool,
};

export default WebChatGettingStartedPage;
