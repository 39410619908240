import baseConfig from "../mc-core-hk/config";
import yueInherited from "./translations/zh-Yue.inherit.json";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";
import yueOverrides from "./translations/zh-Yue.json";

// inherit-partner.sh mc-core-hk subpartner mc-hsbc-hk 14 Hong Kong digitalhealthxtra hsbc-hk allianz mydoc MCHSBCHK yue en

// DEVTEST Test "MCHSBCHK" with BIN "8080808080"
const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHXTRA_HSBC_HK_14",
    partnerGroup: "hsbc-mastercard",
    partnerCountry: "Hong Kong",
    subPartnerCode: "MCHSBCHK",
  },
  languages: "yue en".split(" "),
  messages: {
    yue: { ...yueInherited, ...yueOverrides },
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
