import PropTypes from "prop-types";
import ConsentFormPicker from "components/ConsentForm/ConsentFormPicker";
import useLanguage, { toChatBotLanguage } from "hooks/useLanguage";
import * as URL from "constants/urls";
import GettingStartedPage from "../GettingStartedPage";

const displayName = "WhatsAppGettingStartedPage";

function WhatsAppGettingStartedPage(props) {
  const [selectedLanguage] = useLanguage();

  return (
    <GettingStartedPage {...props} messengerName="WhatsApp">
      {({ activationCode }) => (
        <ConsentFormPicker
          chat="WhatsApp"
          activationCode={activationCode}
          messengerLink={`${URL.WHATSAPP_URL()}%20/start%20ActivationCode-${activationCode}_Language-${toChatBotLanguage(
            selectedLanguage
          )}_Platform-WhatsApp`}
        />
      )}
    </GettingStartedPage>
  );
}
WhatsAppGettingStartedPage.displayName = displayName;
WhatsAppGettingStartedPage.propTypes = {
  forceValid: PropTypes.bool,
};

export default WhatsAppGettingStartedPage;
