import { toChatBotLanguage } from "hooks/useLanguage";
import * as URL from "constants/urls";

export default function makeUrl({
  chat,
  activationCode,
  country,
  selectedLanguage,
  ...agreements
}) {
  const countryParam = country ? `&country=${country}` : "";
  const webChatUrl = `${URL.WEBCHAT_URL()}/?ac=${activationCode}&language=${toChatBotLanguage(
    selectedLanguage
  )}${countryParam}&allowNotifications=${!!agreements.allowNotifications}&keepHistory=${!agreements.clearHistory}&chat=${chat}`;

  return webChatUrl;
}
