// import * as codeType from "constants/generateCodeTypes";
import baseConfig from "../medi24/config";
import arOverrides from "./translations/ar.json";
import deOverrides from "./translations/de.json";
import enOverrides from "./translations/en.json";
import esOverrides from "./translations/es.json";
import frOverrides from "./translations/fr.json";
import idOverrides from "./translations/id.json";
import itOverrides from "./translations/it.json";
import msOverrides from "./translations/ms.json";
// import ruOverrides from "./translations/ru.json";
import ukOverrides from "./translations/uk.json";
import thOverrides from "./translations/th.json";
import viOverrides from "./translations/vi.json";
import zhHansOverrides from "./translations/zh-Hans.json";
import zhHantOverrides from "./translations/zh-Hant.json";
import zhYueOverrides from "./translations/zh-Yue.json";
import jaOverrides from "./translations/ja.json";
// NEWLANGUAGE ADD ABOVE import all languages for Dev Demo Partner

// inherit-partner.sh medi24 theme demo-all 9999 None personal-assistant demo-all medi24 NONE NONE ar de en es fr id it ms th vi zh-Hans zh-Hant yue

// This partner configuration is for developers only for experimenting with all options and languages available

const helpEmail = "help@example.com";

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "PERSONAL_ASSISTANT_DEMO_ALL_9999",
    partnerGroup: "demonstration",
    partnerCode: "",
    subPartnerCode: "",
    privacyEmail: helpEmail,
    generalEmail: helpEmail,
  },
  features: {
    ...baseConfig.features,
    DHA: true,
    MyDoc: true,
    // bilingualPrivacy: ["de", "en"],
    // bilingualPrivacy: ["en", "ms"],
    // generateStepType: codeType.BIN_ONLY,
    helplineEmail: helpEmail,
    // helplineEmail: null, // suppresses display of email on error step - delete to show from value in general section
    helplinePhoneNumber: "+001 213 867 5309",
  },
  // languages: 'ar de en es fr id it ms th vi zh-Hans zh-Hant yue'.split(' '),
  messages: {
    ar: arOverrides,
    de: deOverrides,
    en: enOverrides,
    es: esOverrides,
    fr: frOverrides,
    id: idOverrides,
    it: itOverrides,
    ms: msOverrides,
    // ru: ruOverrides,
    uk: ukOverrides,
    th: thOverrides,
    vi: viOverrides,
    "zh-Hans": zhHansOverrides,
    "zh-Hant": zhHantOverrides,
    yue: zhYueOverrides,
    ja: jaOverrides,
    // NEWLANGUAGE ADD ABOVE access to all languages for Dev Demo Partner
  },
};

config.languages = Object.keys(config.messages).sort();

export default config;
