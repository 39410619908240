import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormInput } from "@medi24-da2c/web-ui";
import { useState, useEffect, useCallback, useRef } from "react";
import isValidActivationCode from "utils/isValidActivationCode";
import { useOptionalMessageOr } from "hooks/useOptionalMessage";
import MessageButton from "components/Button/MessageButton";
import { makeIdOptions } from "components/OptionalMessage";
import { INVALID_ERROR, VALID_CODE } from "api/errorCodes";
import useNavigate from "hooks/useNavigate";
import LoadingButton from "../Button/LoadingButton";
import BasicForm from "../BasicForm";
import useActivationCodeStatus from "./useActivationCodeStatus";
import ButtonWrapper from "./ButtonWrapper";
import ErrorMessage from "./ErrorMessage";
import ErrorCode from "./ErrorCode";

const displayName = "ActivationCodeForm";

const key = "codeInput";

const Form = styled(BasicForm)`
  overflow: visible;
`;

const buttonStyle = {
  margin: "0.5rem",
};

const divStyle = {
  display: "flex",
  flexDirection: "row",
};

const idOptions = makeIdOptions(key);

function ActivationCodeForm({
  idInfix,
  showErrorsInPlace,
  loading,
  onSetLoading,
  onSubmit,
}) {
  const [activationCode, setActivationCode] = useState("");
  const [touched, setTouched] = useState(false);
  const [codeStatus, setCodeStatus] = useState();

  const success = useRef({ previous: false });
  success.current.current = codeStatus === VALID_CODE;
  const error = codeStatus && codeStatus !== VALID_CODE;

  const { willGetCodeStatus } = useActivationCodeStatus({
    setCodeStatus,
    onSetLoading,
  });

  const handleSubmit = useCallback(
    function (event) {
      event.preventDefault();
      event.stopPropagation();
      setTouched(true);

      if (!isValidActivationCode(activationCode)) {
        // 9 characters by default
        setCodeStatus(INVALID_ERROR);
      } else {
        willGetCodeStatus(activationCode);
      }
    },
    [activationCode, willGetCodeStatus]
  );

  const handleChange = useCallback(
    function (event) {
      const value = event.target.value.trim();
      setActivationCode(value);
      setCodeStatus();
    },
    [setActivationCode]
  );

  /*
    will insert into message id like this based on idInfix:
      id="activationCodeForm.codeInput.MyDoc.label",
  */
  const inputIds = idOptions("activationCodeForm.codeInput.label", idInfix);
  const inputLabel = useOptionalMessageOr(inputIds.id, inputIds.idDefault);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      success.current.current &&
      success.current.previous !== success.current.current
    ) {
      onSubmit(activationCode);
    }
    success.current.previous = success.current.current;
  });

  if (!showErrorsInPlace && error) {
    return <ErrorCode errorCode={codeStatus} />;
  }

  return (
    <Form>
      <BasicForm.Fields>
        <div style={divStyle}>
          <FormInput
            type="text"
            autoCapitalize="off"
            spellCheck="false"
            autoCorrect="off"
            autoComplete="off"
            label={inputLabel}
            name="activationCode"
            value={activationCode}
            touched={touched}
            disabled={loading}
            onChange={handleChange}
          />
        </div>
      </BasicForm.Fields>
      {showErrorsInPlace && error ? (
        <ErrorMessage errorCode={codeStatus} />
      ) : null}
      <ButtonWrapper>
        <LoadingButton
          id="activationCodeForm.continueButton"
          style={buttonStyle}
          disabled={!activationCode}
          data-testid="continue-button"
          loading={loading}
          onClick={handleSubmit}
        />
        {showErrorsInPlace ? (
          <MessageButton
            id="webChatLogoutPage.homeButton"
            style={buttonStyle}
            data-testid="home-button"
            onClick={navigate.toLandingPage}
          />
        ) : null}
      </ButtonWrapper>
    </Form>
  );
}

ActivationCodeForm.displayName = displayName;

ActivationCodeForm.propTypes = {
  idInfix: PropTypes.string,
  showErrorsInPlace: PropTypes.bool,
  loading: PropTypes.bool,
  onSetLoading: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default ActivationCodeForm;
