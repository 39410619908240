import { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";
import { Content, ErrorBoundary } from "@medi24-da2c/web-ui";
import useValidActivationCode from "hooks/useValidActivationCode";
import useDisableMyDocButton from "hooks/useDisableMyDocButton";
import { OptionalMessage } from "components/OptionalMessage";
import LoadingButton from "components/Button/LoadingButton";
import MyDocModal from "eligibilityCheck/MyDocModal";
import MyDocOverview from "components/MyDocOverview";
import { useSearchLinks } from "hooks/withHtml";
import useModal from "hooks/useModal";
import useMyDoc from "hooks/useMyDoc";
import useDHA from "hooks/useDHA";
import makeTrackedClick from "tracking/makeTrackedClick";
import useActivationCode from "../../eligibilityCheck/MyDocModal/useActivationCode";
import platform from "../../utils/platform";

import MyDocLegalMessage from "./MyDocLegal";
import ButtonFooter from "./ButtonFooter";
import GeneralInfo from "./GeneralInfo";
import GetStarted from "./GetStarted";
import Headline from "./Headline";
import Callout from "./Callout";
import Text from "./Text";

const displayName = "MyDocIntroduction";

const styleLegal = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingBottom: "2rem",
};

const styleText = {
  display: "flex",
  justifyContent: "center",
};

function MyDocIntroduction({ onGiveModalOpener, forceDHA, forceMyDoc }) {
  const theme = useTheme(); // PROPER WAY TO GET THEME CONTEXT
  const partnerDHA = useDHA();
  const partnerMyDoc = useMyDoc();
  const values = useSearchLinks();
  const [loading, setLoading] = useState(false);
  const partnerDisableMyDoc = useDisableMyDocButton();
  const { isOpen, showModal, hideModal } = useModal();
  const { activationCode, hasCode } = useValidActivationCode();
  const hasDHA = forceDHA === undefined ? partnerDHA : forceDHA;
  const hasMyDoc = forceMyDoc === undefined ? partnerMyDoc : forceMyDoc;
  const disableMyDoc = forceMyDoc === "disabled" ? true : partnerDisableMyDoc;
  useState(() => onGiveModalOpener(showModal)); // tell the parent about our modal opener callback later

  function setErrorCode() {
    showModal();
  }

  const clickTrack = makeTrackedClick("START_TELECONSULT_CLICK");

  const { willGetInvitationUrl } = useActivationCode({
    setStep: null,
    setInviteUrl: platform.redirect,
    setErrorCode,
    onSetLoading: setLoading,
  });

  function handleGetInvitationUrl() {
    // setLoading(true);
    willGetInvitationUrl(activationCode);
  }

  function handleClickMyDoc() {
    if (hasCode) {
      clickTrack();
      handleGetInvitationUrl();
    } else {
      showModal();
    }
  }

  return (
    <ErrorBoundary>
      {hasMyDoc && (
        <>
          <GeneralInfo data-testid={`${displayName}-general-info`}>
            <Content>
              <Headline id={"landingPage.myDoc.headline"} />
              <Text.Messages
                id="landingPage.myDoc.overview"
                idAvailable="landingPage.myDoc.availabilityIndicator"
                values={values}
              />
              <Callout>
                <OptionalMessage id="landingPage.myDoc.comingSoon" />
              </Callout>
            </Content>
          </GeneralInfo>
          <MyDocOverview theme={theme} />
          <GetStarted
            data-testid={`${displayName}-get-started-section`}
            theme={theme}
          >
            <Content>
              <LoadingButton
                data-testid={`${displayName}-button`}
                id="landingPage.getStartedMyDocButton"
                align="center"
                values={values}
                loading={loading}
                disabled={disableMyDoc}
                onClick={handleClickMyDoc}
              />
            </Content>
          </GetStarted>
        </>
      )}
      <ButtonFooter data-testid={`${displayName}-footer`} theme={theme}>
        <Content data-testid={`${displayName}-footer-legal`}>
          <div style={styleLegal}>
            {hasDHA && (
              <>
                <MyDocLegalMessage id="landingPage.legal.p1" theme={theme} />
                <MyDocLegalMessage id="landingPage.legal.p2" theme={theme} />
              </>
            )}
            {hasMyDoc && (
              <MyDocLegalMessage id="landingPage.legal.p3" theme={theme} />
            )}
          </div>
        </Content>
        {hasMyDoc && (
          <Content data-testid={`${displayName}-footer-faq`}>
            <OptionalMessage
              style={styleText}
              id="landingPage.legal.myDoc.faq"
            />
          </Content>
        )}
      </ButtonFooter>
      {!disableMyDoc && <MyDocModal isOpen={isOpen} onClose={hideModal} />}
    </ErrorBoundary>
  );
}
MyDocIntroduction.displayName = displayName;
MyDocIntroduction.propTypes = {
  onGiveModalOpener: PropTypes.func.isRequired,
  forceDHA: PropTypes.bool,
  forceMyDoc: PropTypes.oneOf([false, true, "disabled"]),
};

export default MyDocIntroduction;
