import PropTypes from "prop-types";
import SimpleMessageButton from "components/SimpleMessageButton";
import { useOptionalMessage } from "hooks/useOptionalMessage";
import { useSearchLinks, makeMailTo } from "hooks/withHtml";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import { GENERATE_CODE_STEP } from "constants/steps";
import NoWordWrap from "components/NoWordWrap";
import Modal from "components/Modal";
import {
  INVALID_CREDENTIALS,
  MAXIMUM_NUMBER_OF_CODES_PER_USER_REACHED,
  GENERIC_ERROR,
  INVITE_ERROR,
} from "api/errorCodes";
import StepHeadline from "../StepHeadline";

const displayName = "ErrorStep";

const mailToService = makeMailTo("mail-to-service");

function ErrorStep({
  errorCode,
  setStep,
  retryStep,
  helplinePhoneNumber,
  helplineEmail,
}) {
  const withHtml = { ...useSearchLinks(), serviceEmail: helplineEmail };
  const message = useOptionalMessage("system.serviceEmail", void 0, withHtml);

  useTrackPageEffect("ERROR_ELIGIBILITY_CONTENT");

  function renderHelplineInfo() {
    return (
      <>
        {(helplinePhoneNumber || helplineEmail) && ": "}
        {helplinePhoneNumber && <NoWordWrap>{helplinePhoneNumber}</NoWordWrap>}
        {helplinePhoneNumber && helplineEmail && ", "}
        {helplineEmail && mailToService(message || helplineEmail)}.
      </>
    );
  }

  function renderContent() {
    let id;
    let isRenderHelplineInfo = false;
    switch (errorCode) {
      case MAXIMUM_NUMBER_OF_CODES_PER_USER_REACHED:
        id =
          "chooseMessengerPage.generateActivationCodeModal.errorStep.maxNumberOfCodesError";
        isRenderHelplineInfo = true;
        break;
      case INVALID_CREDENTIALS:
        id =
          "chooseMessengerPage.generateActivationCodeModal.errorStep.invalidCredentialsError";
        isRenderHelplineInfo = true;
        break;
      case INVITE_ERROR:
        id =
          "chooseMessengerPage.generateActivationCodeModal.errorStep.inviteError";
        break;
      case GENERIC_ERROR:
      default:
        id =
          "chooseMessengerPage.generateActivationCodeModal.errorStep.genericError";
        break;
    }
    return (
      <>
        <Modal.Message id={id} />
        {isRenderHelplineInfo ? renderHelplineInfo() : null}
      </>
    );
  }

  return (
    <>
      <Modal.Content>
        <StepHeadline id="chooseMessengerPage.generateActivationCodeModal.errorStep.headline" />
        {renderContent()}
      </Modal.Content>
      <Modal.Controls>
        <SimpleMessageButton
          id="chooseMessengerPage.generateActivationCodeModal.errorStep.retryButton"
          data-testid="retry-button"
          onClick={() => setStep(retryStep)}
        />
      </Modal.Controls>
    </>
  );
}

ErrorStep.displayName = displayName;

ErrorStep.propTypes = {
  errorCode: PropTypes.string.isRequired,
  retryStep: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
  helplinePhoneNumber: PropTypes.string,
  helplineEmail: PropTypes.string,
};
ErrorStep.defaultProps = {
  retryStep: GENERATE_CODE_STEP,
};

export default ErrorStep;
