// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function WebChatLogo() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      role="img"
      aria-labelledby="icon-webchat-logo-title"
    >
      <title id="icon-webchat-logo-title">Secure Web Chat</title>
      <path
        fill="#f4f4f4"
        d="M1.043 7.565v23.739c0 3.443 2.817 6.261 6.261 6.261h34.435c3.443 0 6.261-2.817 6.261-6.261V7.565H1.043z"
      />
      <path
        fill="#713535"
        d="M41.739 0H7.304C3.861 0 1.043 2.817 1.043 6.261v2.087H48V6.261C48 2.817 45.183 0 41.739 0z"
      />
      <path
        fill="#f4f4f4"
        d="M36.674 6.261H19.587c-.825 0-1.5-.675-1.5-1.5V4.63c0-.825.675-1.5 1.5-1.5h17.087c.825 0 1.5.675 1.5 1.5v.13c0 .826-.675 1.501-1.5 1.501z"
      />
      <circle fill="#c40000" cx="5.952" cy="4.696" r="1.565" />
      <circle fill="#a66a18" cx="10.109" cy="4.696" r="1.565" />
      <circle fill="#00d254" cx="14.267" cy="4.696" r="1.565" />
      <path
        fill="#ffffff"
        d="M31.671 43.979c-6.837-.864-12.029-5.783-12.029-11.62 0-6.489 6.361-11.769 14.179-11.769S48 25.87 48 32.359c0 4.343-2.258 6.79-3.085 7.595-4.681 5.05-12.124 7.389-12.428 7.486l-1.751.56.935-4.021z"
      />
      <path
        fill="#e83363"
        d="M46.957 32.359c0-5.923-5.881-10.725-13.135-10.725s-13.135 4.802-13.135 10.725c0 5.686 5.423 10.325 12.273 10.689l-.79 3.398s7.432-2.293 11.981-7.204c.774-.755 2.806-2.919 2.806-6.883z"
      />
      <path
        fill="#ffffff"
        d="M16.329 30.843c6.837-.864 12.029-5.783 12.029-11.62 0-6.489-6.361-11.769-14.179-11.769S0 12.734 0 19.223c0 4.343 2.258 6.79 3.085 7.595 4.681 5.05 12.124 7.389 12.428 7.486l1.751.56-.935-4.021z"
      />
      <path
        fill="#ddd"
        d="M1.043 19.223c0-5.923 5.881-10.725 13.135-10.725S27.313 13.3 27.313 19.223c0 5.686-5.423 10.325-12.273 10.689l.79 3.398s-7.432-2.293-11.981-7.204c-.774-.755-2.806-2.919-2.806-6.883z"
      />
    </svg>
  );
}

export default WebChatLogo;
