// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function IntroConversationChatHandle() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 56 36"
      role="img"
      id="intro-conversation-chat-handle"
    >
      <path
        d="M32.9 28.102A17.914 17.914 0 0036 18c0-4.766-1.852-9.099-4.876-12.319L8.819 2.514C3.539 5.652 0 11.413 0 18c0 1.632.217 3.213.624 4.715l8.485 10.939 13.456 1.761L32.9 28.102zM56 5.056C56 18 48.197 22.892 41 24c2.957 2.218 7.222 3.75 15 2.778V5.056z"
        fill="#fff"
      />
      <path
        fill="#2e3a3e"
        d="M22.558 35.417A18.01 18.01 0 0032.9 28.09c-.96-.96-1.76-2.11-2.19-4.03-1.25-5.69-.02-10.6-.02-10.6L5.67 14.82s-.91 3.87-5.04 7.89a18 18 0 008.486 10.949l13.442 1.758z"
      />
      <path
        d="M9.792 3.667C6.93 7.061 5.586 11.998 7.52 17.64c1 2.9 2.09 5.15 3.23 6.92l-1.64 9.09C11.73 35.15 14.76 36 18 36c1.58 0 3.12-.2 4.58-.59l.11-5.61c4.43-2.12 6.59-8.72 7.81-14.55.812-3.889.09-8.306-2.06-11.583H9.792z"
        fill="#f6aa98"
      />
      <path
        fill="#2e3a3e"
        d="M30.75 13.57c-2.99-2.48-5.05-7.93-5.56-9.38-2.15 4.2-7.33 11.3-18.05 12.32-1.93-.93-2.16-2.14-2.16-2.14S3.71 9.21 8.83 2.5C10.27 1.65 11.84.99 13.5.57 14.94.2 16.45 0 18 0c3.73 0 7.19 1.14 10.06 3.08 1.12.76 2.15 1.64 3.07 2.62.82 2.16.97 4.89-.38 7.87z"
      />
      <path
        d="M7.368 7.989h1.255c2.136 0 3.867 2.904 3.867 6.484 0 3.167-1.353 5.797-3.143 6.365 1.383 2.789 5.243 4.814 9.805 4.872.439-.636 1.473-1.083 2.676-1.083 1.603 0 2.901.789 2.901 1.764 0 .973-1.299 1.762-2.901 1.762-1.208 0-2.245-.45-2.68-1.09-5.316-.065-9.791-2.626-11.221-6.108h-.558c-2.136 0-3.863-2.9-3.863-6.483-.002-3.579 1.726-6.483 3.862-6.483z"
        fill="#37658f"
      />
      <path
        d="M28.566 14.597c.345.064.551.523.458 1.024-.092.502-.447.857-.796.793-.344-.064-.55-.522-.458-1.024.093-.501.449-.857.796-.793zm-7.166.946c.341.096.726-.227.865-.718.139-.489-.029-.967-.366-1.063-.341-.092-.725.228-.865.718-.134.494.029.967.366 1.063z"
        fill="#19262b"
      />
      <path
        d="M19.578 30.496a7.116 7.116 0 003.108-.697l-.084 3.341s-6.247-.562-8.662-4.866c2.112 1.755 4.134 2.222 5.638 2.222zm5.126-8.371c.167 0 .31-.121.337-.289l1.127-7.347a.34.34 0 00-.286-.388.339.339 0 00-.39.285l-1.126 7.347a.34.34 0 00.338.392zm-6.323-.821a.341.341 0 11.615-.296c1.232 2.552 3.916 1.875 4.029 1.845a.343.343 0 01.419.242.344.344 0 01-.241.418 4.585 4.585 0 01-1.064.114c-1.108-.002-2.807-.358-3.758-2.323z"
        fill="#ef7b6f"
      />
    </svg>
  );
}

export default IntroConversationChatHandle;
