// A styled version of a translation message (invertable) button with a hide prop
import PropTypes from "prop-types";
import { OptionalSection, OptionalMessage } from "../OptionalMessage";
import { InvertableButton } from "../SimpleButton";

const displayName = "SimpleMessageButton";

function SimpleMessageButton({ id, hide, values, suffix, ...props }) {
  return (
    <OptionalSection id={id} hide={hide} values={values}>
      <InvertableButton {...props}>
        <OptionalMessage id={id} values={values} />
        {suffix && <>&nbsp;{suffix}</>}
      </InvertableButton>
    </OptionalSection>
  );
}
SimpleMessageButton.displayName = displayName;
SimpleMessageButton.propTypes = {
  ...InvertableButton.propTypes,
  id: PropTypes.string.isRequired,
  hide: PropTypes.bool,
  values: PropTypes.object,
  suffix: PropTypes.node,
};
// window.console.warn(`${displayName} PT`, SimpleMessageButton.propTypes)
export default SimpleMessageButton;
