import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import Hitbox from "../Hitbox";
import Cross from "./Cross";

const Icon = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  color: ${(themedProps) => themedProps.theme.modal.closeButtonColor};
`;

const displayName = "CloseButton";

function CloseButton({ onClose }) {
  const theme = useTheme();
  return (
    <Hitbox
      onClick={onClose}
      aria-label="Close"
      data-testid="modal-close-button"
    >
      <Icon theme={theme}>
        <Cross />
      </Icon>
    </Hitbox>
  );
}

CloseButton.displayName = displayName;

CloseButton.propTypes = {
  onClose: PropTypes.func,
};

export default CloseButton;
