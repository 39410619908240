// Eligibility check for activation code: policy number and year of birth

import { useState } from "react";
import PropTypes from "prop-types";
import { FormInput } from "@medi24-da2c/web-ui";
import { useOptionalMessage } from "hooks/useOptionalMessage";
import GenerateCodeForm from "../GenerateCodeForm";
import useActivationCode from "./useActivationCode";
import isValidPolicyNumberAgeForm, {
  isValidPolicyNumberForm as isValidNoAge,
} from "./isValidPolicyNumberAgeForm";

const displayName = "PolicyNumberAgeStep";

const idPolicyNumberLabel = "generateActivationCodeForm.policyNumber.label";
const idPolicyNumberPlaceholder =
  "generateActivationCodeForm.policyNumber.placeholder";
const idYearOfBirthLabel = "generateActivationCodeForm.yearOfBirth.label";
const idYearOfBirthPlaceholder =
  "generateActivationCodeForm.yearOfBirth.placeholder";
const idLegalInfo = "generateActivationCodeForm.policyNumber.legalInfo";

function PolicyNumberAgeStep(props) {
  const { noAge, policyRegex } = props;
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    policyNumber: "",
    yearOfBirth: noAge ? void 0 : "",
  });
  const validator = noAge
    ? (values) => isValidNoAge(values, policyRegex)
    : (values) => isValidPolicyNumberAgeForm(values, policyRegex);
  const { willGenerateCode } = useActivationCode(
    {
      ...props,
      loading,
      onSetLoading: setLoading,
    },
    validator
  );

  function handleSubmit(event) {
    event.preventDefault();

    // Transform values to shape which remote API will understand
    willGenerateCode({
      userKey: formValues.policyNumber.toUpperCase(),
      yearOfBirth: noAge ? void 0 : formValues.yearOfBirth,
    });
  }

  function handleChange(event) {
    const { value, name } = event.target;

    setFormValues({
      ...formValues,
      [name]: value.trim(),
    });
  }

  function isDirty() {
    return !Object.values(formValues).includes("");
  }

  // TODO(2021-07) change useOptionalMessage to useOptionalMessageOr for alternate MyDoc messages see ActivationCodeForm.js
  const policyNumberLabel = useOptionalMessage(idPolicyNumberLabel);
  const policyNumberPlaceholder = useOptionalMessage(idPolicyNumberPlaceholder);
  const yearOfBirthLabel = useOptionalMessage(idYearOfBirthLabel);
  const yearOfBirthPlaceholder = useOptionalMessage(idYearOfBirthPlaceholder);

  return (
    <GenerateCodeForm
      idLegalInfo={idLegalInfo}
      loading={loading}
      isDirty={isDirty()}
      onSubmit={handleSubmit}
    >
      <FormInput
        data-testid={`FormInput-${idPolicyNumberLabel}`}
        label={policyNumberLabel}
        placeholder={policyNumberPlaceholder}
        name="policyNumber"
        type="text"
        spellCheck="false"
        autoComplete="off"
        value={formValues.policyNumber}
        onChange={handleChange}
      />
      {noAge ? (
        void 0
      ) : (
        <FormInput
          data-testid={`FormInput-${idYearOfBirthLabel}`}
          label={yearOfBirthLabel}
          placeholder={yearOfBirthPlaceholder}
          name="yearOfBirth"
          type="text"
          spellCheck="false"
          autoComplete="off"
          pattern="[0-9]*"
          inputmode="numeric"
          value={formValues.yearOfBirth}
          onChange={handleChange}
        />
      )}
    </GenerateCodeForm>
  );
}

PolicyNumberAgeStep.displayName = displayName;

PolicyNumberAgeStep.propTypes = {
  // TODO(2021-07) add idInfix for alternate MyDoc messages see InitialStep
  setActivationCodeDetails: PropTypes.func.isRequired,
  setErrorCode: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  partnerCode: PropTypes.string.isRequired,
  policyRegex: PropTypes.instanceOf(RegExp),
  noAge: PropTypes.bool,
};

export default PolicyNumberAgeStep;
