import { useRef, useState } from "react";
import noop from "lodash/noop";
import { useTheme } from "@emotion/react";
import useValidActivationCode from "hooks/useValidActivationCode";
import PartnerConfig from "components/__dev__/PartnerConfig";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import useTranslatedTitle from "hooks/useTranslatedTitle";
import useLegalInfo from "hooks/useLegalInfo";
import useMyDoc from "hooks/useMyDoc";
import makeTrackedClick from "tracking/makeTrackedClick";
import useActivationCode from "../../eligibilityCheck/MyDocModal/useActivationCode";
import platform from "../../utils/platform";
import EmmaIntroduction from "./EmmaIntroduction";
import MyDocIntroduction from "./MyDocIntroduction";

const displayName = "LandingPage";

function LandingPage() {
  const [loading, setLoading] = useState(false);

  const theme = useTheme(); // PROPER WAY TO GET THEME CONTEXT
  const showPartner =
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_SHOW_PARTNER;
  const modalOpener = useRef(noop);
  const hasMyDoc = useMyDoc();
  const showLegalInfo = useLegalInfo();
  const { activationCode, hasCode } = useValidActivationCode();

  useTranslatedTitle("landingPage.title", "Personal Assistant");
  useTrackPageEffect("LANDING_PAGE");

  const clickTrack = makeTrackedClick("START_TELECONSULT_CLICK");

  function setErrorCode() {
    modalOpener.current();
  }

  const { willGetInvitationUrl } = useActivationCode({
    setStep: null,
    setInviteUrl: platform.redirect,
    setErrorCode,
    onSetLoading: setLoading,
  });

  function handleGiveModalOpener(showModalFunction) {
    modalOpener.current = showModalFunction;
  }

  function handleGetInvitationUrl() {
    willGetInvitationUrl(activationCode);
  }

  function handleClickMyDoc() {
    if (hasCode) {
      clickTrack();
      handleGetInvitationUrl();
    } else {
      modalOpener.current();
    }
  }

  return (
    <>
      {showPartner && <PartnerConfig />}
      <EmmaIntroduction
        theme={theme}
        myDocLoading={loading}
        onClickMyDoc={handleClickMyDoc}
      />
      {(showLegalInfo || hasMyDoc) && (
        <MyDocIntroduction onGiveModalOpener={handleGiveModalOpener} />
      )}
    </>
  );
}
LandingPage.displayName = displayName;

export default LandingPage;
