import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import CarouselBase from "nuka-carousel";
import { ErrorBoundary } from "@medi24-da2c/web-ui";

import PagingBullets from "./PagingBullets";

const StyledCarousel = styled(CarouselBase)`
  padding-bottom: 8rem;

  &:focus,
  .slider-slide,
  .slide-visible {
    outline: 0;
  }
`;

function renderBullets(props) {
  return <PagingBullets {...props} />;
}

const displayName = "Carousel";

function Carousel({ children }) {
  const { formatMessage } = useIntl();

  function renderCustomAnnounceMessage({ currentSlide, slideCount }) {
    return formatMessage(
      {
        id: "carousel.announceMessage",
        defaultMessage: "Slide {currentSlide} of {slideCount}",
      },
      { currentSlide: currentSlide + 1, slideCount }
    );
  }

  return (
    <ErrorBoundary>
      <StyledCarousel
        disableEdgeSwiping
        cellSpacing={40}
        heightMode="max"
        autoGenerateStyleTag={false}
        renderAnnounceSlideMessage={renderCustomAnnounceMessage}
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
        renderBottomCenterControls={renderBullets}
      >
        {children}
      </StyledCarousel>
    </ErrorBoundary>
  );
}

Carousel.displayName = displayName;

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Carousel;
