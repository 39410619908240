import * as codeType from "constants/generateCodeTypes";
import { hasLetterAndNumber } from "../../eligibilityCheck/GenerateCodeSteps/isValidBinOnlyForm";
import baseConfig from "../medi24-allianz/config";
import enInherited from "./translations/en.inherit.json";
import deInherited from "./translations/de.inherit.json";
import frInherited from "./translations/fr.inherit.json";
import esInherited from "./translations/es.inherit.json";
import enOverrides from "./translations/en.json";
import deOverrides from "./translations/de.json";
import frOverrides from "./translations/fr.json";
import esOverrides from "./translations/es.json";

// inherit-partner.sh medi24-allianz subpartner ioc-bj 23 Europe digitalhealthxtra ioc-bj allianz NONE IOCBEIJING22 en de fr es

const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    title: "", // no suffix to browser title bar
    partnerOverride: "DIGITALHEALTHXTRA_IOC_BJ_23",
    partnerGroup: "international-olympic-committee",
    partnerCountry: "Germany",
    partnerCode: "IOCBEIJING22",
  },
  footer: {
    address: {
      name: "Allianz Partners SAS",
      street: "7 rue Dora Maar 93400",
      city: "Saint Ouen",
      country: "fr",
    },
    legal: {
      securityNumber: "490 381 753 RCS Bobigny",
      commercialRegister: "B 301 763 116",
    },
  },
  features: {
    ...baseConfig.features,
    smallScreenFonts: { de: true, es: true },
    generateStepType: codeType.BIN_ONLY,
    binOnlyRegex: hasLetterAndNumber,
    DHA: true,
    noDHA: false,
    noWebApp: false,
    noRecording: false,
    noPrivacyFaq: false,
    noDoctorChat: false,
    noUsefulLinks: false,
    noMedicalHotline: false,
    noSymptomChecker: true,
    noMedicalConsultation: true,
    noProvisionOfServices: false,
    MyDoc: false,
    QuickSummary: true,
    showQuickSummaryBenefits: true,
    showLegalInfo: true,
    showViber: false,
    showLineApp: false,
  },
  languages: "en de fr es".split(" "),
  messages: {
    en: { ...enInherited, ...enOverrides },
    de: { ...deInherited, ...deOverrides },
    fr: { ...frInherited, ...frOverrides },
    es: { ...esInherited, ...esOverrides },
  },
};

export default config;
