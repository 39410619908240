const system = {
  "system.language": "",
  "system.suppressed": "",
  "system.sp": "\u0020",
  "system.wsp": "\u2007",
  "system.nbsp": "\u00a0",
  "system.apos": "\u02bc", // ʼ
  "system.endash": "\u2013", // –
  "system.softhyphen": "\u00ad", // indicates where to put hyphen only when breaking to a new line
  "system.znb": "\ufeff", // zero width no-break space

  SUPPRESS: "%system.suppressed%",
  sp: "%system.sp%",
  wsp: "%system.wsp%",
  nbsp: "%system.nbsp%",
  apos: "%system.apos%",
  endash: "%system.endash%",
  sh: "%system.softhyphen%",
  znb: "%system.znb%",

  "system.emailSubject":
    "DHA Feedback from user [{partnerCountry}/%system.language%]",
  "system.emailContext":
    "%sp%%sp%%sp%%sp%%sp%%sp%%sp%%sp%%sp%%sp%[on page: <site></site>]",
  "system.generalEmail":
    "{generalEmail}?subject=%system.emailSubject%%system.emailContext%",
  "system.dataRepEmail":
    "datenschutz-azpde@allianz.com?subject=%system.emailSubject%%system.emailContext%",
  "system.privacyEmail":
    "{privacyEmail}?subject=%system.emailSubject%%system.emailContext%",
  "system.serviceEmail":
    "{serviceEmail}?subject=%system.emailSubject%%system.emailContext%",

  "system.landingPage.emma.slide1.image": "WomanUnderPalmTree",
  "system.landingPage.emma.slide2.image": "ManSeekingMedicalAdvice",
  "system.landingPage.emma.slide3.image": "ManPointingAtApps",
  "system.landingPage.myDoc.slide1.image": "WomanWorkingInHomeOffice",
  "system.landingPage.myDoc.slide2.image": "DeliveryScooter",
  "system.landingPage.myDoc.slide3.image": "WomanAndManDiscussingSecurity",

  "system.languagePicker.label.zhHans": "简中",
  "system.languagePicker.label.zhHant": "繁中",
  "system.languagePicker.label.th": "ไทย",
  "system.languagePicker.label.vi": "Việt",
  "system.languagePicker.label.yue": "中文",
  "system.languagePicker.label.uk": "UA",
  // Japanese punctuation: https://www.tofugu.com/japanese/japanese-punctuation/
  "system.languagePicker.label.ja": "日本",
  // NEWLANGUAGE ADD ABOVE if language picker name is customised
};

export default system;
