import PropTypes from "prop-types";
import { Content, ErrorBoundary } from "@medi24-da2c/web-ui";
import useTranslatedTitle from "hooks/useTranslatedTitle";
import useTrackPageEffect from "hooks/useTrackPageEffect";
import PlatformInfo from "components/PlatformInfo";
import Messenger from "components/Messenger";
import useTelegram from "hooks/useTelegram";
import * as URL from "constants/urls";
import useViber from "hooks/useViber";
import useLine from "hooks/useLine";
import {
  WhatsAppLogo,
  TelegramLogo,
  WebChatLogo,
  ViberLogo,
  LineLogo,
} from "components/Icons";
import ValidActivationCodeGuard from "../ValidActivationCodeGuard";
import MessengersContent from "./MessengersContent";
import Messengers from "./Messengers";
import Headline from "./Headline";

const displayName = "ChooseMessengerPage";

function ChooseMessengerPage({ forceValid, forceChannels }) {
  const hasTelegram = useTelegram();
  const hasViber = useViber();
  const hasLine = useLine();
  const showTelegram = hasTelegram || /\b(all|telegram)\b/i.test(forceChannels);
  const showViber = hasViber || /\b(all|viber)\b/i.test(forceChannels);
  const showLine = hasLine || /\b(all|line)\b/i.test(forceChannels);

  useTranslatedTitle("chooseMessengerPage.title", "Choose a messenger");
  useTrackPageEffect("CHOOSE_CHAT_PAGE");

  return (
    <ErrorBoundary>
      <ValidActivationCodeGuard forceValid={forceValid}>
        <MessengersContent>
          <Headline.Message id="chooseMessengerPage.headline" />
          <Messengers data-testid="messengers-list">
            <Messenger
              name="WhatsApp"
              redirectUrl={URL.WHATSAPP}
              icon={<WhatsAppLogo />}
              data-testid="whatsapp-link"
            />
            {showTelegram && (
              <Messenger
                name="Telegram"
                redirectUrl={URL.TELEGRAM}
                icon={<TelegramLogo />}
                data-testid="telegram-link"
              />
            )}
            {showViber && (
              <Messenger
                name="Viber"
                redirectUrl={URL.VIBER}
                icon={<ViberLogo />}
                data-testid="viber-link"
              />
            )}
            {showLine && (
              <Messenger
                name="LINE"
                redirectUrl={URL.LINE}
                icon={<LineLogo />}
                data-testid="line-link"
              />
            )}
            <Messenger
              name="Secure Web Chat"
              id="chooseMessengerPage.messengerButton.secureWebChat"
              redirectUrl={URL.WEBCHAT}
              icon={<WebChatLogo />}
              data-testid="webchat-link"
            />
          </Messengers>
        </MessengersContent>
        <Content>
          <PlatformInfo />
        </Content>
      </ValidActivationCodeGuard>
    </ErrorBoundary>
  );
}
ChooseMessengerPage.displayName = displayName;
ChooseMessengerPage.propTypes = {
  forceValid: PropTypes.bool,
  forceChannels: PropTypes.string,
};

export default ChooseMessengerPage;
