import { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";
import { OptionalSectionOr } from "components/OptionalMessage";
import Modal from "components/Modal";
import OptionalTextOr from "./OptionalTextOr";

const displayName = "InfoLineExpand";

const noPadding = { padding: 0 };

function InfoLineExpand({ info, help }) {
  const [expand, setExpand] = useState(false);

  const theme = useTheme();
  const themeColor = theme.button.bgrColor;
  const styleInfo = {
    color: themeColor,
    cursor: "pointer",
    paddingLeft: "0.5rem",
    left: "calc(100% - 4.2rem)",
    marginTop: "7.5rem",
    position: "absolute",
    border: `2px dotted ${themeColor}`,
    borderRadius: "50%",
    width: "16px",
    height: "17px",
    fontSize: "1rem",
  };

  return (
    <>
      <OptionalSectionOr {...help}>
        <button
          data-testid={`${displayName}-icon`}
          style={styleInfo}
          onClick={() => setExpand(!expand)}
        >
          <strong>i</strong>
        </button>
      </OptionalSectionOr>
      <Modal.ScrollableContent style={noPadding}>
        <OptionalTextOr {...info} />
        {expand && <OptionalTextOr {...help} />}
      </Modal.ScrollableContent>
    </>
  );
}
InfoLineExpand.displayName = displayName;
InfoLineExpand.propTypes = {
  info: PropTypes.shape({
    id: PropTypes.string,
    idDefault: PropTypes.string,
  }).isRequired,
  help: PropTypes.shape({
    id: PropTypes.string,
    idDefault: PropTypes.string,
  }).isRequired,
};
export default InfoLineExpand;
