// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function Minus() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      role="img"
    >
      <rect fill="currentColor" y="8" width="18" height="2" />
    </svg>
  );
}

export default Minus;
