import styled from "@emotion/styled";
import { PHONE_LARGE_MEDIA_MAX } from "@medi24-da2c/web-ui/media";

const ButtonWrapper = styled.div`
  padding: 2rem 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  @media ${PHONE_LARGE_MEDIA_MAX} {
    display: block;
  }
`;

export default ButtonWrapper;
