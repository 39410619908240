// Our application
export const HOME = "/";
export const DEV_ADMIN = "/dev";
export const FAQ = "/faq";
export const PRIVACY = "/privacy";
export const TERMS = "/terms-of-use";
export const CHOOSE = "/choose-messenger";
export const WHATSAPP = "/whatsapp/getting-started";
export const TELEGRAM = "/telegram/getting-started";
export const VIBER = "/viber/getting-started";
export const LINE = "/line/getting-started";
export const WEBCHAT = "/webchat/getting-started";
export const LOGOUT = "/webchat-logout";

export const TEST = "/TESTURL";

// Our API
export const API_HOST_URL = () => process.env.REACT_APP_API_HOST_URL;

// External URLs
export const MAYO_URL = "http://www.mayoclinic.org/about-mayo-clinic";
export const MYDOC_MC_FAQ_URL =
  "https://my-doc.com/mydoc-faq-for-allianz-mastercard";
export const HALODOC_FAQ_URL = "https://www.halodoc.com/faq";
export const AE_PROT_DATA_URL = "https://www.agpd.es";

export const WHATSAPP_URL = () => process.env.REACT_APP_WHATSAPP_URL;
export const TELEGRAM_URL = () => process.env.REACT_APP_TELEGRAM_URL;
export const WEBCHAT_URL = () => process.env.REACT_APP_WEBCHAT_URL;
export const DRCHAT_URL = () => process.env.REACT_APP_DRCHAT_URL;
export const LINE_URL = () => process.env.REACT_APP_LINE_URL;
