import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { fontSmall } from "@medi24-da2c/web-ui/design-tokens";
import { DESKTOP_MEDIA, TABLET_MEDIA, Text } from "@medi24-da2c/web-ui";
import { TABLET_BREAKPOINT } from "@medi24-da2c/web-ui/breakpoints/breakpoints";

import { OptionalMessage, OptionalSection } from "components/OptionalMessage";

const displayName = "LandingPage.MyDocLegalMessage";

const MyDocLegalStyled = styled(Text)`
  font-size: ${fontSmall};
  margin-top: 0;
  margin-bottom: 0;
  color: ${(themedProps) => themedProps.theme.landingPageGetStarted.textColor};

  &:first-of-type {
    margin-top: 0;
  }
  @media ${DESKTOP_MEDIA} {
    margin-left: 20rem;
  }
  @media ${TABLET_MEDIA} {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  @media screen and (width: ${TABLET_BREAKPOINT}px) {
    margin-left: 10rem;
  }
`.withComponent("small");

function MyDocLegalMessage({ id }) {
  const theme = useTheme();
  return (
    <OptionalSection id={id}>
      <MyDocLegalStyled theme={theme}>
        <OptionalMessage id={id} />
      </MyDocLegalStyled>
    </OptionalSection>
  );
}
MyDocLegalMessage.displayName = displayName;
MyDocLegalMessage.propTypes = {
  id: PropTypes.string.isRequired,
};

export default MyDocLegalMessage;
