import { OptionalTitle } from "components/OptionalMessage";

// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function WomanTextingDoctor() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2730.22 1825.27"
      width="100%"
      height="275px"
      role="img"
      aria-labelledby="icon-woman-texting-doctor-title"
    >
      <OptionalTitle
        id="image.womanTextingDoctor"
        titleId="icon-woman-texting-doctor-title"
      />
      <defs>
        <style>
          {`.cls-wtd-1{fill:#99d9da;}`}
          {`.cls-wtd-2{fill:#fff;}`}
          {`.cls-wtd-3{fill:#999;}`}
          {`.cls-wtd-4{fill:#185293;}`}
          {`.cls-wtd-5{fill:#3a7fda;}`}
          {`.cls-wtd-6{fill:#78aaef;}`}
          {`.cls-wtd-7{fill:#a1c9ff;}`}
          {`.cls-wtd-8{fill:#914141;}`}
          {`.cls-wtd-9{fill:#7f3030;}`}
          {`.cls-wtd-10{fill:#d7eeff;}`}
          {`.cls-wtd-11{fill:#332323;}`}
          {`.cls-wtd-12{fill:#4c100d;}`}
          {`.cls-wtd-13{fill:#01090a;}`}
          {`.cls-wtd-14{fill:#160b0c;}`}
          {`.cls-wtd-15{fill:#b4dcf9;}`}
          {`.cls-wtd-16{fill:#6e9adc;}`}
          {`.cls-wtd-17{fill:#5f91d1;}`}
          {`.cls-wtd-18{fill:#002128;}`}
          {`.cls-wtd-19{fill:#8fb7ef;}`}
          {`.cls-wtd-20{fill:#ebf8ff;}`}
          {`.cls-wtd-21{fill:#77c0e0;}`}
          {`.cls-wtd-22{fill:#006989;}`}
          {`.cls-wtd-23{fill:#0084ad;}`}
          {`.cls-wtd-24{fill:#6c3131;}`}
          {`.cls-wtd-25{fill:#00a99d;}`}
          {`.cls-wtd-26{fill:#fc8d68;}`}
          {`.cls-wtd-27{fill:#215079;}`}
          {`.cls-wtd-28{fill:#fdb395;}`}
          {`.cls-wtd-29{fill:#863c3a;}`}
          {`.cls-wtd-30{fill:#02685e;}`}
          {`.cls-wtd-31{fill:#01363d;}`}
          {`.cls-wtd-32{fill:#26afe7;}`}
        </style>
      </defs>
      <path
        id="icon-woman-texting-doctor-blob"
        className="cls-wtd-1"
        d="M2399.7,1326.55c38.15-57.09,166.44-249.09,146.65-482.53-47.88-564.75-1077.93-977-1443.88-707.5C899,286.35,1062.79,381.78,653.5,624.36,305.28,830.74,59.76,804.92,54.56,1104.94,46.66,1560.87,853.09,1911,1628.61,1766.5,1783.73,1737.59,2173.59,1664.93,2399.7,1326.55Z"
        transform="translate(-54.5 -51.98)"
      />
      <g id="icon-woman-texting-doctor-chat-bubble1">
        <rect
          className="cls-wtd-2"
          x="572.73"
          y="89.11"
          width="543.01"
          height="157.56"
          rx="60.9"
        />
        <path
          className="cls-wtd-2"
          d="M659.69,340.19s21.86-11.83,18.7-61.52c0,0,66.75,0,65.89,1.66C727.74,311.92,697.15,340.19,659.69,340.19Z"
          transform="translate(-54.5 -51.98)"
        />
        <rect
          className="cls-wtd-3"
          x="673.38"
          y="183.84"
          width="175.14"
          height="18.25"
          rx="7.05"
          transform="translate(1467.4 333.95) rotate(-180)"
        />
        <rect
          className="cls-wtd-3"
          x="934.38"
          y="183.84"
          width="175.14"
          height="18.25"
          rx="7.05"
          transform="translate(1989.4 333.95) rotate(-180)"
        />
        <rect
          className="cls-wtd-3"
          x="865.7"
          y="183.84"
          width="49.23"
          height="18.25"
          rx="7.05"
          transform="translate(1726.12 333.95) rotate(-180)"
        />
        <rect
          className="cls-wtd-3"
          x="921.7"
          y="232.36"
          width="49.23"
          height="18.25"
          rx="7.05"
          transform="translate(1838.12 430.98) rotate(180)"
        />
        <rect
          className="cls-wtd-3"
          x="673.38"
          y="232.36"
          width="227.21"
          height="18.25"
          rx="7.05"
          transform="translate(1519.47 430.98) rotate(180)"
        />
      </g>
      <g id="icon-woman-texting-doctor-chat-bubble2">
        <rect
          className="cls-wtd-2"
          x="1226.02"
          y="352.31"
          width="362.11"
          height="157.56"
          rx="60.9"
          transform="translate(2759.64 810.2) rotate(180)"
        />
        <path
          className="cls-wtd-2"
          d="M1555.67,551.41s-21.86-11.83-18.7-61.52c0,0-66.75,0-65.89,1.66C1487.62,523.14,1518.2,551.41,1555.67,551.41Z"
          transform="translate(-54.5 -51.98)"
        />
        <rect
          className="cls-wtd-3"
          x="1312.33"
          y="343.08"
          width="175.14"
          height="18.25"
          rx="7.05"
        />
        <rect
          className="cls-wtd-3"
          x="1245.92"
          y="343.08"
          width="49.23"
          height="18.25"
          rx="7.05"
        />
        <rect
          className="cls-wtd-3"
          x="1260.27"
          y="391.6"
          width="227.21"
          height="18.25"
          rx="7.05"
        />
      </g>
      <g id="icon-woman-texting-doctor-chat-bubble3">
        <rect
          className="cls-wtd-2"
          x="760.55"
          y="535.48"
          width="361.98"
          height="157.51"
          rx="60.9"
        />
        <path
          className="cls-wtd-2"
          d="M839,777.67s21.86-11.83,18.7-61.5c0,0,66.72,0,65.86,1.66C907.06,749.4,876.49,777.67,839,777.67Z"
          transform="translate(-54.5 -51.98)"
        />
        <circle className="cls-wtd-3" cx="950.1" cy="614.79" r="14.79" />
        <circle className="cls-wtd-3" cx="894.77" cy="614.79" r="14.79" />
        <circle className="cls-wtd-3" cx="840.4" cy="614.79" r="14.79" />
      </g>
      <g id="icon-woman-texting-doctor-doctor-in-tablet">
        <path
          className="cls-wtd-4"
          d="M1665.72,1847.17h712.12c19.64,0,40.49-15.93,46.57-35.57L2783.05,651.92c6.07-19.64-4.93-35.57-24.57-35.57H2046.36c-19.64,0-40.49,15.93-46.57,35.57L1641.16,1811.6C1635.08,1831.24,1646.08,1847.17,1665.72,1847.17Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-5"
          d="M1663.42,1817.41h702.26c13.29,0,27.4-10.77,31.51-24.06L2753.74,640.42c4.11-13.29-3.33-24.07-16.62-24.07H2034.86c-13.29,0-27.4,10.78-31.51,24.07L1646.8,1793.35C1642.69,1806.64,1650.13,1817.41,1663.42,1817.41Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-6"
          d="M2694.06,695.31l-127.1,411-7.85,25.41-1.27,4.1-28.72,92.85-62.46,202-8.45,27.32-7.17,23.19-42.28,136.7-18.57,60.06-21,67.84a14.23,14.23,0,0,1-.57,1.54c-2.73,6.38-9.69,11.32-16.27,11.32H1713.11a11.09,11.09,0,0,1-3.6-.57c-4.76-1.6-7.07-6.5-5.29-12.29l7-22.6,4-13,9.86-31.89,9.16-29.61,15.34-49.61,7.77-25.11,11.56-37.36,6.53-21.13,10.35-33.46,1.81-5.87,3.08-10,7.94-25.68.45-1.46,1.49-4.79,5.77-18.64,20.73-67,.41-1.32,5.49-17.74,11.49-37.17,2.1-6.79,3-9.78,3.83-12.37L1885,1161.17l7-22.66,9.87-31.91,36.46-117.92,10.26-33.18L1971,883.15l3.18-10.29,2.6-8.39,7.64-24.73,2.31-7.45,2.54-8.21,1-3.15,1.54-5,2.93-9.47,4.55-14.72,3.58-11.58.09-.29,26.16-84.57c2.19-7.1,9.74-12.86,16.84-12.86h639.26C2692.28,682.45,2696.26,688.21,2694.06,695.31Z"
          transform="translate(-54.5 -51.98)"
        />
        <polygon
          className="cls-wtd-7"
          points="1851.46 1041.43 1798.08 1214.02 2555.22 916.06 2608.59 743.47 1851.46 1041.43"
        />
        <polygon
          className="cls-wtd-7"
          points="1759.16 1339.88 1722.59 1458.13 2479.72 1160.17 2516.29 1041.92 1759.16 1339.88"
        />
        <path
          className="cls-wtd-6"
          d="M2363.92,653.47c-1.81,5.85,1.46,10.59,7.31,10.59s12.06-4.74,13.87-10.59-1.46-10.6-7.31-10.6S2365.73,647.61,2363.92,653.47Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-8"
          d="M1558.09,686.94c64.08,62,48.1,54.57,50.09,92.86.65,12.61-2.33,16.14,5.83,58.74,1.54,8.06,18.84,78.26,17.82,81.67-2.49,8.29-103.7,51.47-106.42,35.19-.05-.27-2.87-116.43-16-133.12-21.08-26.79-74-26.51-104.67-35-4.29-1.19-5.79-11.16,3.34-11.17,26.15,0,69.59,3.59,78.3,7.12,7.56-5.3-35.67-13.39-52.29-17.06-25.88-5.72-50.54-4.41-48.16-12.09,1.12-3.63,8.86-5.61,10.24-5.58,24.66.47,60.79,3.55,91.44,12.06,10,1-15.21-7.43-43.79-13.45-32.27-6.8-57.59-8.57-56.47-13.92.42-2,3.48-5.33,6.57-6.1,31-7.75,77.83,8,103.75,9,12.67.47-11.45-6-39-10.35-8.5-1.34-64-8.62-63.43-13.23.45-3.49-.17-7.43,15.13-8.76,38-3.3,91.92,7.84,133.14,17.94,6,1.48,1.63-11.23-.15-13.53-6.3-8.17-35.93-49.76-31.48-55.8C1519.61,641.89,1545.91,675.15,1558.09,686.94Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-9"
          d="M1567,744.6c-6.62-4.35-26.21-6.84-26.9-5.29-1,2.29,21.77,3.64,32,25,2.66,5.58,10.91,20.21,11,27.22C1583.09,795.44,1590.94,760.33,1567,744.6Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-10"
          d="M1853.53,992.26c-3,9.21-7.1,21.42-12.06,35.56-3.32,9.49-7,19.83-10.93,30.75l-1.28,3.57c-.21.58-.43,1.18-.64,1.8s-.43,1.21-.65,1.82c-27.87,77.76-92.14,272.68-152,329.15-.4.39-.84.71-1.25,1a127.6,127.6,0,0,1-22.19,14.94h0c-20.59,10.63-34.53,8.23-44.16,7.76-47.25-2.33-73.47-117.06-58.24-179.34,4-16.22,24.47-53.12,44.33-89.17,14.55-26.35,28.72-52.23,35.87-69.22.06-.17.13-.33.21-.52,4.3-10.26,9.5-23.75,15.07-38.84,4.53-12.28,9.29-25.63,14-39.2,12-34.7,23.71-70.89,30.14-94.4,5.87-21.57,40.54-133,104.69-149.65,12.4-3.24,26.35-15.79,54.39-4.73,18,9.57,14.87,31.09,25.5,68.55C1881,845.52,1870.3,939.7,1853.53,992.26Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-11"
          d="M2122.1,368.51c8,10.95,12.71,16.15,19.31,39.95,5.16,18.66,3.41,47.19,2.63,63.05-1.9,38.58-16.2,85.49-50.16,101.06-53.08,24.34-135.45-4.6-158.24-43.94-4.33-7.48-10.45-30-13.61-74.88-2.13-30.2-1.62-44.2,5.56-59.08,7.75-16.06,19.44-25.23,25.41-29.8C1999.91,328.91,2091.08,326.22,2122.1,368.51Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-8"
          d="M2186.13,758.44c-.36,1.31-.79,2.61-1.28,3.93-21.94,58.44-81.41,87.9-89.68,91.87-18.66,8.94-73.34,35.14-134.06,15-39.66-13.18-68.95-36.48-85.11-59.41.36-1.28.71-2.53,1.08-3.78,13.7-46.63,36.64-83,68.44-108.65,14.35-7.18,21.53-11.37,25.91-17.47a22,22,0,0,0,1.45-2.24,68.54,68.54,0,0,0,3.93-11.06,194.82,194.82,0,0,0,4.78-20.23,135.78,135.78,0,0,0,2-15.93c1.23-19.87-2.6-34.78-5.73-47.68-.12-.42-.23-.83-.31-1.26-2.49-10.26-4.39-19.31-2.73-28.71,9.07-51.31,108.66-66.07,143.12-62.8,1.7.16.52,12.46-1.59,30.7-2.89,25.18-9.66,61.68-11.43,93.24-.34,5.81-.5,11.44-.43,16.81a97,97,0,0,0,5,30.07,62,62,0,0,0,5,11.09,18.55,18.55,0,0,0,2.33,3.09c3.15,3.37,7.85,6.16,13.27,8.68,19.74,14.72,36.56,35.6,50.2,62.41Q2183.3,752.09,2186.13,758.44Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-8"
          d="M1917.23,711.39c11.7-5.91,20.94-10.37,28.29-14-31.8,25.67-54.74,62-68.44,108.65-.37,1.25-.72,2.5-1.08,3.78a95.33,95.33,0,0,1-6.57-10.64c-8.61-16.12-10.3-31.23-4.07-41.51,5.6-9.24,10.3-25.1,47.31-43.92C1914.22,712.91,1915.75,712.14,1917.23,711.39Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-8"
          d="M2186.13,758.44q-2.82-6.35-5.89-12.38c-13.64-26.81-30.46-47.69-50.2-62.41,7.71,3.59,16.88,6.63,25.14,9.94h0c10.08,4.08,18.8,8.59,21.82,15.11,1.2,2.59,9.16,15.33,10.65,31.41h0A50.73,50.73,0,0,1,2186.13,758.44Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-12"
          d="M2114.89,490.32c2.53-2.43,14.06-12.74,28.66-8.94,10.85,2.82,20.56,12.69,20.95,24.57.41,12.76-10.19,20.91-11.94,22.24-13.56,10.43-38.25,11.19-46.48-2.27C2099.46,515.11,2105.84,499,2114.89,490.32Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-12"
          d="M2116.3,520.67c-2.89,25.18-9.66,61.68-11.43,93.24-16.75,6.21-39.9,8.21-64.32,3.16-40.24-8.31-59.06-21.66-62.74-34.33-.12-.42-.23-.83-.31-1.26-5.5-27.54,60.87-87.21,119.63-69.58A78.4,78.4,0,0,1,2116.3,520.67Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-12"
          d="M1936.38,477c-3.36-.29-10.8-.81-18,3.87a26.63,26.63,0,0,0-11.93,22.25c.09,11.54,8,21.68,17.15,24.7,18.62,6.19,43.47-17,40.09-32.78C1961.47,484.76,1947.32,478,1936.38,477Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-8"
          d="M1930.92,400.09c-5,12.25-7.2,26.9-4.44,49.77,3.82,31.6,3.06,36.3,9.84,69.62,7.41,36.42,15,47.5,20.69,54a77.93,77.93,0,0,0,25.59,19.28c19.83,9.27,38,7.39,45,6.56,35.18-4.11,62.58-20.32,71.93-33.71,19-27.26,26.58-69.29,28.52-90,1.3-13.95.36-50.08-21.15-88.78-13.91-25-19.66-33.72-30.51-41.07C2059.2,334.09,1957.64,333.88,1930.92,400.09Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-13"
          d="M2020,501.16a.85.85,0,0,1-.35-.08l-1-.5c-3.51-1.76-7.49-3.75-8.43-8.26-.78-3.74,3.91-56.75,4-57.28a.75.75,0,1,1,1.49.13c-1.22,13.61-4.57,54-4,56.85.79,3.79,4.27,5.54,7.63,7.22l1,.5a.75.75,0,0,1,.32,1A.74.74,0,0,1,2020,501.16Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-2"
          d="M1989.23,513.19c3.43,9.83,8.68,19.85,21.23,21.22s22.93-11.35,25-16C2017.87,523.7,2002.54,521.68,1989.23,513.19Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-11"
          d="M2066.23,382.83c-1.22,4.58,41.21,28.54,44.08,39,18.32,66.56,12.84,79.1,16.21,72.19,6.81-13.94,16-8.63,16.15-17,.42-21.75-5.48-85.26-16.1-94.88C2114.1,370.86,2067.91,376.55,2066.23,382.83Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-11"
          d="M1955.5,380.33c.77,7.56-3.65,9.8-10,17.49-24.78,29.91-13.2,91.3-15.65,90.19-.75-.35-2.31-9.46-4.61-9.84-4.66-.75-11.94-92.52,22.46-104.13C1956.2,371.17,1954.62,371.7,1955.5,380.33Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-13"
          d="M1933.39,337.06c-11,17.07,2.67,39.63,15.46,48.88,21.11,15.27,63.75,15.63,87.76,10,21.41-5,47.6-9.58,70.32-9.11,45.49,1,26,29.81,31.13-14.31,2.79-23.92-34.41-44.88-54.67-52.4-16.2-6-31-3.76-47.85-4.13-15.38-.33-31.56-4.52-46.87-1.47-13,2.59-24.12,10.67-36.8,14.38C1946.51,330.46,1936.46,332.27,1933.39,337.06Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-14"
          d="M2060,460.65c-.44,5.23,1.83,9.76,5.08,10.11s6.25-3.61,6.7-8.84-1.83-9.76-5.08-10.11S2060.45,455.42,2060,460.65Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-14"
          d="M1960.61,460c-.42,5.23,1.87,9.75,5.12,10.08s6.24-3.64,6.66-8.88-1.87-9.74-5.12-10.07S1961,454.77,1960.61,460Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-15"
          d="M2296.66,1758.6H1788.5c3.89-40,7.93-79.52,12.06-117.48,6.91-63.54,14.07-122.84,21.15-173.49,1.71-12.21,3.41-23.93,5.11-35.08q.95-6.27,1.9-12.3c5.87-37.32,11.61-67.51,17-88,3.1-11.69,6.08-20.19,8.93-25,10.09-17.14,24.39-29.26,41.68-37.17a130.89,130.89,0,0,1,36.65-10.26c31.15-4.31,67.3-.21,104.17,9.47q6.21,1.62,12.45,3.46c39.52,11.64,79.33,29.3,114.19,49.5,10.93,6.34,21.37,12.92,31.16,19.65,19,13,35.49,26.58,48.44,39.91,8,8.25,15.22,30.74,21.69,63.69.14.74.29,1.49.43,2.24,5.06,26.3,9.66,59,13.83,96.33q.47,4.13.92,8.32c.33,3.07.66,6.18,1,9.31,3.09,29.65,5.93,61.83,8.54,95.7C2292.32,1689.84,2294.59,1723.81,2296.66,1758.6Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-16"
          d="M2081.84,1455.27c-1.11,10.32-3.54,26-7,45.75-2.34,13.5-5.15,28.94-8.35,46-5.06,26.94-11.05,57.8-17.6,91.12h0c-.3,1.57-.61,3.18-.92,4.86l0,.17c-.3,1.62-.59,3.3-.89,5-4.56,26.59-8.87,64.45-12.94,110.45H1767.35c0-38.7,0-75,.2-108,.25-44.5.68-83.15,1.36-114,.56-25.5,1.28-45.66,2.2-59.4.3-4.62.64-8.51,1-11.64q1.26-11.17,2.51-21.39c4.81-39.31,9.54-68.06,14.07-87.8,4.12-17.91,8.08-28.4,11.79-32.66,1.72-2,3.37-2.61,5-2l.27.1h0a260.79,260.79,0,0,0,27.25,7.73q6.24,1.46,12.79,2.8c17.21,3.54,35.84,6.63,55,10.21,9.33,1.75,18.79,3.62,28.26,5.72a423,423,0,0,1,49.65,13.82,260.7,260.7,0,0,1,33,14.14,196,196,0,0,1,26.75,16.65,161.72,161.72,0,0,1,43.69,48.7C2082.78,1442.82,2082.68,1447.56,2081.84,1455.27Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-16"
          d="M2056.53,1758.6c-3.33-37.83-6.5-74.77-9.46-110.45q-.36-4.45-.73-8.86-.69-8.41-1.37-16.74-2.64-32.33-5-63v-.1c-.27-21.26-.5-44.82-.71-68.08-.13-15-.26-29.9-.38-44-.18-20.75-.34-39.69-.5-54.55-.11-9.67-.22-17.61-.33-23.21-.59-29.35-.9-55.91-.91-79.17q0-11.09.08-21.15c0-1.53,0-3,0-4.54,25.2-6.32,61-10,120.65-10.68,11.62-.13,24.15-.14,37.69,0l3.81,0c29.46.26,63.59,1.07,103.37,2.44,1.63,2.88,6.87,82,11.9,169.15.63,10.84,1.25,21.79,1.86,32.74q.45,8,.89,16c.81,14.72,1.6,29.31,2.34,43.45,1.55,29.37,2.9,56.79,3.9,79.36,1.19,26.56,1.9,46.43,1.86,54.9,0,0,.08,4.19.24,12.11.34,16.57,1,49.43,2.12,94.33Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-17"
          d="M2341.4,1608.19c-42-13.77,4.43-46.89-210.71-92-9.74-2-33.11-8.76-55.83-15.13-18.6-5.23-36.78-10.23-46.76-12.28-9.66-2-16-4.28-25.49-7.29-2.49-.79-5.21-1.63-8.26-2.52-10.19-3-22.37-6.2-38.5-9.36a583.52,583.52,0,0,0-59.91-8c-39.24-3-79.47,6-114.89,16.45l-9.94-.84c.3-4.62.64-8.51,1-11.64,10.07-89.48,19.84-132.1,28.37-141.85,1.72-2,3.37-2.61,5-2l.27.1h0c45.18,15.92,112.85,19.06,172.92,40.28a261.46,261.46,0,0,1,33,14.14,548.61,548.61,0,0,1,20.79-73.13q1.86-5.15,3.86-10.4c2.38-6.25,4.94-12.62,7.68-19.05,153.56-22.84,222.74-27.38,264.76-21.79,3.55,28.41,7.27,63.64,11.06,104.28,2.71,28.94,12.66,120.72,19.53,188.81v.09Q2340.38,1575.73,2341.4,1608.19Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-18"
          d="M2048.86,1646.78a1.49,1.49,0,0,1-1.39-.94c-12.08-29.51-24-48-32.63-61.56-8.34-13-13.85-21.61-14.33-31.43-.39-8.08,1.86-109.74,2.82-153.14l.28-12.72a1.5,1.5,0,0,1,1.54-1.46,1.48,1.48,0,0,1,1.46,1.53l-.28,12.71c-.88,39.65-3.21,145-2.82,152.93.44,9,5.78,17.36,13.86,30,8.73,13.63,20.69,32.28,32.87,62a1.49,1.49,0,0,1-.82,2A1.41,1.41,0,0,1,2048.86,1646.78Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-10"
          d="M2600,1183.5c-4.36,32.47-50.59,37.52-74.47,46.41-18.15,6.76-79.8,29.67-117.17,4.3A53.87,53.87,0,0,1,2404,1231a37.24,37.24,0,0,1-5.66-5.67A32.21,32.21,0,0,1,2395,1220h0c-3-5.81-5.26-13.21-12.59-26.43-.64-1.17-1.35-2.39-2.08-3.67q-1.65-2.88-3.65-6.11c-1.41-2.29-3-4.72-4.64-7.3-4.48-6.83-8-11.7-11.37-16-2.67-3.38-5.21-6.35-8-9.57-1.24-1.42-2.51-2.92-3.87-4.5-3.26-3.78-7-8.19-11.61-14-3.18-4-6.79-8.64-11-14.22-23.32-31.09-35.26-47-41.39-65.59-.16-.44-.3-.9-.44-1.35-5.45-17.37-11.51-25.75-15.73-43.49a178.37,178.37,0,0,1-3.95-26c-4.74-53.75-3.43-62.92-3.32-89.77.12-26.64-41-98.15,20.24-144.3,32.34,9.6,54.58,23.57,66.23,33,20.49,16.55,130.09,168.3,171.66,240.46,12.48,21.63,22.63,39.93,42.16,75.47C2589.93,1148.15,2602.3,1166.45,2600,1183.5Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-16"
          d="M2328.71,1394.35c7,29.55-22.37,33.83-24.74,35.34-32.63,21-150.92,49.4-285.41,12.6-78.91-21.6-203.1-15.61-215.61-28.86-15.11-16-14.77-40.74-14-68,.78-29.16,20-149.3,6.49-265-5.47-46.53-8.84-98.5-7.55-135,2.82-79.79-11.29-137.37-1.84-164.66,7.15-20.63,20.71-23.48,88.38-55.45,17.5-8.27,35-16.21,49.34-22.45l1.81-.78c17.84-7.73,30.48-12.65,31.72-12,5.67,2.75,58,131.73,79.07,134.3,15.44,1.89,52.82-44.12,92.8-140,1.26-3,17.47,3.13,39,12.89,3.58,1.62,7.31,3.34,11.14,5.13,30.51,14.33,66.1,33,79.39,42,41.85,28.21,51.7,4.39,53.92,27.83C2318.31,832.57,2304,1290.49,2328.71,1394.35Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-15"
          d="M2348.83,1146.49c-3.26-3.78-7-8.19-11.61-14-3.18-4-6.79-8.64-11-14.22-23.32-31.09-35.26-47-41.39-65.59-1.6-24.35,26-103.34,21-119.59C2313.52,884.6,2346.17,1089.62,2348.83,1146.49Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-15"
          d="M1841.47,1027.82c-3.32,9.49-7,19.83-10.93,30.75l-1.28,3.57c-.21.58-.43,1.18-.64,1.8s-.43,1.21-.65,1.82c-27.87,77.76-92.14,272.68-152,329.15-.4.39-.84.71-1.25,1a127.6,127.6,0,0,1-22.19,14.94h0c75.82-46.74,94.46-258,157-497.6,35.84-137.27,14-32.36,17.08-11.61C1827.93,910.38,1835.45,971.64,1841.47,1027.82Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-19"
          d="M2168.39,697.24c-18.64,44.12-117.12,183.45-131.81,180.78-18.92-3.43-88-122.87-110.79-175.88,17.84-7.73,30.49-12.65,31.73-12,5.67,2.75,58,131.73,79.07,134.3,15.44,1.89,52.82-44.12,92.8-140C2130.64,681.35,2146.86,687.48,2168.39,697.24Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-10"
          d="M1958.86,690a.13.13,0,0,1,0,.06,1.82,1.82,0,0,0-.07.25c-.09.27-.17.53-.25.81,0,.08,0,.16-.08.25-4.69,15.71-7.18,36.83-8.37,60.16-1.88,36.6-.58,78.63.34,113.73.65,24.87,1.11,46.26.11,59.77-.47,6.44-1.13,16.9-1.92,30.44-3.79,65-10.71,201-15.6,304.33-1.63,34.33-3,65.06-4,88.39-1,22.81-1.55,38.53-1.56,43.6,0,7.74-.78,20.11-2.11,35.88-.91,10.73-2.08,23-3.46,36.55l-.3,3c-8.6,83.56-24.71,210.35-35,291.39H1735c3.12-41,6.26-77.25,9.22-105.93,2.52-24.43,4.39-43,5.35-53.61.45-4.93.7-8.18.74-9.56.27-10.93,7.45-69.14,15.6-143.72,10.25-94.07,18.56-169.34,20.94-265.69.76-30.87.91-63.91.33-100.42-.18-11.39-1.23-34.43-2.71-62.87-4.59-88.49-13.37-229.43-13.37-236,0-7.23,4.08-12.93,12-18.47,16-11.21,47.61-21.81,92.88-43.2,15.45-7.31,31.34-13.63,45-18.51,3.7-1.32,7.24-2.54,10.56-3.64a232.14,232.14,0,0,1,23.35-6.73c2.29-.48,3.66-.6,3.92-.34l0,0A.24.24,0,0,1,1958.86,690Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-19"
          d="M2231.44,1129.87c-41,7.38-104.51,6.66-119.16-7.32,0,0-17.3-186.71-2.66-189,8.39-1.34,54,.54,115.85,6.65C2228.68,1004.88,2228.43,1063,2231.44,1129.87Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-10"
          d="M2368.64,1747.28c-2.73,6.38-9.69,11.32-16.27,11.32H2200.86q-6.84-96.91-16.29-209.15c-.19-2.3-.89-9.88-2-21.43-1.5-16.22-3.74-40.29-6.34-68.61q-.54-5.73-1.08-11.68c-3.51-38.14-7.56-82.58-11.37-125.47-1.14-12.87-2.26-25.61-3.34-38-.58-6.56-1.13-13-1.68-19.37q-.3-3.44-.58-6.81c-.12-1.34-.23-2.67-.34-4-.94-11-1.82-21.56-2.62-31.44-1.77-21.78-3.2-40.36-4.08-53.78-.62-9.53-.95-16.46-.95-20.14,0-5.34,0-10.42-.05-15.29-.15-21.15-.63-38.25-1.15-54.43-1-29.45-2-55.83-1.47-98,.17-12.43.94-28.86,1.83-46.83,2-40.54,4.7-88.93,2.61-116.92-.08-1-.17-2.33-.27-3.91-.82-11.65-2.74-38.07-8.34-71.07-3-17.81-7.1-37.54-12.67-57.9a.69.69,0,0,1,0-.2h0a.39.39,0,0,1,0-.15v0a.67.67,0,0,1,.56-.37h0c2.08-.45,9.35,1.79,19.53,5.63,6.17,2.32,13.41,5.23,21.23,8.49,33.1,13.81,76.49,33.8,92.14,41.71,6.6,3.33,12.33,6.05,17.43,8.41h0c19.48,9,29.72,12.7,43.54,25.23,3.42,3.1-9.39,44.7-7.42,173.14.63,41.13,2.78,91.17,7.46,151.7q.81,10.42,1.78,21.41c5.59,63.34,14.85,139.91,21.14,223,.07.86.13,1.73.2,2.6q.92,12.25,1.73,24.68,1,15.63,1.88,31.5c.06,1,.11,2,.16,3q.91,18.15,1.86,36.59c.08,1.55.15,3.1.23,4.65,0,.73.07,1.46.11,2.19.27,5.37.54,10.77.81,16.17,2.86,57.71,5.65,117.2,8.35,176.83q.22,4.65.43,9.3.28,6.21.56,12.42.57,12.69,1.18,25.38Q2367.06,1717.54,2368.64,1747.28Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-20"
          d="M1950.53,925.06c-4.23,57.65-23.06,437.63-23.11,466.76,0,13.39-2.3,40.66-5.87,75.39-28.88-41.65-31.49-412.59-30.29-437,1.41-28.92,10.08-195.49,22-275.58,3.43-23,5.85-40.6,7.74-54,22.66-10.84,38.33-12,37.86-10.55C1941,747.23,1953.85,880,1950.53,925.06Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-20"
          d="M2203.82,1040c-1.78,16.23.21,397.62-28.63,407.73-4.57-49.59-10-109.84-14.71-163.47-.57-6.56-1.13-13-1.68-19.37-1.28-15-2.48-29.19-3.54-42.25-1.77-21.78-3.2-40.36-4.08-53.78-.62-9.53-.95-16.46-.95-20.14,0-28.51-.56-49.82-1.2-69.72-1-29.45-2-55.83-1.47-98,.55-40.47,7.46-123.35,4.44-163.75-.93-12.56-4-69.67-21.28-132.88-.89-3.26,17.07-2.69,41.35,13.35,2.11,14.38,13.9,43.1,17.21,76.79C2197.15,854.51,2205.47,1024.91,2203.82,1040Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-20"
          d="M2364.47,1662.27c-41,7.38-88.22-2.09-119.06-29.62,0,0-28.62-189.38-14-191.71,8.38-1.33,61.81,16.67,123.69,22.78C2358.34,1528.44,2361.46,1595.4,2364.47,1662.27Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-20"
          d="M1833.34,1620.67c-23.52,21-56.56,31.48-89.11,32,3.69-35.73,6-58.8,6.09-63.17.28-10.93,7.45-69.14,15.6-143.72,42.48-7.63,75-17.83,81.39-16.82C1862,1431.29,1833.34,1620.67,1833.34,1620.67Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-8"
          d="M2354,1398.27c-3.53,1.09-26.67,66-36.74,76.36-5.13,5.25-11.79,16.95-24.77,43-10.92,21.88-16.77,31.29-13.78,34.11,1.61,1.5,4.93.37,7.61-.55,7.44-2.54,11.65-7.41,18.66-15.91,9.92-12,14.62-18.22,23.14-24.84,4.83-3.74,7.28-5.6,8.87-4.83,5.2,2.5,10.42,45.49,11.11,50.75,3.46,26.16,2.18,62.09-2.16,83.15-5.7,27.61-2.64,54.92,9.36,25.1,17.41-43.28,16.32-78.56,16.31-58.5,0,31.65-23.85,87-15.22,89.54s20.13-33,21-36.62c22.07-87.52,9.26-66.16,11.23-66.51s-27.46,116.44-13.11,101.33c29.7-31.28,21.61-111,21.53-111-.25,0-13.56,57.84-8.87,93.27,2.31,17.43,29.19-71.55,24.55-105.41-2.66-19.4-8.33-59.47,3.62-97.68,1.38-4.4-12.36-23.1-22.49-41C2386.82,1419.7,2362.66,1395.6,2354,1398.27Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-15"
          d="M2540.35,1144.63c.35,2.21-19.77,21.43-86.28,51.11-24.46,10.93-33.92,31.47-45.72,38.47a18.52,18.52,0,0,1-3.35,1.58l-6.65-10.5L2395,1220h0c-3-5.81-5.26-13.21-12.59-26.43,22.8-15.07,61.34-36.73,70.5-40C2510.83,1133,2538.15,1130.24,2540.35,1144.63Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-10"
          d="M2322.25,1416.83c.72,3.76,19.27,21.13,46.12,41.85,13,10,32.37,16.37,49.32,26.46,11.34,6.73,45.52-31.14,54.42-44.11.69-1,2.56-3.91,5.34-8.29,22.67-35.58,106-169.5,112.47-194.74,7.16-28,10.14-42.06,10.32-47.19.39-11.76-4.2-32.26-38.85-58.86-15-2-36.87.81-63.54,11.65-40.93,16.64-78,56.25-89.53,74.89-29.08,46.9-52.84,109.94-56.17,116.18-1.61,3-3.21,6.08-4.78,9.23C2333,1372.77,2320.38,1406.77,2322.25,1416.83Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-20"
          d="M2322.25,1416.83c.72,3.76,19.27,21.13,46.12,41.85,13,10,32.37,16.37,49.32,26.46,11.34,6.73,45.52-31.14,54.42-44.11.69-1,2.56-3.91,5.34-8.29-27.11-5-59-12.78-89.06-38.92-24.11-21-31.33-40.12-41-49.92C2333,1372.77,2320.38,1406.77,2322.25,1416.83Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-10"
          d="M1581.07,1410.32c-32.11-15.21-72-90.79-79.32-169.93-4.35-47.05.53-45.51-4.92-89.44-7.89-63.59-12.18-152-8.31-181.66.21-1.65.5-4.77.87-8.93,2.33-25.8,7.95-91.41,19.76-90.67,10.73.67,34.12,4.41,82.08-7.86,21.84-5.6,32.83-8.36,35.59-7,4.51,2.17,14.44,33.34,26,79.08,22.35,88.21,41.3,205.55,44.91,328.87,1.16,39.81,7.07,112-35.31,143.12C1643.42,1419.83,1609.52,1423.8,1581.07,1410.32Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-20"
          d="M1652.86,933.88c-8.17,1.82-23.07,1.31-34,3.53-47.39,9.67-63.17,12.62-99,16-10,.94-22.26,6.26-30.53,7,2.33-25.8,7.95-91.41,19.76-90.67,10.73.67,34.12,4.41,82.08-7.86,21.84-5.6,32.83-8.36,35.59-7C1631.33,857,1641.26,888.14,1652.86,933.88Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-15"
          d="M1690.64,1184.21a2,2,0,0,1-2-1.87c-5.09-75.31-14-132.93-25.76-196.27a2,2,0,0,1,3.93-.73c11.81,63.48,20.72,121.22,25.82,196.73a2,2,0,0,1-1.86,2.13Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-21"
          d="M2270.17,1143.26h-.84c-44.38-1.33-79.61-111.46-83.48-124a2,2,0,0,1,3.82-1.18c1.51,4.9,37.46,119.93,79.77,121.2,7.65.25,14.23-2.67,23.44-17.78a2,2,0,1,1,3.41,2.08C2287.7,1137.65,2280.31,1143.26,2270.17,1143.26Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-21"
          d="M2224.86,1150c-7.76,0-15.14-4.28-24.58-13.9a2,2,0,0,1,2.85-2.8c12.4,12.63,19.47,14,26.86,12,40.93-10.84,49.83-131,50.19-136.14a2,2,0,0,1,4,.28c-.92,13.1-10.24,128.35-53.15,139.72A24.33,24.33,0,0,1,2224.86,1150Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-22"
          d="M2204.05,1138h0a5.71,5.71,0,0,1-8-.53l-3.74-4.28a5.71,5.71,0,0,1,.53-8h0a5.72,5.72,0,0,1,8,.54l3.75,4.27A5.72,5.72,0,0,1,2204.05,1138Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-22"
          d="M1976.81,666.58a68.54,68.54,0,0,1-3.93,11.06,22,22,0,0,1-1.45,2.24,183.62,183.62,0,0,0-25.91,17.47c-31.8,25.67-54.74,62-68.44,108.65-.37,1.25-.72,2.5-1.08,3.78-16,57.8-16.37,141-12.93,164.55a4.64,4.64,0,0,1-3.94,5.27,3.78,3.78,0,0,1-.67,0,4.65,4.65,0,0,1-4.61-4c-3.61-24.71-3.1-113.12,14.3-172.31.41-1.41.84-2.82,1.28-4.22q16.23-52.49,47.8-87.75A191.43,191.43,0,0,1,1976.81,666.58Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-22"
          d="M2225.91,893a4.66,4.66,0,0,1-9.29.67c-3.85-52.89-14.1-98.32-30.49-135.25q-2.82-6.35-5.89-12.38c-13.64-26.81-30.46-47.69-50.2-62.41a130.76,130.76,0,0,0-13.27-8.68,18.55,18.55,0,0,1-2.33-3.09,62,62,0,0,1-5-11.09,144.17,144.17,0,0,1,45.71,32.8h0q18.33,19.29,32.47,46.52h0c.31.56.6,1.13.89,1.71C2209,781.94,2221.53,832.81,2225.91,893Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-22"
          d="M2187.64,1024.79a4.67,4.67,0,0,1-4.43-3.2c-14-42.49-11.89-83.91,5.6-110.79,8.47-13,19.83-20.87,32-22.08a55.52,55.52,0,0,1,43.4,16.09c20.07,20.08,27.68,56,22.6,106.91a4.66,4.66,0,0,1-9.28-.93c4.72-47.27-2.16-81.64-19.91-99.39A45.91,45.91,0,0,0,2221.72,898c-9.29.93-18.2,7.28-25.1,17.89-12.32,18.94-20,55.89-4.56,102.79a4.66,4.66,0,0,1-3,5.89A4.58,4.58,0,0,1,2187.64,1024.79Z"
          transform="translate(-54.5 -51.98)"
        />
        <circle className="cls-wtd-22" cx="1804.39" cy="936.65" r="23.08" />
        <circle className="cls-wtd-23" cx="1804.39" cy="936.65" r="10.08" />
        <path
          className="cls-wtd-22"
          d="M2293.07,1126.32h0a5.71,5.71,0,0,0,7.71-2.34l2.68-5a5.72,5.72,0,0,0-2.35-7.71h0a5.71,5.71,0,0,0-7.71,2.35l-2.68,5A5.71,5.71,0,0,0,2293.07,1126.32Z"
          transform="translate(-54.5 -51.98)"
        />
      </g>
      <g id="icon-woman-texting-doctor-woman-texting">
        <path
          className="cls-wtd-24"
          d="M549.61,844.72c2.94,1.28-12.47,38.48-9.23,39.78,21.33,8.5,47.36,16.81,54,18.47,7.83,2,15.76,3.94,23.84,3.91,19.79-.1,37.76-12.41,50.48-27.58C693.25,850,702,810.31,703,772.06c.67-25.84-7.67-62.66-22.31-84.7-11.88-17.88,2.75-21.41-33.56-32-15.51-4.51-60.62-6.17-97.91,87.42-17.46,43.82-39.05,120.88-38.79,124.85.17,2.61,6.4,6.45,15.43,10.67"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-25"
          d="M800.94,1160.2c0,27.1,10.28,46.9,30.74,86.5,9.41,18.23,16.18,31.33,22.84,40.19,5.16,6.88,10.26,11.22,16.48,13.43,5.71,2,14.2,3.51,94.36-35.74,23.08-11.3,38.52-29.6,32.88-35.75-3.5-3.82-7.69-8.86-12.45-14.89-16.61-21-40.07-54-64.75-88-12.06-16.64-65.06-65.77-98.66-77.21C793.26,1038.77,800.87,1122.06,800.94,1160.2Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-2"
          d="M854.52,1286.89c5.16,6.88,10.26,11.22,16.48,13.43,5.71,2,14.2,3.51,94.36-35.74,23.08-11.3,38.52-29.6,32.88-35.75-3.5-3.82-7.69-8.86-12.45-14.89-2.19,2.35-4.68,4.94-7.56,7.74C959.36,1240,942,1249.51,921,1261,886.07,1280.14,866.29,1280.07,854.52,1286.89Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-26"
          d="M844.54,1231c4.89,37.1,21.82,84.87,50.76,146.55,15.43,32.87,65.61,123.13,103.66,117.24,22.53-3.49,67.2-21.82,67.2-38.61,0-100.08-93.65-253.06-145.12-275.94C910.6,1175.58,892.08,1184.48,844.54,1231Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-27"
          d="M816.63,1419.12c8.69,12.94,13.29,23.5,39.45,58.29,30,39.95,43.62,142.36,43.62,186.08,0,103.17-10.13,197.77-10.13,197.77s-129.39,15.3-184.43,10.25c-29.53-2.71-44.18,3.76-105.8,5-233.7,4.72-252.64-14.6-255.21-23.59-1.43-5,9-34.78,13.58-45.76,65.77-158.7,125.81-330.21,260.93-404.62C728.07,1342.29,774.59,1356.61,816.63,1419.12Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-28"
          d="M593.76,1176.12c16.12,5.57,84.8,24.61,139.63-13.27,26.2-18.1,83.33-105,54-129.18-23.14-19.05-100.08-37.18-110.8-67.92a86.24,86.24,0,0,1-4.84-24c-1.33-25.16,9.58-45.84,21.39-62.41,12.19-17.1,25.33-29.81,26.93-38.52,7.18-39.06-118.79-24.8-139.17,10.7-1.33,2.31-1.16,6.46-.91,12.88.12,2.92.25,6.31.27,10.22.06,12.12-2.92,73.13-12.34,94-12.2,27-77.47,69.22-86.5,83.65C473.55,1064.77,456.42,1128.73,593.76,1176.12Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-26"
          d="M671.71,941.79c-1.33-25.16,9.58-45.84,21.39-62.41-32.3-24.53-95-25-102.34-9.42C586.47,879,622.62,932.88,671.71,941.79Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-26"
          d="M585,788.65c-2.57-2.57-6.71-6.61-13.37-7.67-1.29-.21-11.61-1.67-18.76,6.19a20.24,20.24,0,0,0-3.86,20.8c3,7.56,10,10.53,13.09,11.82,10.5,4.44,21.91,1.66,26.71-7.77C593.4,803.06,589.89,793.51,585,788.65Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-28"
          d="M728.73,696.24c20.06,17.26,29.2,44.33,27,63-1.44,12.11-16.09,92.95-38.81,123.61-24.21,32.68-88.94,48.72-133.51-13.36-23.4-32.61-1.79-116.12,5.23-135.35C619.9,648.33,711,681,728.73,696.24Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-2"
          d="M636.12,842.78a20.78,20.78,0,0,0,1.71,6.87,27.48,27.48,0,0,0,3.53,6,30.23,30.23,0,0,0,11,8.84,25.41,25.41,0,0,0,14.51,2.41,18.14,18.14,0,0,0,7.14-2.6,12.67,12.67,0,0,0,4.88-5.49,32,32,0,0,1-11.75-1.34,70,70,0,0,1-9.66-3.82c-3.21-1.5-6.44-3.23-9.89-5S640.58,844.75,636.12,842.78Z"
          transform="translate(-54.5 -51.98)"
        />
        <ellipse
          className="cls-wtd-14"
          cx="633.23"
          cy="782.66"
          rx="9.66"
          ry="6.01"
          transform="translate(-287.09 1244.41) rotate(-81.71)"
        />
        <path
          className="cls-wtd-14"
          d="M725.93,804.41c-.71,5.29-4,9.26-7.27,8.88s-5.39-5-4.67-10.25,4-9.26,7.27-8.88S726.65,799.13,725.93,804.41Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-18"
          d="M664.75,833.21a1.5,1.5,0,0,1-.67-2.84c1-.52,2-1,2.78-1.35,2.51-1.19,3.89-1.85,5.09-3.07,1.41-1.72,4.89-18.21,6.76-27.06,1-4.83,1.9-9,2.46-11.16a1.5,1.5,0,1,1,2.91.75c-.55,2.1-1.42,6.24-2.43,11-3.89,18.39-5.9,26.88-7.57,28.56s-3.31,2.41-5.93,3.66c-.8.38-1.7.81-2.73,1.33A1.56,1.56,0,0,1,664.75,833.21Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-29"
          d="M713.72,686.58c-6.75,2.54-10.77,6.54-11.44,26.14-.32,9.39-.77,22.52,4.29,36.32,11.68,31.85,49.15,57.72,63.63,51,1.28-.59,6.8-3.5,7.86-19.76C781.57,726.4,730.14,680.42,713.72,686.58Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-29"
          d="M720.73,697.32c3.68,8.83-.63,14.73-7.15,23.58C683.29,762,622.1,762.79,612.06,762.33c-38.6-1.77-49,22.91-55,14-11.58-17.23,26.45-94.36,99.37-103.19C687.13,669.38,713.85,680.83,720.73,697.32Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-25"
          d="M474.5,1133.39c22.65,31.75,55.25,74.29,69.08,133.33,19,81.13,20.73,173.72,20.73,173.72,6.63,8.83,43.31,25.69,88.26,36.25,35.72,8.38,76.66,13.67,111.91,13.64,45.57,0,95.6-12.92,95.6-12.92s-21-22.6-15.54-105.2c.6-8.91,25.85-49.13,29.11-56,2.86-6.07,15.52-24.06,19.12-34.55,7.66-22.32,7.86-50.45-.65-76-13.59-40.75-11.69-38.32-11.83-63.31-.16-27.47-15.36-68.09-17.16-69.34-33.91-23.74-92.78-51.09-127.2-66.26-16.37-7.21-27.21-11.67-27.21-11.67,4.29,7.15,9.29,102.23-3.58,106.52-11.66,3.89-138-117.95-145.12-122.24,0,0-8.95,8.4-22.32,20.19-17.09,15.06-41.4,35.65-63.47,51.3a64.62,64.62,0,0,0-22.16,27.88C446.63,1092.62,457.89,1110.1,474.5,1133.39Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-26"
          d="M972.51,1211.67c1.74-5.38,5-101.51-28.6-144.4-17-21.67-45.42-27.82-55.76-19.3-3.08,2.54-11.29,35.67-13.58,43.6-4.59,15.94,9.07,45.71,15.73,54.33,37.11,48.1,4.65,107.68,21.44,113.67C923.76,1263.86,961,1247.38,972.51,1211.67Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-30"
          d="M474.5,1133.39c22.65,31.75,55.25,74.29,69.08,133.33,19,81.13,20.73,173.72,20.73,173.72,6.63,8.83,26.61,1.35,71.56,11.91-6.29-9.5-.07-48.24-.07-98.41,0-25.74,65.73-40.14,65.77-43.61.06-5.92-58.2,8.75-92.22-31.46-31.46-37.17-58-208.09-63.63-210.89C531.85,1061.08,509.9,1100.9,474.5,1133.39Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-16"
          d="M472.1,1570.13a26.39,26.39,0,0,1-5.44-.49,2,2,0,0,1,.85-3.91c15.93,3.45,83.38-13.52,116.48-76.18a2,2,0,1,1,3.54,1.86c-18.48,35-46.46,54.47-66.67,64.65C501.71,1565.7,483.06,1570.13,472.1,1570.13Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-28"
          d="M1015.4,1274.58c37,69.32,56.24,97.12,68.63,159.42,7,35.28,5.11,51.63-5.72,67.92-14.74,22.17-49.33,18.58-60,15.72-37.2-9.91-50.84-33.14-67.2-77.2-35-94.37-45.26-172.55-61.48-300.25-1.07-8.44,58.06-33.18,62.91-26.45C961.79,1126.6,988.27,1223.71,1015.4,1274.58Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-28"
          d="M448.5,1367.52c13.58,49.33,37.17,107.23,55.05,120.81,32.25,24.52,77.2,14.3,92.22-7.14,9.9-14.15-3.14-51.45-15.1-112.63-2.47-12.6-4.88-26.21-7-40.84q-.64-4.34-1.26-8.81c-.07-.48-.13-1-.2-1.43-9.14-67.83-35.95-97.43-47.89-110.1-33.16-35.13-68.69-38.68-96.51,2.86C412.37,1233.23,428.34,1294.31,448.5,1367.52Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-26"
          d="M574.32,1372.52q3.33-2.14,6.35-4c-2.47-12.6-4.88-26.21-7-40.84a195.23,195.23,0,0,0-62.22,64.1C501.22,1408.72,535.85,1397.37,574.32,1372.52Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-25"
          d="M414.18,1233.84a308.67,308.67,0,0,1,2.36,38.15c0,18.68-1.38,29.26,1.22,29,8.58-.71,24.31-20.73,139.4-18.58,24.36.46,27.75,3.54,26.45-8.58-.68-6.36-1.39-12.33-2.14-18.22-3.93-31.22-8.55-60-12.16-129.76-.7-13.61,10.23-83.73-35-92.22-35.61-6.69-90.92,32.55-107.95,57.9-8.51,12.67-10.49,26.22-12.16,37.89C409.18,1164.49,410.61,1205.24,414.18,1233.84Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-31"
          d="M918.5,1246.56,845.37,1254c-3.74.38-5.82-2.81-4.61-7.08l62.62-220c1.17-4.11,5-7.68,8.59-8.05l73.13-7.44c3.75-.38,5.83,2.82,4.62,7.08l-62.62,220C925.93,1242.62,922.11,1246.19,918.5,1246.56Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-18"
          d="M932.69,1244.91l-73.13,7.44c-3.74.38-5.83-2.82-4.62-7.08l62.63-220c1.17-4.11,5-7.68,8.59-8l73.13-7.43c3.74-.38,5.83,2.82,4.61,7.08l-62.62,220C940.11,1241,936.3,1244.55,932.69,1244.91Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-28"
          d="M840.26,1169.5c20.32-28.26,42.89-68.63,38.16-55-2.86,8.23-9.76,33.64,36.9,24.28,17.76-3.57,51-17.6,61.36-23.12,4.25-2.26,3.62,16.8-9.12,23.6-14.1,7.52-58.31,23.59-50.1,22.39,34.32-5,44.58-16.85,50-12.87,3.2,2.34.38,12.31-5.71,15-22.83,10.12-42.18,13.69-39.32,15,9,4.15,36-9.44,39.32-3.57,1.89,3.35,4.52,11.6-10.85,15.59-11,2.86-31.71,13.26-29.9,13.72,8.58,2.14,31.34-9.26,32.17-4.29,1.43,8.57-5,12.15-13.59,15-4.31,1.44-23.6,15.69-33.17,26.36-21.87,24.4-44.66,23.4-74.77,57.28-5.72,6.43-40.73-37.78-55.76-57.19C764.52,1227,811,1210.24,840.26,1169.5Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-16"
          d="M677.54,1867.42a1.18,1.18,0,0,1-.5-.12,1.69,1.69,0,0,1-.75-2.07c48.79-144.45,48.41-154.51,47.83-169.75-.07-1.83-.15-3.73-.15-6,0-.6-1.74-90.87-2.41-102.59a1.53,1.53,0,0,1,1.26-1.7,1.45,1.45,0,0,1,1.44,1.49c.67,11.75,2.41,102.19,2.41,102.8,0,2.15.07,4,.14,5.81.62,16.2,1,26-48,171.07A1.37,1.37,0,0,1,677.54,1867.42Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-2"
          d="M537.7,999.53c22.32,22.7,46.63,48.44,113.11,101.34,46.59,37.07,63.16,41.8,73.63,35.74,4.77-2.75,12.19-9.66,12.87-95.79.12-14.26-1.23-25.47-1.38-34.09-16.37-7.21-27.21-11.67-27.21-11.67,4.29,7.15,9.29,102.23-3.58,106.52-11.66,3.89-138-117.95-145.12-122.24C560,979.34,551.07,987.74,537.7,999.53Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-2"
          d="M417.76,1301c8.58-.71,24.31-20.73,139.4-18.58,24.36.46,27.75,3.54,26.45-8.58-.68-6.36-1.39-12.33-2.14-18.22-20-2.37-45.31-2.94-77.92-1.08-18.71,1.06-40.55,2.75-67.2,10.72a191.56,191.56,0,0,0-19.81,6.7C416.59,1290.67,415.16,1301.25,417.76,1301Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-28"
          d="M838.11,1178.07c-12,8.58-53.62,44.33-163,102.23-80.53,42.64-144.53,83.17-171.57,122.25-16.92,24.44-11.4,78.8,7.14,91.5,38.61,26.45,110.81-11.43,164.43-46.46C706.2,1427.28,760.76,1370.82,851,1281c5.1-5.08,26.69-16,22.88-25C871.21,1249.71,851.21,1168.69,838.11,1178.07Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-32"
          d="M654,669.67c-1,.58-1.18,1.36-.54,2a6.77,6.77,0,0,0,3.83,1.34c1.76.18,27.83,1.73,29.43,1.37s1.67-1.13,1.62-1.79a5,5,0,0,0-1.46-3.24,10.78,10.78,0,0,0-4.94-2.61,43.23,43.23,0,0,0-12.07-.93c-2.46.09-7.43.35-9.33,1C654.31,668.83,654,669.67,654,669.67Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-30"
          d="M852.86,1333.11c1,.11,10.53.67,11.24-.32,8.07-11.26,19-24.44,22-38a75.57,75.57,0,0,1-31.25,16.2c-4.21,1-8.5,1.71-12.78,2.34-10.79,1.57-21.62,2.91-32.48,3.85C830.54,1329.53,833.93,1331.18,852.86,1333.11Z"
          transform="translate(-54.5 -51.98)"
        />
        <path
          className="cls-wtd-28"
          d="M838.11,1178.07c-12,8.58-53.62,44.33-163,102.23-80.53,42.64-144.53,83.17-171.57,122.25-16.92,24.44-11.4,78.8,7.14,91.5,38.61,26.45,110.81-11.43,164.43-46.46C706.2,1427.28,760.76,1370.82,851,1281c5.1-5.08,26.69-16,22.88-25C871.21,1249.71,851.21,1168.69,838.11,1178.07Z"
          transform="translate(-54.5 -51.98)"
        />
      </g>
    </svg>
  );
}

export default WomanTextingDoctor;
