import styled from "@emotion/styled";
import { Subheadline } from "@medi24-da2c/web-ui";
import { fontMedium } from "@medi24-da2c/web-ui/design-tokens";
import { OptionalMessage } from "components/OptionalMessage";

const displayName = "Section.HeadlineMessage";

const HeadlineStyled = styled(Subheadline.Themed)`
  font-size: ${fontMedium};
`.withComponent("h3");

function HeadlineMessage({ style, ...props }) {
  return (
    <HeadlineStyled style={style}>
      <OptionalMessage {...props} />
    </HeadlineStyled>
  );
}
HeadlineMessage.displayName = displayName;
HeadlineMessage.propTypes = OptionalMessage.propTypes;
HeadlineMessage.defaultProps = OptionalMessage.defaultProps;

export default HeadlineMessage;
