import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

const displayName = "Overview.Wrapper";

const WrapperStyled = styled.div`
  width: 100%;
  background-color: ${(themedProps) =>
    themedProps.theme.landingPageIntroOverview.bgrColor};
`;

function Wrapper(props) {
  const theme = useTheme();
  return <WrapperStyled theme={theme} {...props} />;
}
Wrapper.displayName = displayName;

export default Wrapper;
