import { useTheme } from "@emotion/react";
import { OptionalMessage } from "components/OptionalMessage";

const displayName = "LandingPage.QuickLinkOverview";

export function QuickLinkOverview() {
  const theme = useTheme();
  // TODO(2021-05) should be using @emotion/styled instead of inline styles
  const styleBenefitOverview = {
    fontFamily: theme.fontFamily,
    color: theme.general.textColor,
    marginLeft: 0,
    width: 0,
    fontSize: "1.6rem",
  };
  return (
    <OptionalMessage
      id="landingPage.quickLink.overview"
      style={styleBenefitOverview}
    />
  );
}

QuickLinkOverview.displayName = displayName;
