// HTML which is allowed within translation messages by default.
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import useLanguage, { toMyDocLanguage } from "hooks/useLanguage";
import { getInstance } from "partnerConfigs/singleton";
import { SimpleButton } from "components/SimpleButton";
import { NO_COOKIE_DIALOG } from "constants/switches";
import LargeLink from "components/LargeLink";
import platform from "utils/platform";
import * as URL from "constants/urls";
import Link from "components/Link";

const EMPTY = "";
const LARGE = true;

const styleUpperCase = {
  textTransform: "uppercase",
};

export function checkMessage(id, messages) {
  let hasMessage = messages[id] || EMPTY;
  hasMessage = hasMessage.trim().length;
  return !!hasMessage;
}

export function makeLinkTo(testId, externalUrl, toInternalUrl, target, large) {
  /* istanbul ignore next */
  const LinkTo = large ? LargeLink : Link;
  const internal =
    "function" === typeof toInternalUrl
      ? { onClick: toInternalUrl }
      : { to: toInternalUrl };
  if (/button/.test(testId)) {
    internal.style = {
      float: "right",
      maxWidth: "26rem",
      height: "5rem",
    };
  }
  return (chunks) =>
    /button/.test(testId) ? (
      <SimpleButton
        data-testid={testId}
        theme={getInstance().theme}
        type="submit"
        {...internal}
      >
        {chunks}
      </SimpleButton>
    ) : (
      <LinkTo
        tabIndex="0"
        data-testid={testId}
        theme={getInstance().theme}
        href={externalUrl}
        target={target}
        {...internal}
      >
        {chunks}
      </LinkTo>
    );
}

export function makeLinkToFaqItem(testId, idItem, idSection, idExpand) {
  /* istanbul ignore next */
  let url = idSection ? `${URL.FAQ}/?hs=${idSection}&` : `${URL.FAQ}/?`;
  /* istanbul ignore next */
  url += idExpand ? `ex=${idExpand}` : "";
  url += `#${idItem}`;

  return makeLinkTo(testId, void 0, url, "_blank");
}

export function makeMailTo(testId, large) {
  const LinkTo = large ? LargeLink : Link;
  return (chunks) => {
    const address = Array.isArray(chunks) ? chunks.join("") : chunks;
    const email = address.replace(/\?.*$/, "");
    return (
      <em data-testid={testId}>
        <LinkTo href={`mailto:${address}`}>{email}</LinkTo>
      </em>
    );
  };
}

const color = {
  color: "blue",
};

const withHtml = {
  /* istanbul ignore next */
  partnerCountry: getInstance().config.general.partnerCountry || "None",
  br: <br />,
  site: (chunks) => platform.location().toString(),
  em: (chunks) => <em>{chunks}</em>,
  uc: (chunks) => <span style={styleUpperCase}>{chunks}</span>,
  strong: (chunks) => <strong>{chunks}</strong>,
  color: (chunks) => (
    <strong style={color}>
      <big>
        <big>{chunks}</big>
      </big>
    </strong>
  ),
  linkToFaq: makeLinkTo("link-to-faq-page", void 0, URL.FAQ),
  // linkToFaqLg: makeLinkTo("link-to-faq-page", void 0, URL.FAQ, void 0, LARGE),
  linkToFaqItemSACH: makeLinkToFaqItem(
    "link-to-faq-item-sach",
    "om-faq-sach.head",
    "os-faq-sach",
    "faq-sh"
  ),
  linkToFaqItemSACNWH: makeLinkToFaqItem(
    "link-to-faq-item-sacnwh",
    "om-faq-sacnwh.head",
    "os-faq-sacnwh",
    "faq-sh"
  ),
  linkToFaqItemSWEH: makeLinkToFaqItem(
    "link-to-faq-item-sweh",
    "om-faq-sweh.head",
    "os-faq-sweh",
    "faq-sh"
  ),
  linkToFaqNewTab: makeLinkTo(
    "link-to-faq-page-blank",
    void 0,
    URL.FAQ,
    "_blank"
  ),
  // linkToFaqNewTabLg: makeLinkTo(
  //   "link-to-faq-page-blank",
  //   void 0,
  //   URL.FAQ,
  //   "_blank",
  //   LARGE
  // ),
  linkToTerms: makeLinkTo("link-to-terms-of-use", void 0, URL.TERMS),
  // linkToTermsLg: makeLinkTo("link-to-terms-of-use", void 0, URL.TERMS, void 0, LARGE),
  linkToPrivacy: makeLinkTo("link-to-privacy-notice", void 0, URL.PRIVACY),
  // linkToPrivacyLg: makeLinkTo("link-to-privacy-notice", void 0, URL.PRIVACY, void 0, LARGE),
  // Links which leave the App use href
  linkToMayo: makeLinkTo("link-to-mayo-clinic", URL.MAYO_URL),
  // linkToMayoLg: makeLinkTo("link-to-mayo-clinic", URL.MAYO_URL, void 0, void 0, LARGE),
  linkToHaloDoc: makeLinkTo("link-to-halodoc", URL.HALODOC_FAQ_URL),
  // linkToHaloDocLg: makeLinkTo("link-to-halodoc", URL.HALODOC_FAQ_URL, void 0, void 0, LARGE),
  linkToAEProtData: makeLinkTo(
    "link-to-aepd",
    URL.AE_PROT_DATA_URL,
    void 0,
    "_blank"
  ),
  // linkToAEProtDataLg: makeLinkTo(
  //   "link-to-aepd",
  //   URL.AE_PROT_DATA_URL,
  //   void 0,
  //   "_blank",
  //   LARGE
  // ),
  mailToHelp: makeMailTo("mail-to-help"),
  mailToHelpLg: makeMailTo("mail-to-help", LARGE),
  mailToPrivacy: makeMailTo("mail-to-privacy"),
  // mailToPrivacyLg: makeMailTo("mail-to-privacy", LARGE),
  mailToDataRep: makeMailTo("mail-to-data-rep"),
  // mailToDataRepLg: makeMailTo("mail-to-data-rep", LARGE),
  mailToService: makeMailTo("mail-to-service"),
  // mailToServiceLg: makeMailTo("mail-to-service", LARGE),
};

export function useSearchLinks({ onClickCookieConsent } = {}) {
  const { messages } = useIntl();
  const { search } = useLocation();
  const [language] = useLanguage();
  /* istanbul ignore next */
  const hasCookies = NO_COOKIE_DIALOG
    ? false
    : checkMessage("global.cookieSettings", messages);

  return {
    linkToTermsSearch: makeLinkTo(
      "link-to-terms-of-use",
      void 0,
      `${URL.TERMS}${search}`
    ),
    // linkToTermsSearchLg: makeLinkTo(
    //   "link-to-terms-of-use",
    //   void 0,
    //   `${URL.TERMS}${search}`,
    //   void 0,
    //   LARGE
    // ),
    linkToPrivacySearch: makeLinkTo(
      "link-to-privacy-notice",
      void 0,
      `${URL.PRIVACY}${search}`
    ),
    // linkToPrivacySearchLg: makeLinkTo(
    //   "link-to-privacy-notice",
    //   void 0,
    //   `${URL.PRIVACY}${search}`,
    //   void 0,
    //   LARGE
    // ),
    linkToMyDocMC: makeLinkTo(
      "link-to-mydoc-mc",
      `${URL.MYDOC_MC_FAQ_URL}/?lang=${toMyDocLanguage(language)}`
    ),
    // linkToMyDocMCLg: makeLinkTo(
    //   "link-to-mydoc-mc",
    //   `${URL.MYDOC_MC_FAQ_URL}/?lang=${toMyDocLanguage(language)}`,
    //   void 0,
    //   void 0,
    //   LARGE
    // ),
    linkToCookieConsent: hasCookies
      ? makeLinkTo("link-to-cookie-consent", void 0, onClickCookieConsent)
      : /* istanbul ignore next */
        (chunks) => chunks,
    // linkToCookieConsentLg: hasCookies
    //   ? makeLinkTo("link-to-cookie-consent", void 0, onClickCookieConsent, void 0, LARGE)
    //   : (chunks) => chunks,
    buttonForCookieConsent: hasCookies
      ? makeLinkTo("button-cookie-consent", void 0, onClickCookieConsent)
      : /* istanbul ignore next */
        () => "",
  };
}

export default withHtml;
