import { OptionalTitle } from "components/OptionalMessage";

// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function WomanWithTabletAndPlant() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 200"
      role="img"
      aria-labelledby="icon-woman-with-tablet-and-plant"
    >
      <OptionalTitle
        id="image.womanWithTabletAndPlant"
        titleId="icon-woman-with-tablet-and-plant"
      />
      <path
        fill="#24333b"
        d="M60.321 199.033c-36.644-21.902-46.218-84.515-26.425-100.391 13.122-10.526 15.612-8.604 22.747-16.294 7.137-7.692 2.228-16.839 10.011-28.789 10.769-13.395 17.085-6.816 24.83-15.594C99.228 29.189 103.251 0 132.699 0c18.287 0 32.843 9.546 38.671 32.114 9.042 35.026 54.841 46.576-18.579 105.733-26.35 21.233-23.069 59.737-92.47 61.186z"
      />
      <path
        fill="#37658f"
        d="M231.927 200c-9.043-29.995-18.711-69.838-33.699-76.772-12.18-5.178-24.798-10.926-24.798-10.926s-1.009 37.681-38.217 38.613c-37.207.934-43.151-37.261-43.151-37.261l-24.725 11.374c-18.714 9.72-27.007 51.123-27.007 51.123L45.371 200h186.556z"
      />
      <path
        fill="#f6aa98"
        d="M153.521 103.131v-18.97c5.642-6.712 9.422-16.076 10.131-26.085 1.779-1.299 3.171-3.564 3.601-6.122.291-1.724.107-3.388-.432-4.807-.163.007-.325.015-.497.016-15.858.065-34.883-7.114-47.719-12.955-5.349 8.048-11.963 15.424-18.35 21.648 1.009 1.532 2.385 2.679 3.881 3.142.848 9.43 4.407 18.234 9.66 24.702l.018 19.712-24.399 11.46c.536 13.858 16.727 41.39 43.766 41.39s41.752-29.175 42.291-43.035l-21.951-10.096z"
      />
      <path
        fill="#ea8f81"
        d="M136.364 94.224c-.828.097-1.663.158-2.512.158-7.718 0-14.75-4.15-20.055-10.682l.018 19.712-24.399 11.46c.336 8.661.052 17.952 8.919 19.756l39.042-40.556c-.337.056-.673.112-1.013.152z"
      />
      <path
        fill="#24333b"
        d="M144.104 47.758c-.077-1.207.646-2.298 1.615-2.428.972-.13 1.813.74 1.906 1.95.078 1.203-.653 2.289-1.617 2.428-.972.131-1.819-.74-1.904-1.95zm-20.269 1.401c.964-.14 1.694-1.226 1.616-2.429-.093-1.209-.933-2.08-1.904-1.95-.969.131-1.692 1.222-1.616 2.429.085 1.211.931 2.082 1.904 1.95z"
      />
      <path
        fill="#ea8f81"
        d="M133.19 66.189l-.01-.001a.95.95 0 01-.907-.994l.864-18.26c.022-.525.381-.985.99-.907a.951.951 0 01.911.997l-.865 18.257a.953.953 0 01-.983.908zm-1.886 10.388l12.707-2.45a.947.947 0 00.75-1.122c-.07-.61-.603-.847-1.119-.744l-12.71 2.45a.95.95 0 00-.746 1.121v.01a.95.95 0 001.118.735z"
      />
      <path
        fill="#31547a"
        d="M62.704 200l4.709-52.365-31.759 27.505L48.837 200z"
      />
      <path
        fill="#f6aa98"
        d="M1.271 142.084c.652 3.5 8.437 7.197 9.943 10.263 1.333 2.717 8.722 39.337 10.39 47.653h31.551c-5.196-14.076-39.685-95.148-39.685-95.148s-5.862 1.331-8.074 3.616c-3.372 3.483-5.818 4.606-5.334 12.121.485 7.515.557 17.995 1.209 21.495z"
      />
      <path
        fill="#010101"
        d="M101.19 155.464a7.254 7.254 0 01-5.533 8.64l-58.943 12.919a7.255 7.255 0 01-8.639-5.533L8.523 82.283a7.253 7.253 0 015.532-8.638l58.943-12.921a7.256 7.256 0 018.639 5.533l19.553 89.207z"
      />
      <path
        fill="#c2dde5"
        transform="rotate(-12.366 53.971 114.859)"
        d="M21.895 71.463h64.157v86.773H21.895z"
      />
      <path
        fill="#e8f1f5"
        d="M68.593 163.016a3.968 3.968 0 10-7.751 1.7 3.968 3.968 0 007.751-1.7z"
      />
      <path
        fill="#5f6d7c"
        d="M41.672 110.958l-2.337-10.661c-1.319-6.024 2.507-11.999 8.529-13.32 6.026-1.318 11.999 2.508 13.319 8.53l.727 3.312-4.913 1.079-.727-3.312a6.163 6.163 0 00-7.33-4.695 6.163 6.163 0 00-4.693 7.33l2.337 10.661-4.912 1.076z"
      />
      <path
        fill="#f6aa98"
        d="M13.469 104.852s3.78-.776 8.628-.394c4.847.383 7.478 5.345 7.759 7.16.281 1.815 1.776 11.153 2.017 16.001.242 4.849-4.606 5.818-4.606 5.818l-13.929 3.896.131-32.481z"
      />
      <path
        fill="#ea8f81"
        d="M20.17 135.423l-7.495 2.096s.055 3.411 8.523 2.595l-1.028-4.691z"
      />
      <path
        fill="#b98447"
        d="M39.85 111.032a1.545 1.545 0 00-1.179 1.841l5.773 26.34a1.546 1.546 0 001.841 1.18l23.668-5.188-6.601-29.324-23.502 5.151z"
      />
      <path
        fill="#c99859"
        d="M72.821 132.994l-5.773-26.34a1.545 1.545 0 00-1.841-1.179l-1.855.407 6.601 29.324 1.688-.37a1.546 1.546 0 001.18-1.842z"
      />
      <path
        fill="#010101"
        d="M58.903 126.394l-.84-3.834a3.668 3.668 0 00.713-3.059 3.696 3.696 0 00-7.22 1.581 3.671 3.671 0 001.927 2.48l.841 3.834a2.344 2.344 0 004.579-1.002z"
      />
      <path
        fill="#31547a"
        d="M195.182 166.372S184.433 200 170.659 200h33.815l-9.292-33.628z"
      />
      <path
        fill="#016f79"
        d="M232.697 107.494c15.6 23.442 24.058 45.233 18.891 48.671-5.165 3.437-21.999-12.779-37.598-36.22-15.601-23.442-18.892-48.671-18.892-48.671s21.997 12.779 37.599 36.22zm31.116-86.257s-15.28 25.699-17.89 58.683c-2.612 32.985 1.164 60.192 8.432 60.767 7.271.578 15.282-25.697 17.892-58.683 2.612-32.984-8.434-60.767-8.434-60.767z"
      />
      <path
        fill="#008390"
        d="M289.989 94.438c7.128-26.796 4.587-49.91 4.587-49.91s-21.552 20.722-32.824 51.83a262.373 262.373 0 00-5.259 16.093 247.227 247.227 0 00-2.805-16.851c-6.185-30.714-23.454-53.144-23.454-53.144s-7.242 27.366-1.057 58.08c5.724 28.43 15.145 50.914 21.882 53.022.574 1.923 1.463 3.18 2.695 3.627 2.056.746 4.822-.834 7.985-4.232.107.105.214.21.337.295 5.106 3.527 22.214-12.403 38.209-35.578C316.28 94.495 320 69.325 320 69.325s-15.559 8.694-30.011 25.113z"
      />
      <path
        fill="#24333b"
        d="M227.495 171.591c0 20.173 13.775 28.409 13.775 28.409h33.527s13.775-8.236 13.775-28.409v-12.675h-61.077v12.675z"
      />
      <path
        fill="#b98447"
        d="M286.646 149.492h-57.223a1.925 1.925 0 00-1.927 1.926v7.498h61.077v-7.498a1.927 1.927 0 00-1.927-1.926z"
      />
      <path
        fill="#c2dde5"
        d="M203.048 179.146c0 5.665 4.871 10.272 10.859 10.272h5.868c1.968 3.684 4.568 7.33 8.018 10.581h15.91c14.905-14.047 14.08-35.462 14.08-35.462h-44.069s-.061 1.654.165 4.339c-5.975.015-10.831 4.616-10.831 10.27zm5.458 0c0-2.655 2.422-4.814 5.402-4.814h.704a54.576 54.576 0 002.675 9.629h-3.379c-2.98.001-5.402-2.159-5.402-4.815z"
      />
    </svg>
  );
}

export default WomanWithTabletAndPlant;
