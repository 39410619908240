import styled from "@emotion/styled";

import { zIndexModalOverlay } from "@medi24-da2c/web-ui/design-tokens";

const Wrapper = styled.div`
  position: fixed;
  z-index: ${zIndexModalOverlay};
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;

export default Wrapper;
