import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { useTheme } from "@emotion/react";
import { useFocusRing } from "@react-aria/focus";

import { Dot } from "../Icons";

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  width: 6rem;
  height: 6rem;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  outline: 0;
  transition: color 100ms linear;
  color: ${(themedProps) => {
    // PROPER WAY TO ACCESS THEME in styled.element
    // we use the name themedProps instead of props to allow check-code tool to find the
    // remaining places we need to check in Storybook and fix
    return themedProps.isActive
      ? themedProps.theme.carousel.activeBulletColor
      : themedProps.theme.carousel.bulletColor;
  }};
`;

const displayName = "Bullet";

function Bullet({ slideIndex, currentSlide, goToSlide }) {
  const { isFocusVisible, focusProps } = useFocusRing();
  const { formatMessage } = useIntl();
  const theme = useTheme(); // PROPER WAY TO GET THEME CONTEXT

  function slideLabel(slideIndex) {
    return formatMessage(
      {
        id: "carousel.pagingBulletLabel",
        defaultMessage: "Go to slide {slideIndex}",
      },
      { slideIndex: slideIndex + 1 }
    );
  }

  const label = slideLabel(slideIndex);

  // PROPER WAY TO PASS THEME into a styled component which doesn't useTheme() itself.
  return (
    <Button
      theme={theme}
      onClick={() => goToSlide(slideIndex)}
      isActive={slideIndex === currentSlide}
      aria-label={label}
      title={label}
      data-testid={`carousel-bullet-${slideIndex + 1}`}
      {...focusProps}
      isFocusVisible={isFocusVisible}
    >
      <Dot
        theme={theme}
        isFocusVisible={isFocusVisible}
        variant={theme.carousel.bulletVariant}
        isActive={slideIndex === currentSlide}
      />
    </Button>
  );
}

Bullet.displayName = displayName;

Bullet.propTypes = {
  slideIndex: PropTypes.number.isRequired,
  currentSlide: PropTypes.number.isRequired,
  goToSlide: PropTypes.func.isRequired,
};

export default Bullet;
