import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { spacingSmall, borderRadius } from "@medi24-da2c/web-ui/design-tokens";
import { Plus, Minus } from "../Icons";

const Wrapper = styled.span`
  width: 2.8rem;
  min-width: 2.8rem;
  padding: 0 0.5rem;
  margin-left: ${spacingSmall};
  border-radius: ${borderRadius};
  background-color: ${(themedProps) =>
    themedProps.isFocusVisible
      ? themedProps.theme.general.a11yColor
      : "transparent"};
  transition: background-color 100ms linear;
`;

const displayName = "Icon";

function Icon({ isCollapsed = true, isFocusVisible }) {
  const theme = useTheme();
  return (
    <Wrapper theme={theme} isFocusVisible={isFocusVisible}>
      {isCollapsed ? <Plus /> : <Minus />}
    </Wrapper>
  );
}

Icon.displayName = displayName;

Icon.propTypes = {
  isCollapsed: PropTypes.bool,
  isFocusVisible: PropTypes.bool,
};

export default Icon;
