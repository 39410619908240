import { Routes, Route } from "react-router-dom";

import DevAdminPage from "pages/DevAdminPage";
import useValidActivationCode from "hooks/useValidActivationCode";
import LandingPage from "pages/LandingPage";
import ChooseMessengerPage from "pages/ChooseMessengerPage";
import WrongActivationCodePage from "pages/WrongActivationCodePage";
import WhatsAppGettingStartedPage from "pages/WhatsAppGettingStartedPage";
import TelegramGettingStartedPage from "pages/TelegramGettingStartedPage";
import WebChatGettingStartedPage from "pages/WebChatGettingStartedPage";
import ViberGettingStartedPage from "pages/ViberGettingStartedPage";
import LineGettingStartedPage from "pages/LineGettingStartedPage";
import PrivacyNoticePage from "pages/PrivacyNoticePage";
import WebChatLogoutPage from "pages/WebChatLogoutPage";
import TermsOfUsePage from "pages/TermsOfUsePage";
import PageNotFound from "pages/PageNotFound";
import useViber from "hooks/useViber";
import * as URL from "constants/urls";
import useLine from "hooks/useLine";
import FaqPage from "pages/FaqPage";

// How activation code validation works:
// 1. Activation code is passed in URL query param
// 2. For every route if activationCode query param is provided it is always validated
// 3. When activation code is provided and is not correct WrongActivationCodePage is displayed
// 4. For LandingPage activation code is not required
// 5. For ChooseMessengerPage code is not required, but if it is not passed modal appears to type it in
// 6. For GettingStartedPage code must be provided otherwise WrongActivationCodePage is shown

function AppRoutes() {
  const hasViber = useViber();
  const hasLine = useLine();
  const { hasCode, isValidCode } = useValidActivationCode();

  const devAdmin = process.env.REACT_APP_SHOW_DEV_LINKS ? (
    <Route path={URL.DEV_ADMIN} element={<DevAdminPage />} />
  ) : null;

  // Show Wrong Activation Code page when wrong code is passed in URL
  // If no code is present in URL query then render regular routes
  if (hasCode && !isValidCode) {
    return <WrongActivationCodePage />;
  }

  return (
    <Routes>
      <Route path={URL.HOME} element={<LandingPage />} />
      <Route path={URL.CHOOSE} element={<ChooseMessengerPage />} />
      <Route path={URL.WHATSAPP} element={<WhatsAppGettingStartedPage />} />
      <Route path={URL.TELEGRAM} element={<TelegramGettingStartedPage />} />
      {hasViber && (
        <Route path={URL.VIBER} element={<ViberGettingStartedPage />} />
      )}
      {hasLine && (
        <Route path={URL.LINE} element={<LineGettingStartedPage />} />
      )}
      <Route path={URL.WEBCHAT} element={<WebChatGettingStartedPage />} />
      <Route path={URL.PRIVACY} element={<PrivacyNoticePage />} />
      <Route path={URL.TERMS} element={<TermsOfUsePage />} />
      <Route path={URL.FAQ} element={<FaqPage />} />
      <Route path={URL.LOGOUT} element={<WebChatLogoutPage />} />
      {devAdmin}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default AppRoutes;
