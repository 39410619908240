import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";
import useNavigate from "hooks/useNavigate";
import MessengerIcon from "./MessengerIcon";
import { MessageButton } from "./Button";
import Subheadline from "./Subheadline";
import Wrapper from "./Wrapper";

const displayName = "Messenger";

function Messenger({ name, id, icon, redirectUrl, ...props }) {
  const theme = useTheme();
  const navigate = useNavigate();
  function handleClick() {
    navigate.redirectWithSearch(redirectUrl);
  }

  return (
    <Wrapper theme={theme}>
      <Subheadline theme={theme}>{name}</Subheadline>
      <MessengerIcon>{icon}</MessengerIcon>
      <MessageButton
        theme={theme}
        id={id}
        name={name}
        onClick={handleClick}
        {...props}
      />
    </Wrapper>
  );
}

Messenger.displayName = displayName;
Messenger.defaultProps = { id: "chooseMessengerPage.messengerButton" };
Messenger.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};

export default Messenger;
