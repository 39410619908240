function isValidBinOnlyForm(values, binOnlyRegex = /^[0-9]{10}$/g) {
  let result;
  if ("function" === typeof binOnlyRegex) {
    result = binOnlyRegex(values.userKey);
  } else {
    result = binOnlyRegex.test(values.userKey);
  }
  // window.console.warn(
  // `isValidBinOnlyForm(${binOnlyRegex}) = ${result}`,
  // values
  // );
  return result;
}

export function hasLetterAndNumber(text = "", length = 8) {
  // user types 8+ characters at least one letter and number before we make network request
  if (text.length < length) {
    return false;
  }
  if (!/[0-9]/.test(text)) {
    return false;
  }
  return /[a-z]/i.test(text);
}

export default isValidBinOnlyForm;
