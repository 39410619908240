import {
  theme as themeBase,
  colorWhite, // #ffffff
  colorSignal, // #e83363
  colorAnthracite, // #4b4b4d
  colorAnthraciteMedium, // #b5b5b5
} from "@medi24-da2c/web-ui/default-theme/index.js";
import BGR_IMAGE_MOBILE from "./IntroConversationBgrMobile.jpg";
import BGR_IMAGE from "./IntroConversationBgr.jpg";
import LOGO from "./Logotype";

let bgrImageMobile;
let bgrImage;
let logo;

if (
  "undefined" !== typeof process &&
  process.env.NODE_ENV !== "test" &&
  process.env.NODE_ENV !== "production"
) {
  // when running under node, but not unit tests
  bgrImageMobile = "./IntroConversationBgrMobile.jpg";
  bgrImage = "./IntroConversationBgr.jpg";
  logo = "./Logotype.js";
} else {
  bgrImageMobile = BGR_IMAGE_MOBILE;
  bgrImage = BGR_IMAGE;
  logo = LOGO;
}

const colorLightTeal = "#f5f3ed";
const colorDimmedWhite = "#efefef";
const outlineBulletVariant = "DOT_SOLID";

const theme = {
  themeName: "medi24-pink",
  ...themeBase,
  footer: {
    bgrColor: colorWhite,
    textColor: colorAnthracite,
    linkColor: colorSignal,
  },
  languagePicker: {
    textColor: colorAnthracite,
    selectedColor: colorSignal,
  },
  carousel: {
    bulletVariant: outlineBulletVariant,
    bulletColor: colorAnthraciteMedium,
    activeBulletColor: colorSignal,
  },
  modal: {
    bgrColor: colorWhite,
    closeButtonColor: colorAnthraciteMedium,
  },
  landingPageGeneralInfo: {
    bgrImage: bgrImage,
    bgrImageMobile: bgrImageMobile,
    bgrColor: colorWhite,
    headlineColor: colorSignal,
    textColor: colorAnthracite,
  },
  landingPageIntroOverview: {
    bgrColor: colorDimmedWhite,
  },
  landingPageGetStarted: {
    bgrColor: colorWhite,
    textColor: colorAnthracite,
  },
};

theme.header.logo = logo;
theme.general.highlightBgrColor = colorLightTeal;
theme.general.manifestThemeColor = colorSignal;
theme.general.manifestBgrColor = colorWhite;

export default theme;
