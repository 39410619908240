import baseConfig from "../mc-asia/config";
import thInherited from "./translations/th.inherit.json";
import thOverrides from "./translations/th.json";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh mc-asia subpartner mc-core-th 8 Thailand digitalhealthxtra th allianz mydoc MCCORETH th en

// DEVTEST Test Thailand with Mastercard: 2020202025
const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHXTRA_TH_8",
    partnerCountry: "Thailand",
    subPartnerCode: "MCCORETH",
  },
  features: {
    ...baseConfig.features,
    DHA: true,
    MyDoc: true,
    QuickSummary: true,
    showLineApp: true,
  },
  languages: "th en".split(" "),
  messages: {
    th: { ...thInherited, ...thOverrides },
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
