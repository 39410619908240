import PropTypes from "prop-types";
import ConsentFormPicker from "components/ConsentForm/ConsentFormPicker";
import useLanguage, { toChatBotLanguage } from "hooks/useLanguage";
import * as URL from "constants/urls";
import GettingStartedPage from "../GettingStartedPage";

const displayName = "LineGettingStartedPage";

function LineGettingStartedPage(props) {
  const [selectedLanguage] = useLanguage();

  return (
    <GettingStartedPage {...props} messengerName="LINE">
      {({ activationCode }) => (
        <ConsentFormPicker
          chat="LINE"
          activationCode={activationCode}
          messengerLink={`${URL.LINE_URL()}?AC-${activationCode}_Language-${toChatBotLanguage(
            selectedLanguage
          )}_Platform-Line`}
        />
      )}
    </GettingStartedPage>
  );
}
LineGettingStartedPage.displayName = displayName;
LineGettingStartedPage.propTypes = {
  forceValid: PropTypes.bool,
};

export default LineGettingStartedPage;
