import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";

const DOT_SOLID = "DOT_SOLID";
const DOT_OUTLINE = "DOT_OUTLINE";

const displayName = "Dot";

// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function Dot({ isFocusVisible, variant = DOT_SOLID, isActive }) {
  const theme = useTheme();

  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      role="img"
    >
      {isFocusVisible && (
        <circle cx="13" cy="13" r="13" fill={theme.general.a11yColor} />
      )}
      {variant === DOT_SOLID && (
        <circle cx="13" cy="13" r="8" fill="currentColor" />
      )}
      {variant === DOT_OUTLINE && (
        <circle
          cx="13"
          cy="13"
          r="8"
          stroke="currentColor"
          strokeWidth="3"
          fill={isActive ? "currentColor" : "transparent"}
        />
      )}
    </svg>
  );
}

Dot.displayName = displayName;

Dot.propTypes = {
  isFocusVisible: PropTypes.bool,
  variant: PropTypes.oneOf([DOT_SOLID, DOT_OUTLINE]),
  isActive: PropTypes.bool,
};

export default Dot;
