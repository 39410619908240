import styled from "@emotion/styled";

import Modal from "components/Modal";

const CustomContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default CustomContent;
