import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { spacingLarge } from "@medi24-da2c/web-ui/design-tokens";

const displayName = "CollapsibleSection.Content";

const Content = styled.div`
  display: ${(props) => (props.isCollapsed ? "none" : "block")};
  margin-bottom: ${spacingLarge};
`;
Content.displayName = displayName;
Content.propTypes = {
  isCollapsed: PropTypes.bool,
};
export default Content;
