import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Subheadline } from "@medi24-da2c/web-ui";
import { OptionalMessage } from "../OptionalMessage";

const displayName = "ModalBase.TitleMessage";

const Title = styled(Subheadline.Themed)`
  margin: 0;
  flex: 1;
  align-self: center;
`;

export function TitleMessage({ id, titleId, toolTip, toolTipId, values }) {
  return (
    <Title id={id}>
      <OptionalMessage
        id={titleId}
        values={values}
        toolTipId={toolTipId}
        toolTip={toolTip}
      />
    </Title>
  );
}
TitleMessage.displayName = displayName;
TitleMessage.propTypes = {
  ...OptionalMessage.propTypes,
  titleId: PropTypes.string.isRequired,
};

export default Title;
