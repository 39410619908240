import styled from "@emotion/styled";

import { Text as TextBase } from "@medi24-da2c/web-ui";
const Text = styled(TextBase.Themed)`
  &:last-of-type:not(:first-of-type) {
    margin-bottom: 0;
  }

  &:first-of-type {
    margin-top: 0;
  }
`;

export default Text;
