import styled from "@emotion/styled";
import { Content as ContentBase } from "@medi24-da2c/web-ui";

const displayName = "QuickLinksContent";
const displayName2 = "MobileQuickLinksContent";
const displayName3 = "DesktopQuickLinksContent";

export const QuickLinksContent = styled(ContentBase)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top: 3.5rem;
`;
QuickLinksContent.displayName = displayName;

// TODO(2021-05) should be using @emotion/styled instead of inline styles
const styleSinglePanel = {
  width: "100%",
  padding: 0,
};
const stylePanelLeft = {
  margin: "0 -4rem",
  paddingBottom: 0,
};

export function MobileQuickLinksContent(props) {
  return (
    <QuickLinksContent
      data-testid={props["data-testid"]}
      style={styleSinglePanel}
    >
      {props.children}
    </QuickLinksContent>
  );
}
MobileQuickLinksContent.displayName = displayName2;

export function DesktopQuickLinksContent(props) {
  return (
    <QuickLinksContent
      data-testid={props["data-testid"]}
      style={stylePanelLeft}
    >
      {props.children}
    </QuickLinksContent>
  );
}
DesktopQuickLinksContent.displayName = displayName3;
