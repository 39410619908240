import styled from "@emotion/styled";

import { spacingMedium } from "@medi24-da2c/web-ui/design-tokens";
import { TABLET_MEDIA } from "@medi24-da2c/web-ui/media";

const ButtonAndLoader = styled.div`
  position: relative;
  padding: ${spacingMedium};
  width: 100%;

  @media ${TABLET_MEDIA} {
    max-width: 36rem;
  }
`;

export default ButtonAndLoader;
