import PropTypes from "prop-types";
import { useMessageList, useMessageListLookup } from "hooks/useOptionalMessage";
import ImageCard from "components/Carousel/ImageCard";
import Overview from "components/Overview";
import namedImages from "./NamedImages";

const displayName = "ImageCarousel";

function ImageCarousel({ id, cards, ...props }) {
  const imageIdForCard = {};
  const cardsValid = useMessageList(cards);
  const imageIds = cardsValid.map(function mapCardIdToImageId(id) {
    const imageId = id.replace(/^(.+slide\d+)\.headline/, "system.$1.image");
    imageIdForCard[id] = imageId;
    return imageId;
  });
  const imageNamesMap = useMessageListLookup(imageIds);
  return (
    <Overview id={id} {...props}>
      {cardsValid.map(function renderCard(idCard) {
        return (
          <ImageCard key={idCard} id={idCard}>
            {namedImages[imageNamesMap[imageIdForCard[idCard]]]}
          </ImageCard>
        );
      })}
    </Overview>
  );
}
ImageCarousel.displayName = displayName;
ImageCarousel.propTypes = {
  id: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ImageCarousel;
