const colorWhite = "#ffffff";
const colorGray = "#4b4b4d";
const colorMediumGray = "#b5b5b5";
const colorLightGray = "#d9d9d9";
const vividGray = "#f5f5f5";
const colorBlue = "#007ab3";
const colorDarkBlue = "#006192";
const colorTransparentBlue = "rgba(0, 122, 179, 0.3)"; // #007ab3
const colorLightTeal = "#f5f3ed";
const colorYellow = "#fdd25c";
const colorRed = "#dc3149";
const colorLightRed = "#eeccd5";

const borderRadiusRegular = "0.6rem";
const textTransformUppercase = "uppercase";
const solidBulletVariant = "DOT_SOLID";

const theme = {
  themeName: "allianz-blue",
  general: {
    manifestThemeColor: colorDarkBlue,
    manifestBgrColor: colorWhite,
    bgrColor: colorWhite,
    headlineColor: colorGray,
    textColor: colorGray,
    linkColor: colorDarkBlue,
    borderColor: colorLightGray,
    errorColor: colorRed,
    errorBgrColor: colorLightRed,
    a11yColor: colorYellow,
    selectionColor: colorTransparentBlue,
    highlightBgrColor: colorLightTeal,
  },
  button: {
    bgrColor: colorBlue,
    textColor: colorWhite,
    textTransform: textTransformUppercase,
    borderRadius: borderRadiusRegular,
    nextStepArrowColor: colorBlue,
  },
  header: {
    mainBgrColor: colorWhite,
    decorBgrColor: colorWhite,
  },
  footer: {
    bgrColor: vividGray,
    textColor: colorGray,
    linkColor: colorDarkBlue,
  },
  languagePicker: {
    textColor: colorGray,
    selectedColor: colorBlue,
  },
  carousel: {
    bulletVariant: solidBulletVariant,
    bulletColor: colorMediumGray,
    activeBulletColor: colorBlue,
  },
  form: {
    inputTextColor: colorGray,
    inputBorderColor: colorGray,
    neutralColor: colorLightGray,
    checkboxBgrColor: colorBlue,
    checkboxTickColor: colorWhite,
  },
  modal: {
    bgrColor: colorWhite,
    closeButtonColor: colorMediumGray,
  },
  landingPageGeneralInfo: {
    bgrColor: colorWhite,
    headlineColor: colorGray,
    textColor: colorGray,
  },
  landingPageIntroOverview: {
    bgrColor: colorLightTeal,
  },
  landingPageGetStarted: {
    bgrColor: colorLightTeal,
    textColor: colorGray,
  },
};

export default theme;
