import baseConfig from "../mc-core-sg/config";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh mc-core-sg subpartner mc-hsbc-sg 22 Singapore digitalhealthxtra hsbc-sg allianz mydoc MCHSBCSG en

// DEVTEST Test "MCHSBCSG" with BIN "7070707070"
const config = {
  ...baseConfig,
  general: {
    ...baseConfig.general,
    partnerOverride: "DIGITALHEALTHXTRA_HSBC_SG_22",
    partnerGroup: "hsbc-mastercard",
    partnerCountry: "Singapore",
    subPartnerCode: "MCHSBCSG",
  },
  languages: "en".split(" "),
  messages: {
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
