import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Bullet from "./Bullet";

const displayName = "PagingBullets";

const BulletsWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
`;

const SingleBulletWrapper = styled.li`
  list-style: none;
`;

function PagingBullets({ goToSlide, slideCount, currentSlide }) {
  const slides = [...Array(slideCount).keys()];

  return (
    <BulletsWrapper data-testid="carousel-controls">
      {slides.map((slideIndex) => (
        <SingleBulletWrapper key={`slide-${slideIndex}`}>
          <Bullet
            slideIndex={slideIndex}
            currentSlide={currentSlide}
            goToSlide={goToSlide}
          />
        </SingleBulletWrapper>
      ))}
    </BulletsWrapper>
  );
}

BulletsWrapper.displayName = displayName;

BulletsWrapper.propTypes = {
  goToSlide: PropTypes.func,
  slideCount: PropTypes.number,
  currentSlide: PropTypes.number,
};

export default PagingBullets;
