import { OptionalTitle } from "components/OptionalMessage";

// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function WomanWorkingInHomeOffice() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 200"
      role="img"
      aria-labelledby="icon-woman-working-in-home-office"
    >
      <OptionalTitle
        id="image.womanWorkingInHomeOffice"
        titleId="icon-woman-working-in-home-office"
      />
      <path
        fill="#6bbfc3"
        d="M82.415 56.92c-5.381-4.83-12.2-9.372-10.338-17.466 1.706-7.403 5.188-13.369 2.032-21.102C71.476 11.891 64.875 5.75 58.77 4.916c-4.532-.623-8.784 1.68-10.927 8.959-2.353 8.018.092 16.137-1.638 24.201-1.8 8.358-10.993 12.841-12.388 21.065-1.804 10.661 18.946 13.766 6.614 25.469-12.424 11.781-5.84 20.691 5.251 30.153 3.57 3.049 8.243 5.126 12.943 5.996 3.903.725 7.821.62 11.149-.447 7.341-2.351 14.604-5.651 16.157-12.826 1.503-6.963-4.008-11.829-8.208-16.634-13.185-15.075 20.038-20.154 4.692-33.932z"
      />
      <path
        fill="#c99859"
        d="M52.349 125.232h35.559l-1.751 11.881H35.865l-1.753-11.881z"
      />
      <path
        fill="#b98447"
        d="M84.535 139.146h-47.05l4.94 33.638.02.017c.307 1.378 1.599 2.417 3.145 2.417l.032-.002.009.002h30.753l.006-.002.032.002c1.622 0 2.953-1.146 3.171-2.623l.023-.019 4.919-33.43z"
      />
      <path
        fill="#6bbfc3"
        d="M316.606 193.029s-28.071-22.81-28.231-22.954a3.625 3.625 0 00-2.455-.95H34.049c-.944 0-1.808.358-2.457.95-.156.144-28.202 22.954-28.202 22.954h313.216z"
      />
      <path
        fill="#a6d7d9"
        d="M314.268 192.238H5.736a3.88 3.88 0 100 7.762h308.532a3.876 3.876 0 003.877-3.879 3.877 3.877 0 00-3.877-3.883z"
      />
      <path
        fill="#19262b"
        d="M132.568 152.215s-51.006.379-60.195-30.466c-9.187-30.845 14.98-37.327 23.796-42.802 5.415-3.362 32.833-16.794 28.33-32.324C114.511 12.169 139.96 0 155.507 0c27.201 0 48.377 47.633-2.757 72.939-43.031 21.3-20.182 79.276-20.182 79.276z"
      />
      <path
        fill="#846a9e"
        d="M238.004 155.574s-7.524-19.775-13.744-35.216c-12.034-29.877-26.922-40.081-26.922-40.081-5.535-4.253-12.079-6.986-15.96-8.072l-43.982 1.556c-7.827 2.24-31.614 14.152-44.419 42.855-10.571 23.693-14.474 32.537-14.474 32.537l30.136-9.316-3.231 29.288h96.021l.532-22.631 4.908-.15 4.042-2.49-1.199-2.737 28.292 14.457z"
      />
      <path
        fill="#f6aa98"
        d="M171.237 70.219l-.452-4.921c14.559-11.068 4.454-43.548 4.454-43.548l-30.833 9.146-1.304 6.181s-2.642-2.92-4.806-2.962c-4.835-.093-1.262 13.241 2.525 12.79 2.703 5.862 3.262 5.874 3.262 5.874l.323 19.208a130.247 130.247 0 00-7.01 1.774s6.731 12.335 23.373 12.335c16.643 0 20.387-13.896 20.387-13.896a69.995 69.995 0 00-9.919-1.981z"
      />
      <path
        fill="#ea8f81"
        d="M170.785 65.298l.652 7.111s-13.292 1.999-22.202-14.114c15.654 13.486 21.55 7.003 21.55 7.003z"
      />
      <path
        fill="#19262b"
        d="M171.928 36.502c-.007-.942.52-1.705 1.171-1.703.651-.013 1.179.751 1.188 1.699 0 .94-.526 1.707-1.177 1.707-.653 0-1.182-.758-1.182-1.703zM158.119 37.203c-.009-.939.508-1.711 1.155-1.719.652-.008 1.187.748 1.207 1.691.013.938-.506 1.708-1.156 1.722-.648.011-1.185-.745-1.206-1.694z"
      />
      <path
        fill="#ea8f81"
        d="M168.705 48.637h-.006a.623.623 0 01-.722-.515l-1.939-11.911c-.053-.338.115-.688.519-.718a.628.628 0 01.725.517l1.934 11.908a.626.626 0 01-.511.719zM165.51 53.479c-1.633-.147-3.463-.853-5.032-2.791a.625.625 0 01.093-.882.627.627 0 01.884.091c3.307 4.077 7.822 1.719 8.013 1.612a.635.635 0 01.854.261.63.63 0 01-.257.853c-.032.018-2.073 1.083-4.555.856z"
      />
      <path
        fill="#765f8d"
        d="M128.49 109.391c10.719 0-7.908 60.704 45.927 59.734 53.835-.971-69.008 0-69.008 0l23.081-59.734z"
      />
      <path fill="#fff" d="M200.897 188.118h-84.955l11.649-14.083h61.656z" />
      <path
        fill="#ea8f81"
        d="M163.979 136.633s-1.007 3.313 6.3 2.79c7.308-.523 14.615-1.367 14.615-1.367l-2.268-7.834-18.647 6.411zM142.241 136.946s1.008 3.314-6.3 2.791c-7.307-.523-14.615-1.368-14.615-1.368l2.268-7.833 18.647 6.41z"
      />
      <path
        fill="#19262b"
        d="M173.009 136.568a.97.97 0 01-.971.97h-47.492a.971.971 0 01-.971-.97v-2.804c0-.536.435-.971.971-.971h47.492c.536 0 .971.435.971.971v2.804z"
      />
      <path
        fill="#535f6b"
        d="M283.444 188.048h-66.758c-2.267 0-4.445-.398-4.852-.887l-9.409-11.295c-.407-.487 1.11-.886 3.378-.886h66.758c2.267 0 4.443.399 4.849.886l9.412 11.295c.403.489-1.107.887-3.378.887z"
      />
      <path
        fill="#765f8d"
        d="M185.245 135.804c5.238-8.854 10.861-6.999 10.861-6.999s4.793 8.642 1.29 15.1c-3.503 6.459-12.83 5.842-12.83 5.842s-3.918-6.17.679-13.943z"
      />
      <path
        fill="#f6aa98"
        d="M180.123 137.93l9.199 10.196s12.626-.507 9.203-11.824c-7.682-7.659-9.061-9.353-9.061-9.353l-9.341 10.981z"
      />
      <path
        fill="#846a9e"
        d="M184.566 149.747s18.231-3.219 11.54-20.942c30.248 14.172 38.548 19.334 38.548 19.334s8.626 15.366 3.579 20.985c-5.047 5.618-15.104 4.534-18.93 2.144-2.993-1.871-34.737-21.521-34.737-21.521z"
      />
      <path
        fill="#f6aa98"
        d="M161.651 131.214c1.151 4.02 11.087 9.724 25.702 9.2 14.615-.522 6.31-4.572 6.31-4.572s-2.145-14.74-8.133-15.425c-5.988-.684-25.643 4.639-23.879 10.797z"
      />
      <path
        fill="#846a9e"
        d="M121.162 136.283c-4.433-7.494-9.192-5.924-9.192-5.924s-4.056 7.314-1.092 12.78c2.965 5.466 10.858 4.944 10.858 4.944s3.316-5.222-.574-11.8z"
      />
      <path
        fill="#f6aa98"
        d="M127.143 137.887l-9.198 10.196s-10.767-.968-7.345-12.285l7.202-8.892 9.341 10.981z"
      />
      <path
        fill="#846a9e"
        d="M121.736 148.083s-15.953 0-9.766-17.724c-27.967 14.172-32.811 17.533-32.811 17.533s-8.76 15.903-4.094 21.521c4.666 5.618 14.291 6.123 17.393 5.033 8.133-2.854 29.278-26.363 29.278-26.363z"
      />
      <path
        fill="#f6aa98"
        d="M145.616 131.17c-1.151 4.02-11.087 9.724-25.702 9.201-14.616-.522-6.31-4.573-6.31-4.573s2.144-14.74 8.132-15.424c5.988-.685 25.643 4.639 23.88 10.796z"
      />
      <path
        fill="#5f6d7c"
        d="M210.899 184.001l9.41-52.439c.406-2.27 2.582-4.116 4.849-4.116h79.37c2.268 0 3.786 1.846 3.379 4.116l-9.409 52.439c-.406 2.269-2.585 4.117-4.853 4.117h-79.37c-2.269 0-3.781-1.848-3.376-4.117z"
      />
      <path
        fill="#6bbfc3"
        d="M262.449 161.697c-3.151 2.16-6.646 2.166-7.802 0-1.165-2.146.45-5.641 3.604-7.798"
      />
      <path
        fill="#4e3c78"
        d="M258.251 153.899c3.152-2.158 6.642-2.158 7.805 0 1.163 2.157-.456 5.652-3.606 7.798"
      />
      <path
        d="M245.661 24.641c-14.122 12.675-15.301 34.414-2.626 48.536 12.684 14.128 34.42 15.297 48.543 2.624 14.132-12.684 15.303-34.421 2.628-48.547-12.679-14.126-34.415-15.299-48.545-2.613z"
        fill="#e6f2f6"
      />
      <path
        fill="#6bbfc3"
        d="M295.773 25.839c13.439 14.972 12.192 38.09-2.783 51.532-14.967 13.437-38.084 12.185-51.521-2.789-13.439-14.968-12.192-38.078 2.781-51.515 14.974-13.441 38.086-12.196 51.523 2.772zm-51.169 45.933c11.882 13.24 32.323 14.337 45.564 2.456 13.241-11.888 14.346-32.327 2.464-45.567-11.884-13.239-32.323-14.339-45.562-2.45-13.241 11.884-14.353 32.322-2.466 45.561z"
      />
      <path
        fill="#6bbfc3"
        d="M289.152 31.402c-.007-.004-.009-.007-.014-.017a1.41 1.41 0 00-1.985-.081L269.18 47.889a2.382 2.382 0 00-2.057.472l-16.067-4.392a1.392 1.392 0 00-1.719.983 1.4 1.4 0 00.983 1.728l16.083 4.401c.102.259.243.508.439.727a2.394 2.394 0 003.371.183c.574-.515.831-1.24.781-1.95l18.064-16.669a1.408 1.408 0 00.094-1.97z"
      />
    </svg>
  );
}

export default WomanWorkingInHomeOffice;
