import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { TABLET_MEDIA } from "@medi24-da2c/web-ui/media";
import { getInstance } from "partnerConfigs/singleton";

const displayName = "EmmaConversation.Wrapper";

const WrapperStyled = styled.section`
  width: 100%;
  height: 26rem;
  background-color: ${(themedProps) => {
    return themedProps.theme.general.bgrColor;
  }};
  background-image: url(${getInstance().IntroConversationBgrMobile});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${TABLET_MEDIA} {
    height: 40rem;
    background-image: url(${getInstance().IntroConversationBgr});
  }
`;

function Wrapper(props) {
  const theme = useTheme();
  return <WrapperStyled theme={theme} {...props} />;
}
Wrapper.displayName = displayName;

export default Wrapper;
