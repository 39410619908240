// Eligibility check for activation code: valid email
import { useState } from "react";
import PropTypes from "prop-types";
import { FormInput } from "@medi24-da2c/web-ui";
import { useOptionalMessage } from "hooks/useOptionalMessage";
import GenerateCodeForm from "../GenerateCodeForm";
import useActivationCode from "./useActivationCode";
import isValid from "./isValidEmailOnlyForm";

const displayName = "EmailOnlyStep";

const idLabel = "generateActivationCodeForm.email.label";
const idPlaceholder = "generateActivationCodeForm.email.placeholder";
const idLegalInfo = "generateActivationCodeForm.email.legalInfo";

function EmailOnlyStep(props) {
  const [loading, setLoading] = useState(false);
  const [customerEmail, setCustomerEmail] = useState("");
  const { willGenerateCode } = useActivationCode(
    {
      ...props,
      loading,
      onSetLoading: setLoading,
    },
    isValid
  );

  function handleSubmit(event) {
    event.preventDefault();
    // Transform values to shape which remote API will understand
    willGenerateCode({ userKey: customerEmail });
  }

  function handleChange(event) {
    const { value } = event.target;
    setCustomerEmail(value.trim());
  }

  // TODO(2021-07) change useOptionalMessage to useOptionalMessageOr for alternate MyDoc messages see ActivationCodeForm.js
  const customerEmailLabel = useOptionalMessage(idLabel);
  const placeholderLabel = useOptionalMessage(idPlaceholder);

  return (
    <GenerateCodeForm
      idLegalInfo={idLegalInfo}
      loading={loading}
      isDirty={customerEmail !== ""}
      onSubmit={handleSubmit}
    >
      <FormInput
        data-testid={`FormInput-${idLabel}`}
        label={customerEmailLabel}
        placeholder={placeholderLabel}
        name="customerEmail"
        type="email"
        spellCheck="false"
        autoCorrect="off"
        autoComplete="off"
        value={customerEmail}
        onChange={handleChange}
      />
    </GenerateCodeForm>
  );
}

EmailOnlyStep.displayName = displayName;

EmailOnlyStep.propTypes = {
  // TODO(2021-07) add idInfix for alternate MyDoc messages see InitialStep
  setActivationCodeDetails: PropTypes.func.isRequired,
  setErrorCode: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  partnerCode: PropTypes.string.isRequired,
};

export default EmailOnlyStep;
