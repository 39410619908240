import partnerEnabledLanguages from "utils/partnerEnabledLanguages";
import { getInstance } from "../partnerConfigs/singleton";
import messages, { overrideMessages as om } from "./messages";
import { generateSupportedLanguages as gm } from "./languages";

export const overrideMessages = om;
export const generateSupportedLanguages = gm;

// Both supported and enabled languages are generated automatically
// Supported languages are all languages with full set of translations ready (defined in messages file)
// Enabled languages are languages passed in partner-specific config (found in partnerConfigs directory)

export function extractDefaultLanguage(supportedLanguages, enabledLanguages) {
  if (enabledLanguages.length > 0) {
    return enabledLanguages[0];
  }
  return supportedLanguages[0];
}

export const supportedLanguages = generateSupportedLanguages(messages);
export const enabledLanguages = partnerEnabledLanguages(
  getInstance().config.languages,
  supportedLanguages
);
export const DEFAULT_LANGUAGE = extractDefaultLanguage(
  supportedLanguages,
  enabledLanguages
);

// produce language picker labels if not two letter language code in english.
// Uses values from the message translations files:
// system.languagePicker.label.zhHant
// system.languagePicker.label.zhHans
// etc...
export const languageLabels = supportedLanguages.reduce(
  function getLanguageLabels(map, language) {
    const labelKey = `system.languagePicker.label.${language}`.replace(
      /[-_]/g,
      ""
    );
    const message = (messages[language][labelKey] || "").trim().length
      ? messages[language][labelKey]
      : void 0;
    map[language] = message;
    return map;
  },
  {}
);
