import {
  DeliveryScooter,
  ManPointingAtApps,
  WomanUnderPalmTree,
  WomanTextingDoctor,
  ManWalkingWithPhone,
  ManSeekingMedicalAdvice,
  WomanWorkingInHomeOffice,
  ManWithBeardPointingAtApps,
  WomanAndManDiscussingSecurity,
  ChatWithRealDoctors,
} from "../Icons";

// Provide names for image slides so we can configure them
// in the partner translations override file in near future.
const namedImages = {
  // Bot slides (DHA, Emma, Allie, etc)
  WomanUnderPalmTree: <WomanUnderPalmTree />,
  ManSeekingMedicalAdvice: <ManSeekingMedicalAdvice />,
  ManPointingAtApps: <ManPointingAtApps />,
  // DocChat
  ChatWithRealDoctors: <ChatWithRealDoctors />,
  // IOC Beijing
  ManWalkingWithPhone: <ManWalkingWithPhone />,
  WomanTextingDoctor: <WomanTextingDoctor />,
  ManWithBeardPointingAtApps: <ManWithBeardPointingAtApps />,
  // Teleconsulttaion slides (MyDoc/HaloDoc)
  WomanWorkingInHomeOffice: <WomanWorkingInHomeOffice />,
  DeliveryScooter: <DeliveryScooter />,
  WomanAndManDiscussingSecurity: <WomanAndManDiscussingSecurity />,
};

export default namedImages;
