import { OptionalTitle } from "components/OptionalMessage";

// See src/partnerConfigs/ioc-bj/Logotype.js for notes on converting raw SVG to a Logotype.js file
function WomanUnderPalmTree() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 200"
      role="img"
      aria-labelledby="icon-woman-under-palm-tree-title"
    >
      <OptionalTitle
        id="image.womanUnderPalmTree"
        titleId="icon-woman-under-palm-tree-title"
      />
      <path
        fill="#d2ebec"
        d="M177.291 200h102.777s-7.817-13.749-19.423-9.658c-18.291 6.447-23.433-2.118-33.645-7.467-8.529-4.469-13.045 1.844-22.365 1.844-6.651-.026-9.787-9.267-25.174-9.681-1.992-.054-3.904.326-5.723 1.026L177.291 200z"
      />
      <path
        fill="#a6d7d9"
        d="M257.021 190.342c-18.292 6.447-23.435-2.118-33.648-7.467-8.531-4.469-13.043 1.844-22.364 1.844-6.65-.026-9.788-9.267-25.174-9.681 0 0-12.304-12.559-17.705-14.397-14.572-4.957-1.812 24.052-8.462 24.078-9.321 0-13.833-6.314-22.365-1.844-10.212 5.349-15.355 13.914-33.647 7.467C82.054 186.251 74.235 200 74.235 200h202.207c.001 0-7.818-13.749-19.421-9.658zM220.678 39.47c24.385-19.381 54.959-6.65 54.959-6.65-22.472-35.266-60.303-15.153-65.988 7.842 3.068-44.169-55.323-44.084-69.548-24.737 22.968-3.903 38.698 2.735 49.133 11.454-24.321-12.412-59.25 1.562-67.463 19.38 20.491-5.998 37.596-6.722 51.148-5.157-16.439 5.704-29.344 22.95-28.766 35.503 28.047-26.795 53.626-27.05 61.552-26.354 0 0 1.634 2.117 1.75 2.196 15.889 10.852 34.519 31.509 42.504 77.789 13.85-17.012 10.24-59.625-15.332-76.634 38.123 11.681 50.37 54.807 50.37 54.807 7.418-53.814-27.7-83.901-64.319-69.439z"
      />
      <path
        fill="#c9c3c3"
        d="M203.242 47.683c-39.949 45.023-55.349 103.441-42.591 152.285.047-.013 27.132.076 27.132 0-19.099-47.81-9.982-102.741 25.021-148.802-3.39-.962-6.196-2.511-9.562-3.483z"
      />
      <path
        fill="#3ca3a7"
        d="M217.148 39.186c24.85-23.737 58.488-9.73 58.488-9.73-21.022-32.991-55.484-17.518-64.499 3.44-.829-39.931-55.901-39.234-69.657-20.525 27.05-4.596 44.06 5.428 54.205 16.253-23.961-20.089-65.639-5.088-74.712 14.595 22.397-6.555 40.748-6.809 54.836-4.66-18.524 3.808-33.913 23.063-33.285 36.745 32.214-30.772 61.17-26.538 63.866-26.063 9.992 6.274 37.813 28.093 46.912 80.824 15.285-18.774 9.307-68.73-23.994-81.036 44.448 7.608 58.391 56.709 58.391 56.709 7.837-56.865-31.816-87.239-70.551-66.552z"
      />
      <path
        fill="#5d2310"
        d="M91.124 139.76s-4.789-12.237-.516-22.778c1.994-5.84 9.898-9.004 10.61-11.711 2.992-6.694-6.07-10.608 5.041-18.299 11.111-7.692 20.511-5.983 28.061.997 7.55 6.979 6.03 23.527 3.324 30.364-2.706 6.837-7.141 53.027-7.141 53.027l-39.379-31.6z"
      />
      <path fill="#846a9e" d="M94.138 135.907l8.423-7.076h6.541v6.182z" />
      <path
        fill="#f6aa98"
        d="M107.669 111.626c-.76-1.269-1.733-3.697-5.892.563-4.261 4.365-5.219 4.992-3.965 9.831 1.254 4.839 3.763 7.617 3.763 7.617h7.527s3.518-9.745-1.433-18.011z"
      />
      <path
        fill="#24333b"
        d="M105.163 125.701l-4.901-4.239a2.006 2.006 0 01-.205-2.821l8.797-10.173a2.006 2.006 0 012.821-.205l4.901 4.239c.832.72.924 1.989.205 2.821l-8.797 10.173a2.006 2.006 0 01-2.821.205z"
      />
      <path
        fill="#f6aa98"
        d="M122.217 128.748l13.372-6.076-4.157-11.388s.466-1.295.434-4.945c2.045-.685.656-8.373-1.792-7.158-1.103.543-1.752 2.677-1.752 2.677-2.002 3.272-7.668 2.478-12.273-.193-4.603-2.672-7.974-2.977-7.974-2.977s.021 21.199 12.892 24.301l1.25 5.759z"
      />
      <path
        fill="#ea8f81"
        d="M129.862 114.989c-4.028 7.559-7.465 8.158-8.892 8.007l.803 3.701c2.058-.899 7.02-3.849 8.089-11.708z"
      />
      <path
        fill="#5d2310"
        d="M112.421 109.229c-.374.129-.835-.213-1.019-.755-.177-.548-.024-1.096.359-1.213.372-.132.828.205 1.01.752.185.546.031 1.089-.35 1.216zm7.796-3.165c-.178-.547-.629-.892-1.004-.766-.378.119-.539.666-.367 1.214.181.543.633.889 1.007.767.383-.124.54-.66.364-1.215z"
      />
      <path
        fill="#ea8f81"
        d="M116.389 113.999l-1.22-7.268a.383.383 0 01.315-.443c.242-.061.406.102.443.317l1.222 7.268a.38.38 0 01-.316.437l-.005.004a.389.389 0 01-.439-.315zm5.87 1.443a.385.385 0 00-.278-.465.388.388 0 00-.471.276c-.032.131-.814 3.148-4.015 2.967-.214-.007-.4.157-.405.362a.382.382 0 00.362.409c1.516.085 2.591-.454 3.328-1.136a5.473 5.473 0 001.479-2.413z"
      />
      <path
        fill="#f6aa98"
        d="M124.649 162.461c-4.273-5.279-8.768-11.064-12.89-16.002l23.786-3.907-.8-21.98s-18.595 7.351-35.223 14.122c-.394-.151-.761-.239-1.09-.243-3.283-.037-6.452 1.971-9.097 4.426-1.371.569-2.689 1.119-3.936 1.642-5.641 2.371-5.097 6.212-4.038 8.596l-28.415 42.281s-12.869 2.468-17.806 3.35c-4.936.88-3.702 5.231-3.702 5.231H58.06c1.763 0 2.526-.788 3.349-2.057 9.839-18.451 22.839-15.676 33.85-34.026 7.934 24.683 22.038 36.083 34.203 36.083 12.165 0 20.451-6.466 20.451-6.466s-11.96-14.613-25.264-31.05z"
      />
      <path
        fill="#ea8f81"
        d="M93.169 155.88c-.688 7.519-7.409 16.798-13.54 23.829 5.295-3.563 10.658-7.538 15.618-15.795-1.533-4.855-2.078-8.034-2.078-8.034zM82.964 151.189c.659.329 1.615.478 2.964.363l28.05-2.401a307.346 307.346 0 00-2.218-2.691l-28.796 4.729z"
      />
      <path
        fill="#c99859"
        d="M120.984 157.825l-16.578 27.965s9.346 14.211 23.863 14.211c21.259 0 23.726-9.823 23.726-9.823l-18.076-16.393-12.935-15.96z"
      />
      <path
        fill="#b98447"
        d="M151.69 190.951l-.014-.006c-20.265 13.02-37.104-1.213-44.6-9.661l-2.671 4.505S113.751 200 128.268 200c17.609 0 22.321-6.734 23.422-9.049z"
      />
      <path
        fill="#846a9e"
        d="M156.286 147.85c-.714-19.545-21.596-27.627-21.596-27.627l.003.026-22.379 9.129 2.205 16.933 6.505-.81c2.022 9.726 5.99 20.109 5.99 20.109s3.546 10.076 24.663 25.335c3.258-7.442 5.323-23.551 4.609-43.095z"
      />
      <path
        fill="#765f8d"
        d="M131.371 144.212l-10.346 1.289c2.022 9.726 5.989 20.109 5.989 20.109s.168.475.644 1.373c-.138-6.09.304-15.701 3.713-22.771z"
      />
    </svg>
  );
}

export default WomanUnderPalmTree;
