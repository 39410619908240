import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useFocusRing } from "@react-aria/focus";
import { DESKTOP_MEDIA } from "@medi24-da2c/web-ui/media";
import { fontMedium, spacingSmall } from "@medi24-da2c/web-ui/design-tokens";

import { OptionalMessage } from "components/OptionalMessage";
import NextStepButton from "../NextStepButton";
import MessengerNameAffix from "./MessengerNameAffix";

const Icon = styled(NextStepButton.Icon)`
  @media ${DESKTOP_MEDIA} {
    display: none;
  }
`;

const StyledButton = styled(NextStepButton)`
  padding-left: ${spacingSmall};
  text-align: left;

  @media ${DESKTOP_MEDIA} {
    background-color: ${(themedProps) => themedProps.theme.button.bgrColor};
    color: ${(themedProps) => themedProps.theme.button.textColor};
    text-decoration: none;
    text-transform: ${(themedProps) => themedProps.theme.button.textTransform};
    text-align: center;
    letter-spacing: 0.1rem;
    border-radius: ${(themedProps) => themedProps.theme.button.borderRadius};
    padding: 0.5rem;
    height: 6rem;
    font-size: ${fontMedium};
    justify-content: center;
    max-width: 36rem;
    box-shadow: ${(themedProps) =>
      themedProps.isFocusVisible
        ? `0 0 0 0.5rem ${themedProps.theme.general.a11yColor}`
        : "none"};
  }
`;

const displayName = "MessengerButton";
const displayName2 = "MessengerMessageButton";

function Button({ children, ...props }) {
  const theme = useTheme();
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <StyledButton
      theme={theme}
      {...props}
      {...focusProps}
      isFocusVisible={isFocusVisible}
      customIcon={<Icon />}
    >
      {children}
    </StyledButton>
  );
}
Button.displayName = displayName;
Button.propTypes = {
  children: PropTypes.node,
};

export function MessageButton({ id, name, ...props }) {
  return (
    <Button {...props}>
      <OptionalMessage
        id={id}
        values={{
          messengerName: name,
          span: (nodes) => <MessengerNameAffix>{nodes}</MessengerNameAffix>,
        }}
      />
    </Button>
  );
}
MessageButton.displayName = displayName2;
MessageButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

export default Button;
