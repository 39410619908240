import { useIntl } from "react-intl";
import wh, { useSearchLinks as sl } from "./withHtml";

export const withHtml = wh;
export const useSearchLinks = sl;

const SUPPRESS_ID = " ";
const EMPTY = "";

function checkMessage(id, messages) {
  let hasMessage = messages[id] || EMPTY;
  hasMessage = hasMessage.trim().length;
  return !!hasMessage;
}

/**
  A react hook to use an optional internationalised message with bullet numbering.
  It differs from useOptionalMessage by returning the message and bulletNumber values object.

  @param {string} id The message identifier string.
  @param {string} defaultMessage Unused for compatability with useFormatMessage hook.
  @param {object} values Substitution values to replace within the translated string, marked by {marker}.  It also allows <elements> in the string to be handled by functions in the values object.
  @returns {[string, {bulletNumber: string}]} the message corresponding to the id, or the defaultMessage with substitutions from values along with an object containing the bulletNumber value for the message if found.
  @note Messages with bullet numbering is automatically handled. The id must end with .headline or .p[0-9]+
    When such a message is seen the message text can contain {bulletNumber} and in that case the additional message ending in .bulletNumber will be examined to get the number value or expect to be passed in from the values object.
*/
export function useBulletMessage(id, unused, values) {
  const { messages } = useIntl();

  // window.console.warn(`useBulletMessage ${id} [${messages[id]}] <${messages[id+".bulletNumber"]}>`, values)
  let bulletNumber = {};
  if (/\.(headline|p\d+)/.test(id)) {
    // only look up .bulletNumber message for headlines and numbered paragraphs
    const idBullet = `${id}.bulletNumber`;
    if (checkMessage(idBullet, messages)) {
      bulletNumber.bulletNumber = messages[idBullet].trim();
    }
  }
  if (values.bulletNumber) {
    // bulletNumber passed in from values takes priority.
    bulletNumber.bulletNumber = values.bulletNumber;
  }
  const message = useOptionalMessage(id, unused, {
    ...bulletNumber,
    ...values,
  });
  return [message, bulletNumber];
}

/**
  A react hook to use an optional internationalised message.
  It differs from useFormatMessage by returning an empty string instead of the
  message Id if there is no message associated with the Id.

  @param {string} id The message identifier string.
  @param {string} defaultMessage Unused for compatability with useFormatMessage hook.
  @param {object} values Substitution values to replace within the translated string, marked by {marker}.  It also allows <elements> in the string to be handled by functions in the values object.
  @param {string} idDefault The fallback message identifier string if id is missing or empty.
  @returns {string} the message corresponding to the id, or the defaultMessage with substitutions from values.
*/
export function useOptionalMessage(
  id,
  unused,
  values = {},
  idDefault = void 0
) {
  // defaultMessage as second parameter unused to make it easy to
  // replace useFormatMessage with useOptionalMessage without changing
  // the call signature at point of replacement.
  const { formatMessage, messages } = useIntl();

  // if (id === "chooseMessengerPage.generateActivationCodeModal.initialStep.generateNewCodeScenarioButton") {
  //   window.console.warn("OPTIONAL MSG", messages[id]);
  // }

  let message = EMPTY;
  let idUsed = id;
  let hasMessage = checkMessage(idUsed, messages);
  if (!hasMessage) {
    idUsed = idDefault;
    hasMessage = checkMessage(idUsed, messages);
  }
  if (hasMessage) {
    message = formatMessage(
      { id: idUsed, defaultMessage: SUPPRESS_ID },
      { ...withHtml, ...values }
    );
  }
  // if (id === "chooseMessengerPage.generateActivationCodeModal.initialStep.generateNewCodeScenarioButton") {
  //   window.console.warn("OPTIONAL MSG OUT", message);
  // }
  return message;
}

export function useOptionalMessageOr(id, idDefault, values) {
  return useOptionalMessage(id, void 0, values, idDefault);
}

/**
 * Check a list of possible message Id's and return only those ones
 * which have defined messages available.
 * @param  {[string]} idListPossibles List of possible message Id's to filter
 * @param {object} values Substitution values to replace within the translated string, marked by {marker}.  It also allows <elements> in the string to be handled by functions in the values object.
 * @returns {[string]} filtered list of message Id's which are not empty.
 */
export function useMessageList(idListPossibles, values) {
  const { messages } = useIntl();
  const result = idListPossibles.filter(function filterDefinedMessages(id) {
    return checkMessage(id, messages);
  });
  return result;
}

/**
 * Check a list of possible message Id's and return only those ones
 * which have defined messages available.
 * This returns a map of the message itself keyed on the message Id
 * @param  {[string]} idListPossibles List of possible message Id's to filter
 * @param {object} values Substitution values to replace within the translated string, marked by {marker}.  It also allows <elements> in the string to be handled by functions in the values object.
 * @return {object} filtered object map of message id to message text for valid messages.
 */
export function useMessageListLookup(idListPossibles = [], values = {}) {
  const { formatMessage, messages } = useIntl();
  return idListPossibles
    .map(function mapToMessage(id) {
      const hasMessage = checkMessage(id, messages);
      const message = hasMessage
        ? formatMessage(
            { id, defaultMessage: SUPPRESS_ID },
            { ...withHtml, ...values }
          )
        : void 0;
      return { id, message };
    })
    .reduce(function accumulateMessages(state, message) {
      if (message.message !== void 0) {
        state[message.id] = message.message;
      }
      return state;
    }, {});
}
