import styled from "@emotion/styled";

import { spacingMedium } from "@medi24-da2c/web-ui/design-tokens";

const Content = styled.section`
  padding: ${spacingMedium};
  padding-top: 0;
`;

export default Content;
