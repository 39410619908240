import PropTypes from "prop-types";

import StyledButton from "./StyledButton";
import ArrowIcon from "./ArrowIcon";

const displayName = "NextStepButton";

function NextStepButton({ children, customIcon, ...props }) {
  return (
    <StyledButton {...props}>
      {children}
      {customIcon ? customIcon : <ArrowIcon />}
    </StyledButton>
  );
}
NextStepButton.displayName = displayName;
NextStepButton.propTypes = {
  ...StyledButton.propTypes,
  children: PropTypes.node.isRequired,
  customIcon: PropTypes.element,
};

NextStepButton.Icon = ArrowIcon;

export default NextStepButton;
